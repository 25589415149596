import type { OrderBookDetail } from 'zklighter-perps'

export const formatUSD = (value: number | string, showCurrency = true) =>
  Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...(showCurrency && { style: 'currency', currency: 'USD' }),
  })

export const formatUSDFixed = (value: number | string, decimals = 2) =>
  floorNumber(Number(value), decimals).toFixed(decimals)

export const formatLeverage = (value: number, decimals = 0) =>
  `${value.toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })}x`

export const formatMarketPrice = (
  value: number | string,
  market: OrderBookDetail,
  showCurrency = true,
) =>
  Number(value).toLocaleString('en-US', {
    minimumFractionDigits: market.price_decimals,
    maximumFractionDigits: market.price_decimals,
    ...(showCurrency && { style: 'currency', currency: 'USD' }),
  })

export const formatMarketSize = (value: number | string, market: OrderBookDetail) =>
  Number(value).toLocaleString('en-US', {
    minimumFractionDigits: market.size_decimals,
    maximumFractionDigits: market.size_decimals,
  })

export const formatOpenInterest = (value: number | string, showCurrency = true) =>
  Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...(showCurrency && { style: 'currency', currency: 'USD' }),
  })

export const floorNumber = (num: number, decimal = 2) => {
  return Math.floor(num * 10 ** decimal) / 10 ** decimal
}
