import { useTranslation } from 'react-i18next'

import Input from 'js/shared-components/uikit/Input'

import { useOrderInputStore } from '../PlaceOrder'

export const RunningTime = () => {
  const { t } = useTranslation()
  const { runtimeMinutes, runtimeHours, changeRuntimeMinutes, changeRuntimeHours } =
    useOrderInputStore()

  return (
    <div className="mt-2 flex flex-col gap-2">
      <p className="typography-body-1 text-white">{t('running_time_input_label')}</p>
      <div className="flex gap-2">
        <div className="flex w-full items-center gap-4 rounded-lg border bg-white-transparent px-4 py-2">
          <Input
            placeholder="0"
            inputMode="decimal"
            value={runtimeHours}
            onChange={(e) => changeRuntimeHours(e.target.value)}
            className="mt-1 h-[unset] w-full overflow-hidden rounded-none border-none indent-0 text-sm placeholder:indent-0 placeholder:text-sm max-mobile:text-base max-mobile:placeholder:text-base"
          />
          <p className="typography-body-2 text-white-opaque">{t('hours')}</p>
        </div>
        <div className="flex w-full items-center gap-4 rounded-lg border bg-white-transparent px-4 py-2">
          <Input
            inputMode="decimal"
            value={runtimeMinutes}
            onChange={(e) => changeRuntimeMinutes(e.target.value)}
            className="mt-1 h-[unset] w-full overflow-hidden rounded-none border-none indent-0 text-sm placeholder:indent-0 placeholder:text-sm max-mobile:text-base max-mobile:placeholder:text-base"
          />
          <p className="typography-body-2 text-white-opaque">{t('minutes')}</p>
        </div>
      </div>
    </div>
  )
}
