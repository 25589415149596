import { type IconType } from 'js/shared-components/uikit/Icon'

export const LIGHTER_CHAIN_ID = 300

// known symbols, maybe use this for better types?
export type CoinSymbol =
  | 'BTC'
  | 'DOGE'
  | 'SOL'
  | 'ETH'
  | 'kPEPE'
  | 'AVAX'
  | 'NEAR'
  | 'WLD'
  | 'TAO'
  | 'LINK'
  | 'XRP'
  | 'DOT'
  | 'POL'
  | 'TON'
  | 'WIF'
  | 'TRUMP'
  | 'SUI'
  | 'kSHIB'
  | 'kBONK'
  | 'kFLOKI'
  | 'BERA'
  | 'FARTCOIN'
  | 'AI16Z'
  | 'POPCAT'

export const TOKEN_LOGOS = {
  BTC: 'btc',
  ETH: 'eth',
  AVAX: 'avax',
  SOL: 'sol',
  DOGE: 'doge',
  LINK: 'link',
  POL: 'matic',
  kPEPE: 'pepe',
  XRP: 'xrp',
  WIF: 'wif',
  WLD: 'wld',
  NEAR: 'near',
  DOT: 'dot',
  TON: 'ton',
  TAO: 'tao',
  TRUMP: 'trump',
  SUI: 'sui',
  kSHIB: 'shib',
  kBONK: 'bonk',
  kFLOKI: 'floki',
  BERA: 'bera',
  FARTCOIN: 'fartcoin',
  AI16Z: 'ai16z',
  POPCAT: 'popcat',
} as const satisfies Record<CoinSymbol, IconType>

export const TOKEN_DESCRIPTION_KEYS = {
  BTC: 'token_description_btc',
  ETH: 'token_description_eth',
  AVAX: 'token_description_avax',
  SOL: 'token_description_sol',
  DOGE: 'token_description_doge',
  LINK: 'token_description_link',
  POL: 'token_description_pol',
  kPEPE: 'token_description_pepe',
  XRP: 'token_description_xrp',
  WIF: 'token_description_wif',
  WLD: 'token_description_wld',
  NEAR: 'token_description_near',
  DOT: 'token_description_dot',
  TON: 'token_description_ton',
  TAO: 'token_description_tao',
  TRUMP: 'token_description_trump',
  SUI: 'token_description_sui',
  kSHIB: 'token_description_shib',
  kBONK: 'token_description_bonk',
  kFLOKI: 'token_description_floki',
  BERA: 'token_description_bera',
  FARTCOIN: 'token_description_fartcoin',
  AI16Z: 'token_description_ai16z',
  POPCAT: 'token_description_popcat',
} as const satisfies Record<CoinSymbol, string>

export const TOKEN_NAMES = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  AVAX: 'Avalanche',
  SOL: 'Solana',
  DOGE: 'Dogecoin',
  LINK: 'Chainlink',
  POL: 'POL',
  kPEPE: 'Pepe',
  XRP: 'XRP',
  WIF: 'dogwifhat',
  WLD: 'Worldcoin',
  NEAR: 'NEAR Protocol',
  DOT: 'Polkadot',
  TON: 'Toncoin',
  TAO: 'Bittensor',
  TRUMP: 'Official Trump',
  SUI: 'SUI',
  kSHIB: 'SHIB',
  kBONK: 'BONK',
  kFLOKI: 'FLOKI',
  BERA: 'Berachain',
  FARTCOIN: 'Fartcoin',
  AI16Z: 'ai16z',
  POPCAT: 'Popcat',
} as const satisfies Record<CoinSymbol, string>

export enum TX_STATUSES {
  StatusFailed = 0,
  StatusPending = 1,
  StatusExecuted = 2,
  StatusPacked = 3,
  StatusCommitted = 4,
  StatusVerified = 5,
}

export enum OrderDirections {
  Short, // Sell / Ask
  Long, // Buy / Bid
}

export const USDC_SYMBOL = 'USD'
