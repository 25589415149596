import { cloneDeep, partition, pickBy, unionBy, uniqBy } from 'lodash-es'
import { OrderStatusEnum, OrderTypeEnum, TradeTypeEnum } from 'zklighter-perps'

import { isMarketIdAllowed } from 'js/util/util'

import { useLighterStore } from '../lighterStore'
import {
  type Liquidation,
  type Trade,
  type AccountStoreData,
  type Share,
  type FundingHistoryItem,
  type Tx,
} from '../types'

import {
  type WsSubscribedMarketStatsMessage,
  type WsSubscribedOrderBookMessage,
  type WsSubscribedTradeMessage,
  type WsSubscribedAccountMessage,
  type WsUpdateMarketStatsMessage,
  type WsUpdateOrderBookMessage,
  type WsUpdateTradeMessage,
  type WsOrderbook,
  type WsUpdateAccountMessage,
  type WsSubscribedUserStatsMessage,
  type WsUpdateUserStatsMessage,
  type WsTrade,
  type WsMarketStats,
  type WsOrder,
  type WsPosition,
  type WsUpdateExecutedTransactionMessage,
  type WsSubscribedPoolInfoMessage,
  type WsUpdatePoolInfoMessage,
  type WsSubscribedHeightMessage,
  type WsUpdateHeightMessage,
  type WsSubscribedPoolDataMessage,
  type WsUpdatePoolDataMessage,
  type WsSubscribedAccountOrderMessage,
  type WsUpdateAccountOrderMessage,
  type WsMessage,
  type WsPoolInfo,
} from './types'

import { useWsSubStore } from '.'

const checkMarketIndexFromChannel = (channel: string, currentId: number) =>
  channel.split(':')[1] !== 'all' && currentId !== Number(channel.split(':')[1])

const getAccountIndexFromChannel = (channel: string) => {
  const accountIndex = Number(channel.split(':')[1])

  return Number.isNaN(accountIndex) ? -1 : accountIndex
}

const filterUnavailableMarkets = <T>(entity: Record<string, T>) =>
  pickBy(entity, (_, key) => isMarketIdAllowed(Number(key)))

type AccountTxCallback = (tx: Tx) => void

const accountTxCallbacks: AccountTxCallback[] = []

export const subscribeToAccountTx = (callback: AccountTxCallback) =>
  accountTxCallbacks.push(callback)
export const unsubscribeToAccountTx = (callback: AccountTxCallback) =>
  accountTxCallbacks.filter((cb) => cb !== callback)

export const handleWsMessage = (data: WsMessage) => {
  switch (data.type) {
    case 'ping':
      return handlePing()
    case 'subscribed/order_book':
      return handleSubscribedOrderBook(data)
    case 'update/order_book':
      return handleUpdateOrderBook(data)
    case 'subscribed/trade':
      return handleSubscribedTrade(data)
    case 'update/trade':
      return handleUpdateTrade(data)
    case 'subscribed/market_stats':
      return handleSubscribedMarketStats(data)
    case 'update/market_stats':
      return handleUpdateMarketStats(data)
    case 'subscribed/account_all':
      return handleSubscribedAccount(data)
    case 'update/account_all':
      return handleUpdateAccount(data)
    case 'subscribed/account_orders':
      return handleSubscribedAccountOrders(data)
    case 'update/account_orders':
      return handleUpdateAccountOrders(data)
    case 'subscribed/pool_data':
      return handleSubscribedPoolData(data)
    case 'update/pool_data':
      return handleUpdatePoolData(data)
    case 'subscribed/user_stats':
      return handleSubscribedUserStats(data)
    case 'update/user_stats':
      return handleUpdateUserStats(data)
    case 'update/account_tx':
      return handleUpdateAccountTx(data)
    case 'subscribed/pool_info':
      return handleSubscribedPoolInfo(data)
    case 'update/pool_info':
      return handleUpdatePoolInfo(data)
    case 'subscribed/height':
      return handleSubscribedHeight(data)
    case 'update/height':
      return handleUpdatedHeight(data)
    default:
      return
  }
}

class WsReducer {
  orderBook: WsOrderbook
  trades: Trade[]
  marketsStats: Record<string, WsMarketStats>
  poolInfo: Record<string, WsPoolInfo>
  accounts: Record<number, AccountStoreData>

  bufferedOrderMessages: Record<number, Array<Record<string, WsOrder[]>>>
  currentMarketId: number

  constructor() {
    this.orderBook = { asks: [], bids: [] }
    this.trades = []
    this.marketsStats = {}
    this.accounts = {}
    this.poolInfo = {}
    this.bufferedOrderMessages = {}
    this.currentMarketId = -1
  }

  reduceOrderbook(newOrderbook: WsOrderbook, clearPreviousBook: boolean) {
    if (clearPreviousBook) {
      this.orderBook = { asks: [], bids: [] }
    }

    this.orderBook = {
      asks: unionBy(newOrderbook.asks, this.orderBook.asks, 'price')
        .filter((ask) => Number(ask.size) !== 0)
        .sort((a, b) => Number(a.price) - Number(b.price)),
      bids: unionBy(newOrderbook.bids, this.orderBook.bids, 'price')
        .filter((bid) => Number(bid.size) !== 0)
        .sort((a, b) => Number(b.price) - Number(a.price)),
    }
  }

  reduceTrades(newTrades: WsTrade[]) {
    this.trades = newTrades.filter(
      (trade) => trade.type === TradeTypeEnum.Trade || trade.type === TradeTypeEnum.Liquidation,
    )
  }

  reduceUpdateTrades(newTrades: WsTrade[]) {
    // we only show 30 in TradesList.tsx, but because we do some more filtering
    // there, we'll keep more than enough here so there's always at least 30 there
    // it's necessary to prune some trades here to prevent memory leak
    this.trades = [
      ...newTrades.filter(
        (trade) => trade.type === TradeTypeEnum.Trade || trade.type === TradeTypeEnum.Liquidation,
      ),
      ...this.trades,
    ].slice(0, 100)
  }

  reduceMarketStats(newMarketsStats: Record<string, WsMarketStats>) {
    this.marketsStats = { ...this.marketsStats, ...newMarketsStats }
  }

  reducePoolInfo(accountIndex: number, newPoolInfo: WsPoolInfo) {
    this.poolInfo = { ...this.poolInfo, [accountIndex]: newPoolInfo }
  }

  reduceAccount(
    accountIndex: number,
    activeOrders: Record<string, WsOrder[]>,
    trades: Record<string, WsTrade[]>,
    positions: Record<string, WsPosition>,
    liquidations: Liquidation[],
    shares: Share[],
    fundingHistory: Record<string, FundingHistoryItem[]>,
    totalTradesCount: number,
    totalVolume: number,
  ) {
    this.accounts[accountIndex] = {
      trades,
      activeOrders,
      inactiveOrders: {},
      positions,
      liquidations,
      shares: shares.filter((share) => share.shares_amount !== 0),
      fundingHistory,
      totalTradesCount,
      totalVolume,
    }

    this.bufferedOrderMessages[accountIndex]?.forEach((orders) =>
      this.reduceUpdateAccountOrders(accountIndex, orders),
    )
    delete this.bufferedOrderMessages[accountIndex]
  }

  reduceUpdateAccountOrders(accountIndex: number, orders: Record<string, WsOrder[]>) {
    if (!this.accounts[accountIndex]) {
      if (!this.bufferedOrderMessages[accountIndex]) {
        this.bufferedOrderMessages[accountIndex] = []
      }

      return this.bufferedOrderMessages[accountIndex]!.push(orders)
    }

    const updatedActiveOrders = { ...this.accounts[accountIndex]!.activeOrders }
    const updatedInactiveOrders = { ...this.accounts[accountIndex]!.inactiveOrders }

    Object.keys(orders).forEach((marketId) => {
      const oldMarketActiveOrders = updatedActiveOrders[marketId] ?? []
      const oldMarketInactiveOrders = updatedInactiveOrders[marketId] ?? []
      const newMarketOrders = orders[marketId] ?? []

      const [newMarketActiveOrders, newMarketInactiveOrders] = partition(
        newMarketOrders,
        (order) => {
          if (Number(order.remaining_base_amount) === 0) {
            return false
          }

          if (order.status === OrderStatusEnum.Open) {
            return true
          }

          if (
            (order.type === OrderTypeEnum.StopLoss ||
              order.type === OrderTypeEnum.TakeProfit ||
              order.type === OrderTypeEnum.StopLossLimit ||
              order.type === OrderTypeEnum.TakeProfitLimit ||
              order.type === OrderTypeEnum.Twap ||
              order.type === OrderTypeEnum.TwapSub) &&
            order.status === OrderStatusEnum.Pending
          ) {
            return true
          }

          return false
        },
      )
      const inactiveIDs = new Set(newMarketInactiveOrders.map((order) => order.order_index))

      updatedActiveOrders[marketId] = uniqBy(
        [
          // order of spread matters here: uniqBy keeps first occurence and we want to keep the new ones
          ...newMarketActiveOrders,
          // an order can only go from active -> inactive so this filter is only needed here
          ...oldMarketActiveOrders.filter((order) => !inactiveIDs.has(order.order_index)),
        ],
        'order_index',
      ).slice(0, 300)
      updatedInactiveOrders[marketId] = uniqBy(
        // order of spread matters here: uniqBy keeps first occurence and we want to keep the new ones
        [...newMarketInactiveOrders, ...oldMarketInactiveOrders],
        'order_index',
      ).slice(0, 200)
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      activeOrders: updatedActiveOrders,
      inactiveOrders: updatedInactiveOrders,
    }
  }

  reduceUpdateAccountTrades(accountIndex: number, trades: Record<string, WsTrade[]>) {
    const updatedTrades = { ...this.accounts[accountIndex]!.trades }
    let updatedTradesCount = this.accounts[accountIndex]!.totalTradesCount
    let updatedTotalVolume = this.accounts[accountIndex]!.totalVolume

    Object.keys(trades).forEach((marketId) => {
      const oldTrades = updatedTrades[marketId] ?? []
      const newTrades = trades[marketId] ?? []
      updatedTrades[marketId] = [...newTrades, ...oldTrades].slice(0, 200)
      updatedTradesCount += newTrades.length
      updatedTotalVolume += newTrades.reduce((acc, trade) => acc + Number(trade.usd_amount), 0)
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      trades: updatedTrades,
      totalTradesCount: updatedTradesCount,
      totalVolume: updatedTotalVolume,
    }
  }

  reduceUpdateAccountPositions(accountIndex: number, positions: Record<string, WsPosition>) {
    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      positions: { ...this.accounts[accountIndex]!.positions, ...positions },
    }
  }

  reduceUpdateAccountLiquidations(accountIndex: number, liquidations: Liquidation[]) {
    const updatedLiquidations = [...this.accounts[accountIndex]!.liquidations]

    liquidations.forEach((liquidation) => {
      const existentLiquidation = updatedLiquidations.find(
        ({ liquidation_id }) => liquidation_id === liquidation.liquidation_id,
      )

      if (!existentLiquidation) {
        return updatedLiquidations.push(liquidation)
      }

      // TODO: check if this case even makes sense
      existentLiquidation.liquidation_type = liquidation.liquidation_type
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      liquidations: updatedLiquidations,
    }
  }

  reduceUpdateAccountShares(accountIndex: number, shares: Share[]) {
    const updatedShares = [...this.accounts[accountIndex]!.shares]

    shares.forEach((share) => {
      const existentShare = updatedShares.find(
        ({ public_pool_index }) => public_pool_index === share.public_pool_index,
      )
      if (!existentShare) {
        return updatedShares.push(share)
      }

      existentShare.shares_amount = share.shares_amount
      existentShare.entry_usdc = share.entry_usdc
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      shares: updatedShares.filter((share) => share.shares_amount !== 0),
    }
  }

  reduceUpdateAccountFundingHistory(
    accountIndex: number,
    fundingHistory: Record<string, FundingHistoryItem[]>,
  ) {
    const updatedFundingHistory = { ...this.accounts[accountIndex]!.fundingHistory }

    Object.keys(fundingHistory).forEach((marketId) => {
      const oldFundingHistory = updatedFundingHistory[marketId] ?? []
      const newFundingHistory = fundingHistory[marketId] ?? []
      updatedFundingHistory[marketId] = [...newFundingHistory, ...oldFundingHistory]
    })

    this.accounts[accountIndex] = {
      ...this.accounts[accountIndex]!,
      fundingHistory: updatedFundingHistory,
    }
  }
}

const handlePing = () => useWsSubStore.getState().ws?.send(JSON.stringify({ type: 'pong' }))

export const wsReducer = new WsReducer()
let throttledDataIntervalId: NodeJS.Timeout | null = null

const publishThrottledData = () =>
  useLighterStore.setState({
    trades: wsReducer.trades,
    // orderBook/accounts are mutated quite a bit
    // zustand if we don't pass a new object, reselect
    // will detect the changes sometimes even when not published
    orderBook: cloneDeep(wsReducer.orderBook),
    accounts: cloneDeep(wsReducer.accounts),
    marketsStats: wsReducer.marketsStats,
    poolInfo: wsReducer.poolInfo,
  })

const handleSubscribedOrderBook = (data: WsSubscribedOrderBookMessage) => {
  if (checkMarketIndexFromChannel(data.channel, wsReducer.currentMarketId)) {
    return
  }

  wsReducer.reduceOrderbook(data.order_book, true)
  useLighterStore.setState({ orderBook: cloneDeep(wsReducer.orderBook) })

  if (!throttledDataIntervalId) {
    throttledDataIntervalId = setInterval(publishThrottledData, 500)
  }
}

const handleUpdateOrderBook = (data: WsUpdateOrderBookMessage) => {
  if (checkMarketIndexFromChannel(data.channel, wsReducer.currentMarketId)) {
    return
  }

  wsReducer.reduceOrderbook(data.order_book, false)
}

const handleSubscribedTrade = (data: WsSubscribedTradeMessage) => {
  if (checkMarketIndexFromChannel(data.channel, wsReducer.currentMarketId)) {
    return
  }

  wsReducer.reduceTrades(data.trades.filter((trade) => isMarketIdAllowed(trade.market_id)))

  useLighterStore.setState({ trades: wsReducer.trades })

  if (!throttledDataIntervalId) {
    throttledDataIntervalId = setInterval(publishThrottledData, 500)
  }
}

const handleUpdateTrade = (data: WsUpdateTradeMessage) => {
  if (checkMarketIndexFromChannel(data.channel, wsReducer.currentMarketId)) {
    return
  }

  wsReducer.reduceUpdateTrades(data.trades.filter((trade) => isMarketIdAllowed(trade.market_id)))
}

const handleSubscribedMarketStats = (data: WsSubscribedMarketStatsMessage) => {
  wsReducer.reduceMarketStats(filterUnavailableMarkets(data.market_stats))

  useLighterStore.setState({ marketsStats: wsReducer.marketsStats })

  if (!throttledDataIntervalId) {
    throttledDataIntervalId = setInterval(publishThrottledData, 500)
  }
}

const handleUpdateMarketStats = (data: WsUpdateMarketStatsMessage) =>
  wsReducer.reduceMarketStats(filterUnavailableMarkets(data.market_stats))

const handleSubscribedAccount = (data: WsSubscribedAccountMessage) => {
  wsReducer.reduceAccount(
    getAccountIndexFromChannel(data.channel),
    {},
    filterUnavailableMarkets(data.trades),
    filterUnavailableMarkets(data.positions),
    data.liquidations,
    data.shares,
    filterUnavailableMarkets(data.funding_histories),
    data.total_trades_count,
    data.total_volume,
  )

  useLighterStore.setState({ accounts: wsReducer.accounts })
}

const handleUpdateAccount = (data: WsUpdateAccountMessage) => {
  const accountIndex = getAccountIndexFromChannel(data.channel)

  if (!wsReducer.accounts[accountIndex]) {
    return
  }

  wsReducer.reduceUpdateAccountTrades(accountIndex, filterUnavailableMarkets(data.trades))
  wsReducer.reduceUpdateAccountPositions(accountIndex, filterUnavailableMarkets(data.positions))
  wsReducer.reduceUpdateAccountLiquidations(accountIndex, data.liquidations)
  wsReducer.reduceUpdateAccountShares(accountIndex, data.shares)
  wsReducer.reduceUpdateAccountFundingHistory(
    accountIndex,
    filterUnavailableMarkets(data.funding_histories),
  )

  useLighterStore.setState({ accounts: wsReducer.accounts })
}

const handleSubscribedAccountOrders = (data: WsSubscribedAccountOrderMessage) => {
  wsReducer.reduceUpdateAccountOrders(data.account, filterUnavailableMarkets(data.orders))

  useLighterStore.setState({ accounts: wsReducer.accounts })
}

const handleUpdateAccountOrders = (data: WsUpdateAccountOrderMessage) => {
  wsReducer.reduceUpdateAccountOrders(data.account, filterUnavailableMarkets(data.orders))

  useLighterStore.setState({ accounts: wsReducer.accounts })
}

const handleSubscribedPoolData = (data: WsSubscribedPoolDataMessage) => {
  wsReducer.reduceAccount(
    getAccountIndexFromChannel(data.channel),
    filterUnavailableMarkets(data.orders),
    filterUnavailableMarkets(data.trades),
    filterUnavailableMarkets(data.positions),
    data.liquidations,
    data.shares,
    filterUnavailableMarkets(data.funding_histories),
    0,
    0,
  )

  useLighterStore.setState({ accounts: wsReducer.accounts })

  if (!throttledDataIntervalId) {
    throttledDataIntervalId = setInterval(publishThrottledData, 500)
  }
}

const handleUpdatePoolData = (data: WsUpdatePoolDataMessage) => {
  const accountIndex = getAccountIndexFromChannel(data.channel)

  wsReducer.reduceUpdateAccountOrders(accountIndex, filterUnavailableMarkets(data.orders))
  wsReducer.reduceUpdateAccountTrades(accountIndex, filterUnavailableMarkets(data.trades))
  wsReducer.reduceUpdateAccountPositions(accountIndex, filterUnavailableMarkets(data.positions))
  wsReducer.reduceUpdateAccountLiquidations(accountIndex, data.liquidations)
  wsReducer.reduceUpdateAccountShares(accountIndex, data.shares)
  wsReducer.reduceUpdateAccountFundingHistory(
    accountIndex,
    filterUnavailableMarkets(data.funding_histories),
  )
}

const handleSubscribedUserStats = (data: WsSubscribedUserStatsMessage) =>
  useLighterStore.setState((prev) => ({
    ...prev,
    portfolioStats: {
      ...prev.portfolioStats,
      [getAccountIndexFromChannel(data.channel)]: data.stats,
    },
  }))

const handleUpdateUserStats = (data: WsUpdateUserStatsMessage) =>
  useLighterStore.setState((prev) => ({
    ...prev,
    portfolioStats: {
      ...prev.portfolioStats,
      [getAccountIndexFromChannel(data.channel)]: data.stats,
    },
  }))

const handleUpdateAccountTx = (data: WsUpdateExecutedTransactionMessage) =>
  data.txs.forEach((tx) => accountTxCallbacks.forEach((cb) => cb(tx)))

const handleSubscribedPoolInfo = (data: WsSubscribedPoolInfoMessage) => {
  wsReducer.reducePoolInfo(getAccountIndexFromChannel(data.channel), data.pool_info)

  useLighterStore.setState({ poolInfo: wsReducer.poolInfo })

  if (!throttledDataIntervalId) {
    throttledDataIntervalId = setInterval(publishThrottledData, 500)
  }
}

const handleUpdatePoolInfo = (data: WsUpdatePoolInfoMessage) =>
  wsReducer.reducePoolInfo(getAccountIndexFromChannel(data.channel), data.pool_info)

const handleSubscribedHeight = (data: WsSubscribedHeightMessage) =>
  useLighterStore.setState({ height: data.height })

const handleUpdatedHeight = (data: WsUpdateHeightMessage) =>
  useLighterStore.setState({ height: data.height })

export const handleChangeDesiredMarket = (marketIndex: number) => {
  wsReducer.currentMarketId = marketIndex
  wsReducer.trades = []
  wsReducer.orderBook = { asks: [], bids: [] }

  useLighterStore.setState({ orderBook: null, trades: null })
}
