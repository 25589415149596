import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { useTranslation } from 'react-i18next'
import { OrderTypeEnum } from 'zklighter-perps'

import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { DropdownMenuContent, DropdownMenuItem } from 'js/shared-components/uikit/DropdownMenu'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'

const stopButtonTypes = [
  { labelKey: 'stop_limit', value: OrderTypeEnum.StopLossLimit, shortLabelKey: 'sl_limit' },
  { labelKey: 'stop_market', value: OrderTypeEnum.StopLoss, shortLabelKey: 'sl_market' },
  {
    labelKey: 'take_profit_limit',
    value: OrderTypeEnum.TakeProfitLimit,
    shortLabelKey: 'tp_limit',
  },
  { labelKey: 'take_profit_market', value: OrderTypeEnum.TakeProfit, shortLabelKey: 'tp_market' },
  { labelKey: 'twap', value: OrderTypeEnum.Twap, shortLabelKey: 'twap' },
] as const

export const OrderTypeSelector = ({
  orderType,
  setOrderTypeFromButton,
}: {
  orderType: OrderTypeEnum
  setOrderTypeFromButton: (orderType: OrderTypeEnum) => void
}) => {
  const { t } = useTranslation()

  return (
    <TabBar>
      <TabButton
        isSelected={orderType === OrderTypeEnum.Market}
        onClick={() => setOrderTypeFromButton(OrderTypeEnum.Market)}
      >
        {t('market')}
      </TabButton>
      <TabButton
        isSelected={orderType === OrderTypeEnum.Limit}
        onClick={() => setOrderTypeFromButton(OrderTypeEnum.Limit)}
      >
        {t('limit')}
      </TabButton>
      <RadixDropdownMenu.DropdownMenu>
        <TabButton isSelected={!!stopButtonTypes.find((button) => button.value === orderType)}>
          <RadixDropdownMenu.Trigger className="group" asChild>
            <div className="flex items-center justify-center gap-2">
              {t(
                stopButtonTypes.find((button) => button.value === orderType)?.shortLabelKey ??
                  'advanced',
              )}
              <Icon
                icon="chevron"
                className="mt-[2px] size-2.5 transition-transform group-data-[state=open]:rotate-180"
              />
            </div>
          </RadixDropdownMenu.Trigger>
        </TabButton>
        <DropdownMenuContent>
          {stopButtonTypes.map((button) => (
            <DropdownMenuItem
              className={cn({
                'bg-semi-transparent text-white': orderType === button.value,
                'bg-transparent text-white-opaque': orderType !== button.value,
              })}
              key={button.value}
              onClick={() => setOrderTypeFromButton(button.value)}
            >
              {t(button.labelKey)}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </RadixDropdownMenu.DropdownMenu>
      <SelectorIndicator
        numButtons={3}
        selectedElemIdx={
          orderType === OrderTypeEnum.Market ? 0 : orderType === OrderTypeEnum.Limit ? 1 : 2
        }
      />
    </TabBar>
  )
}
