import { Trans, useTranslation } from 'react-i18next'

import { useIsGeoLocBlocked, useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useHealthQuery } from 'js/util/queries'

export const WarningBanner = () => {
  const { t } = useTranslation()
  const userAddress = useUserAddress()
  const isGeoLocBlocked = useIsGeoLocBlocked()
  const healthQuery = useHealthQuery()

  if (!healthQuery.data!.online) {
    return (
      <div className="flex w-full items-center justify-center bg-red-500 px-6 py-2 text-white">
        <p className="typography-body-2 text-white">{t('offline_title')}</p>
      </div>
    )
  }

  if (!isGeoLocBlocked) {
    return null
  }

  return (
    <div className="flex w-full items-center justify-center bg-red-500 px-6 py-2 text-white">
      <p className="typography-body-2 text-white">
        {userAddress ? (
          <Trans
            i18nKey="geo_banner_user_address"
            components={{
              ToSLink: (
                <a
                  className="underline"
                  href="https://lighter.xyz/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        ) : (
          <Trans
            i18nKey={'geo_banner_user_address'}
            components={{
              ToSLink: (
                <a
                  className="underline"
                  href="https://lighter.xyz/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              TestnetLink: (
                <a
                  className="underline"
                  href="https://testnet.app.lighter.xyz"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        )}
      </p>
    </div>
  )
}
