import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { OrderBookDetail } from 'zklighter-perps'

import { orderApi } from 'js/util/api/sdk'
import { isMarketIdAllowed } from 'js/util/util'

import { type OrderbookItem } from '../types'

import { useTradePageParams } from './useTradePageParams'

const SYMBOL_MAPPING: { [key: string]: string } = {
  '1000PEPE': 'kPEPE',
  '1000SHIB': 'kSHIB',
  '1000BONK': 'kBONK',
  '1000FLOKI': 'kFLOKI',
}

export const useOrderBookMetasQuery = () =>
  useQuery({
    queryKey: ['orderBookDetails'],
    queryFn: () =>
      orderApi
        .orderBookDetails()
        .then(
          ({ order_book_details }) =>
            Object.fromEntries(
              order_book_details
                .filter((detail) => isMarketIdAllowed(detail.market_id))
                .map((detail) => [
                  detail.market_id,
                  { ...detail, symbol: SYMBOL_MAPPING[detail.symbol] ?? detail.symbol },
                ]),
            ) as Record<string, OrderBookDetail>,
        ),
  })

// loading is being handled at the top level
export const useOrderBookMetas = () => useOrderBookMetasQuery().data!

export const getMarketOrderFromAmount0 = (
  market: OrderBookDetail,
  amount0: string,
  orders: OrderbookItem[],
) => {
  if (Number(amount0) === 0) {
    return { fullyFilled: true, estPrice: '0.00', amount0, amount1: '' }
  }

  let amount0Left = Number(amount0)
  let matchedAmount0 = 0
  let matchedAmount1 = 0

  for (const order of orders) {
    const price = Number(order.price)
    const size = Number(order.size)
    const matchedSize = Math.min(amount0Left, size)

    matchedAmount0 = matchedAmount0 + matchedSize
    matchedAmount1 = matchedAmount1 + matchedSize * price
    amount0Left = amount0Left - matchedSize

    if (amount0Left === 0) {
      break
    }
  }

  if (matchedAmount0 === 0) {
    return { fullyFilled: false, estPrice: '0.00', amount0, amount1: '' }
  }

  const estPrice = matchedAmount1 / matchedAmount0

  return {
    fullyFilled: amount0Left === 0,
    estPrice: estPrice.toFixed(market.price_decimals),
    amount0,
    amount1: matchedAmount1.toFixed(market.price_decimals),
  }
}

export const getMarketOrderFromAmount1 = (
  market: OrderBookDetail,
  amount1: string,
  orders: OrderbookItem[],
) => {
  if (Number(amount1) === 0) {
    return { fullyFilled: true, estPrice: '0.00', amount0: '', amount1 }
  }

  let amount1Left = Number(amount1)
  let matchedAmount0 = 0
  let matchedAmount1 = 0

  for (const order of orders) {
    const price = Number(order.price)
    const size = Number(order.size)
    const matchedSize = Math.min(amount1Left / price, size)

    matchedAmount0 = matchedAmount0 + matchedSize
    matchedAmount1 = matchedAmount1 + matchedSize * price
    amount1Left = amount1Left - matchedSize * price

    // using 2 here because of possible rounding errors :(
    const almostZero = 10 ** (-2 * market.price_decimals * market.size_decimals)
    if (Math.abs(amount1Left) < almostZero) {
      amount1Left = 0
      break
    }
  }

  if (matchedAmount0 === 0) {
    return { fullyFilled: false, estPrice: '0.00', amount0: '', amount1 }
  }

  const estPrice = matchedAmount1 / matchedAmount0

  return {
    fullyFilled: amount1Left === 0,
    estPrice: estPrice.toFixed(market.price_decimals),
    amount0: matchedAmount0.toFixed(market.size_decimals),
    amount1,
  }
}

export const getAvailableLiquidityUSD = (orders: OrderbookItem[]) =>
  orders.reduce((total, order) => total + Number(order.price) * Number(order.size), 0)

export const useMaybeCurrentMarketId = () => {
  const { symbol } = useTradePageParams()
  const orderBookMetas = useOrderBookMetas()

  return useMemo(
    () => Object.values(orderBookMetas).find((metas) => metas.symbol === symbol)?.market_id,
    [orderBookMetas, symbol],
  )
}

export const useCurrentMarketId = () => useMaybeCurrentMarketId()!

export const useCurrentMarket = () => {
  const currentMarketId = useCurrentMarketId()
  const orderBookMetas = useOrderBookMetas()

  return useMemo(() => orderBookMetas[currentMarketId]!, [orderBookMetas, currentMarketId])
}

export const useChangeMarket = () => {
  const orderBookMetas = useOrderBookMetas()
  const navigate = useNavigate()

  return (marketId: number) => {
    localStorage.setItem('last_symbol', orderBookMetas[marketId]!.symbol)

    return navigate(`/trade/${orderBookMetas[marketId]!.symbol}`)
  }
}
