import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  useMainAccount,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Clickable } from 'js/shared-components/Clickable'
import HeaderCell from 'js/shared-components/HeaderCell'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { formatUSD } from 'js/util/formatting'

const MainAccountList = () => {
  const navigate = useNavigate()
  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()
  const mainAccount = useMainAccount()
  const accountIndex = useAccountIndex()
  const { t } = useTranslation()

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
          <HeaderCell title={t('name')} />
          <HeaderCell title={t('type')} />
          <HeaderCell title={t('portfolio_value_title')} className="justify-end" />
          <HeaderCell />
        </TableHeaderRow>
      </TableHeader>
      {!!mainAccount && (
        <TableBody>
          <TableRow>
            <TableCell>
              <p className="typography-body-2 text-white">{t('main_account')}</p>
            </TableCell>
            <TableCell>
              <p className="typography-body-2 text-white">{t('main')}</p>
            </TableCell>
            <TableCell>
              <p className="typography-body-2 text-end text-white">
                {formatUSD(mainAccount.total_asset_value)}
              </p>
            </TableCell>
            <TableCell>
              {accountIndex === mainAccount.index && (
                <Clickable
                  color="blue"
                  onClick={() => navigate(`/trade/${localStorage.getItem('last_symbol')}`)}
                  disabled={verifyAndSwitchAccountMutation.isPending}
                >
                  {t('trade')}
                </Clickable>
              )}
              {accountIndex !== mainAccount.index && (
                <Clickable
                  color="blue"
                  onClick={() => verifyAndSwitchAccountMutation.mutate(mainAccount)}
                  disabled={verifyAndSwitchAccountMutation.isPending}
                >
                  {t('switch')}
                </Clickable>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  )
}

export default MainAccountList
