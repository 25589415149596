import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { OrderBookDetail } from 'zklighter-perps'

import { type OrderSide } from 'js/constants/trades'
import { useAccountLoading, useAccountPositions } from 'js/providers/accountsSlice/selectors'
import { useCurrentMarketId, useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/orderBookSlice/selectors'
import MarketCell from 'js/shared-components/cells/MarketCell'
import PositionAvgEntryPriceCell from 'js/shared-components/cells/positions/PositionAvgEntryPriceCell'
import PositionLeverageCell from 'js/shared-components/cells/positions/PositionLeverageCell'
import PositionLiquidationPriceCell from 'js/shared-components/cells/positions/PositionLiquidationPriceCell'
import PositionMarkPriceCell from 'js/shared-components/cells/positions/PositionMarkPriceCell'
import PositionSizeCell from 'js/shared-components/cells/positions/PositionSizeCell'
import PositionUnrealizedPnlCell from 'js/shared-components/cells/positions/PositionUnrealizedPnlCell'
import SideCell from 'js/shared-components/cells/SideCell'
import HeaderCell from 'js/shared-components/HeaderCell'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableLoader from 'js/shared-components/uikit/table/TableLoader'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import cn from 'js/util/cn'
import { usePositionsWithLiqPrices } from 'js/util/positions'

import { NoOrdersText } from '../NoOrdersText'

import CancelPositionButton from './CancelPositionButton'

interface PositionsTableHeaderProps {
  showCloseColumn: boolean
}

const PositionsTableHeader = ({ showCloseColumn }: PositionsTableHeaderProps) => {
  const { t } = useTranslation()

  return (
    <TableHeader>
      <TableHeaderRow>
        <HeaderCell title={t('market')} />
        <HeaderCell title={t('side')} />
        <HeaderCell title={t('size')} />
        <HeaderCell title={t('liq_price')} />
        <HeaderCell title={t('mark_price')} />
        <HeaderCell title={t('avg_entry')} />
        <HeaderCell title={t('unrealized_pnl_title')} />
        <HeaderCell
          title={t('leverage_title')}
          className={cn({ 'justify-end': !showCloseColumn })}
        />
        {showCloseColumn && <HeaderCell title={t('close')} className="justify-end" />}
      </TableHeaderRow>
    </TableHeader>
  )
}
interface PositionsTableProps {
  accountIndex: number
  showCloseColumn?: boolean
  selectedSide?: OrderSide
  onRowClick?: (market: OrderBookDetail) => void
}

export const PositionsTable = ({
  accountIndex,
  showCloseColumn = false,
  selectedSide = 'all',
  onRowClick,
}: PositionsTableProps) => {
  const marketsStats = useMarketsStats()
  const orderBookMetas = useOrderBookMetas()
  const currentMarketId = useCurrentMarketId()
  const positionsLoading = useAccountLoading(accountIndex)
  const positions = useAccountPositions(accountIndex)
  const positionsWithLiqPrices = usePositionsWithLiqPrices(positions, accountIndex)

  const positionsToDisplay = useMemo(
    () =>
      Object.values(positionsWithLiqPrices)
        .filter((position) => {
          switch (selectedSide) {
            case 'asks':
              return position.sign === -1
            case 'bids':
              return position.sign === 1
            case 'all':
            default:
              return true
          }
        })
        .sort(
          (a, b) =>
            Number(b.position) * Number(marketsStats[b.market_id]?.mark_price ?? 0) -
            Number(a.position) * Number(marketsStats[a.market_id]?.mark_price ?? 0),
        ),

    [selectedSide, positionsWithLiqPrices, marketsStats],
  )

  if (positionsLoading) {
    return (
      <Table className="max-mobile:w-[200%]">
        <PositionsTableHeader showCloseColumn={showCloseColumn} />
        <TableBody>
          <TableLoader rows={4} columns={8 + Number(showCloseColumn)} />
        </TableBody>
      </Table>
    )
  }

  if (!Object.values(positionsWithLiqPrices).flat().length) {
    return <NoOrdersText type="positions" />
  }

  return (
    <Table className="max-mobile:w-[200%]">
      <PositionsTableHeader showCloseColumn={showCloseColumn} />
      <TableBody>
        {positionsToDisplay.map((position) => (
          <TableRow
            key={position.market_id}
            onClick={
              !!onRowClick && position.market_id !== currentMarketId
                ? () => onRowClick(orderBookMetas[position.market_id]!)
                : undefined
            }
          >
            <MarketCell marketIndex={position.market_id} />
            <SideCell is_short={position.sign === -1} />
            <PositionSizeCell size={position.position} marketIndex={position.market_id} />
            <PositionLiquidationPriceCell position={position} />
            <PositionMarkPriceCell position={position} />
            <PositionAvgEntryPriceCell position={position} />
            <PositionUnrealizedPnlCell position={position} />
            <PositionLeverageCell position={position} accountIndex={accountIndex} />
            {showCloseColumn && <CancelPositionButton position={position} />}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
