import type { ComponentProps } from 'react'
import SVG from 'react-inlinesvg'

import arb from 'img/network/arb.svg'
import avalanche from 'img/network/avalanche.svg'
import base from 'img/network/base.svg'
import binance from 'img/network/binance.svg'
import coinbase from 'img/network/coinbase.svg'
import optimism from 'img/network/optimism.svg'
import polygon from 'img/network/polygon.svg'
import allOrders from 'img/orders/all-orders.svg'
import asks from 'img/orders/asks.svg'
import bids from 'img/orders/bids.svg'
import arrow from 'img/shared/arrow.svg'
import check from 'img/shared/check.svg'
import chevron from 'img/shared/chevron.svg'
import copy from 'img/shared/copy.svg'
import deposit from 'img/shared/deposit.svg'
import edit from 'img/shared/edit.svg'
import externalLink from 'img/shared/external-link.svg'
import globe from 'img/shared/globe.svg'
import logo from 'img/shared/logo.svg'
import priceDown from 'img/shared/price-down.svg'
import priceUp from 'img/shared/price-up.svg'
import question from 'img/shared/question.svg'
import search from 'img/shared/search.svg'
import spinner from 'img/shared/spinner.svg'
import swap from 'img/shared/swap.svg'
import triangle from 'img/shared/triangle.svg'
import trophy from 'img/shared/trophy.svg'
import x from 'img/shared/x.svg'
import book from 'img/small-screen/book.svg'
import depth from 'img/small-screen/depth.svg'
import details from 'img/small-screen/details.svg'
import funding from 'img/small-screen/funding.svg'
import price from 'img/small-screen/price.svg'
import trades from 'img/small-screen/trades.svg'
import ai16z from 'img/tokens/ai16z.png'
import avax from 'img/tokens/avax.svg'
import bera from 'img/tokens/bera.svg'
import bonk from 'img/tokens/bonk.svg'
import btc from 'img/tokens/btc.svg'
import coin from 'img/tokens/coin.svg'
import doge from 'img/tokens/doge.svg'
import dot from 'img/tokens/dot.svg'
import eth from 'img/tokens/eth.svg'
import fartcoin from 'img/tokens/fartcoin.png'
import floki from 'img/tokens/floki.svg'
import link from 'img/tokens/link.svg'
import matic from 'img/tokens/matic.svg'
import near from 'img/tokens/near.svg'
import pepe from 'img/tokens/pepe.jpg'
import popcat from 'img/tokens/popcat.svg'
import shib from 'img/tokens/shib.svg'
import sol from 'img/tokens/sol.svg'
import sui from 'img/tokens/sui.svg'
import tao from 'img/tokens/tao.svg'
import ton from 'img/tokens/ton.svg'
import trump from 'img/tokens/trump.jpg'
import wif from 'img/tokens/wif.webp'
import wld from 'img/tokens/wld.svg'
import xrp from 'img/tokens/xrp.svg'
import address from 'img/wallet/address.svg'
import coinbasewallet from 'img/wallet/coinbasewallet.svg'
import explorer from 'img/wallet/explorer.svg'
import logout from 'img/wallet/logout.svg'
import metamask from 'img/wallet/metamask.svg'
import wallet from 'img/wallet/wallet.svg'
import walletconnect from 'img/wallet/walletconnect.svg'
import cn from 'js/util/cn'

const ICON_MAP = {
  trophy,
  wallet,
  x,
  swap,
  chevron,
  spinner,
  triangle,
  logo,
  externalLink,
  check,
  edit,
  arrow,
  question,
  priceUp,
  priceDown,
  search,
  copy,
  deposit,
  globe,

  address,
  explorer,
  logout,

  asks,
  bids,
  allOrders,

  price,
  depth,
  trades,
  book,
  funding,
  details,

  btc,
  doge,
  sol,
  eth,
  fartcoin,
  floki,
  pepe,
  popcat,
  shib,
  link,
  ai16z,
  avax,
  bera,
  bonk,
  matic,
  xrp,
  trump,
  wif,
  wld,
  near,
  dot,
  ton,
  sui,
  tao,

  coin,

  arb,
  polygon,
  avalanche,
  optimism,
  binance,
  coinbase,
  base,

  metamask,
  coinbasewallet,
  walletconnect,
} as const

export type IconType = keyof typeof ICON_MAP

type ReactSVGProps = Omit<ComponentProps<typeof SVG>, 'src'>

interface IconProps extends ReactSVGProps {
  icon: IconType
}

const Icon = ({ icon, className, ...rest }: IconProps) => {
  if (
    icon === 'trump' ||
    icon === 'wif' ||
    icon === 'pepe' ||
    icon === 'fartcoin' ||
    icon === 'ai16z'
  ) {
    return <img src={ICON_MAP[icon]} className={cn(className, 'rounded-full')} />
  }

  return (
    <span className={cn('flex shrink-0 items-center justify-center', className)}>
      <SVG src={ICON_MAP[icon]} {...rest} className="size-full" />
    </span>
  )
}

export default Icon
