import { TOKEN_LOGOS } from 'js/constants/shared'
import { useCurrentMarketId, useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/orderBookSlice/selectors'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import Icon from 'js/shared-components/uikit/Icon'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import cn from 'js/util/cn'
import { formatLeverage, formatMarketPrice, formatUSD } from 'js/util/formatting'
import { marginFractionToLeverage } from 'js/util/positions'

interface MarketRowProps {
  onClick: (marketid: number) => void
  marketId: number
}

export const MarketRow = ({ marketId, onClick }: MarketRowProps) => {
  const currentMarketId = useCurrentMarketId()
  const market = useOrderBookMetas()[marketId]!
  const marketStats = useMarketsStats()[marketId]
  const diffPct = marketStats?.daily_price_change ?? 0
  const roundedDiffPct = parseFloat(diffPct.toFixed(2))
  const isSelected = marketId === currentMarketId

  return (
    <TableRow key={`${marketId}`} onClick={() => onClick(marketId)}>
      <TableCell className="border-r">
        <div className="flex items-center gap-2" data-testid="market-selector-table-cell">
          <Icon icon={TOKEN_LOGOS[market.symbol as keyof typeof TOKEN_LOGOS]!} className="size-5" />
          <p
            className={cn('typography-body-1', {
              'text-white': isSelected,
              'text-white-opaque': !isSelected,
            })}
          >
            {market.symbol}
          </p>
          <div
            className="rounded-full border border-semi-transparent bg-white-transparent bg-origin-border p-1 px-2"
            data-testid="market-selector-table-cell-margin-fraction"
          >
            <p className="typography-body-1 !leading-3 text-white">
              {formatLeverage(marginFractionToLeverage(market.min_initial_margin_fraction))}
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {marketStats && (
          <p
            className={cn('typography-body-2 text-center', {
              'text-white': isSelected,
              'text-white-opaque': !isSelected,
            })}
          >
            {formatMarketPrice(marketStats.last_trade_price, market)}
          </p>
        )}
        {!marketStats && <SkeletonRectangle className="h-[1ch] w-10" />}
      </TableCell>
      <TableCell>
        <div className="flex items-center justify-center gap-1">
          {roundedDiffPct !== 0 && (
            <Icon
              icon={roundedDiffPct > 0 ? 'priceUp' : 'priceDown'}
              className={cn('size-3.5', {
                'text-green-main': roundedDiffPct > 0,
                'text-red-main': roundedDiffPct <= 0,
              })}
            />
          )}
          <p
            className={cn('typography-body-1 text-white', {
              'text-green-main': roundedDiffPct > 0,
              'text-red-main': roundedDiffPct < 0,
              'text-white': roundedDiffPct === 0 && isSelected,
              'text-white-opaque': roundedDiffPct === 0 && !isSelected,
            })}
          >
            {roundedDiffPct}%
          </p>
        </div>
      </TableCell>
      <TableCell>
        {marketStats && (
          <p
            className={cn('typography-body-2 text-center', {
              'text-white': isSelected,
              'text-white-opaque': !isSelected,
            })}
          >
            {formatMarketPrice(marketStats.index_price, market)}
          </p>
        )}
        {!marketStats && <SkeletonRectangle className="h-[1ch] w-10" />}
      </TableCell>
      <TableCell>
        {marketStats && (
          <p
            className={cn('typography-body-2 text-center', {
              'text-white': isSelected,
              'text-white-opaque': !isSelected,
            })}
          >
            {formatMarketPrice(marketStats.mark_price, market)}
          </p>
        )}
        {!marketStats && <SkeletonRectangle className="h-[1ch] w-10" />}
      </TableCell>
      <TableCell>
        {marketStats && (
          <p
            className={cn('typography-body-2 text-center', {
              'text-white': isSelected,
              'text-white-opaque': !isSelected,
            })}
          >
            {formatUSD(marketStats.daily_quote_token_volume)}
          </p>
        )}
        {!marketStats && <SkeletonRectangle className="h-[1ch] w-10" />}
      </TableCell>
    </TableRow>
  )
}
