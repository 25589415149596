import { useTranslation } from 'react-i18next'

import type { Trade } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface TradeRoleCellProps {
  trade: Trade
  accountIndex: number
}

const TradeRoleCell = ({ trade, accountIndex }: TradeRoleCellProps) => {
  const { t } = useTranslation()

  return (
    <TableCell>
      <p className="typography-body-1 text-white">
        {(trade.ask_account_id === accountIndex) === trade.is_maker_ask ? t('maker') : t('taker')}
      </p>
    </TableCell>
  )
}

export default TradeRoleCell
