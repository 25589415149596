import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import type { WithdrawHistoryRequest } from 'zklighter-perps'
import { create } from 'zustand'

import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useWallet } from 'js/providers/hooks/useWallet'
import { useZkLighterL1 } from 'js/providers/hooks/useZkLighterL1'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Button } from 'js/shared-components'
import { Clickable } from 'js/shared-components/Clickable'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { Modal } from 'js/shared-components/Modal'
import Icon from 'js/shared-components/uikit/Icon'
import Input from 'js/shared-components/uikit/Input'
import UIKitPriceInput from 'js/shared-components/uikit/PriceInput'
import Toast from 'js/shared-components/uikit/Toast'
import { bridgeApi, transactionApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { floorNumber, formatUSDFixed } from 'js/util/formatting'
import { useUserAccountPortfolioStats } from 'js/util/positions'
import { enforceNumber, isMainnet, wait } from 'js/util/util'
import { fastWithdraw, withdraw } from 'js/zklighter-js-sdk/sdk'

import { CHAIN_ID, useSupportedNetworksQuery } from '../deposit/hooks'

type WithdrawStatus = 'idle' | 'pending' | 'success' | 'error'

const MIN_WITHDRAW = 1

interface WithdrawStore {
  status: WithdrawStatus
  setStatus: (s: WithdrawStatus) => void
  setType: (wType?: WithdrawType) => void
  openModal: () => void
  closeModal: () => void
  isModalOpen: boolean
  withdrawType?: WithdrawType
}

export const useWithdrawStore = create<WithdrawStore>((set) => ({
  status: 'idle',
  isModalOpen: false,
  setStatus: (status) => set(() => ({ status })),
  setType: (wType?: WithdrawType) => set(() => ({ withdrawType: wType })),
  closeModal: () => set(() => ({ isModalOpen: false })),
  openModal: () => set(() => ({ isModalOpen: true })),
}))

export const WithdrawButton = ({ onClickCallback }: { onClickCallback?: () => void }) => {
  const { t } = useTranslation()
  const withdrawStore = useWithdrawStore()
  const accountExistence = useAccountExistence()

  if (!isMainnet() || accountExistence !== 'Exists') {
    return null
  }

  return (
    <Button
      onClick={() => {
        if (onClickCallback) onClickCallback()
        withdrawStore.openModal()
      }}
      className={cn('flex items-center gap-2', {
        'animate-pulse bg-orange-500/20 text-orange-500 hover:bg-orange-500/40':
          withdrawStore.status === 'pending',
      })}
    >
      {withdrawStore.status === 'pending' && <Icon icon="spinner" className="size-5" />}
      {t('withdraw')}
    </Button>
  )
}

const FastWithdraw = () => {
  const accountIndex = useAccountIndex() ?? -1
  const withdrawStore = useWithdrawStore()
  const userAddress = useUserAddress()
  const portfolioStats = useUserAccountPortfolioStats()
  const [amount, setAmount] = useState('')
  const supportedNetworksQuery = useSupportedNetworksQuery()
  const { primaryWallet } = useDynamicContext()
  const isEmbeddedWallet = !!primaryWallet?.connector.isEmbeddedWallet
  const [targetAddress, setTargetAddress] = useState(!isEmbeddedWallet ? userAddress : '')
  const { t } = useTranslation()

  const withdrawalInfo = useQuery({
    queryKey: ['withdrawalInfo'],
    queryFn: () => bridgeApi.fastwithdrawInfo(),
  })

  const withdrawLimit = useMemo(() => {
    if (!withdrawalInfo.data || !portfolioStats) return 0
    return floorNumber(
      Math.min(
        Number(withdrawalInfo.data.withdraw_limit),
        Number(portfolioStats.withdrawableCollateral),
      ),
    )
  }, [withdrawalInfo.data, portfolioStats])

  const requestWithdrawToL2Mutation = useMutation({
    mutationFn: async (args: {
      fromIdx: number
      toIdx: number
      toAddress: string
      amount: string
    }) =>
      fastWithdraw({
        accountIndex: args.fromIdx,
        toAccountIndex: args.toIdx,
        collateralAmount: Number((Number(args.amount) * 10 ** 6).toFixed(0)),
        toAddress: args.toAddress,
      }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('withdraw_modal_toasts_fast_request_success')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('withdraw_modal_toasts_request_error')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: withdrawStore.closeModal,
  })

  const amtErrMsg = useMemo(() => {
    if (!withdrawalInfo.data || !portfolioStats) {
      return
    }

    if (Number(amount) > Number(withdrawalInfo.data.withdraw_limit)) {
      return t('withdraw_modal_withdraw_limit_error')
    }

    if (Number(amount) > Number(portfolioStats.withdrawableCollateral)) {
      return t('insufficient_balance')
    }

    if (amount && Number(amount) < MIN_WITHDRAW) {
      return t('withdraw_modal_min_withdraw_error', {
        minWithdraw: `${MIN_WITHDRAW}`,
      })
    }
  }, [t, amount, portfolioStats, withdrawalInfo.data])

  return (
    <>
      <div className="text-white-opaque">
        {isEmbeddedWallet
          ? t('withdraw_modal_fast_withdraw_embedded_wallet_description')
          : t('withdraw_modal_fast_withdraw_description')}
      </div>
      <div className="col-span-2 flex w-full flex-col items-center justify-center gap-2 space-x-1 align-middle">
        <div className="w-full rounded-md bg-white/10 py-2">
          <div className="flex h-4 items-center justify-center gap-3">
            {supportedNetworksQuery.data?.find((x) => x.chainId == CHAIN_ID.arbitrum)?.icon}
            {supportedNetworksQuery.data?.find((x) => x.chainId == CHAIN_ID.arbitrum)?.name}
          </div>
        </div>
      </div>
      <div className="relative w-full">
        <Input
          className="w-full rounded-lg border-white/10 bg-white/10 p-4"
          inputMode="text"
          placeholder="0x..."
          value={targetAddress}
          onChange={(e) => setTargetAddress(e.target.value)}
        />
      </div>
      <UIKitPriceInput
        label={t('amount')}
        placeholder="0.00"
        value={amount}
        onChange={(e) => {
          if (!enforceNumber(e, 2)) return
          setAmount(e.target.value)
        }}
        disabled={portfolioStats === null || Number(portfolioStats.withdrawableCollateral) < 1}
        buttonText={t('max')}
        onClick={() => {
          if (portfolioStats) {
            setAmount(formatUSDFixed(withdrawLimit))
          }
        }}
      />
      {portfolioStats !== null && (
        <p className="text-xs text-white-opaque">
          {t('available')} {formatUSDFixed(portfolioStats.withdrawableCollateral)} USDC
        </p>
      )}
      <div className="flex w-full items-center gap-1 py-4 text-white-opaque">
        <span>
          {t('withdraw_modal_max_withdraw_amount')} {formatUSDFixed(withdrawLimit)}
        </span>
        <span className="rounded-full bg-white/5 px-2 py-0.5">USDC</span>
      </div>
      {amtErrMsg && (
        <div className="mx-auto flex text-red-main">
          <span>{amtErrMsg}</span>
        </div>
      )}
      <Button
        className="absolute inset-x-0 bottom-0 w-full rounded-t-none border-t-0 py-8 max-mobile:rounded-b-none"
        isLoading={
          portfolioStats === null ||
          requestWithdrawToL2Mutation.isPending ||
          withdrawalInfo.isLoading
        }
        color={amtErrMsg ? 'red' : 'green'}
        disabled={Number(amount) < MIN_WITHDRAW || !!amtErrMsg || !targetAddress}
        onClick={() =>
          requestWithdrawToL2Mutation.mutate({
            fromIdx: accountIndex,
            toIdx: Number(withdrawalInfo.data!.to_account_index),
            amount,
            toAddress: targetAddress,
          })
        }
      >
        {t('withdraw')}
      </Button>
    </>
  )
}

type L2ToL1Step =
  | 'checkingPendingBalance'
  | 'request'
  | 'claim'
  | 'claimWaitSign'
  | 'claimWaitReceipt'
  | 'claimFailed'
  | 'claimSuccessful'

const L2ToL1Withdraw = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const accountIndex = useAccountIndex() ?? -1
  const userAddress = useUserAddress()
  const withdrawStore = useWithdrawStore()
  const wallet = useWallet()
  const [step, setStep] = useState<L2ToL1Step>('checkingPendingBalance')
  const zkLighter = useZkLighterL1()
  const portfolioStats = useUserAccountPortfolioStats()
  const [amount, setAmount] = useState('')
  const hasAttemptedSwitch = useRef(false)
  const { t } = useTranslation()

  const pendingBalanceQuery = useQuery({
    queryKey: ['pendingBalance', userAddress],
    queryFn: () => zkLighter.getPendingBalance(),
    enabled: !!userAddress && wallet.network?.toString() === zkLighter.chainId.toString(),
    refetchInterval: 5000,
  })

  const pendingWithdrawalsParams: WithdrawHistoryRequest = useMemo(
    () => ({ account_index: accountIndex, filter: 'pending' }),
    [accountIndex],
  )
  const pendingWithdrawalsQuery = useQuery({
    queryKey: ['withdrawHistory', pendingWithdrawalsParams],
    queryFn: () => transactionApi.withdrawHistory(pendingWithdrawalsParams),
    enabled: !!accountIndex,
  })

  const requestWithdrawFromL2Mutation = useMutation({
    mutationFn: async (args: { amount: string }) => {
      await withdraw({
        accountIndex,
        assetAmount: (Number(args.amount) * 10 ** 6).toFixed(0),
      })

      let lastWithdrawRequest = (await transactionApi.withdrawHistory(pendingWithdrawalsParams))
        .withdraws[0]

      while (
        !lastWithdrawRequest ||
        lastWithdrawRequest.id === pendingWithdrawalsQuery.data?.withdraws[0]?.id
      ) {
        await wait()
        lastWithdrawRequest = (await transactionApi.withdrawHistory(pendingWithdrawalsParams))
          .withdraws[0]
      }
    },
    onSuccess: () => {
      withdrawStore.closeModal()
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('withdraw_modal_toasts_request_success')}
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      queryClient.invalidateQueries({ queryKey: ['withdrawHistory'] })
      navigate('/portfolio', { state: { tab: 'withdraws' } })
    },
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('withdraw_modal_toasts_request_error')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
  })

  const claimL1WithdrawalMutation = useMutation({
    onMutate: () => setStep('claimWaitSign'),
    mutationFn: (args: { amount: string }) => zkLighter.withdrawPendingBalance(args.amount),
    onSuccess: () => {
      setStep('claimWaitReceipt')
      queryClient.invalidateQueries({ queryKey: ['withdrawHistory'] })
    },
    onError: () => setStep('claimFailed'),
  })

  const txReceiptQuery = useQuery({
    queryKey: ['txReceipt', claimL1WithdrawalMutation.data],
    queryFn: () => wallet.waitTx(claimL1WithdrawalMutation.data!),
    enabled: !!claimL1WithdrawalMutation.data,
  })

  useEffect(() => {
    if (pendingBalanceQuery.isPending) {
      return
    }

    setStep(Number(pendingBalanceQuery.data ?? 0) === 0 ? 'request' : 'claim')
  }, [pendingBalanceQuery.isPending, pendingBalanceQuery.data])

  useEffect(() => {
    if (txReceiptQuery.isSuccess && txReceiptQuery.data.status === 'success') {
      setTimeout(() => navigate('/portfolio', { state: { tab: 'withdraws' } }), 3000)
    }
  }, [navigate, txReceiptQuery.isSuccess, txReceiptQuery.data])

  const amtErrMsg = useMemo(() => {
    if (
      portfolioStats !== null &&
      Number(amount) > floorNumber(portfolioStats.withdrawableCollateral) &&
      !requestWithdrawFromL2Mutation.isPending
    ) {
      return t('insufficient_balance')
    }

    return ''
  }, [t, amount, portfolioStats, requestWithdrawFromL2Mutation.isPending])

  useEffect(() => {
    const switchNetwork = async () => {
      if (!zkLighter.chainId) return
      if (
        wallet.network?.toString() !== zkLighter.chainId.toString() &&
        !hasAttemptedSwitch.current
      ) {
        hasAttemptedSwitch.current = true

        // try twice as dynamic is not stable
        wallet.switch(zkLighter.chainId).catch(async () => {
          wallet
            .switch(zkLighter.chainId)
            .then(() => pendingBalanceQuery.refetch())
            .catch((err) => {
              toast.custom((toastId) => (
                <Toast
                  level="error"
                  description={err + ''}
                  onClose={() => toast.dismiss(toastId)}
                />
              ))
            })
        })
      }
    }
    switchNetwork()
  }, [zkLighter.chainId, wallet, pendingBalanceQuery])

  if (wallet.network?.toString() !== zkLighter.chainId.toString()) {
    return (
      <div className="flex flex-col items-center gap-4">
        <p className="typography-label-1 text-center text-lg">{t('switching_to_eth')}</p>
        <Icon icon="spinner" className="size-12" />
      </div>
    )
  }

  if (step === 'checkingPendingBalance') {
    return (
      <div className="flex flex-col items-center gap-4 ">
        <p className="typography-label-1 text-center text-lg">{t('checking_pending_balance')}</p>
        <Icon icon="spinner" className="size-12" />
      </div>
    )
  }

  if (step === 'request') {
    return (
      <>
        {!!Number(pendingBalanceQuery.data) && (
          <Clickable color="blue" onClick={() => setStep('claim')}>
            {t('claim_usdc', {
              amount: Number(pendingBalanceQuery.data).toFixed(2),
            })}
          </Clickable>
        )}
        <div className="flex items-center gap-1">
          {pendingWithdrawalsQuery.data && pendingWithdrawalsQuery.data?.withdraws.length > 0 && (
            <>
              <p className="typography-body-2">
                {t('pending_withdrawals', {
                  amount: `${pendingWithdrawalsQuery.data.withdraws.length}`,
                })}
              </p>
              <Explanation explanation={t('pending_withdrawals_description')} />
            </>
          )}
        </div>
        <div className="relative w-full">
          <p className="absolute left-5 top-1/2 -translate-y-1/2 text-sm text-white-opaque">
            {t('amount')}
          </p>
          <Input
            className="w-full rounded-lg border-white/10 bg-white/10 p-8 pl-[7ch]"
            inputMode="decimal"
            placeholder="0.00"
            value={amount}
            onChange={(e) => {
              if (!enforceNumber(e, 2)) return
              setAmount(e.target.value)
            }}
          />
          {portfolioStats !== null && (
            <div className="absolute right-5 top-1/2 -translate-y-1/2 rounded-lg border border-white/10 bg-white/10 px-2 py-1 text-center text-xs text-white-opaque">
              <span className="text-white-opaque">{t('available')} </span>
              {formatUSDFixed(portfolioStats.withdrawableCollateral)} USDC
            </div>
          )}
        </div>
        <div className="typography-body-2 flex items-center justify-between text-white">
          <span>{t('min_withdraw_amount')}</span>
          <span>1 USDC</span>
        </div>
        <div className="typography-body-2 flex items-center justify-between text-white">
          <span>{t('estimated_processing_time')}</span>
          <span>48h</span>
        </div>
        <div className="text-start text-red-main">{amtErrMsg}</div>
        <Button
          className="absolute inset-x-0 bottom-0 w-full rounded-t-none border-t-0 py-8 max-mobile:rounded-b-none"
          isLoading={requestWithdrawFromL2Mutation.isPending || portfolioStats === null}
          color={amtErrMsg ? 'red' : 'green'}
          disabled={Number(amount) < 1 || !!amtErrMsg}
          onClick={() => requestWithdrawFromL2Mutation.mutate({ amount })}
        >
          {t('request_withdrawal')}
        </Button>
      </>
    )
  }

  if (step === 'claim') {
    return (
      <>
        {portfolioStats !== null && portfolioStats.withdrawableCollateral !== 0 && (
          <Clickable color="blue" onClick={() => setStep('request')}>
            {t('request_more_usdc')}
          </Clickable>
        )}
        <Button
          className="absolute inset-x-0 bottom-0 w-full rounded-t-none border-t-0 py-8 max-mobile:rounded-b-none"
          isLoading={claimL1WithdrawalMutation.isPending || pendingBalanceQuery.isLoading}
          disabled={Number(pendingBalanceQuery.data) === 0}
          color="green"
          onClick={() => claimL1WithdrawalMutation.mutate({ amount: pendingBalanceQuery.data! })}
        >
          {t('claim_usdc', {
            amount: Number(pendingBalanceQuery.data).toFixed(2),
          })}
        </Button>
      </>
    )
  }

  if (step === 'claimWaitSign') {
    return (
      <div className="flex flex-col items-center gap-4">
        <p className="typography-label-1 text-center text-lg">{t('waiting_tx_approval')}</p>
        <Icon icon="spinner" className="size-12" />
      </div>
    )
  }

  if (step === 'claimWaitReceipt') {
    return (
      <div className="flex flex-col items-center gap-3 pb-8">
        <p className="typography-label-1 pb-4 text-center text-lg">
          {txReceiptQuery.isPending &&
            t('withdraw_modal_receipt_pending', {
              address: truncateAddress(claimL1WithdrawalMutation.data ?? ''),
            })}
          {txReceiptQuery.data?.status === 'success' && t('withdraw_modal_receipt_success')}
          {txReceiptQuery.data?.status === 'reverted' && (
            <span className="text-red-main">
              {t('withdraw_modal_receipt_error', {
                address: truncateAddress(txReceiptQuery.data.transactionHash),
              })}
            </span>
          )}
        </p>
        <div className="relative grid place-items-center">
          <Icon
            icon="logo"
            className={cn('size-16 text-white', {
              'animate-pulse': txReceiptQuery.isPending,
            })}
          />
        </div>
      </div>
    )
  }

  if (step === 'claimFailed') {
    return (
      <div className="flex flex-col items-center gap-3">
        <p className="typography-label-1 pb-4 text-center text-lg">
          {t('failed_to_claim_funds')}
          <br />
          {claimL1WithdrawalMutation.error?.name}
        </p>
        <Button
          className="w-full"
          onClick={() => {
            claimL1WithdrawalMutation.reset()
            setStep('claim')
          }}
          color="red"
        >
          {t('start_over')}
        </Button>
      </div>
    )
  }

  return null
}

type WithdrawType = 'fastWithdraw' | 'l2ToL1'

export const WithdrawModal = () => {
  const { t } = useTranslation()
  const withdrawStore = useWithdrawStore()
  const withdrawType = useMemo(() => withdrawStore.withdrawType, [withdrawStore])
  const { primaryWallet } = useDynamicContext()
  const { getPendingBalance } = useZkLighterL1()
  const isEmbeddedWallet = !!primaryWallet?.connector.isEmbeddedWallet

  useEffect(() => {
    if (!withdrawStore.isModalOpen) {
      return withdrawStore.setType(undefined)
    }

    if (isEmbeddedWallet) {
      return withdrawStore.setType('fastWithdraw')
    }

    // TODO: this can lead to a race condition if this takes too long and user
    // clicks on fastWithdraw, as he'll get switched to l2ToL1
    getPendingBalance().then((balance) => {
      if (Number(balance) > 0) {
        withdrawStore.setType('l2ToL1')
      }
    })
  }, [withdrawStore.isModalOpen, withdrawStore.setType, isEmbeddedWallet])

  return (
    <Modal
      title={
        <div className="flex items-center gap-2">
          {!!withdrawType && !isEmbeddedWallet && (
            <Icon
              icon="chevron"
              className="size-2.5 rotate-90 cursor-pointer"
              onClick={() => withdrawStore.setType()}
            />
          )}
          {withdrawType === undefined && <span>{t('withdraw_funds')}</span>}
          {withdrawType === 'fastWithdraw' && <span>{t('fast_withdrawal')}</span>}
          {withdrawType === 'l2ToL1' && <span>{t('secure_withdrawal')}</span>}
        </div>
      }
      preventCloseOutside
      open={withdrawStore.isModalOpen}
      onOpenChange={(newOpen) => {
        if (newOpen) {
          return
        }

        withdrawStore.closeModal()
      }}
    >
      <div
        className={cn('flex flex-col gap-3', {
          'pb-16': withdrawType !== undefined,
        })}
      >
        {withdrawType === undefined && (
          <div className="flex flex-col items-center gap-3">
            <button
              className="flex w-full flex-col gap-1 rounded-lg border-white/10 bg-white/10 p-4 text-start hover:bg-white/5"
              onClick={() => withdrawStore.setType('fastWithdraw')}
            >
              <div className="flex items-start justify-between">
                <div>
                  <p className="text-sm font-medium text-white">{t('fast_withdrawal')}</p>
                  <p className="text-xs text-white/60">{t('fast_withdrawal_description')}</p>
                </div>
                <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white/5">
                  <Icon icon="arb" className="size-6" />
                </div>
              </div>
            </button>
            {!isEmbeddedWallet && (
              <button
                className="flex w-full flex-col gap-1 rounded-lg border-white/10 bg-white/10 p-4 text-start hover:bg-white/5"
                onClick={() => withdrawStore.setType('l2ToL1')}
              >
                <div className="flex items-start justify-between">
                  <div>
                    <p className="text-sm font-medium text-white">{t('secure_withdrawal')}</p>
                    <p className="text-xs text-white/60">{t('secure_withdrawal_description')}</p>
                  </div>
                  <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white/5">
                    <Icon icon="eth" className="size-6" />
                  </div>
                </div>
              </button>
            )}
          </div>
        )}
        {withdrawType === 'fastWithdraw' && <FastWithdraw />}
        {withdrawType === 'l2ToL1' && <L2ToL1Withdraw />}
      </div>
    </Modal>
  )
}

const truncateAddress = (address: string) => `${address.slice(0, 4)}...${address.slice(-4)}`
