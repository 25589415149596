import { createSelector } from 'reselect'

import { useCurrentMarketId } from '../hooks/order-book-metas-hooks'
import { useLighterStore } from '../lighterStore'

import type { OrderBookSlice } from '.'

const selectBlockHeight = (state: OrderBookSlice) => state.height
export const useBlockHeight = () => useLighterStore(selectBlockHeight)

const selectOrderBookTrades = (state: OrderBookSlice) => state.trades
export const useOrderBookTrades = () => useLighterStore(selectOrderBookTrades)

const selectMarketsStats = (state: OrderBookSlice) => state.marketsStats
export const useMarketsStats = () => useLighterStore(selectMarketsStats)

const selectMarketStats = createSelector(
  [selectMarketsStats, (_state: OrderBookSlice, marketId: number) => marketId],
  (marketsStats, marketId) => marketsStats[marketId],
)
export const useCurrentMarketStats = () => {
  const currentMarketId = useCurrentMarketId()

  return useLighterStore((state) => selectMarketStats(state, currentMarketId))
}

const selectRawOrderBook = (state: OrderBookSlice) => state.orderBook
const selectOrderBook = createSelector(
  [selectRawOrderBook, selectMarketStats],
  (orderBook, marketStats) => {
    if (!orderBook || !marketStats) {
      return null
    }

    const markPrice = Number(marketStats.mark_price)

    // important to check both ways so we don't compare markPrice of 1 market against ask of another market
    // which often leads to asks being [] and bids being full
    return {
      asks: orderBook.asks.filter(
        (ask) => Number(ask.price) < markPrice * 1.02 && Number(ask.price) > markPrice * 0.98,
      ),
      bids: orderBook.bids.filter(
        (bid) => Number(bid.price) > markPrice * 0.98 && Number(bid.price) < markPrice * 1.02,
      ),
    }
  },
)

export const useOrderBook = () => {
  const currentMarketId = useCurrentMarketId()

  return useLighterStore((state) => selectOrderBook(state, currentMarketId))
}

const selectPrunedOrderBook = createSelector([selectOrderBook], (orderBook) => ({
  asks: (orderBook?.asks ?? []).slice(0, 15),
  bids: (orderBook?.bids ?? []).slice(0, 15),
}))
export const usePrunedOrderbook = () => {
  const currentMarketId = useCurrentMarketId()

  return useLighterStore((state) => selectPrunedOrderBook(state, currentMarketId))
}

const selectMaxTotalSize = createSelector([selectPrunedOrderBook], ({ asks, bids }) =>
  Math.max(
    asks.reduce((sum, ask) => sum + Number(ask.size), 0),
    bids.reduce((sum, bid) => sum + Number(bid.size), 0),
  ),
)
export const useMaxTotalSize = () => {
  const currentMarketId = useCurrentMarketId()

  return useLighterStore((state) => selectMaxTotalSize(state, currentMarketId))
}

const selectMidPrice = createSelector([selectPrunedOrderBook], ({ asks, bids }) => {
  if (!asks.length && !bids.length) {
    return 0
  }

  if (!asks.length) {
    return Number(bids[0]!.price)
  }

  if (!bids.length) {
    return Number(asks[0]!.price)
  }

  return (Number(asks[0]!.price) + Number(bids[0]!.price)) / 2
})
export const useMidPrice = () => {
  const currentMarketId = useCurrentMarketId()

  return useLighterStore((state) => selectMidPrice(state, currentMarketId))
}

const selectSpread = createSelector([selectPrunedOrderBook], ({ asks, bids }) =>
  // We don't show the spread when we don't have asks/bids so default to 0
  // so that our sentry doesn't catch it as a problem
  Math.max(0, Number(asks[0]?.price ?? 0) - Number(bids[0]?.price ?? 0)),
)
export const useSpread = () => {
  const currentMarketId = useCurrentMarketId()

  return useLighterStore((state) => selectSpread(state, currentMarketId))
}

const selectDepthChartData = createSelector(
  [selectOrderBook, selectMidPrice],
  (orderBook, midPrice) => {
    const depthChartData = {
      prices: [] as number[],
      asksDepth: [] as number[],
      bidsDepth: [] as number[],
      maxDepth: 0,
    }

    if (!orderBook) {
      return depthChartData
    }

    const { bids, asks } = orderBook

    const depthChartBids = bids
      .map((bid) => ({ price: Number(bid.price), size: Number(bid.size) }))
      .filter((bid) => bid.price >= midPrice * 0.9)
    const depthChartAsks = asks
      .map((ask) => ({ price: Number(ask.price), size: Number(ask.size) }))
      .filter((ask) => ask.price <= midPrice * 1.1)
    const distance = Math.max(
      (depthChartAsks.at(-1)?.price ?? 0) - midPrice,
      midPrice - (depthChartBids.at(-1)?.price ?? 0),
    )

    if (bids.length !== 0 && midPrice) {
      const minPrice = midPrice - distance
      const bids = accumulate(
        depthChartBids.filter((r) => r.price >= minPrice),
        minPrice,
      )
      depthChartData.prices = bids.map((r) => r.x)
      depthChartData.bidsDepth = bids.map((r) => r.y)
    }
    if (asks.length !== 0 && midPrice) {
      const maxPrice = midPrice + distance
      const asks = accumulate(
        depthChartAsks.filter((r) => r.price <= maxPrice),
        maxPrice,
      )
      depthChartData.prices = depthChartData.prices.concat(asks.map((r) => r.x))
      depthChartData.asksDepth = (
        Array.from({ length: depthChartData.prices.length - asks.length }) as number[]
      ).concat(asks.map((r) => r.y))
    }

    const maxDepthSum = Math.max(
      depthChartAsks.reduce((acc, row) => acc + Number(row.size), 0),
      depthChartBids.reduce((acc, row) => acc + Number(row.size), 0),
    )
    depthChartData.maxDepth = maxDepthSum > 1 ? Math.ceil(maxDepthSum) : maxDepthSum

    return depthChartData
  },
)
export const useDepthChartData = () => {
  const currentMarketId = useCurrentMarketId()

  return useLighterStore((state) => selectDepthChartData(state, currentMarketId))
}

const accumulate = (orders: Array<{ price: number; size: number }>, basePoint: number) => {
  if (orders.length === 0) {
    return []
  }
  const accumulated: Array<{ x: number; y: number }> = []
  let sum = 0

  for (let i = 0; i < orders.length; i += 1) {
    sum = sum + orders[i]!.size

    accumulated.push({ x: orders[i]!.price, y: sum })
  }

  if (basePoint < orders[orders.length - 1]!.price) {
    accumulated.push({ x: basePoint, y: sum })
  } else if (basePoint > orders[0]!.price) {
    accumulated.unshift({ x: basePoint, y: sum })
  }

  return accumulated.slice().sort((a, b) => a.x - b.x)
}
