import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import type { Trade } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketSize, formatUSD } from 'js/util/formatting'

interface TradeSizeCellProps {
  trade: Trade
}

const TradeSizeCell = ({ trade }: TradeSizeCellProps) => {
  const market = useOrderBookMetas()[trade.market_id]!

  return (
    <TableCell>
      <div className="flex flex-col">
        <div className="flex gap-1">
          <p className="typography-body-1 text-white">{formatMarketSize(trade.size, market)}</p>
          <div className="rounded-lg bg-grey-light px-2 py-px text-[10px] text-white">
            {market.symbol}
          </div>
        </div>
        <p className="typography-body-1 text-white-opaque">
          {formatUSD(Number(trade.price) * Number(trade.size))}
        </p>
      </div>
    </TableCell>
  )
}

export default TradeSizeCell
