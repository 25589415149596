import { useTranslation } from 'react-i18next'

import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'
import { useHealthQuery } from 'js/util/queries'

export const ServerStatus = () => {
  const { t } = useTranslation()
  const healthQuery = useHealthQuery()
  const isUp = !!healthQuery.data?.serverOk

  return (
    <div
      className={cn('z-[3] flex cursor-pointer items-center rounded-lg', {
        'bg-green-pale': isUp,
        'bg-red-pale': !isUp,
      })}
      onClick={() => window.open('https://status.lighter.xyz', '_blank')}
    >
      <div
        className={cn('ml-2 mr-1 size-2 rounded-full', {
          'bg-green-main/80': isUp,
          'bg-red-main/80': !isUp,
        })}
      />
      <p className={cn('typography-body-2', { 'text-green-main': isUp, 'text-red-main': !isUp })}>
        {isUp ? t('online') : t('offline')}
      </p>
      <Icon
        icon="externalLink"
        className={cn('size-6', { 'text-green-main': isUp, 'text-red-main': !isUp })}
      />
    </div>
  )
}
