import * as Sentry from '@sentry/react'

import { TX_STATUSES } from 'js/constants/shared'
import { SubAccountType, type Tx } from 'js/providers/types'
import { subscribeToAccountTx, unsubscribeToAccountTx } from 'js/providers/wsStore/utils'
import type { TxEventInfo } from 'js/types/api-types'
import { TxOrderTypes, TxTypes, TxTimeInForceTypes } from 'js/types/user'
import {
  transactionApi,
  accountApi,
  liquidationApi,
  parseErrorFromCodegen,
  bridgeApi,
} from 'js/util/api/sdk'
import { txResponseSchama } from 'js/util/localStorage'

import wasmUrl from './target.wasm?url'

const handleAndSendTxResponse = async (txType: TxTypes, txResponse: string) => {
  const { txHash, txInfo } = txResponseSchama.parse(JSON.parse(txResponse))

  return new Promise<Tx>((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      const timeoutError = new Error('Tx Timeout')
      Sentry.captureException(timeoutError, { tags: { txHash } })
      reject(timeoutError)
    }, 10000)

    const callback = (tx: Tx) => {
      const { hash, status, event_info } = tx

      if (hash !== txHash || status === TX_STATUSES.StatusPending) {
        return
      }

      const { ae } = JSON.parse(event_info) as TxEventInfo

      clearTimeout(timeoutId)
      unsubscribeToAccountTx(callback)

      if (status === TX_STATUSES.StatusFailed || !!ae) {
        const error = new Error(ae)
        Sentry.captureException(error, { tags: { txHash } })
        reject(error)
      } else {
        resolve(tx)
      }
    }

    subscribeToAccountTx(callback)

    transactionApi
      .sendTx({ tx_type: txType, tx_info: txInfo, price_protection: false })
      .catch((e) =>
        parseErrorFromCodegen(e).then(({ message }) => {
          const error = new Error(message)
          Sentry.captureException(error, { tags: { txHash, message } })
          reject(error)
        }),
      )
  })
}

let wasm: Promise<WebAssembly.WebAssemblyInstantiatedSource> | null = null
let wasmReadyResolve: (v: unknown) => void
const wasmReady = new Promise((resolve) => {
  wasmReadyResolve = resolve
})

function setWasmReady() {
  wasmReadyResolve(true)
}

// Use this to wait for WASM
export async function waitForWasm() {
  return wasmReady
}

export async function initWASM() {
  if (wasm !== null) {
    return wasm
  }
  const go = new window.Go()

  wasm = fetch(wasmUrl)
    .then((response) => response.arrayBuffer())
    .then((buffer) => WebAssembly.instantiate(buffer, go.importObject))

  const instance = await wasm!.then(({ instance }) => instance)
  go.run(instance)
  setTimeout(setWasmReady, 10)
}

export async function getNonce({ accountIndex }: { accountIndex: number }) {
  const { nonce } = await transactionApi.nextNonce({
    account_index: accountIndex,
    api_key_index: 0,
  })

  return nonce
}

export async function getMasterAccountIndex({ l1Address }: { l1Address: string }) {
  const sub_accounts = await accountApi.accountsByL1Address({
    l1_address: l1Address,
  })

  return sub_accounts.sub_accounts.find((obj) => obj.account_type === 0)?.index
}

export async function getFirstPublicPoolIndex({
  l1Address,
}: {
  l1Address: string
}): Promise<number | undefined> {
  try {
    const sub_accounts = await accountApi.accountsByL1Address({
      l1_address: l1Address,
    })
    sub_accounts.sub_accounts.sort((a, b) => a.index - b.index)
    const firstPublicPool = sub_accounts.sub_accounts.find(
      (obj) => obj.account_type === SubAccountType.public,
    )
    return firstPublicPool?.index
  } catch {
    return undefined
  }
}

export async function createClient({
  url,
  seed,
  address,
  accountIndex,
  chainId,
  nonce,
  apiKeyIndex,
}: {
  url: string
  seed: string
  address: string
  chainId: number
  accountIndex: number
  nonce?: number
  apiKeyIndex?: number
}) {
  if (nonce == null) nonce = await getNonce({ accountIndex })
  if (nonce == null) {
    return null
  }

  if (apiKeyIndex == null) {
    apiKeyIndex = 0
  }

  const response = await (
    await window._createClient(url, seed, address, chainId, accountIndex, nonce, apiKeyIndex)
  )()
  return JSON.parse(response)
}

async function signChangePubKey({
  accountIndex,
  signature,
  nonce,
}: {
  accountIndex: number
  signature: string
  nonce: number
}) {
  return (await window._signChangePubKey(accountIndex, signature, nonce))()
}

export async function changePubKey({
  accountIndex,
  signature,
  nonce,
}: {
  accountIndex: number
  signature: string
  nonce: number
}) {
  const txResponse = await signChangePubKey({
    accountIndex,
    signature,
    nonce: nonce,
  })

  return handleAndSendTxResponse(TxTypes.TxTypeL2ChangePubKey, txResponse)
}

const signCreateOrder = async ({
  accountIndex,
  orderBookIndex,
  clientOrderIndex,
  baseAmount,
  price,
  isAsk,
  orderType,
  timeInForce,
  reduceOnly,
  triggerPrice,
  orderExpiry,
  nonce,
}: {
  accountIndex: number
  orderBookIndex: number
  clientOrderIndex: number
  baseAmount: number
  price: number
  isAsk: number
  orderType: TxOrderTypes
  timeInForce: TxTimeInForceTypes
  reduceOnly: number
  triggerPrice: number
  orderExpiry: number
  nonce: number
}) => {
  return (
    await window._signCreateOrder(
      accountIndex,
      orderBookIndex,
      clientOrderIndex,
      baseAmount.toString(),
      price.toString(),
      isAsk,
      orderType,
      timeInForce,
      reduceOnly,
      triggerPrice.toString(),
      orderExpiry,
      nonce,
    )
  )()
}

export async function createOrder(request: {
  accountIndex: number
  orderBookIndex: number
  clientOrderIndex: number
  baseAmount: number
  price: number
  isAsk: number
  orderType: TxOrderTypes
  timeInForce: TxTimeInForceTypes
  orderExpiry: number
  reduceOnly: number
  triggerPrice: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signCreateOrder({ ...request, nonce })
  return handleAndSendTxResponse(TxTypes.TxTypeL2CreateOrder, txResponse)
}

export async function createOrderBatch(
  requests: {
    accountIndex: number
    orderBookIndex: number
    clientOrderIndex: number
    baseAmount: number
    price: number
    isAsk: number
    orderType: TxOrderTypes
    timeInForce: TxTimeInForceTypes
    orderExpiry: number
    reduceOnly: number
    triggerPrice: number
  }[],
) {
  if (requests.length === 0) {
    return
  }
  const accountIndex = requests.at(0)?.accountIndex
  if (!accountIndex) {
    return
  }
  let nonce = await getNonce({ accountIndex: accountIndex })
  const txInfos: string[] = []
  const txHashes: string[] = []
  const txTypes: any[] = []
  for (const request of requests) {
    const txResponse = await signCreateOrder({ ...request, nonce })
    nonce += 1
    const { txHash, txInfo } = txResponseSchama.parse(JSON.parse(txResponse))
    txTypes.push(TxTypes.TxTypeL2CreateOrder)
    txInfos.push(txInfo)
    txHashes.push(txHash)
  }

  const txInfoStr = JSON.stringify(txInfos)
  const txTypeStr = JSON.stringify(txTypes)
  const txHashesStr = JSON.stringify(txTypes)
  return new Promise<Tx>((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      const timeoutError = new Error('Tx Timeout')
      Sentry.captureException(timeoutError, { tags: { txHashesStr } })
      reject(timeoutError)
    }, 10000)
    transactionApi.sendTxBatch({ tx_infos: txInfoStr, tx_types: txTypeStr }).catch((e) =>
      parseErrorFromCodegen(e).then(({ message }) => {
        const error = new Error(message)
        Sentry.captureException(error, { tags: { txHashesStr, message } })
      }),
    )
    const callback = (tx: Tx) => {
      const { hash, status, event_info } = tx

      if (!txHashes.some((h) => h === hash) || status === TX_STATUSES.StatusPending) {
        return
      }
      const { ae } = JSON.parse(event_info) as TxEventInfo
      clearTimeout(timeoutId)
      unsubscribeToAccountTx(callback)
      if (status === TX_STATUSES.StatusFailed || !!ae) {
        const error = new Error(ae)
        Sentry.captureException(error, { tags: { hash } })
        reject(error)
      } else {
        resolve(tx)
      }
    }

    subscribeToAccountTx(callback)
  })
}

async function signCancelOrder({
  accountIndex,
  marketIndex,
  orderIndex,
  nonce,
}: {
  accountIndex: number
  marketIndex: number
  orderIndex: number
  nonce: number
}) {
  return (await window._signCancelOrder(accountIndex, marketIndex, orderIndex, nonce))()
}

export async function cancelOrder(request: {
  accountIndex: number
  marketIndex: number
  orderIndex: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signCancelOrder({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2CancelOrder, txResponse)
}

async function signCreateSubAccount({
  accountIndex,
  nonce,
}: {
  accountIndex: number
  nonce: number
}) {
  return (await window._signCreateSubAccount(accountIndex, nonce))()
}

export async function createSubAccount(request: { accountIndex: number }) {
  const nonce = await getNonce(request)
  const txResponse = await signCreateSubAccount({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2CreateSubAccount, txResponse)
}

async function signCancelAllOrders({
  accountIndex,
  timeInForce,
  time,
  nonce,
}: {
  accountIndex: number
  timeInForce: number
  time: number
  nonce: number
}) {
  return (await window._signCancelAllOrders(accountIndex, timeInForce, time, nonce))()
}

export async function cancelAllOrders(request: {
  accountIndex: number
  timeInForce: number
  time: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signCancelAllOrders({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2CancelAllOrders, txResponse)
}

async function signModifyOrder({
  accountIndex,
  marketIndex,
  orderIndex,
  newBaseAmount,
  newPrice,
  triggerPrice,
  nonce,
}: {
  accountIndex: number
  marketIndex: number
  orderIndex: number
  newBaseAmount: number
  newPrice: number
  triggerPrice: number
  nonce: number
}) {
  return (
    await window._signModifyOrder(
      accountIndex,
      marketIndex,
      orderIndex,
      newBaseAmount,
      newPrice,
      triggerPrice.toString(),
      nonce,
    )
  )()
}

export async function modifyOrder(request: {
  accountIndex: number
  marketIndex: number
  orderIndex: number
  newBaseAmount: number
  newPrice: number
  triggerPrice: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signModifyOrder({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2ModifyOrder, txResponse)
}

async function signTransfer({
  accountIndex,
  toAccountIndex,
  collateralAmount,
  nonce,
}: {
  accountIndex: number
  toAccountIndex: number
  collateralAmount: number
  nonce: number
}) {
  return (await window._signTransfer(accountIndex, toAccountIndex, collateralAmount, nonce))()
}

export async function transfer(request: {
  accountIndex: number
  toAccountIndex: number
  collateralAmount: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signTransfer({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2Transfer, txResponse)
}

// ======= Public Pool =======
async function signCreatePublicPool({
  accountIndex,
  operatorFee,
  initialTotalShares,
  minOperatorShareRate,
  nonce,
}: {
  accountIndex: number
  operatorFee: number
  initialTotalShares: number
  minOperatorShareRate: number
  nonce: number
}) {
  return (
    await window._signCreatePublicPool(
      accountIndex,
      operatorFee,
      initialTotalShares,
      minOperatorShareRate,
      nonce,
    )
  )()
}

export async function createPublicPool(request: {
  accountIndex: number
  operatorFee: number
  initialTotalShares: number
  minOperatorShareRate: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signCreatePublicPool({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2CreatePublicPool, txResponse)
}

async function signMintShares({
  accountIndex,
  publicPoolIndex,
  shareAmount,
  nonce,
}: {
  accountIndex: number
  publicPoolIndex: number
  shareAmount: number
  nonce: number
}) {
  return (await window._signMintShares(accountIndex, publicPoolIndex, shareAmount, nonce))()
}

export async function mintShares(request: {
  accountIndex: number
  publicPoolIndex: number
  shareAmount: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signMintShares({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2MintShares, txResponse)
}

async function signBurnShares({
  accountIndex,
  publicPoolIndex,
  shareAmount,
  nonce,
}: {
  accountIndex: number
  publicPoolIndex: number
  shareAmount: number
  nonce: number
}) {
  return (await window._signBurnShares(accountIndex, publicPoolIndex, shareAmount, nonce))()
}

export async function burnShares(request: {
  accountIndex: number
  publicPoolIndex: number
  shareAmount: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signBurnShares({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2BurnShares, txResponse)
}

async function signUpdatePublicPool({
  accountIndex,
  publicPoolIndex,
  status,
  operatorFee,
  minOperatorShareRate,
  nonce,
}: {
  accountIndex: number
  publicPoolIndex: number
  status: number
  operatorFee: number
  minOperatorShareRate: number
  nonce: number
}) {
  return (
    await window._signUpdatePublicPool(
      accountIndex,
      publicPoolIndex,
      status,
      operatorFee,
      minOperatorShareRate,
      nonce,
    )
  )()
}

export async function updatePublicPool(request: {
  accountIndex: number
  publicPoolIndex: number
  status: number
  operatorFee: number
  minOperatorShareRate: number
}) {
  const nonce = await getNonce(request)
  const txResponse = await signUpdatePublicPool({ ...request, nonce })

  return handleAndSendTxResponse(TxTypes.TxTypeL2UpdatePublicPool, txResponse)
}

// ================================== Post requests with authentication =================================
async function signNotifRead({
  accountIndex,
  liquidationIds,
}: {
  accountIndex: number
  liquidationIds: string
}) {
  return (await window._signNotifRead(accountIndex, liquidationIds))()
}

export async function notifRead({
  accountIndex,
  liquidationIds,
}: {
  accountIndex: number
  liquidationIds: number[]
}) {
  const liquidationIdsStr = JSON.stringify(liquidationIds)
  const signature = await signNotifRead({ accountIndex, liquidationIds: liquidationIdsStr })

  return liquidationApi.markNotifRead({
    notif_type: 'liquidation',
    liquidation_ids: liquidationIdsStr,
    signature,
    account_index: accountIndex,
    api_key_index: 0,
  })
}

export async function createAuthToken({ accountIndex }: { accountIndex: number }) {
  return (await window._createAuthToken(accountIndex))()
}

export async function setAccountMetadata({
  targetAccountIndex,
  masterAccountIndex,
  name,
  description,
}: {
  targetAccountIndex: number
  masterAccountIndex: number
  name: string
  description: string
}) {
  const auth = await createAuthToken({ accountIndex: masterAccountIndex })

  return transactionApi.setAccountMetadata({
    target_account_index: targetAccountIndex,
    master_account_index: masterAccountIndex,
    metadata: JSON.stringify({ name, description }),
    api_key_index: 0,
    auth: auth.token,
  })
}

async function signUpdateLeverage({
  accountIndex,
  marketIndex,
  initialMarginFraction,
  nonce,
}: {
  accountIndex: number
  marketIndex: number
  initialMarginFraction: number
  nonce: number
}) {
  return await window._signUpdateLeverage(accountIndex, marketIndex, initialMarginFraction, nonce)
}

export async function updateLeverage(request: {
  accountIndex: number
  marketIndex: number
  initialMarginFraction: number
}) {
  const nonce = await getNonce(request)

  const txResponse = await (await signUpdateLeverage({ ...request, nonce }))()

  return handleAndSendTxResponse(TxTypes.TxTypeL2UpdateLeverage, txResponse)
}

async function signWithdraw({
  accountIndex,
  assetAmount,
  nonce,
}: {
  accountIndex: number
  assetAmount: string
  nonce: number
}) {
  return await window._signWithdraw(accountIndex, assetAmount, nonce)
}

export async function withdraw(request: { accountIndex: number; assetAmount: string }) {
  const nonce = await getNonce(request)

  const txResponse = await (await signWithdraw({ ...request, nonce }))()

  return handleAndSendTxResponse(TxTypes.TxTypeL2Withdraw, txResponse)
}

export async function fastWithdraw(request: {
  accountIndex: number
  toAccountIndex: number
  collateralAmount: number
  toAddress: string
}) {
  const nonce = await getNonce(request)
  const txResponse = await signTransfer({ ...request, nonce })
  const { txInfo } = txResponseSchama.parse(JSON.parse(txResponse))
  const authToken = await createAuthToken({ accountIndex: request.accountIndex })

  return bridgeApi.fastwithdraw({
    tx_info: txInfo,
    to_address: request.toAddress,
    auth: authToken.token,
  })
}
