import { useTranslation } from 'react-i18next'

import { useBlockHeight } from 'js/providers/orderBookSlice/selectors'

export const CurrentBlock = () => {
  const { t } = useTranslation()
  const blockHeight = useBlockHeight()

  if (blockHeight === null) return null

  return (
    <div className="flex cursor-pointer flex-col justify-center whitespace-nowrap py-3 max-mobile:p-2">
      <div
        className="flex items-baseline gap-2"
        role="button"
        onClick={() =>
          window.open(`${import.meta.env.VITE_SCANNER_BASE}/block/${blockHeight}`, '_blank')
        }
      >
        <div className="size-3 animate-[flicker_2s_infinite] rounded-full bg-green-main" />
        <p className="typography-label-1 text-white max-mobile:hidden">{t('block')}</p>
        <p className="typography-body-2 text-green-main">{blockHeight}</p>
      </div>
    </div>
  )
}
