import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { Button } from 'js/shared-components'
import { Modal } from 'js/shared-components/Modal'
import { accountApi } from 'js/util/api/sdk'
import { useUserAccountPortfolioStats } from 'js/util/positions'

import Toast from './uikit/Toast'

interface FaucetModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const FaucetModal = ({ open, onOpenChange }: FaucetModalProps) => {
  const { t } = useTranslation()
  const userAddress = useUserAddress()
  const portfolioStats = useUserAccountPortfolioStats()
  const canRequestFunds = portfolioStats !== null && portfolioStats.portfolioValue <= 100

  const faucetMutation = useMutation({
    mutationFn: () => accountApi.faucet({ l1_address: userAddress }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('faucet_modal_toasts_success')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('toasts_error')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => onOpenChange(false),
  })

  return (
    <Modal title={t('faucet_modal_title')} open={open} onOpenChange={onOpenChange}>
      <div className="flex w-full flex-col gap-3">
        <p className="typography-label-1 text-white">
          {canRequestFunds ? t('faucet_modal_description') : t('faucet_modal_description_error')}
        </p>
        <Button
          disabled={!canRequestFunds}
          isLoading={faucetMutation.isPending}
          className="w-full"
          onClick={() => faucetMutation.mutate()}
        >
          {t('request_funds')}
        </Button>
      </div>
    </Modal>
  )
}
