import type { StateCreator } from 'zustand'

export interface UserSlice {
  accountIndex: number | null
  showOnboarding: boolean
  showWhitelist: boolean
  showFeedback: boolean
}

export const createUserSlice: StateCreator<UserSlice, [], [], UserSlice> = () => ({
  accountIndex: null,
  showOnboarding: false,
  showWhitelist: false,
  showFeedback: false,
})
