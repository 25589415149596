import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { toast } from 'sonner'

import { DepositButton } from 'js/pages/deposit/form'
import { CreateSubAccountModal } from 'js/pages/sub-accounts/CreateSubAccount'
import { TransferModal } from 'js/pages/sub-accounts/TransferModal'
import { WithdrawButton } from 'js/pages/withdraw/form'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import { useIsGeoLocBlocked, useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useWallet } from 'js/providers/hooks/useWallet'
import { useLighterStore } from 'js/providers/lighterStore'
import { useWsSubStore } from 'js/providers/wsStore'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Button } from 'js/shared-components'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Clickable } from 'js/shared-components/Clickable'
import { FaucetModal } from 'js/shared-components/FaucetModal'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import Icon from 'js/shared-components/uikit/Icon'
import { Popover, PopoverContent, PopoverTrigger } from 'js/shared-components/uikit/Popover'
import Toast from 'js/shared-components/uikit/Toast'
import { formatUSD } from 'js/util/formatting'
import { useUserAccountPortfolioStats } from 'js/util/positions'
import { copyStringToClipboard } from 'js/util/util'

import RequestFundsButton from './RequestFundsButton'
import SubAccountsList from './SubAccountsList'

export const WalletDropdown = () => {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false)
  const [subAccountModalOpen, setSubAccountModalOpen] = useState(false)
  const queryClient = useQueryClient()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [isFaucetOpen, setIsFaucetOpen] = useState(false)
  const userAddress = useUserAddress()
  const portfolioStats = useUserAccountPortfolioStats()
  const [isSubAccountsOpen, setIsSubAccountsOpen] = useState(false)
  const { handleLogOut } = useDynamicContext()
  const isGeoLocBlocked = useIsGeoLocBlocked()
  const accountExistence = useAccountExistence()
  const { t } = useTranslation()

  const { isMobile } = useResponsiveness()

  const disconnectWallet = useWallet().disconnect

  const links = [
    {
      icon: 'address',
      text: t('address'),
      onClick: () => {
        copyStringToClipboard(userAddress)
        toast.custom((toastId) => (
          <Toast
            level="success"
            description={t('address_copied')}
            onClose={() => toast.dismiss(toastId)}
          />
        ))
      },
    },
    {
      icon: 'explorer',
      text: t('explorer'),
      onClick: () =>
        window.open(`${import.meta.env.VITE_SCANNER_BASE}/account/${userAddress}`, '_blank'),
    },
    {
      icon: 'logout',
      text: t('log_out'),
      onClick: () => {
        queryClient.removeQueries({ queryKey: ['isRegistered'] })
        window.localStorage.removeItem('signature')
        window.localStorage.removeItem('lastAccountIndex')
        useWsSubStore.getState().actions.logout()
        handleLogOut()
        disconnectWallet()
        setPopoverOpen(false)
      },
    },
  ] as const

  return (
    <>
      <TransferModal open={isTransferModalOpen} onOpenChange={setIsTransferModalOpen} />
      <CreateSubAccountModal open={subAccountModalOpen} onOpenChange={setSubAccountModalOpen} />
      <FaucetModal open={isFaucetOpen} onOpenChange={setIsFaucetOpen} />
      <Backdrop isVisible={isMobile && popoverOpen} onClick={() => setPopoverOpen(false)} />
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild onClick={() => setPopoverOpen((prev) => !prev)}>
          {isMobile ? (
            <Clickable>
              <Icon icon="wallet" className="size-5" />
            </Clickable>
          ) : (
            <Button className="w-fit items-center gap-3">
              <Icon icon="wallet" className="size-5" />
              {t('wallet')}
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent
          className="w-80 bg-black-darkest/30 p-4 backdrop-blur-xl max-mobile:w-screen max-mobile:rounded-none max-mobile:border-0"
          align="end"
          sideOffset={20}
        >
          {isSubAccountsOpen ? (
            <>
              <button
                onClick={() => setIsSubAccountsOpen(false)}
                className="sticky flex w-full items-center justify-center gap-1 p-2"
              >
                <SmallArrow className="absolute left-0 w-2.5" direction="left" />
                <p className="typography-text-7 text-white">{t('sub_accounts')}</p>
              </button>
              <div className="flex max-h-[400px] flex-col gap-4 overflow-y-auto">
                <SubAccountsList openTransferModalOpen={() => setIsTransferModalOpen(true)} />
                {!isGeoLocBlocked && (
                  <Button className="w-full" onClick={() => setSubAccountModalOpen(true)}>
                    {t('create_sub_account')}
                  </Button>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-col gap-3">
              <div className="flex w-full flex-col items-center gap-2 py-4">
                <div className="flex w-full justify-center">
                  <div className="flex w-full items-center justify-around">
                    <div className="flex items-center gap-1">
                      <Jazzicon diameter={20} seed={jsNumberForAddress(userAddress)} />
                      <Clickable
                        onClick={() => {
                          copyStringToClipboard(userAddress)
                          toast.custom((toastId) => (
                            <Toast
                              level="success"
                              description={t('address_copied')}
                              onClose={() => toast.dismiss(toastId)}
                            />
                          ))
                        }}
                      >
                        <p className="typography-body-2 relative max-w-[190px] overflow-hidden truncate text-white">
                          {userAddress.length > 13
                            ? userAddress
                                .substring(0, 6)
                                .concat('...')
                                .concat(userAddress.slice(-4))
                            : userAddress}
                        </p>
                      </Clickable>
                    </div>
                    <Clickable
                      color="blue"
                      onClick={() => setIsSubAccountsOpen(true)}
                      className="flex items-center gap-1"
                    >
                      <p className="typography-label-1">{t('sub_accounts')}</p>
                      <Icon icon="chevron" className="size-3 -rotate-90" />
                    </Clickable>
                  </div>
                </div>

                <p className="max-w-full self-center overflow-hidden break-words p-1 text-center text-2xl font-light">
                  {portfolioStats === null ? '-' : formatUSD(portfolioStats.portfolioValue)}
                </p>
                <div className="flex gap-6 self-center">
                  {links.map(({ text, onClick, icon }) => (
                    <Clickable key={text} onClick={onClick} className="flex flex-col gap-2">
                      <Icon icon={icon} className="size-10 rounded-full bg-grey-main" />
                      <p className="typography-body-1 text-white">{text}</p>
                    </Clickable>
                  ))}
                </div>
              </div>
              {accountExistence === 'KeysDontMatch' && (
                <Button
                  className="w-full"
                  onClick={() => useLighterStore.setState({ showOnboarding: true })}
                >
                  {t('authenticate')}
                </Button>
              )}
              {isMobile && <DepositButton onClickCallback={() => setPopoverOpen(false)} />}
              <WithdrawButton onClickCallback={() => setPopoverOpen(false)} />
              <RequestFundsButton
                openModal={() => setIsFaucetOpen(true)}
                closeDropdown={() => setPopoverOpen(false)}
              />
            </div>
          )}
        </PopoverContent>
      </Popover>
    </>
  )
}
