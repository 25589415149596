import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { LOCALES } from 'js/util/i18next'

import { Clickable } from './Clickable'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem } from './uikit/DropdownMenu'
import Icon from './uikit/Icon'

// don't localize this
const LOCALE_LABEL_MAP = {
  en: 'English',
  zh: '简体中文',
} as const

const LanguagePicker = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useTranslation()

  useEffect(() => {
    const paramsLocale = searchParams.get('locale')

    if (paramsLocale === i18n.language) {
      return
    }

    searchParams.set('locale', i18n.language)

    if (i18n.language === 'en') {
      searchParams.delete('locale')
    }

    setSearchParams(searchParams, { replace: true })
  }, [i18n.language, searchParams, setSearchParams])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild
        className="flex h-10 items-center gap-2 rounded-lg border border-white/10 bg-white/5 px-3 hover:bg-white/10"
      >
        <Clickable color="white">
          <Icon icon="globe" className="size-5" />
          <Icon
            icon="chevron"
            className="size-2.5 rotate-0 text-white-opaque transition-all group-data-[state=open]:rotate-180 max-mobile:hidden"
          />
        </Clickable>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {LOCALES.map((locale) => (
          <DropdownMenuItem
            key={locale}
            onClick={() => {
              localStorage.setItem('selected_locale', locale)
              i18n.changeLanguage(locale)
            }}
            className="flex min-w-40 justify-between"
          >
            {LOCALE_LABEL_MAP[locale]}
            {i18n.language === locale && (
              <Icon
                icon="check"
                className="size-5 rotate-0 text-white transition-all group-data-[state=open]:rotate-180"
              />
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default LanguagePicker
