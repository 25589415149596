import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import type {
  AccountRequest,
  DetailedAccount,
  DetailedAccounts,
  ResponseError,
} from 'zklighter-perps'

import { LIGHTER_CHAIN_ID } from 'js/constants/shared'
import { getLocation } from 'js/util/api/api'
import { accountApi } from 'js/util/api/sdk'
import {
  clientRegistrationDataSchema,
  readLSLastAccountIndex,
  readLSSignature,
  writeLSLastAccountIndex,
  writeLSSignature,
} from 'js/util/localStorage'
import { useIdentifyUser } from 'js/util/sentry'
import { isMainnet } from 'js/util/util'
import { createClient, initWASM } from 'js/zklighter-js-sdk/sdk'

import { useLighterStore } from '../lighterStore'
import { SubAccountType } from '../types'
import { useAccountIndex } from '../userSlice/selectors'

export const useUserAddress = () => {
  const { primaryWallet } = useDynamicContext()

  return primaryWallet?.address ?? ''
}

export const useIsGeoLocBlockedQuery = () =>
  useQuery({
    queryKey: ['geoLocation'],
    queryFn: () => getLocation(),
    select: ({ country }) =>
      ['US', 'CA', 'GB', 'CN', 'KP', 'RU', 'UA', 'CU', 'IR', 'VE', 'SY'].includes(country) &&
      isMainnet(),
    refetchInterval: 30000,
  })

// loading state is being handled upstream
export const useIsGeoLocBlocked = () => useIsGeoLocBlockedQuery().data!

export const useIsWhitelistedQuery = () => {
  const userAddress = useUserAddress()

  return useQuery({
    queryKey: ['whitelist', userAddress],
    queryFn: () => accountApi.isWhitelisted({ l1_address: userAddress }),
    select: ({ whitelisted }) => whitelisted || !isMainnet(),
    enabled: !!userAddress.length,
  })
}

export const useAccountsQuery = () => {
  const location = useLocation()
  const userAddress = useUserAddress()
  const isWhitelistedQuery = useIsWhitelistedQuery()

  const params: AccountRequest = useMemo(
    () => ({ by: 'l1_address', value: userAddress }),
    [userAddress],
  )

  return useQuery<DetailedAccounts, ResponseError>({
    queryKey: ['account', params],
    queryFn: () => accountApi.account(params),
    enabled: !!userAddress && isWhitelistedQuery.data,
    refetchInterval: (query) => {
      // if the query is in error and the error is not 400 (not found), refetch every 5 seconds
      if (
        (query.state.error && query.state.error.response?.status !== 400) ||
        location.pathname.includes('sub-accounts')
      ) {
        return 5000
      }

      return undefined
    },
  })
}

const useVerifyAccount = () => {
  const queryClient = useQueryClient()

  return useCallback(
    async (account: DetailedAccount) => {
      if (queryClient.getQueryData(['isRegistered', account.index])) {
        return true
      }

      const { data: signatureFromStorage } = readLSSignature()

      const storageAccount = signatureFromStorage?.[account.index]

      if (!storageAccount) {
        return false
      }

      const apiKey = await accountApi
        .apikeys({ api_key_index: 0, account_index: account.index })
        .then(({ api_keys }) => api_keys[0]?.public_key)
        .catch(() => '')

      if (!apiKey || storageAccount.pk !== apiKey) {
        return false
      }

      await initWASM()
      const clientResData = await createClient({
        url: '',
        seed: storageAccount.seed,
        address: account.l1_address,
        chainId: LIGHTER_CHAIN_ID,
        accountIndex: account.index,
        nonce: 0, // nonce is not used only used when initializing
      })

      const { error } = clientRegistrationDataSchema.safeParse(clientResData)

      // recovery went wrong, removing from storage
      if (error) {
        writeLSSignature({
          ...signatureFromStorage,
          [account.index]: undefined,
        })

        return false
      }

      return true
    },
    [queryClient],
  )
}

export const useVerifyAndSwitchAccountMutation = () => {
  const verifyAccount = useVerifyAccount()
  const setIsRegistered = useSetIsRegistered()

  return useMutation({
    mutationFn: (account: DetailedAccount) =>
      verifyAccount(account).then((isRegistered) => {
        setIsRegistered(account.index, isRegistered)
        writeLSLastAccountIndex(account.index)

        useLighterStore.setState({ accountIndex: account.index, showOnboarding: !isRegistered })
      }),
  })
}

export const useSetIsRegistered = () => {
  const queryClient = useQueryClient()

  return (accountIndex: number, value: boolean) =>
    queryClient.setQueryData(['isRegistered', accountIndex], value)
}

export const useIsRegisteredQuery = () => {
  const verifyAccount = useVerifyAccount()
  const userAccount = useUserAccount()

  return useQuery({
    queryKey: ['isRegistered', userAccount?.index],
    queryFn: () => verifyAccount(userAccount!),
    initialData: null,
    enabled: !!userAccount,
  })
}

export const useUserAccount = () => {
  const accountIndex = useAccountIndex()
  const accountQuery = useAccountsQuery()

  return useMemo(
    () => accountQuery.data?.accounts.find(({ index }) => index === accountIndex),
    [accountQuery.data, accountIndex],
  )
}

export const useMainAccount = () => {
  const accountsQuery = useAccountsQuery()

  return useMemo(
    () =>
      accountsQuery.data?.accounts.find(({ account_type }) => account_type === SubAccountType.main),
    [accountsQuery.data],
  )
}

export const useSubAccounts = () => {
  const accountQuery = useAccountsQuery()

  return useMemo(
    () =>
      accountQuery.data?.accounts.filter(
        ({ account_type }) => account_type === SubAccountType.sub,
      ) ?? [],
    [accountQuery.data],
  )
}

export const usePublicAccounts = () => {
  const accountQuery = useAccountsQuery()

  return useMemo(
    () =>
      accountQuery.data?.accounts.filter(
        ({ account_type }) => account_type === SubAccountType.public,
      ) ?? [],
    [accountQuery.data],
  )
}

const useCreateKnownClients = () => {
  const queryClient = useQueryClient()
  const verifyAccount = useVerifyAccount()
  const accountsQuery = useAccountsQuery()

  useEffect(() => {
    const lsSignature = readLSSignature().data

    if (!lsSignature || !accountsQuery.data) {
      return
    }

    Object.keys(lsSignature).forEach((accountIndex) => {
      const account = accountsQuery.data.accounts.find(({ index }) => `${index}` === accountIndex)

      if (!account) {
        return
      }

      verifyAccount(account).then((isRegistered) =>
        queryClient.setQueryData(['isRegistered', account.index], isRegistered),
      )
    })
  }, [accountsQuery.data, verifyAccount, queryClient])
}

export const useAccount = () => {
  const accountsQuery = useAccountsQuery()

  useEffect(() => {
    if (accountsQuery.isPending) {
      return
    }

    if (accountsQuery.isError) {
      return useLighterStore.setState({ accountIndex: null })
    }

    const subAccounts = accountsQuery.data.accounts
    useLighterStore.setState({
      accountIndex:
        (
          subAccounts.find(({ index }) => index === readLSLastAccountIndex().data) ??
          subAccounts.find(({ account_type }) => account_type === SubAccountType.main)
        )?.index ?? null,
    })
  }, [accountsQuery.isPending, accountsQuery.isError, accountsQuery.data])

  useCreateKnownClients()
  useIdentifyUser()
}
