import { useQuery } from '@tanstack/react-query'
import { fromUnixTime, getUnixTime, formatDate, sub as subDuration } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { PnlRequest } from 'zklighter-perps'

import { colors, fonts } from 'css/css'
import {
  useAccountsQuery,
  useIsWhitelistedQuery,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { useEchart } from 'js/shared-components/useEchart'
import { accountApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { formatUSD } from 'js/util/formatting'
import { isMainnet } from 'js/util/util'

import { ChartLoader } from '../pages/trade/components/chart/ChartLoader'

const TIMELINES = [
  {
    key: '1d',
    value: 1,
    timeMeasure: 'days',
    resolution: '1h',
    titleKey: 'pnl_balance_chart_timeline_1d_title',
  },
  {
    key: '1w',
    value: 1,
    timeMeasure: 'weeks',
    resolution: '1h',
    titleKey: 'pnl_balance_chart_timeline_1w_title',
  },
  {
    key: '1m',
    value: 1,
    timeMeasure: 'months',
    resolution: '4h',
    titleKey: 'pnl_balance_chart_timeline_1m_title',
  },
] as const
type Timeline = (typeof TIMELINES)[number]['key']

interface PnlBalanceChartsProps {
  accountIndex: number | null
  isPublicPool?: boolean
}

export const PnlBalanceCharts = ({ accountIndex, isPublicPool = false }: PnlBalanceChartsProps) => {
  const { t } = useTranslation()
  const [activeChart, setActiveChart] = useState<'pnl' | 'balance'>('balance')
  const [timeline, setTimeline] = useState<Timeline>('1d')
  const selectedTimeline = TIMELINES.find((t) => t.key === timeline)!
  const { isMobile } = useResponsiveness()

  const userAddress = useUserAddress()
  const accountsQuery = useAccountsQuery()
  const isAccountLoading = accountsQuery.isLoading
  const isWhitelistedQuery = useIsWhitelistedQuery()

  const startTime = useMemo(
    () =>
      getUnixTime(
        subDuration(new Date(), {
          [selectedTimeline.timeMeasure]: selectedTimeline.value,
        }),
      ),
    [selectedTimeline],
  )
  const endTime = useMemo(() => getUnixTime(new Date()), [])

  const params: PnlRequest = useMemo(
    () => ({
      by: 'index' as const,
      value: accountIndex?.toString() as string,
      resolution: selectedTimeline.resolution,
      start_timestamp: startTime,
      end_timestamp: endTime,
      count_back: 0,
    }),
    [accountIndex, selectedTimeline, startTime, endTime],
  )
  const pnlQuery = useQuery({
    queryKey: ['pnl', params],
    queryFn: () => accountApi.pnl({ ...params, ignore_transfers: false }),
    enabled: accountIndex !== null,
    staleTime: 1000000,
  })
  const balanceQuery = useQuery({
    queryKey: ['balance', params],
    queryFn: () => accountApi.pnl({ ...params, ignore_transfers: true }),
    enabled: accountIndex !== null,
    staleTime: 1000000,
  })

  const userBalance: [number, number][] = useMemo(
    () => (balanceQuery.data?.pnl ?? []).map((d) => [d.timestamp, d.value]),
    [balanceQuery.data],
  )
  const pnl: [number, number][] = useMemo(
    () => (pnlQuery.data?.pnl ?? []).map((d) => [d.timestamp, d.value]),
    [pnlQuery.data],
  )

  const chartData = useMemo(
    () =>
      (isPublicPool || activeChart === 'balance' ? userBalance : pnl).map((d) => [
        fromUnixTime(d[0]),
        d[1],
      ]),
    [activeChart, pnl, userBalance, isPublicPool],
  )

  const chartOpts = useMemo(
    () => ({
      textStyle: { fontFamily: fonts.satoshi },
      grid: { left: 12, right: 50, top: 10, bottom: 30 },
      tooltip: {
        trigger: 'axis',
        backgroundColor: colors.greyMain,
        borderWidth: 0,
        padding: 3,
        formatter: (d: { data: [number, number] }[]) => {
          const dataPoint = d[0]
          if (!dataPoint) {
            return null
          }

          const [ts, value] = dataPoint.data
          const containerClassName = cn(
            'relative flex min-w-[250px] flex-col gap-1 p-4',
            'before:absolute before:left-0 before:top-0 before:h-full before:w-0.5',
            { 'before:bg-green-main': value > 0, 'before:bg-red-main': value < 0 },
          )
          const label = isPublicPool ? t('tvl') : activeChart === 'pnl' ? t('pnl') : t('balance')

          return `
            <div class="${containerClassName}">
              <div class="text-white">${formatDate(ts, "dd MMM''yy HH:mm")}</div>
              <div class="flex justify-between">
                <span class="text-white-opaque">${label}</span>
                <span class="text-white">${formatUSD(value)}</span>
              </div>
            </div>
          `
        },
      },
      xAxis: {
        type: 'time',
        show: true,
        min: chartData[0]?.[0],
        max: chartData[chartData.length - 1]?.[0],
        axisLine: { onZero: false },
        axisLabel: {
          color: colors.whiteMain,
        },
      },
      yAxis: {
        show: true,
        position: 'right',
        min: Number(Math.min(...chartData.map((d) => Number(d[1]))))?.toFixed(2),
        max: Number(Math.max(...chartData.map((d) => Number(d[1]))))?.toFixed(2),
        axisLine: { onZero: false },
        axisLabel: {
          color: colors.whiteMain,
        },
      },
      series: [
        {
          name: isPublicPool ? t('tvl') : t('pnl'),
          encode: { x: 'timestamp', y: 'value' },
          type: 'line',
          data: chartData,
          lineStyle: { color: colors.greenMain, width: 0.8 },
        },
      ],
      backgroundColor: 'transparent',
    }),
    [chartData, activeChart, isPublicPool, t],
  )

  const { chartContainerRef, disposeRef } = useEchart(chartOpts, isAccountLoading, accountIndex)

  useEffect(() => {
    const dispose = disposeRef.current
    if (accountIndex === null && !isPublicPool) {
      dispose()
    }
    return () => {
      dispose()
    }
  }, [disposeRef, accountIndex, isPublicPool])

  return (
    <>
      <div className="flex w-full justify-between bg-white/5 p-5 max-mobile:p-3">
        {!isPublicPool && (
          <div className="flex rounded-lg bg-white/5 p-1 max-mobile:w-full">
            <div
              className={cn(
                'typography-label-1 cursor-pointer rounded-lg px-4 py-2 text-center transition-all max-mobile:flex-1',
                {
                  'bg-transparent text-white-opaque': activeChart !== 'balance',
                  'bg-white/5 text-white': activeChart === 'balance',
                },
              )}
              onClick={() => setActiveChart('balance')}
            >
              {t('balance')}
            </div>
            <div
              className={cn(
                'typography-label-1 cursor-pointer rounded-lg px-4 py-2 text-center transition-all max-mobile:flex-1',
                {
                  'bg-transparent text-white-opaque': activeChart !== 'pnl',
                  'bg-white/5 text-white': activeChart === 'pnl',
                },
              )}
              onClick={() => setActiveChart('pnl')}
            >
              {t('pnl')}
            </div>
          </div>
        )}
        {isPublicPool && (
          <div className="flex rounded-lg bg-white/5 p-1 max-mobile:w-full">
            <div
              className={cn(
                'typography-label-1 rounded-lg px-4 py-2 text-center transition-all max-mobile:flex-1',
                {
                  'bg-transparent text-white-opaque': activeChart !== 'balance',
                  'bg-white/5 text-white': activeChart === 'balance',
                },
              )}
              onClick={() => setActiveChart('balance')}
            >
              {t('tvl')}
            </div>
          </div>
        )}
        {!isMobile && (
          <div className="flex rounded-lg bg-white/5 p-1">
            {TIMELINES.map(({ key, titleKey }) => (
              <div
                className={cn(
                  'typography-label-1 cursor-pointer rounded-lg px-4 py-2 transition-all duration-500',
                  {
                    'bg-transparent text-white-opaque': timeline !== key,
                    'bg-white/5 text-white': timeline === key,
                  },
                )}
                key={key}
                onClick={() => setTimeline(key)}
              >
                {t(titleKey)}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="relative flex max-w-full flex-1">
        <div className="size-full">
          {isAccountLoading ? (
            <div className="flex h-full min-h-[200px] flex-col justify-center bg-white/5">
              <ChartLoader />
            </div>
          ) : (
            <div className="flex h-full min-h-[200px] flex-col justify-center bg-white/5">
              {accountIndex !== null ? (
                <div className="absolute inset-0 min-h-full" ref={chartContainerRef} />
              ) : (
                <div className="flex size-full flex-col items-center justify-center ">
                  <p className="typography-text-6 text-white">
                    {!userAddress
                      ? t('ctas_connect_wallet')
                      : !isWhitelistedQuery.data
                        ? ''
                        : isMainnet()
                          ? t('ctas_deposit_funds')
                          : t('ctas_request_funds')}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className="flex w-full bg-white/5 p-3">
          <div className="flex rounded-lg bg-white/5 p-1 max-mobile:w-full">
            {TIMELINES.map(({ key, titleKey }) => (
              <div
                className={cn(
                  'typography-label-1 rounded-lg px-4 py-2 text-center transition-all max-mobile:flex-1',
                  {
                    'bg-transparent text-white-opaque': timeline !== key,
                    'bg-white/5 text-white': timeline === key,
                  },
                )}
                key={key}
                onClick={() => setTimeline(key)}
              >
                {t(titleKey)}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
