import { useMutation } from '@tanstack/react-query'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { OrderTypeEnum } from 'zklighter-perps'

import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useIsGeoLocBlocked } from 'js/providers/hooks/useAccountQuery'
import { useOrderBook } from 'js/providers/orderBookSlice/selectors'
import type { Position } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Button } from 'js/shared-components'
import Toast from 'js/shared-components/uikit/Toast'
import type { TxEventInfo } from 'js/types/api-types'
import { TxOrderTypes, TxTimeInForceTypes } from 'js/types/user'
import { createOrder } from 'js/zklighter-js-sdk/sdk'

import { useOrderInputStore, useOrderToPlaceUpdater } from '../PlaceOrder'

import { OrderNotification } from './OrderNotification'

const useClosePositionMutation = () => {
  const { t } = useTranslation()
  const accountIndex = useAccountIndex() ?? -1
  const maxSlippage = useOrderInputStore().maxSlippage
  const toastIdRef = useRef<number | string | null>(null)
  const orderBookMetas = useOrderBookMetas()
  const orderBook = useOrderBook() ?? { asks: [], bids: [] }
  const { matchInfo } = useOrderToPlaceUpdater()

  const { amount0: orderSize, estPrice } = matchInfo
  return useMutation({
    mutationFn: async (position: Position) => {
      const market = orderBookMetas[position.market_id]!
      const isAsk = position.sign === 1

      const amount0 = Math.floor(Number(orderSize) * 10 ** market.size_decimals)
      const orders = isAsk ? orderBook.asks : orderBook.bids
      const bestPrice = Number(orders[0]?.price)
      const worstExecutionPrice = bestPrice * ((100 + maxSlippage * (isAsk ? -1 : 1)) / 100)
      const price = worstExecutionPrice * 10 ** market.price_decimals

      toastIdRef.current = toast.custom(
        () => (
          <OrderNotification
            price={Number(estPrice)}
            size={Number(orderSize)}
            orderType={OrderTypeEnum.Market}
            isAsk={isAsk}
            marketId={position.market_id}
            onClose={() => toast.dismiss(toastIdRef.current!)}
          />
        ),
        { position: 'top-right', duration: 12000 },
      )

      return createOrder({
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: position.market_id,
        baseAmount: Math.floor(amount0),
        price: isAsk ? Math.floor(price) : Math.ceil(price),
        orderExpiry: 0,
        isAsk: Number(isAsk),
        orderType: TxOrderTypes.OrderTypeMarket,
        timeInForce: TxTimeInForceTypes.OrderImmediateOrCancel,
        reduceOnly: 0,
        triggerPrice: 0,
      })
    },
    onSuccess: (tx, position) => {
      const eventInfo = JSON.parse(tx.event_info) as TxEventInfo
      const orderIndex = eventInfo.to ? eventInfo.to.i : eventInfo.i
      const isAsk = position.sign === 1

      toast.custom(
        () => (
          <OrderNotification
            price={Number(estPrice)}
            size={Number(orderSize)}
            orderType={OrderTypeEnum.Market}
            isAsk={isAsk}
            marketId={position.market_id}
            orderIndex={orderIndex}
            onClose={() => toast.dismiss(toastIdRef.current!)}
          />
        ),
        { id: toastIdRef.current! },
      )

      useOrderInputStore.getState().changeIsClosingPosition(false)
    },
    onError: () => {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('errors_generic_try_again')}
          onClose={() => toast.dismiss(toastId)}
        />
      ))

      toast.dismiss(toastIdRef.current!)
    },
  })
}

interface ClosePositionButtonProps {
  position: Position
}

const ClosePositionButton = ({ position }: ClosePositionButtonProps) => {
  const { t } = useTranslation()
  const isGeoLocBlocked = useIsGeoLocBlocked()
  const closePositionMutation = useClosePositionMutation()
  const maxSlippage = useOrderInputStore().maxSlippage
  const {
    matchInfo: { amount0: orderSize, fullyFilled },
    slippage,
  } = useOrderToPlaceUpdater()
  const notEnoughLiquidity = !fullyFilled

  if (isGeoLocBlocked) {
    return null
  }

  if (notEnoughLiquidity) {
    return (
      <Button className="w-full" disabled color="red">
        {t('not_enough_liquidity')}
      </Button>
    )
  }

  if (slippage > maxSlippage) {
    return (
      <Button className="w-full" disabled color="red">
        {t('too_much_slippage')}
      </Button>
    )
  }

  return (
    <Button
      className="w-full"
      color="red"
      onClick={() => closePositionMutation.mutate(position)}
      disabled={Number(orderSize) === 0}
      isLoading={closePositionMutation.isPending && !closePositionMutation.isError}
    >
      {t('close_position')}
    </Button>
  )
}

export default ClosePositionButton
