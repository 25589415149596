import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  useMainAccount,
  usePublicAccounts,
  useSubAccounts,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { SubAccountType } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Clickable } from 'js/shared-components/Clickable'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { isZero } from 'js/util/util'

const SubAccountsList = ({ openTransferModalOpen }: { openTransferModalOpen: () => void }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const accountIndex = useAccountIndex()
  const mainAccount = useMainAccount()!
  const subAccounts = useSubAccounts()
  const publicAccounts = usePublicAccounts()
  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()
  const isOnTradePage = useLocation().pathname.includes('/trade')

  return (
    <Table className="!table-fixed">
      <TableHeader>
        <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
          <TableCell>
            <p className="typography-body-2 text-white">{t('name')}</p>
          </TableCell>
          <TableCell className="w-20">
            <p className="typography-body-2 text-white">{t('type')}</p>
          </TableCell>
          <TableCell className="w-20" />
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {[mainAccount, ...subAccounts, ...publicAccounts].map((account) => (
          <TableRow key={account.index}>
            <TableCell>
              <p className="typography-body-2 max-w-full truncate text-white">
                {account.account_type === SubAccountType.main
                  ? t('main_account')
                  : account.name || t('unnamed_account')}
              </p>
            </TableCell>
            <TableCell className="w-20">
              <p className="typography-body-2 text-white">
                {account.account_type === SubAccountType.main && t('account_type_main_title')}
                {account.account_type === SubAccountType.sub && t('account_type_sub_title')}
                {account.account_type === SubAccountType.public && t('account_type_public_title')}
              </p>
            </TableCell>
            <TableCell className="w-20">
              <div className="flex justify-end">
                {isZero(account.collateral) ? (
                  <Clickable
                    color="blue"
                    onClick={openTransferModalOpen}
                    disabled={verifyAndSwitchAccountMutation.isPending}
                  >
                    {t('deposit')}
                  </Clickable>
                ) : account.index !== accountIndex ? (
                  <Clickable
                    color="blue"
                    onClick={() => verifyAndSwitchAccountMutation.mutate(account)}
                    disabled={verifyAndSwitchAccountMutation.isPending}
                  >
                    {t('switch')}
                  </Clickable>
                ) : (
                  !isOnTradePage && (
                    <Clickable
                      color="blue"
                      onClick={() => navigate(`/trade/${localStorage.getItem('last_symbol')}`)}
                      disabled={verifyAndSwitchAccountMutation.isPending}
                    >
                      {t('trade')}
                    </Clickable>
                  )
                )}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default SubAccountsList
