import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { type PublicPool } from 'zklighter-perps'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { SubAccountType } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface PublicPoolLeaderCellProps {
  publicPool: PublicPool
}

const PublicPoolLeaderCell = ({ publicPool }: PublicPoolLeaderCellProps) => {
  const { t } = useTranslation()
  const userAddress = useUserAddress()

  const address = useMemo(() => {
    if (publicPool.account_type !== SubAccountType.public) {
      return '-'
    }

    if (publicPool.l1_address === userAddress) {
      return t('you')
    }

    return publicPool.l1_address
      .substring(0, 6)
      .concat('...')
      .concat(publicPool.l1_address.slice(-4))
  }, [t, publicPool.account_type, publicPool.l1_address, userAddress])

  return (
    <TableCell>
      <p className="typography-body-2 text-white">{address}</p>
    </TableCell>
  )
}

export default PublicPoolLeaderCell
