import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import Markdown from 'react-markdown'

import maintenance from 'img/shared/maintenance.svg'
import { useLighterStore } from 'js/providers/lighterStore'
import { Button } from 'js/shared-components'
import { SupportModal } from 'js/shared-components/SupportModal'

import { PageContainer } from '../page-container/PageContainer'

export const Maintenance = () => {
  const { t } = useTranslation()

  return (
    <PageContainer>
      <SupportModal />
      <div className="flex max-w-[600px] flex-col items-center justify-center self-center p-2 py-16 text-center max-mobile:max-w-[100dvh]">
        <p className="typography-text-8 -mb-12 text-white">503 - {t('service_unavailable')}</p>
        <SVG src={maintenance} className="size-full" />

        <div className="typography-label-1 -mt-12 text-white">
          <Markdown
            components={{ li: (props) => <li className="list-inside list-disc" {...props} /> }}
          >
            {t('maintenance_body')}
          </Markdown>
        </div>
        <Button
          onClick={() => useLighterStore.setState({ showFeedback: true })}
          className="mt-3 w-fit"
        >
          {t('support')}
        </Button>
      </div>
    </PageContainer>
  )
}
