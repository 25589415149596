import { useTranslation } from 'react-i18next'

import { OrderDirections } from 'js/constants/shared'
import { TabBar } from 'js/shared-components'
import { Clickable } from 'js/shared-components/Clickable'
import cn from 'js/util/cn'

interface OrderDirectionSelectorProps {
  orderDirection: OrderDirections
  setOrderDirectionFromButton: (orderDirection: OrderDirections) => void
}

export const OrderDirectionSelector = ({
  orderDirection,
  setOrderDirectionFromButton,
}: OrderDirectionSelectorProps) => {
  const { t } = useTranslation()

  return (
    <div className="px-2">
      <TabBar className="my-3 gap-0 rounded-lg border p-0 max-mobile:relative">
        <Clickable
          className={cn('z-[1] h-full w-1/2 hover:text-white', {
            'text-white-opaque': orderDirection !== OrderDirections.Long,
            'text-white': orderDirection === OrderDirections.Long,
          })}
          onClick={() => setOrderDirectionFromButton(OrderDirections.Long)}
        >
          {t('buy_long')}
        </Clickable>
        <Clickable
          className={cn('z-[1] h-full w-1/2 hover:text-white', {
            'text-white-opaque': orderDirection !== OrderDirections.Short,
            'text-white': orderDirection === OrderDirections.Short,
          })}
          onClick={() => setOrderDirectionFromButton(OrderDirections.Short)}
        >
          {t('sell_short')}
        </Clickable>
        <div
          className={cn('absolute h-full w-1/2 rounded-lg transition-all', {
            'bg-green-main left-0': orderDirection === OrderDirections.Long,
            'bg-red-main left-1/2': orderDirection === OrderDirections.Short,
          })}
        />
      </TabBar>
    </div>
  )
}
