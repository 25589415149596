import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { OrderBookDetail } from 'zklighter-perps'

import { type OrderSide } from 'js/constants/trades'
import { useAccountFundingHistory, useAccountLoading } from 'js/providers/accountsSlice/selectors'
import DateCell from 'js/shared-components/cells/DateCell'
import FundingPaymentCell from 'js/shared-components/cells/funding-history/FundingPaymentCell'
import FundingRateCell from 'js/shared-components/cells/funding-history/FundingRateCell'
import SymbolCell from 'js/shared-components/cells/funding-history/SymbolCell'
import MarketCell from 'js/shared-components/cells/MarketCell'
import PositionSizeCell from 'js/shared-components/cells/positions/PositionSizeCell'
import SideCell from 'js/shared-components/cells/SideCell'
import HeaderCell from 'js/shared-components/HeaderCell'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'

import TableLoader from '../uikit/table/TableLoader'

import { NoItemsInMarket } from './NoItemsInMarket'
import { NoOrdersText } from './NoOrdersText'

interface FundingHistoryTableHeaderProps {
  showMarketColumn: boolean
}

const FundingHistoryTableHeader = ({ showMarketColumn }: FundingHistoryTableHeaderProps) => {
  const { t } = useTranslation()
  return (
    <TableHeader>
      <TableHeaderRow>
        {showMarketColumn && <HeaderCell title={t('market')} />}
        <HeaderCell title={t('time')} />
        <HeaderCell title={t('token')} />
        <HeaderCell title={t('position_side')} />
        <HeaderCell title={t('position_size')} />
        <HeaderCell title={t('payment')} />
        <HeaderCell title={t('rate')} className="justify-end" />
      </TableHeaderRow>
    </TableHeader>
  )
}
interface FundingHistoryProps {
  accountIndex: number
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (selectedMarket: OrderBookDetail | null) => void
}

export const FundingHistoryTable = ({
  accountIndex,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: FundingHistoryProps) => {
  const fundingHistory = useAccountFundingHistory(accountIndex)
  const arePaymentsLoading = useAccountLoading(accountIndex)
  const showMarketColumn = !selectedMarket

  const paymentsToDisplay = useMemo(
    () =>
      (selectedMarket
        ? (fundingHistory[selectedMarket.market_id] ?? [])
        : Object.values(fundingHistory).flat()
      )
        .filter((payment) => {
          switch (selectedSide) {
            case 'asks': {
              return payment.position_side === 'short'
            }
            case 'bids': {
              return payment.position_side === 'long'
            }
            case 'all':
            default: {
              return true
            }
          }
        })
        .sort((a, b) => b.timestamp - a.timestamp),
    [selectedMarket, selectedSide, fundingHistory],
  )

  if (arePaymentsLoading) {
    return (
      <Table>
        <FundingHistoryTableHeader showMarketColumn={showMarketColumn} />
        <TableBody>
          <TableLoader rows={4} columns={6 + Number(showMarketColumn)} />
        </TableBody>
      </Table>
    )
  }

  if (!Object.values(fundingHistory).flat().length) {
    return <NoOrdersText type="fundingHistory" />
  }

  if (selectedMarket && !fundingHistory[selectedMarket.market_id]?.length) {
    return <NoItemsInMarket type="fundingHistory" buttonOnClick={() => setSelectedMarket(null)} />
  }

  return (
    <Table>
      <FundingHistoryTableHeader showMarketColumn={showMarketColumn} />
      <TableBody>
        {paymentsToDisplay.map((item) => (
          <TableRow key={item.funding_id}>
            {showMarketColumn && <MarketCell marketIndex={item.market_id} />}
            <DateCell timestamp={item.timestamp} />
            <SymbolCell funding={item} />
            <SideCell is_short={item.position_side === 'short'} />
            <PositionSizeCell size={item.position_size} marketIndex={item.market_id} />
            <FundingPaymentCell funding={item} />
            <FundingRateCell funding={item} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
