import { useTranslation } from 'react-i18next'

import {
  useIsWhitelistedQuery,
  useUserAccount,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'

export const NotLoggedInText = () => {
  const userAddress = useUserAddress()
  const userAccount = useUserAccount()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const { t } = useTranslation()

  const getLogInText = () => {
    if (!userAddress) {
      return t('ctas_connect_wallet')
    } else if (!isWhitelistedQuery.data) {
      return ''
    } else if (!userAccount) {
      return t('ctas_deposit_funds')
    }
  }
  return (
    <div className="flex h-full max-h-full min-h-64 items-center justify-center text-center">
      <span className="typography-label-1 m-auto text-white">{getLogInText()}</span>
    </div>
  )
}
