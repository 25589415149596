import { useTranslation } from 'react-i18next'

import { useAccountPositions } from 'js/providers/accountsSlice/selectors'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { formatLeverage, formatUSD } from 'js/util/formatting'
import {
  useAccountTotalUnrealizedPnL,
  useMaintenanceMarginReq,
  usePortfolioStats,
} from 'js/util/positions'

import { usePublicPoolQuery } from '../utils'

export const PublicPoolPerformance = () => {
  const { t } = useTranslation()
  const publicPoolQuery = usePublicPoolQuery()
  const positions = useAccountPositions(publicPoolQuery.data.index)
  const portfolioStats = usePortfolioStats(positions, publicPoolQuery.data.index)
  const maintenanceMarginReq = useMaintenanceMarginReq(positions)

  const unrealizedPnl = useAccountTotalUnrealizedPnL(positions)

  return (
    <div className="flex overflow-hidden rounded-lg bg-white/5">
      <div className="flex min-h-[200px] w-full flex-col gap-3 p-4">
        <div className="flex w-full items-center justify-between">
          <p className="typography-body-2 text-white-opaque">{t('operator_fee')}</p>
          <p className="typography-label-1 text-white">
            {Number(publicPoolQuery.data.pool_info.operator_fee).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
            %
          </p>
        </div>
        <div className="flex w-full items-center justify-between">
          <p className="typography-body-2 text-white-opaque">{t('minimum_operator_share')}</p>
          <p className="typography-label-1 text-white">
            {Number(publicPoolQuery.data.pool_info.min_operator_share_rate).toLocaleString(
              'en-US',
              { maximumFractionDigits: 2, minimumFractionDigits: 0 },
            )}
            %
          </p>
        </div>
        <Stat
          label={t('cross_leverage_title')}
          value={portfolioStats === null ? '-' : formatLeverage(portfolioStats.leverage)}
          explanation={t('cross_leverage_description')}
        />
        <Stat
          label={t('cross_margin_usage_title')}
          value={portfolioStats === null ? '-' : `${portfolioStats.initialMarginUsage.toFixed(2)}%`}
          explanation={t('cross_margin_usage_description')}
        />
        <Stat
          label={t('maintenance_margin_requirement_title')}
          value={formatUSD(maintenanceMarginReq)}
          explanation={t('maintenance_margin_requirement_description')}
        />
        <Stat
          label={t('cross_margin_ratio_title')}
          value={
            portfolioStats === null
              ? '-'
              : `${portfolioStats.maintenanceMarginUsage.toLocaleString('en-US', { maximumFractionDigits: 6 })}%`
          }
          explanation={t('cross_margin_ratio_description')}
        />
        <Stat
          label="Unrealized PnL"
          value={formatUSD(unrealizedPnl)}
          explanation={t('unrealized_pnl_description')}
        />
      </div>
    </div>
  )
}

const Stat = ({
  label,
  value,
  explanation,
}: {
  label: string
  value: string
  explanation: string
}) => {
  return (
    <div className="flex w-full items-center justify-between">
      <Explanation
        explanation={explanation}
        trigger={<p className="typography-body-2 text-white-opaque underline">{label}</p>}
      />
      <p className="typography-label-1 text-white">{value}</p>
    </div>
  )
}
