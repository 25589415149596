import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketPrice } from 'js/util/formatting'

interface OrderAveragePriceCellProps {
  order: Order
}

const OrderAveragePriceCell = ({ order }: OrderAveragePriceCellProps) => {
  const market = useOrderBookMetas()[order.market_index]!
  const avgPrice = Number(order.filled_quote_amount) / Number(order.filled_base_amount)

  return (
    <TableCell>
      <p className="typography-body-1 text-end text-white">
        {Number.isNaN(avgPrice) ? '-' : formatMarketPrice(avgPrice, market)}
      </p>
    </TableCell>
  )
}
export default OrderAveragePriceCell
