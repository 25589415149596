import { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserAccountPositions } from 'js/providers/accountsSlice/selectors'
import { useCurrentMarket, useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Clickable } from 'js/shared-components/Clickable'
import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import Icon from 'js/shared-components/uikit/Icon'
import { enforceNumber } from 'js/util/util'

import { useIsClosingPosition, useOrderInputStore, useOrderToPlaceUpdater } from '../PlaceOrder'

import { BuyingStats } from './BuyingStats'
import ClosePositionButton from './ClosePositionButton'
import { PriceImpact } from './PriceImpact'

interface ClosePositionProps {
  setLiquidationWarning: Dispatch<SetStateAction<boolean>>
}

export const ClosePosition = ({ setLiquidationWarning }: ClosePositionProps) => {
  const { t } = useTranslation()
  const orderInputs = useOrderInputStore()
  const [selectedPercent, setSelectedPercent] = useState(1)

  const currentMarket = useCurrentMarket()
  const currentMarketId = useCurrentMarketId()
  const positions = useUserAccountPositions()
  const isClosingPosition = useIsClosingPosition()
  const position = positions[currentMarketId]
  const {
    matchInfo: { amount0: orderSize },
    slippage,
  } = useOrderToPlaceUpdater()
  const update = orderInputs.update

  useEffect(() => {
    if (position?.position) {
      update('size', position.position)
      setSelectedPercent(1)
    }
  }, [update, position?.position])

  if (!position) return null

  return (
    <BottomAnimatedDropdown className="h-full overflow-hidden bg-black" isOpen={isClosingPosition}>
      <div className="flex h-10 w-full items-center justify-between border-b bg-white-transparent px-3">
        <p className="typography-body-2 text-white">{t('close_position')}</p>
        <Clickable onClick={() => useOrderInputStore.getState().changeIsClosingPosition(false)}>
          <Icon icon="x" className="size-5" />
        </Clickable>
      </div>
      <BuyingStats
        setLiquidationWarning={setLiquidationWarning}
        isShort={position.sign === 1}
        newSize={Number(orderSize)}
      />
      <div className="flex flex-col gap-4 p-2">
        <UIKitCurrencyInput
          label={t('amount')}
          helperText={t('coin_amount_input_helper_text', { symbol: currentMarket.symbol })}
          symbol={currentMarket.symbol}
          value={orderSize}
          onChange={(e) => {
            if (!enforceNumber(e, currentMarket.size_decimals)) {
              return
            }

            const value = e.target.value

            if (Number(value) <= Number(position.position)) {
              update('size', value)
            }
          }}
          placeholder={Number(0).toFixed(currentMarket.size_decimals)}
        />
        <div className="flex w-full justify-between gap-2">
          {[
            { value: 0.25, label: '25%' },
            { value: 0.5, label: '50%' },
            { value: 0.75, label: '75%' },
            { value: 1, label: t('full_close') },
          ].map((button) => (
            <Clickable
              key={button.label}
              className="w-full rounded-lg border bg-white-transparent py-3"
              color={selectedPercent === button.value ? 'blue' : 'white'}
              onClick={() => {
                update(
                  'size',
                  (Number(position.position) * button.value).toFixed(currentMarket.size_decimals),
                )
                setSelectedPercent(button.value)
              }}
            >
              <p className="typography-body-1">{button.label}</p>
            </Clickable>
          ))}
        </div>
        <ClosePositionButton position={position} />
        <PriceImpact
          orderSize={orderSize}
          isLimit={false}
          slippage={slippage}
          isClosingPosition
          isLongOrder={position.sign === -1}
        />
      </div>
    </BottomAnimatedDropdown>
  )
}
