import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { useUserAccountActiveOrders } from 'js/providers/accountsSlice/selectors'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Button } from 'js/shared-components'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Clickable } from 'js/shared-components/Clickable'
import Toast from 'js/shared-components/uikit/Toast'
import { cancelAllOrders } from 'js/zklighter-js-sdk/sdk'

interface CancelAllModalProps {
  closeModal: () => void
}

export const CancelAllModal = ({ closeModal }: CancelAllModalProps) => {
  const activeOrders = useUserAccountActiveOrders()
  const accountIndex = useAccountIndex()!
  const { t } = useTranslation()
  const cancelAllOrdersMutation = useMutation({
    mutationFn: () => cancelAllOrders({ accountIndex, timeInForce: 0, time: 0 }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description="Orders canceled"
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('errors_generic_try_again')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => closeModal(),
  })

  const activeOrderCount = Object.values(activeOrders).flat().length

  return (
    <div className="absolute flex size-full flex-col items-center justify-center p-8">
      <Backdrop
        onClick={closeModal}
        fullScreen={false}
        isVisible
        className="absolute top-0 z-10 h-full"
      />
      <div className="z-10 flex w-1/2 flex-col items-center gap-4 text-center max-mobile:w-full max-mobile:py-12">
        <p className="typography-text-6 text-white">
          You’re about to cancel all {activeOrderCount} open orders accross all markets, please
          confirm below
        </p>
        <div className="flex flex-col items-center gap-1">
          <Button
            isLoading={cancelAllOrdersMutation.isPending && !cancelAllOrdersMutation.isError}
            color="red"
            onClick={() => cancelAllOrdersMutation.mutate()}
          >
            {t('confirm')}
          </Button>
          <Clickable
            disabled={cancelAllOrdersMutation.isPending && !cancelAllOrdersMutation.isError}
            onClick={closeModal}
          >
            <p className="typography-label-1 text-white">Back</p>
          </Clickable>
        </div>
      </div>
    </div>
  )
}
