import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useProtocolPoolListQuery } from 'js/pages/public-pool/utils'
import HeaderCell from 'js/shared-components/HeaderCell'
import Icon from 'js/shared-components/uikit/Icon'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'

import PublicPoolLeaderCell from './cells/PublicPoolLeaderCell'
import PublicPoolNameCell from './cells/PublicPoolNameCell'
import PublicPoolOperatorFeeCell from './cells/PublicPoolOperatorFeeCell'
import PublicPoolTVLCell from './cells/PublicPoolTVLCell'
import PublicPoolUserDepositCell from './cells/PublicPoolUserDepositCell'

const ProtocolPublicPoolList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const protocolPoolListQuery = useProtocolPoolListQuery()
  const protocolPublicPools = protocolPoolListQuery.data?.public_pools ?? []

  if (protocolPoolListQuery.isPending) {
    return (
      <div className="flex h-[100px] items-center justify-center">
        <Icon icon="spinner" className="size-6" />
      </div>
    )
  }

  if (!protocolPublicPools.length) {
    return (
      <p className="typography-body-2 flex min-h-[100px] w-full items-center justify-center text-center text-white">
        {t('no_protocol_public_pools')}
      </p>
    )
  }

  return (
    <>
      <p className="typography-text-6 max-mobile:typography-label-1 p-5 text-white">
        {t('protocol_public_pools')}
      </p>
      <Table>
        <TableHeader>
          <TableHeaderRow className="static border-b bg-transparent shadow-none backdrop-filter-none">
            <HeaderCell title={t('public_pool')} />
            <HeaderCell title={t('leader')} />
            <HeaderCell title={t('tvl')} />
            <HeaderCell title={t('operator_fee')} className="justify-end" />
            <HeaderCell title={t('your_deposit')} className="justify-end" />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {protocolPublicPools.map((publicPool) => (
            <TableRow
              key={publicPool.index}
              onClick={() => navigate(`/public-pools/${publicPool.index}`)}
            >
              <PublicPoolNameCell publicPool={publicPool} />
              <PublicPoolLeaderCell publicPool={publicPool} />
              <PublicPoolTVLCell publicPool={publicPool} />
              <PublicPoolOperatorFeeCell publicPool={publicPool} />
              <PublicPoolUserDepositCell publicPool={publicPool} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default ProtocolPublicPoolList
