import * as Sentry from '@sentry/react'
import { useState, useEffect, useMemo, useRef } from 'react'

interface MemoryOptions {
  pollingInterval?: number
  sampleLimit?: number
}

interface MemoryStats {
  current: string
  max: string
  min: string
  avg: string
  samplesCount: number
  isSupported: boolean
  showMem: boolean
}

interface MemoryData {
  samplesCount: number
  max: number
  min: number
  avg: number
  sum: number
}

function getShouldShowMem() {
  // Check URL parameters first (if running in browser)

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search)
    const memParam = urlParams.get('mem')?.toLowerCase()
    if (memParam === 'false') {
      localStorage.setItem('showmem', 'false')
    }
    if (memParam === 'true') {
      localStorage.setItem('showmem', 'true')
    }
    return (
      memParam === 'true' ||
      localStorage.getItem('showmem') === 'true' ||
      window.location.hostname === 'localhost'
    )
  }

  return false
}

export const useMemoryUsage = (options: MemoryOptions = {}): MemoryStats => {
  const didSendRef = useRef(false)
  const { pollingInterval = 1000, sampleLimit = Infinity } = options

  const showMem = useMemo(() => getShouldShowMem(), [])

  const [memoryStats, setMemoryStats] = useState<MemoryStats>({
    current: '0',
    max: '0',
    min: '0',
    avg: '0',
    samplesCount: 0,
    isSupported: false,
    showMem: false,
  })

  useEffect(() => {
    // Check if the browser supports the memory API
    const isSupported = !!(window.performance && 'memory' in window.performance)

    if (!isSupported) {
      setMemoryStats((prev) => ({ ...prev, isSupported: false, showMem: false }))
      return
    }

    const performance = window.performance as any

    const data: MemoryData = {
      samplesCount: 0,
      max: performance.memory.usedJSHeapSize,
      min: performance.memory.usedJSHeapSize,
      avg: performance.memory.usedJSHeapSize,
      sum: 0,
    }

    const intervalId = setInterval(() => {
      const currentUsage = performance.memory.usedJSHeapSize

      // Update max/min values
      if (currentUsage > data.max) {
        data.max = currentUsage
      }
      if (currentUsage < data.min) {
        data.min = currentUsage
      }

      // Update running average
      data.sum = data.sum + currentUsage
      data.samplesCount++
      data.avg = data.sum / data.samplesCount

      // Convert to MB and round to 2 decimal places
      const toMBReadable = (b: number) => (b / 1024 / 1024).toFixed(0)
      if (showMem) {
        setMemoryStats({
          current: toMBReadable(currentUsage),
          max: toMBReadable(data.max),
          min: toMBReadable(data.min),
          avg: toMBReadable(data.avg),
          samplesCount: data.samplesCount,
          isSupported: true,
          showMem: true,
        })
      }
      const threholdMB = 500

      if (Number(toMBReadable(currentUsage)) >= threholdMB && !didSendRef.current) {
        Sentry.captureException(new Error('High Memory Usage'), {
          contexts: {
            memory: {
              usage: toMBReadable(currentUsage),
              threshold: `${threholdMB.toFixed(0)}MB`,
            },
          },
        })
        didSendRef.current = true
      }

      // Stop collecting samples if we've reached the limit
      if (data.samplesCount >= sampleLimit) {
        clearInterval(intervalId)
      }
    }, pollingInterval)

    // Cleanup on unmount
    return () => clearInterval(intervalId)
  }, [pollingInterval, sampleLimit, showMem])

  return memoryStats
}
