import { OrderTypeEnum } from 'zklighter-perps'

import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketPrice } from 'js/util/formatting'

interface OrderPriceCellProps {
  order: Order
}

const OrderPriceCell = ({ order }: OrderPriceCellProps) => {
  const market = useOrderBookMetas()[order.market_index]!
  const isLimit =
    order.type === OrderTypeEnum.Limit ||
    order.type === OrderTypeEnum.StopLossLimit ||
    order.type === OrderTypeEnum.TakeProfitLimit

  return (
    <TableCell>
      <p className="typography-body-1 text-end text-white">
        {isLimit ? formatMarketPrice(order.price, market) : '-'}
      </p>
    </TableCell>
  )
}

export default OrderPriceCell
