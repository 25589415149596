import { TOKEN_LOGOS } from 'js/constants/shared'
import { useCurrentMarketId, useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/orderBookSlice/selectors'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import Icon from 'js/shared-components/uikit/Icon'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import cn from 'js/util/cn'
import { formatLeverage, formatMarketPrice } from 'js/util/formatting'
import { marginFractionToLeverage } from 'js/util/positions'

interface MobileMarketRowProps {
  marketId: number
  onClick: (marketid: number) => void
}

export const MobileMarketRow = ({ marketId, onClick }: MobileMarketRowProps) => {
  const currentMarketId = useCurrentMarketId()
  const market = useOrderBookMetas()[marketId]!
  const marketStats = useMarketsStats()[marketId]
  const diffPct = marketStats?.daily_price_change ?? 0
  const roundedDiffPct = parseFloat(diffPct.toFixed(2))
  const isSelected = marketId === currentMarketId

  return (
    <TableRow className="last:border-b" onClick={() => onClick(marketId)}>
      <TableCell className="border-r">
        <div className="flex items-center gap-2 pl-6">
          <Icon icon={TOKEN_LOGOS[market.symbol as keyof typeof TOKEN_LOGOS]!} className="size-5" />
          <p
            className={cn('typography-body-1', {
              'text-white': isSelected,
              'text-white-opaque': !isSelected,
            })}
          >
            {market.symbol}
          </p>
          <div className="rounded-full border border-semi-transparent bg-white-transparent bg-origin-border p-1 px-2">
            <p className="typography-body-1 !leading-3 text-white">
              {formatLeverage(marginFractionToLeverage(market.min_initial_margin_fraction))}
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-col items-center justify-center">
          <p
            className={cn('typography-body-2 text-center', {
              'text-white': isSelected,
              'text-white-opaque': !isSelected,
            })}
          >
            {marketStats && formatMarketPrice(marketStats.last_trade_price, market)}
            {!marketStats && <SkeletonRectangle className="h-[1ch] w-10" />}
          </p>
          <div className="flex items-center justify-center gap-1">
            {roundedDiffPct !== 0 && (
              <Icon
                icon={roundedDiffPct > 0 ? 'priceUp' : 'priceDown'}
                className={cn('size-3.5', {
                  'text-green-main': roundedDiffPct > 0,
                  'text-red-main': roundedDiffPct <= 0,
                })}
              />
            )}
            <p
              className={cn('typography-body-1', {
                'text-green-main': roundedDiffPct > 0,
                'text-red-main': roundedDiffPct < 0,
                'text-white': roundedDiffPct === 0 && isSelected,
                'text-white-opaque': roundedDiffPct === 0 && !isSelected,
              })}
            >
              {roundedDiffPct}%
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <p
          className={cn('typography-body-2 text-center', {
            'text-white': isSelected,
            'text-white-opaque': !isSelected,
          })}
        >
          {marketStats &&
            Intl.NumberFormat('en', {
              notation: 'compact',
              style: 'currency',
              currency: 'USD',
            }).format(marketStats.daily_quote_token_volume)}
          {!marketStats && <SkeletonRectangle className="h-[1ch] w-10" />}
        </p>
      </TableCell>
    </TableRow>
  )
}
