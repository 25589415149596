import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderTypeEnum } from 'zklighter-perps'

import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface OrderTypeCellProps {
  order: Order
}

const OrderTypeCell = ({ order }: OrderTypeCellProps) => {
  const { t } = useTranslation()

  const label = useMemo(() => {
    switch (order.type) {
      case OrderTypeEnum.Market: {
        return t('market')
      }
      case OrderTypeEnum.Limit: {
        return t('limit')
      }
      case OrderTypeEnum.StopLoss: {
        return t('sl_market')
      }
      case OrderTypeEnum.StopLossLimit: {
        return t('sl_limit')
      }
      case OrderTypeEnum.TakeProfit: {
        return t('tp_market')
      }
      case OrderTypeEnum.TakeProfitLimit: {
        return t('tp_limit')
      }
      case OrderTypeEnum.Twap: {
        return t('twap')
      }
      case OrderTypeEnum.TwapSub: {
        return t('twap_sub')
      }
      case OrderTypeEnum.Liquidation: {
        return t('liquidation')
      }
    }
  }, [t, order.type])

  return (
    <TableCell>
      <p className="typography-body-1 whitespace-nowrap text-white">{label}</p>
    </TableCell>
  )
}

export default OrderTypeCell
