import type { PropsWithChildren } from 'react'

import { useIsGeoLocBlocked, useIsWhitelistedQuery } from 'js/providers/hooks/useAccountQuery'

const PublicPoolActionsBlocker = ({ children }: PropsWithChildren) => {
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const isGeoLocBlocked = useIsGeoLocBlocked()

  if (isGeoLocBlocked || isWhitelistedQuery.isLoading || isWhitelistedQuery.data === false) {
    return null
  }

  return children
}

export default PublicPoolActionsBlocker
