import { useTranslation } from 'react-i18next'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import WarningContainer from 'js/shared-components/WarningContainer'
import { formatMarketSize, formatUSD } from 'js/util/formatting'

interface WarningProps {
  isAmount0Less: boolean
  isAmount1Less: boolean
}

const MinimumOrderSizeWarning = ({ isAmount0Less, isAmount1Less }: WarningProps) => {
  const { t } = useTranslation()
  const currentMarket = useCurrentMarket()

  if (!isAmount0Less && !isAmount1Less) {
    return null
  }

  return (
    <WarningContainer>
      <p className="typography-body-2 text-white">
        {t('minimum_order_size_warning', {
          amount: isAmount0Less
            ? `${formatMarketSize(currentMarket.min_base_amount, currentMarket)} ${currentMarket.symbol}`
            : formatUSD(currentMarket.min_quote_amount),
        })}
      </p>
    </WarningContainer>
  )
}

export default MinimumOrderSizeWarning
