import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { WalletDropdown } from 'js/pages/navbar/wallet/WalletDropdown'
import {
  useAccountsQuery,
  useIsGeoLocBlocked,
  useIsWhitelistedQuery,
  useUserAddress,
} from 'js/providers/hooks/useAccountQuery'
import { useWallet } from 'js/providers/hooks/useWallet'
import { useLighterStore } from 'js/providers/lighterStore'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Button } from 'js/shared-components'
import { Clickable } from 'js/shared-components/Clickable'
import { MetamaskLogin } from 'js/shared-components/MetamaskLogin'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import Icon from 'js/shared-components/uikit/Icon'
import Toast from 'js/shared-components/uikit/Toast'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipArrow,
} from 'js/shared-components/uikit/Tooltip'
import { copyStringToClipboard, isMainnet } from 'js/util/util'

export const WalletDropdownButton = () => {
  const [isExplanationOpen, setIsExplanationOpen] = useState(true)

  const { t } = useTranslation()
  const userAddress = useUserAddress()
  const accountIndex = useAccountIndex()
  const accountsQuery = useAccountsQuery()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const isGeoLocBlocked = useIsGeoLocBlocked()
  const { isMobile } = useResponsiveness()

  if (isGeoLocBlocked || isWhitelistedQuery.isLoading || accountsQuery.isLoading) {
    return null
  }

  if (!userAddress) {
    return (
      <Tooltip open={isExplanationOpen && !isMainnet()}>
        <TooltipTrigger asChild>
          <MetamaskLogin />
        </TooltipTrigger>
        <TooltipContent
          align={'end'}
          sideOffset={4}
          alignOffset={-4}
          className="relative max-w-[200px] pr-8"
        >
          <Clickable className="absolute right-2 top-3" onClick={() => setIsExplanationOpen(false)}>
            <Icon icon="x" className="size-4" />
          </Clickable>
          <p className="typography-body-2 text-white">{t('connect_test_tokens')}</p>
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    )
  }

  if (!isWhitelistedQuery.data) {
    if (isMobile) {
      return (
        <Clickable onClick={() => useLighterStore.setState({ showWhitelist: true })}>
          <Icon icon="wallet" className="size-5" />
        </Clickable>
      )
    }

    return (
      <div className="flex items-center gap-2">
        <Button onClick={() => useLighterStore.setState({ showWhitelist: true })}>
          {t('request_access')}
        </Button>
        <AddressDropdown />
      </div>
    )
  }

  if (accountIndex === null) {
    if (isMobile) {
      // handled someplace else
      return null
    }

    return <AddressDropdown />
  }

  return <WalletDropdown />
}

const AddressDropdown = () => {
  const userAddress = useUserAddress()
  const disconnectWallet = useWallet().disconnect
  const { t } = useTranslation()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={
          'typography-label-1 items-baseline border-0 !bg-transparent p-0 text-white hover:text-white data-[state=open]:text-white'
        }
        data-testid="navbar-more-link"
      >
        {userAddress.length > 13
          ? userAddress.substring(0, 6).concat('...').concat(userAddress.slice(-4))
          : userAddress}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="min-w-56" align="start">
        <DropdownMenuItem
          className="flex items-center gap-2"
          onClick={(e) => {
            e.preventDefault()
            copyStringToClipboard(userAddress)
            toast.custom((toastId) => (
              <Toast
                level="success"
                description={t('address_copied')}
                onClose={() => toast.dismiss(toastId)}
              />
            ))
          }}
        >
          <p className="typography-body-2 relative max-w-[190px] overflow-hidden truncate text-white">
            {userAddress.length > 13
              ? userAddress.substring(0, 6).concat('...').concat(userAddress.slice(-4))
              : userAddress}
          </p>
          <Icon icon="copy" className="size-4 text-white" />
        </DropdownMenuItem>
        <DropdownMenuItem onClick={disconnectWallet} data-testid="navbar-sub-accounts-link">
          {t('disconnect')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
