import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { usePublicPoolInfo, useUserAccountShares } from 'js/providers/accountsSlice/selectors'
import { useAccountsQuery } from 'js/providers/hooks/useAccountQuery'
import { SubAccountType } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Button } from 'js/shared-components'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import UIKitPriceInput from 'js/shared-components/uikit/PriceInput'
import Toast from 'js/shared-components/uikit/Toast'
import cn from 'js/util/cn'
import { formatUSD } from 'js/util/formatting'
import { useUserAccountPortfolioStats } from 'js/util/positions'
import { enforceNumber } from 'js/util/util'
import { mintShares } from 'js/zklighter-js-sdk/sdk'

import { usePublicPoolQuery, usePublicPoolSharePrice } from '../utils'

import ActionButtonBlocker from './ActionButtonBlocker'

const useAvailableSharesToMint = () => {
  const accountsQuery = useAccountsQuery()
  const accountShares = useUserAccountShares()
  const publicPoolQuery = usePublicPoolQuery()
  const portfolioStats = useUserAccountPortfolioStats()
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)

  // catch all for loading state
  if (
    publicPoolSharePrice === null ||
    !poolInfo ||
    !accountsQuery.data ||
    portfolioStats === null
  ) {
    return 0
  }

  const isOperator = accountsQuery.data.accounts.some(
    (account) => account.index === publicPoolQuery.data.index,
  )

  // operator_share can only increase if the operator is depositing
  if (isOperator) {
    return Math.floor(portfolioStats.availableBalance / publicPoolSharePrice)
  }

  // coming from BE as '5.000' -> 5% and needs to be converted to 0.05
  const minOperatorShareRate = Number(poolInfo.min_operator_share_rate) / 100

  let maxAvailableDeposit = Infinity
  if (publicPoolQuery.data.account_type === SubAccountType.lighterPublic) {
    const accountShare = accountShares.find(
      (share) => share.public_pool_index === publicPoolQuery.data.index,
    )

    const accountPoolShare = (accountShare?.shares_amount ?? 0) * publicPoolSharePrice

    maxAvailableDeposit = Math.floor((Number(portfolioStats.portfolioValue) - accountPoolShare) / 2)
  }

  /*
    min_operator_share_rate <= operator_shares / (total_shares + delta)
    min_operator_share_rate * (total_shares + delta) <= operator_shares
    total_shares + delta <= operator_shares / min_operator_share_rate
    delta <= (operator_shares / min_operator_share_rate) - total_shares
  */
  return Math.max(
    0,
    Math.floor(
      Math.min(
        maxAvailableDeposit / publicPoolSharePrice,
        portfolioStats.availableBalance / publicPoolSharePrice,
        minOperatorShareRate === 0
          ? Infinity
          : poolInfo.operator_shares / minOperatorShareRate - poolInfo.total_shares,
      ),
    ),
  )
}

const DepositForm = () => {
  const [value, setValue] = useState('')
  const { t } = useTranslation()

  const accountIndex = useAccountIndex()!
  const publicPoolQuery = usePublicPoolQuery()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const { isMobile } = useResponsiveness()
  const portfolioStats = useUserAccountPortfolioStats()

  const mintSharesMutation = useMutation({
    mutationFn: mintShares,
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('public_pool_deposit_toasts_success')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('toasts_error')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => setValue(''),
  })
  const availableToMintShares = useAvailableSharesToMint()
  const availableToDeposit = availableToMintShares * (publicPoolSharePrice ?? 0)

  if (!poolInfo) {
    return null
  }

  if (poolInfo.status === 1) {
    return <p className="typography-body-2 text-white">{t('public_pool_frozen_description')}</p>
  }

  const invalidAmountError = Math.round(Number(value) * 100) > Math.round(availableToDeposit * 100)

  const form = (
    <div className="flex h-full flex-col justify-between gap-10 max-mobile:gap-6">
      <div className="flex flex-col gap-2">
        <p className="typography-body-2 text-white">{t('deposit_funds')}</p>
        <UIKitPriceInput
          label={`${t('amount')} (USDC)`}
          value={value}
          onChange={(e) => {
            if (enforceNumber(e, 2)) {
              setValue(e.target.value)
            }
          }}
          disabled={availableToDeposit < 1}
          buttonText={t('max')}
          onClick={() => setValue(availableToDeposit.toFixed(2))}
          placeholder={'0.00'}
        />
        {publicPoolQuery.data.account_type === SubAccountType.lighterPublic && portfolioStats && (
          <div className="flex items-center justify-between">
            <p className="typography-body-1 text-white">{t('available_balance_title')}</p>
            <p className="typography-body-1 text-white">
              {formatUSD(portfolioStats.availableBalance)}
            </p>
          </div>
        )}
        <div className="flex items-center justify-between">
          <p className="typography-body-1 text-white">{t('available_to_deposit')}</p>
          <p className="typography-body-1 text-white">{formatUSD(availableToDeposit)}</p>
        </div>
        <p className={cn('typography-body-1 text-red-main', { 'opacity-0': !invalidAmountError })}>
          {t('insufficient_funds')}
        </p>
      </div>
      <ActionButtonBlocker>
        <Button
          className="w-full"
          color="green"
          disabled={!value || invalidAmountError}
          isLoading={mintSharesMutation.isPending || publicPoolSharePrice === null}
          onClick={() =>
            mintSharesMutation.mutate({
              accountIndex,
              publicPoolIndex: publicPoolQuery.data.index,
              shareAmount: Math.min(
                availableToMintShares,
                Math.floor(Number(value) / (publicPoolSharePrice as number)),
              ),
            })
          }
        >
          {t('deposit')}
        </Button>
      </ActionButtonBlocker>
    </div>
  )

  if (!isMobile) {
    return form
  }

  return (
    <BottomAnimatedDropdown isOpen className="z-[101] min-h-[50%] p-6">
      {form}
    </BottomAnimatedDropdown>
  )
}

export default DepositForm
