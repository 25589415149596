import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { FeeBucketRequest } from 'zklighter-perps'

import { useUserAccountPositions } from 'js/providers/accountsSlice/selectors'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useCurrentMarketStats } from 'js/providers/orderBookSlice/selectors'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Button } from 'js/shared-components'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { Modal } from 'js/shared-components/Modal'
import PercentageInput from 'js/shared-components/PercentageInput'
import Icon from 'js/shared-components/uikit/Icon'
import { accountApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { formatLeverage, formatMarketPrice, formatMarketSize, formatUSD } from 'js/util/formatting'
import { readSSUserSelections, writeSSUserSelection } from 'js/util/localStorage'
import {
  computePositionLeverage,
  computePositionMarginReq,
  marginFractionToLeverage,
  useGetInitialMarginFraction,
  usePortfolioStats,
  usePositionsWithLiqPrices,
  useUpdatedPositions,
} from 'js/util/positions'
import { enforceNumber } from 'js/util/util'

import { useOrderInputStore } from '../PlaceOrder'

export const PriceImpact = ({
  isLimit,
  isClosingPosition,
  slippage,
  isLongOrder,
  estPrice,
  orderValue,
  orderSize,
}: {
  isLimit: boolean
  isClosingPosition: boolean
  slippage: number
  isLongOrder?: boolean
  estPrice?: string
  orderValue?: string
  orderSize?: string
}) => {
  const maxSlippage = useOrderInputStore().maxSlippage
  const changeMaxSlippage = useOrderInputStore().changeMaxSlippage
  const { t } = useTranslation()
  const [isSlippageModalOpen, setIsSlippageModalOpen] = useState(false)
  const [inputSlippage, setInputSlippage] = useState(maxSlippage?.toString() ?? '0')
  const accountIndex = useAccountIndex() ?? -1
  const params: FeeBucketRequest = useMemo(() => ({ account_index: accountIndex }), [accountIndex])
  const feeBucketQuery = useQuery({
    queryKey: ['feeBucket', params],
    queryFn: () => accountApi.feeBucket(params),
    enabled: accountIndex !== -1,
  })
  const currentMarket = useCurrentMarket()
  const getInitialMarginFraction = useGetInitialMarginFraction()
  const currentMarketStats = useCurrentMarketStats()
  const markPrice = Number(currentMarketStats?.mark_price ?? 0)
  const { symbol } = currentMarket
  const rawPositions = useUserAccountPositions()
  const portfolioValue = usePortfolioStats(rawPositions, accountIndex)?.portfolioValue
  const positions = usePositionsWithLiqPrices(rawPositions, accountIndex)
  const position = positions[currentMarket.market_id]
  const initialMarginFraction = getInitialMarginFraction(currentMarket.market_id)
  const positionSize = Number(position?.position ?? 0)
  const positionMargin = computePositionMarginReq(positionSize, markPrice, initialMarginFraction)
  const positionLeverage = computePositionLeverage(positionSize, markPrice, portfolioValue ?? 0)
  const positionLiqPrice = position?.liquidation_price

  const rawUpdatedPositions = useUpdatedPositions(
    Number(orderSize ?? 0),
    !isLongOrder,
    estPrice ?? '0',
  )
  const updatedPortfolioValue = usePortfolioStats(rawUpdatedPositions, accountIndex)?.portfolioValue
  const updatedPositions = usePositionsWithLiqPrices(rawUpdatedPositions, accountIndex)
  const updatedPosition = updatedPositions[currentMarket.market_id]
  const updatedPositionSize = Number(updatedPosition?.position ?? 0)
  const updatedPositionMargin = computePositionMarginReq(
    updatedPositionSize,
    markPrice,
    initialMarginFraction,
  )
  const updatedPositionLeverage = computePositionLeverage(
    updatedPositionSize,
    markPrice,
    updatedPortfolioValue ?? 0,
  )
  const updatedPositionLiqPrice = updatedPosition?.liquidation_price

  const orderMarginReq = Number(orderValue) / marginFractionToLeverage(initialMarginFraction)

  const onConfirmMaxSlippage = () => {
    if (!inputSlippage) return
    changeMaxSlippage(Number(inputSlippage))
    const { data: userSelections = {} } = readSSUserSelections()

    writeSSUserSelection({ ...userSelections, maxSlippage: inputSlippage })
    setIsSlippageModalOpen(false)
  }

  const orderSizeSummary = {
    title: t('order_size'),
    value: orderSize ? `${formatMarketSize(orderSize, currentMarket)} ${symbol}` : '-',
  }
  const orderValueSummary = {
    title: t('order_value'),
    value: orderValue ? `${formatUSD(orderValue)}` : '-',
  }
  const estLiqPriceSummary = {
    title: t('est_liq_price'),
    value: positionLiqPrice ? formatMarketPrice(positionLiqPrice, currentMarket) : '-',
    updatedValue: updatedPositionLiqPrice
      ? formatMarketPrice(updatedPositionLiqPrice, currentMarket)
      : '-',
  }
  const positionSummary = {
    title: t('position'),
    value: `${formatMarketSize(positionSize, currentMarket)} ${symbol}`,
    updatedValue: `${formatMarketSize(updatedPositionSize, currentMarket)} ${symbol}`,
  }
  const positionMarginSummary = {
    title: t('position_margin'),
    value: formatUSD(positionMargin),
    updatedValue: formatUSD(updatedPositionMargin),
  }
  const positionLeverageSummary = {
    title: t('position_leverage'),
    value: isNaN(positionLeverage) ? '-' : formatLeverage(positionLeverage, 2),
    updatedValue: formatLeverage(updatedPositionLeverage, 2),
    explanation: t('leverage_description'),
  }
  const estPriceSummary = {
    title: t('est_price'),
    value: estPrice ? formatMarketPrice(estPrice, currentMarket) : '-',
  }
  const slippageSummary = {
    title: t('slippage'),
    value: t('slippage_value', {
      estSlippage: `${(isNaN(slippage) ? 0 : slippage).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}%`,
      maxSlippage: `${maxSlippage}%`,
    }),
    onClick: () => setIsSlippageModalOpen(true),
    buttonExplanation: t('adjust_max_slippage'),
  }
  const feesSummary = {
    title: t('fees'),
    value: t('fees_value', {
      takerFee: `${(feeBucketQuery.data?.taker_fee ?? 0) / 10 ** 6}%`,
      makerFee: `${(feeBucketQuery.data?.maker_fee ?? 0) / 10 ** 6}%`,
    }),
  }

  const marketSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [
    orderSizeSummary,
    orderValueSummary,
    estLiqPriceSummary,
    positionSummary,
    positionMarginSummary,
    positionLeverageSummary,
    estPriceSummary,
    slippageSummary,
    feesSummary,
  ]

  const marginRequiredSummary = {
    title: t('margin_required'),
    value: orderMarginReq ? formatUSD(orderMarginReq) : '-',
  }

  const limitSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [marginRequiredSummary, orderSizeSummary, orderValueSummary, feesSummary]

  const closePositionSummary: {
    title: string
    value: string
    updatedValue?: string | null
    onClick?: () => void
    explanation?: string
    buttonExplanation?: string
  }[] = [slippageSummary, positionSummary, feesSummary]

  const summary = isClosingPosition ? closePositionSummary : isLimit ? limitSummary : marketSummary

  return (
    <>
      <Modal
        title={t('adjust_max_slippage')}
        open={isSlippageModalOpen}
        onOpenChange={setIsSlippageModalOpen}
      >
        <div className="flex w-full flex-col items-center gap-6">
          <p className="typography-label-1 text-white">{t('max_leverage_modal_description')}</p>
          <div className="flex w-full items-center justify-between rounded-lg border bg-white-transparent p-2">
            <PercentageInput
              type="text"
              value={inputSlippage}
              onChange={(e) => {
                // TODO: this form doesn't validate max value
                if (enforceNumber(e, 2)) {
                  setInputSlippage(e.target.value)
                }
              }}
              className="w-full text-left text-sm max-mobile:text-base max-mobile:placeholder:text-base"
            />
            <p className="typography-body-2 text-white">%</p>
          </div>
          <Button disabled={!inputSlippage} className="w-full" onClick={onConfirmMaxSlippage}>
            {t('confirm')}
          </Button>
        </div>
      </Modal>

      <div className="flex w-full flex-col gap-3 rounded-lg border bg-white-transparent p-3">
        {summary.map(({ title, explanation, value, updatedValue, onClick, buttonExplanation }) => (
          <div key={title} className="flex w-full justify-between">
            {explanation ? (
              <Explanation
                explanation={explanation}
                trigger={<p className="typography-body-2 text-white-opaque underline">{title}:</p>}
              />
            ) : (
              <p className="typography-body-2 text-white-opaque">{title}:</p>
            )}
            <div className="flex items-center gap-1">
              {buttonExplanation ? (
                <Explanation
                  explanation={buttonExplanation}
                  trigger={
                    <div
                      className={cn('flex gap-2', {
                        'cursor-pointer': !!onClick,
                        'text-primary-blue-main': !!onClick,
                        'text-white': !onClick,
                        'font-normal': !!onClick,
                      })}
                      onClick={() => onClick?.()}
                    >
                      <p className="typography-body-2">{value}</p>
                      <Icon icon="edit" className="size-4" />
                    </div>
                  }
                />
              ) : (
                <p
                  className={cn('typography-body-2', {
                    'cursor-pointer': !!onClick,
                    'text-primary-blue-main': !!onClick,
                    'text-white': !onClick,
                    'font-normal': !!onClick,
                  })}
                  onClick={() => onClick?.()}
                >
                  {value}
                </p>
              )}

              {updatedValue && Number(orderSize ?? 0) !== 0 && (
                <>
                  <Icon icon="arrow" className="size-4 text-white" />
                  <p className="typography-body-2 text-white">{updatedValue}</p>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
