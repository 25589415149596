import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { OrderBookDetail, TradesRequest } from 'zklighter-perps'

import { type OrderSide } from 'js/constants/trades'
import { useAccountLoading, useAccountTrades } from 'js/providers/accountsSlice/selectors'
import DateCell from 'js/shared-components/cells/DateCell'
import MarketCell from 'js/shared-components/cells/MarketCell'
import SideCell from 'js/shared-components/cells/SideCell'
import TradePriceCell from 'js/shared-components/cells/trades/TradePriceCell'
import TradeRoleCell from 'js/shared-components/cells/trades/TradeRoleCell'
import TradeSizeCell from 'js/shared-components/cells/trades/TradeSizeCell'
import HeaderCell from 'js/shared-components/HeaderCell'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { orderApi } from 'js/util/api/sdk'
import { useLastTableItemRef } from 'js/util/table'
import { isMarketIdAllowed } from 'js/util/util'

import TableLoader from '../uikit/table/TableLoader'

import { NoItemsInMarket } from './NoItemsInMarket'
import { NoOrdersText } from './NoOrdersText'

interface TradeHistoryHeaderProps {
  showMarketColumn: boolean
}

const TradeHistoryHeader = ({ showMarketColumn }: TradeHistoryHeaderProps) => {
  const { t } = useTranslation()

  return (
    <TableHeader>
      <TableHeaderRow>
        {showMarketColumn && <HeaderCell title={t('market')} />}
        <HeaderCell title={t('date')} />
        <HeaderCell title={t('side')} />
        <HeaderCell title={t('size')} />
        <HeaderCell title={t('price')} className="justify-end" />
        <HeaderCell title={t('role')} className="justify-end" />
      </TableHeaderRow>
    </TableHeader>
  )
}

interface TradeHistoryTableProps {
  accountIndex: number
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (market: OrderBookDetail | null) => void
}

const PAGE_SIZE = 20

const getAskFilter = (selectedSide: OrderSide) => {
  switch (selectedSide) {
    case 'asks': {
      return 1
    }
    case 'bids': {
      return 0
    }
    case 'all':
    default: {
      return -1
    }
  }
}

export const TradeHistoryTable = ({
  accountIndex,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: TradeHistoryTableProps) => {
  const userTrades = useAccountTrades(accountIndex)
  const areTradesLoading = useAccountLoading(accountIndex)
  const showMarketColumn = !selectedMarket

  const params: TradesRequest = useMemo(
    () => ({
      market_id: selectedMarket?.market_id,
      account_index: accountIndex!,
      sort_by: 'timestamp',
      sort_dir: 'desc',
      limit: PAGE_SIZE,
      ask_filter: getAskFilter(selectedSide),
    }),
    [selectedMarket, selectedSide, accountIndex],
  )

  const tradeHistoryQuery = useInfiniteQuery({
    queryKey: ['tradeHistory', params],
    queryFn: ({ pageParam }) => orderApi.trades({ cursor: pageParam, ...params }),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage.next_cursor,
    select: (data) =>
      data.pages
        .map((page) => page.trades)
        .flat()
        .filter((trade) => isMarketIdAllowed(trade.market_id)),
    enabled: !!accountIndex,
  })
  const lastTradeRef = useLastTableItemRef(tradeHistoryQuery)

  const wsUserTrades = useMemo(
    () =>
      (selectedMarket
        ? (userTrades[selectedMarket.market_id] ?? [])
        : Object.values(userTrades).flat()
      ).filter((t) => {
        switch (selectedSide) {
          case 'asks': {
            return !t.is_maker_ask
          }
          case 'bids': {
            return t.is_maker_ask
          }
          case 'all':
          default: {
            return true
          }
        }
      }),
    [selectedMarket, selectedSide, userTrades],
  )

  const restApiUserTrades = useMemo(
    () =>
      (tradeHistoryQuery.data ?? []).filter(
        (trade) => !wsUserTrades.some((wsTrade) => wsTrade.trade_id === trade.trade_id),
      ),
    [tradeHistoryQuery.data, wsUserTrades],
  )

  const userTradesToDisplay = useMemo(
    () => [...wsUserTrades, ...restApiUserTrades].sort((a, b) => b.timestamp - a.timestamp),
    [restApiUserTrades, wsUserTrades],
  )

  if (areTradesLoading || tradeHistoryQuery.isLoading) {
    return (
      <Table>
        <TradeHistoryHeader showMarketColumn={showMarketColumn} />
        <TableBody>
          <TableLoader rows={4} columns={5 + Number(showMarketColumn)} />
        </TableBody>
      </Table>
    )
  }

  if (!userTradesToDisplay.length) {
    if (selectedMarket) {
      return <NoItemsInMarket type={'tradeHistory'} buttonOnClick={() => setSelectedMarket(null)} />
    }

    return <NoOrdersText type="tradeHistory" />
  }

  return (
    <Table>
      <TradeHistoryHeader showMarketColumn={showMarketColumn} />
      <TableBody>
        {userTradesToDisplay.map((trade, index) => (
          <TableRow
            key={trade.trade_id}
            onClick={() =>
              window.open(`${import.meta.env.VITE_SCANNER_BASE}/tx/${trade.tx_hash}`, '_blank')
            }
            ref={index === userTradesToDisplay.length - 1 ? lastTradeRef : undefined}
          >
            {showMarketColumn && <MarketCell marketIndex={trade.market_id} />}
            <DateCell timestamp={Math.floor(trade.timestamp / 1000)} />
            <SideCell is_short={trade.ask_account_id === accountIndex} />
            <TradeSizeCell trade={trade} />
            <TradePriceCell trade={trade} />
            <TradeRoleCell trade={trade} accountIndex={accountIndex!} />
          </TableRow>
        ))}
        {tradeHistoryQuery.isFetchingNextPage && (
          <TableLoader rows={1} columns={5 + Number(showMarketColumn)} />
        )}
      </TableBody>
    </Table>
  )
}
