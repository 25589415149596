import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { OrderTypeEnum } from 'zklighter-perps'

import { DepositBtnStatusStyle, useDepositStore } from 'js/pages/deposit/form'
import { useLatestDepositQuery } from 'js/pages/deposit/hooks'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import {
  useAccountsQuery,
  useIsWhitelistedQuery,
  useUserAccount,
} from 'js/providers/hooks/useAccountQuery'
import { useLighterStore } from 'js/providers/lighterStore'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Button } from 'js/shared-components'
import { useCreateAccountMutation } from 'js/shared-components/ConnectWalletModal'
import Icon from 'js/shared-components/uikit/Icon'
import Toast from 'js/shared-components/uikit/Toast'
import type { TxEventInfo } from 'js/types/api-types'
import { constants } from 'js/util/api/constants'
import cn from 'js/util/cn'
import { useUserAccountPortfolioStats } from 'js/util/positions'
import { isMainnet, isZero } from 'js/util/util'
import { createOrderBatch, createOrder } from 'js/zklighter-js-sdk/sdk'

import { OrderNotification } from './components/OrderNotification'
import { useOrderInputStore, useOrderToPlaceUpdater } from './PlaceOrder'
import {
  getOrderExpiry,
  getOrderPrice,
  getOrderTimeInForce,
  getOrderType,
  usePlaceOrderButtonOrderTypeLabel,
  getTimestampDiff,
} from './utils'

interface PlaceOrderMutationParams {
  accountIndex: number
  marketId: number
  amount0: string
  worstExecutionPrice: number
  estimatedPrice: string
}

const usePlaceOrderMutation = () => {
  const { t } = useTranslation()
  const toastIdRef = useRef<number | string | null>(null)
  const {
    isShort,
    timeInForce,
    timeInForceUnit,
    timeInForceValue,
    limitPrice,
    runtimeMinutes,
    runtimeHours,
    postOnly,
    triggerPrice,
    kind,
    isStop,
  } = useOrderInputStore()
  const orderInputs = useOrderInputStore()

  const { stopLossInfo, takeProfitInfo } = useOrderToPlaceUpdater()
  const currentMarket = useCurrentMarket()
  const reduceOnly = isStop() ? 1 : orderInputs.reduceOnly

  return useMutation({
    mutationFn: ({
      accountIndex,
      amount0,
      worstExecutionPrice,
      marketId,
    }: PlaceOrderMutationParams) => {
      const baseAmount = Math.floor(Number(amount0) * 10 ** currentMarket.size_decimals)
      const baseLimitPrice = Math.round(Number(limitPrice) * 10 ** currentMarket.price_decimals)
      const baseWorstExecutionPrice = Math.round(
        worstExecutionPrice * 10 ** currentMarket.price_decimals,
      )

      const userOrder = {
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: marketId,
        baseAmount,
        price: getOrderPrice(kind, isShort(), baseLimitPrice, baseWorstExecutionPrice, baseAmount),
        isAsk: Number(isShort()),
        reduceOnly: Number(reduceOnly),
        orderType: getOrderType(kind),
        timeInForce: getOrderTimeInForce(kind, timeInForce, postOnly),
        orderExpiry: getOrderExpiry(
          kind,
          timeInForce,
          timeInForceValue,
          timeInForceUnit,
          runtimeMinutes,
          runtimeHours,
        ),
        triggerPrice: Number(triggerPrice) * 10 ** currentMarket.price_decimals,
      }
      const tpOrder = {
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: marketId,
        baseAmount,
        price: getOrderPrice(
          OrderTypeEnum.TakeProfit,
          !isShort(),
          baseLimitPrice,
          baseWorstExecutionPrice,
          baseAmount,
        ),
        isAsk: Number(!isShort()),
        reduceOnly: 1,
        orderType: getOrderType(OrderTypeEnum.TakeProfit),
        timeInForce: getOrderTimeInForce(OrderTypeEnum.TakeProfit, timeInForce, postOnly),
        orderExpiry: getOrderExpiry(
          OrderTypeEnum.TakeProfit,
          timeInForce,
          timeInForceValue,
          timeInForceUnit,
          runtimeMinutes,
          runtimeHours,
        ),
        triggerPrice: Math.floor(
          Number(takeProfitInfo.takeProfitPrice) * 10 ** currentMarket.price_decimals,
        ),
      }
      const slOrder = {
        clientOrderIndex: 0,
        accountIndex,
        orderBookIndex: marketId,
        baseAmount,
        price: getOrderPrice(
          OrderTypeEnum.StopLoss,
          !isShort(),
          baseLimitPrice,
          baseWorstExecutionPrice,
          baseAmount,
        ),
        isAsk: Number(!isShort()),
        reduceOnly: 1,
        orderType: getOrderType(OrderTypeEnum.StopLoss),
        timeInForce: getOrderTimeInForce(OrderTypeEnum.StopLoss, timeInForce, postOnly),
        orderExpiry: getOrderExpiry(
          OrderTypeEnum.StopLoss,
          timeInForce,
          timeInForceValue,
          timeInForceUnit,
          runtimeMinutes,
          runtimeHours,
        ),
        triggerPrice: Math.floor(
          Number(stopLossInfo.stopLossPrice) * 10 ** currentMarket.price_decimals,
        ),
      }
      const orders = [
        takeProfitInfo.takeProfitPrice ? tpOrder : [],
        stopLossInfo.stopLossPrice ? slOrder : [],
        userOrder,
      ].flat()
      return orders.length > 1 ? createOrderBatch(orders) : createOrder(orders[0]!)
    },
    onMutate: ({ amount0, estimatedPrice, marketId }) => {
      toastIdRef.current = toast.custom(
        (toastId) => (
          <OrderNotification
            orderType={kind}
            size={Number(amount0)}
            price={
              [
                OrderTypeEnum.Limit,
                OrderTypeEnum.StopLossLimit,
                OrderTypeEnum.TakeProfitLimit,
              ].includes(kind)
                ? Number(limitPrice)
                : Number(estimatedPrice)
            }
            isAsk={isShort()}
            marketId={marketId}
            onClose={() => toast.dismiss(toastId)}
          />
        ),
        { position: 'top-right', duration: 12000 },
      )
    },
    onSuccess: (txHashes, { amount0, estimatedPrice }) => {
      if (!txHashes || !txHashes.hash) return
      const eventInfo = JSON.parse(txHashes.event_info) as TxEventInfo
      const orderIndex = eventInfo.to ? eventInfo.to.i : eventInfo.i

      toast.custom(
        (toastId) => (
          <OrderNotification
            orderType={kind}
            size={Number(amount0)}
            price={
              [
                OrderTypeEnum.Limit,
                OrderTypeEnum.StopLossLimit,
                OrderTypeEnum.TakeProfitLimit,
              ].includes(kind)
                ? Number(limitPrice)
                : Number(estimatedPrice)
            }
            isAsk={isShort()}
            marketId={currentMarket.market_id}
            orderIndex={orderIndex}
            onClose={() => toast.dismiss(toastId)}
          />
        ),
        { id: toastIdRef.current! },
      )
    },
    onError: (err) => {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={
            err.message === constants.FAT_FINGER_ERROR
              ? t('errors_fat_finger')
              : err.message === constants.LIMIT_PRICE_FAR_ERROR
                ? t('errors_limit_fat_finger')
                : err.message === constants.SLTP_PRICE_FAR_ERROR
                  ? t('errors_sltp_fat_finger')
                  : t('errors_generic_try_again')
          }
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      toast.dismiss(toastIdRef.current!)
    },
  })
}

interface PlaceOrderButtonProps {
  liquidationWarning: boolean
  triggerPriceInvalid: boolean
  passesLimitOrderChecks: boolean
  passesSLTPOrderChecks: boolean
  amount0: string
  worstExecutionPrice: number
  estimatedPrice: string
}

const PlaceOrderButton = ({
  liquidationWarning,
  triggerPriceInvalid,
  passesLimitOrderChecks,
  passesSLTPOrderChecks,
  amount0,
  worstExecutionPrice,
  estimatedPrice,
}: PlaceOrderButtonProps) => {
  const userAccount = useUserAccount()
  const accountsQuery = useAccountsQuery()
  const currentMarket = useCurrentMarket()
  const portfolioStats = useUserAccountPortfolioStats()
  const { setShowAuthFlow } = useDynamicContext()
  const orderInputs = useOrderInputStore()
  const orderData = useOrderToPlaceUpdater()
  const placeOrderMutation = usePlaceOrderMutation()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const latestDepositQuery = useLatestDepositQuery()

  const depositModal = useDepositStore()
  const queryClient = useQueryClient()
  const accountIndex = useAccountIndex()
  const didTriggerRef = useRef(false)
  const { t } = useTranslation()

  const accountExistence = useAccountExistence()

  const depositStore = useDepositStore()
  const createAccountMutation = useCreateAccountMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['account'] }),
  })

  useEffect(() => {
    if (
      isMainnet() &&
      !didTriggerRef.current &&
      latestDepositQuery.data?.status === 'bridging' &&
      !accountIndex
    ) {
      createAccountMutation.mutate()
      didTriggerRef.current = true
    }
  }, [accountIndex, latestDepositQuery.data, createAccountMutation])

  const orderTypeLabel = usePlaceOrderButtonOrderTypeLabel(orderInputs.kind)

  switch (accountExistence) {
    case 'GeoBlocked':
      return null
    case 'NoWallet':
      return (
        <Button onClick={() => setShowAuthFlow(true)} className="flex w-full gap-2">
          <Icon className="size-5" icon="wallet" />
          {t('connect_wallet_to_trade')}
        </Button>
      )
    case 'NotWhitelisted':
      return (
        <Button
          className="w-full"
          isLoading={isWhitelistedQuery.isLoading}
          onClick={() => useLighterStore.setState({ showWhitelist: true })}
        >
          {t('request_access')}
        </Button>
      )
    case 'KeysDontMatch':
      return (
        <Button
          className="w-full"
          onClick={() => useLighterStore.setState({ showOnboarding: true })}
        >
          {t('authenticate')}
        </Button>
      )
    case 'ShouldDeposit':
      if (!isMainnet()) {
        return (
          <Button
            className="w-full"
            isLoading={accountsQuery.isLoading}
            onClick={() => useLighterStore.setState({ showOnboarding: true })}
          >
            {t('create_account')}
          </Button>
        )
      }
      return (
        <Button className="w-full" onClick={() => depositModal.openModal()}>
          {t('create_account')}
        </Button>
      )
    case 'DepositInProgress':
      return (
        <Button
          className={cn('animate-pulse gap-2', DepositBtnStatusStyle('pending'))}
          onClick={depositStore.openModal}
        >
          {depositStore.status === 'pending' && <Icon icon="spinner" className="size-5" />}
          {t('deposit_in_progress')}
        </Button>
      )
    case 'Creating':
      return (
        <Button className="animate-pulse cursor-default gap-2 hover:bg-primary-blue-main">
          <Icon icon="spinner" className="size-5" />
          {t('creating_account')}
        </Button>
      )
    case 'Deciding':
      return <Button className="w-full" isLoading />
  }

  if (!userAccount || portfolioStats === null) {
    return <Button className="w-full" isLoading />
  }

  if (isZero(orderInputs.value)) {
    return (
      <Button className="w-full" disabled>
        {t('enter_amount')}
      </Button>
    )
  }

  if (orderInputs.kind === OrderTypeEnum.Market && !orderData.matchInfo.fullyFilled) {
    return (
      <Button className="w-full" disabled>
        {t('not_enough_liquidity')}
      </Button>
    )
  }

  if (
    (orderInputs.kind === OrderTypeEnum.Market || orderInputs.kind === OrderTypeEnum.Limit) &&
    !orderData.isReduceOnlyValid
  ) {
    return (
      <Button className="w-full" disabled>
        {t('reduce_only_too_large')}
      </Button>
    )
  }

  if (orderInputs.isLimit() && isZero(orderInputs.limitPrice)) {
    return (
      <Button className="w-full" disabled>
        {t('enter_limit_price')}
      </Button>
    )
  }

  if (orderInputs.isStop() && isZero(orderInputs.triggerPrice)) {
    return (
      <Button className="w-full" disabled>
        {t('enter_stop_price')}
      </Button>
    )
  }

  if (
    orderInputs.isTwap() &&
    isZero(orderInputs.runtimeHours) &&
    isZero(orderInputs.runtimeMinutes)
  ) {
    return (
      <Button className="w-full" disabled>
        {t('enter_running_time')}
      </Button>
    )
  }

  const runningTime =
    getTimestampDiff(Number(orderInputs.runtimeMinutes), 'm') +
    getTimestampDiff(Number(orderInputs.runtimeHours), 'h')

  if (
    orderInputs.isTwap() &&
    (runningTime < getTimestampDiff(5, 'm') || runningTime > getTimestampDiff(24, 'h'))
  ) {
    return (
      <Button className="w-full" disabled>
        {t('invalid_running_time')}
      </Button>
    )
  }

  if (liquidationWarning) {
    return (
      <Button className="w-full" disabled>
        {t('not_enough_margin')}
      </Button>
    )
  }

  if (!orderInputs.isLimit() && orderData.slippage > orderInputs.maxSlippage)
    return (
      <Button className="w-full" disabled>
        {t('too_much_slippage')}
      </Button>
    )

  if (
    orderInputs.isLimit() &&
    orderInputs.timeInForce === 'gtd' &&
    (orderInputs.timeInForceValue === '' ||
      (orderInputs.timeInForceUnit === 'm' && Number(orderInputs.timeInForceValue) < 10))
  ) {
    return (
      <Button className="w-full" disabled>
        {t('min_duration_in_minutes', { minutes: `${10}` })}
      </Button>
    )
  }

  if (triggerPriceInvalid) {
    return (
      <Button className="w-full" disabled>
        {t('trigger_price_invalid')}
      </Button>
    )
  }

  return (
    <Button
      className="w-full"
      isLoading={placeOrderMutation.isPending && !placeOrderMutation.isError}
      disabled={!passesLimitOrderChecks || !passesSLTPOrderChecks}
      onClick={() =>
        placeOrderMutation.mutate({
          accountIndex: userAccount.index,
          marketId: currentMarket.market_id,
          amount0,
          worstExecutionPrice,
          estimatedPrice,
        })
      }
    >
      {t('place')} {orderTypeLabel} {t('order')}
    </Button>
  )
}

export default PlaceOrderButton
