import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/orderBookSlice/selectors'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketSize, formatUSD } from 'js/util/formatting'

interface PositionSizeCellProps {
  size: string
  marketIndex: number
}

const PositionSizeCell = ({ size, marketIndex }: PositionSizeCellProps) => {
  const marketsStats = useMarketsStats()
  const mark_price = marketsStats[marketIndex]?.mark_price
  const market = useOrderBookMetas()[marketIndex]!

  return (
    <TableCell>
      <div className="flex flex-col">
        <div className="flex gap-1">
          <p className="typography-body-1 text-white">{formatMarketSize(size, market)}</p>
          <div className="rounded-lg bg-grey-light px-2 py-px text-[10px] text-white">
            {market.symbol}
          </div>
        </div>
        <p className="typography-body-1 text-white-opaque">
          {formatUSD(Number(mark_price) * Number(size))}
        </p>
      </div>
    </TableCell>
  )
}

export default PositionSizeCell
