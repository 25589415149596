import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccountsQuery, useIsWhitelistedQuery } from 'js/providers/hooks/useAccountQuery'
import { readSSUserSelections, writeSSUserSelection } from 'js/util/localStorage'
import { isMainnet } from 'js/util/util'

import { Button } from './Button'
import { Modal } from './Modal'

export const WelcomeModal = () => {
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true)
  const accountsQuery = useAccountsQuery()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const { data } = readSSUserSelections()
  const { t } = useTranslation()
  const shouldShowWelcomeModal =
    isMainnet() &&
    isWelcomeModalOpen &&
    !data?.hasClosedWelcomeModal &&
    isWhitelistedQuery.data === true &&
    accountsQuery.isError &&
    accountsQuery.error?.response?.status === 400

  return (
    <Modal
      title={t('welcome_modal_title')}
      open={shouldShowWelcomeModal}
      onOpenChange={(newOpen) => {
        if (!newOpen) {
          writeSSUserSelection({ ...data, hasClosedWelcomeModal: true })
        }

        setIsWelcomeModalOpen(newOpen)
      }}
    >
      <div className="typography-label-1 text-white">
        <p>{t('welcome_modal_description')}</p>
        <br />
        <p>{t('happy_trading')}</p>
      </div>
      <Button className="mt-4 w-full" onClick={() => setIsWelcomeModalOpen(false)}>
        {t('welcome_modal_cta')}
      </Button>
    </Modal>
  )
}
