import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import type { Order } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import Toast from 'js/shared-components/uikit/Toast'
import { cancelOrder } from 'js/zklighter-js-sdk/sdk'

interface CancelOrderButtonProps {
  order: Order
}

export const useCancelOrderMutation = () => {
  const accountIndex = useAccountIndex()!
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (order: Order) => {
      const promise = cancelOrder({
        accountIndex: accountIndex,
        marketIndex: order.market_index,
        orderIndex: order.order_index,
      })

      toast.promise(promise, {
        loading: <Toast level="loading" description="Cancelling Order" />,
        success: <Toast level="success" description="Order canceled" />,
        error: <Toast level="error" description={t('errors_generic_try_again')} />,
        unstyled: true,
      })

      return promise
    },
  })
}

const CancelOrderButton = ({ order }: CancelOrderButtonProps) => {
  const cancelOrderMutation = useCancelOrderMutation()

  const isLoading = cancelOrderMutation.isPending && !cancelOrderMutation.isError

  return (
    <Clickable color="red" onClick={() => cancelOrderMutation.mutate(order)} disabled={isLoading}>
      <Icon icon={isLoading ? 'spinner' : 'x'} className="size-4" />
    </Clickable>
  )
}

export default CancelOrderButton
