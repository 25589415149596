import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { formatDate } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { WithdrawHistoryItemStatusEnum, type WithdrawHistoryRequest } from 'zklighter-perps'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useZkLighterL1 } from 'js/providers/hooks/useZkLighterL1'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Clickable } from 'js/shared-components/Clickable'
import HeaderCell from 'js/shared-components/HeaderCell'
import { NoOrdersText } from 'js/shared-components/tables/NoOrdersText'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableLoader from 'js/shared-components/uikit/table/TableLoader'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { transactionApi } from 'js/util/api/sdk'
import { formatUSD } from 'js/util/formatting'
import { useLastTableItemRef } from 'js/util/table'
import { deserializeCursor } from 'js/util/util'

import { useWithdrawStore } from './form'

const WithdrawsHeader = () => {
  const { t } = useTranslation()

  return (
    <TableHeader>
      <TableHeaderRow>
        <HeaderCell title={t('date')} />
        <HeaderCell title={t('amount')} />
        <HeaderCell title={t('status')} />
        <HeaderCell title={t('transaction')} />
      </TableHeaderRow>
    </TableHeader>
  )
}

const REFRESH_INTERVAL = 10000

export const WithdrawHistory = () => {
  const { t } = useTranslation()
  const WITHDRAW_STATUS_LABELS = {
    completed: t('completed'),
    claimable: t('claimable'),
    failed: t('failed'),
    pending: t('pending'),
    refunded: t('refunded'),
  } as const

  const withdrawStore = useWithdrawStore()
  const accountIndex = useAccountIndex()
  const params: WithdrawHistoryRequest = useMemo(
    () => ({ account_index: accountIndex! }),
    [accountIndex],
  )
  const withdrawHistoryQuery = useInfiniteQuery({
    queryKey: ['withdrawHistory', params],
    queryFn: ({ pageParam }) => {
      return transactionApi.withdrawHistory({ ...params, cursor: pageParam })
    },
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => {
      const cursor = deserializeCursor(lastPage.cursor)
      // Don't fetch next page if cursor has -1 values
      if (cursor.index === -1 && cursor.index2 === -1) {
        return undefined
      }
      return lastPage.cursor || undefined
    },
    enabled: !!accountIndex,
    refetchInterval: REFRESH_INTERVAL,
  })
  const lastWithdrawRef = useLastTableItemRef(withdrawHistoryQuery)

  const withdraws = useMemo(
    () => (withdrawHistoryQuery.data?.pages ?? []).map(({ withdraws }) => withdraws).flat(),
    [withdrawHistoryQuery.data],
  )

  const userAddress = useUserAddress()
  const { getPendingBalance } = useZkLighterL1()
  const pendingBalanceQuery = useQuery({
    queryKey: ['pendingBalance', userAddress],
    queryFn: () => getPendingBalance(),
    select: (pendingBalance) => Number(pendingBalance),
    refetchInterval: REFRESH_INTERVAL * 2,
    enabled: !!withdraws.length && withdraws.some((withdraw) => withdraw.status === 'claimable'),
  })

  const displayStatuses = useMemo(() => {
    let processedAmount = 0

    return withdraws.reduce(
      (statuses: Record<string, WithdrawHistoryItemStatusEnum | 'completed'>, withdraw) => {
        if (withdraw.status === 'claimable') {
          processedAmount += Number(withdraw.amount)

          if (processedAmount > (pendingBalanceQuery.data ?? Number.MAX_VALUE)) {
            statuses[withdraw.id] = 'completed'
          } else {
            statuses[withdraw.id] = 'claimable'
          }
        } else {
          statuses[withdraw.id] = withdraw.status
        }
        return statuses
      },
      {},
    )
  }, [withdraws, pendingBalanceQuery.data])

  if (withdrawHistoryQuery.isLoading) {
    return (
      <Table>
        <WithdrawsHeader />
        <TableBody>
          <TableLoader rows={4} columns={4} />
        </TableBody>
      </Table>
    )
  }

  if (!withdraws.length) {
    return <NoOrdersText type="withdraws" />
  }

  return (
    <Table>
      <WithdrawsHeader />
      <TableBody>
        {withdraws.map((withdraw, index) => (
          <TableRow
            key={withdraw.id}
            ref={index === withdraws.length - 1 ? lastWithdrawRef : undefined}
          >
            <TableCell>
              <p className="typography-body-1 text-white">
                {formatDate(withdraw.timestamp, 'yyyy-MM-dd HH:mm:ss')}
              </p>
            </TableCell>
            <TableCell>
              <p className="typography-body-1 text-white">{formatUSD(withdraw.amount)}</p>
            </TableCell>
            <TableCell>
              <p className="typography-body-1 text-left text-white">
                {displayStatuses[withdraw.id] === 'claimable' ? (
                  <Clickable color="blue" onClick={() => withdrawStore.openModal()}>
                    {t('claimable')}
                  </Clickable>
                ) : (
                  WITHDRAW_STATUS_LABELS[
                    (displayStatuses[withdraw.id] ?? 'pending') as WithdrawHistoryItemStatusEnum
                  ]
                )}
              </p>
            </TableCell>
            <TableCell>
              {withdraw.l1_tx_hash && (
                <a
                  href={`https://${withdraw.type === 'fast' ? 'arbiscan.io' : 'etherscan.io'}/tx/${withdraw.l1_tx_hash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="typography-body-1 float-left text-blue-500 hover:text-blue-400"
                >
                  {`${withdraw.l1_tx_hash.slice(0, 6)}...${withdraw.l1_tx_hash.slice(-4)}`}
                </a>
              )}
              {!withdraw.l1_tx_hash && (
                <p className="typography-body-1 text-left text-gray-500">-</p>
              )}
            </TableCell>
          </TableRow>
        ))}
        {withdrawHistoryQuery.isFetchingNextPage && <TableLoader rows={1} columns={4} />}
      </TableBody>
    </Table>
  )
}
