import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import type { OrderBookDetail } from 'zklighter-perps'

import { TOKEN_LOGOS } from 'js/constants/shared'
import { ORDER_SIDE_TABS, type OrderSide } from 'js/constants/trades'
import { useChangeMarket, useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useIsRegisteredQuery } from 'js/providers/hooks/useAccountQuery'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Backdrop } from 'js/shared-components/Backdrop'
import { SelectorDropdown } from 'js/shared-components/SelectorDropdown'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import { ActiveOrderTable } from 'js/shared-components/tables/ActiveOrderTable'
import { FundingHistoryTable } from 'js/shared-components/tables/FundingHistoryTable'
import { OrderHistoryTable } from 'js/shared-components/tables/OrderHistoryTable'
import { PositionsTable } from 'js/shared-components/tables/PositionsTable'
import { TradeHistoryTable } from 'js/shared-components/tables/TradeHistoryTable'
import { UserPublicPoolsTable } from 'js/shared-components/tables/UserPublicPoolsTable'
import cn from 'js/util/cn'

import { DepositHistory } from '../deposit/history'
import { OrderListContainer } from '../trade/components/orders/styles'
import TradeTablesBlocker from '../trade/components/orders/user-orders/TradeTablesBlocker'
import { WithdrawHistory } from '../withdraw/history'

const TABS = [
  { key: 'positions', titleKey: 'positions' },
  { key: 'activeOrders', titleKey: 'open_orders' },
  { key: 'orderHistory', titleKey: 'order_history' },
  { key: 'tradeHistory', titleKey: 'trade_history' },
  { key: 'fundingHistory', titleKey: 'funding_history' },
  { key: 'publicPools', titleKey: 'public_pools' },
  { key: 'deposits', titleKey: 'deposits' },
  { key: 'withdraws', titleKey: 'withdrawals' },
] as const

type TabType = (typeof TABS)[number]['key']

export const UserTransactions = () => {
  const { t } = useTranslation()
  const changeMarket = useChangeMarket()
  const accountIndex = useAccountIndex()
  const location = useLocation()
  const defaultTab = location.state as { tab?: TabType }
  const [selectedTab, setSelectedTab] = useState<TabType>(defaultTab?.tab ?? 'positions')
  const [selectedSide, setSelectedSide] = useState<OrderSide>('all')
  const [selectedMarket, setSelectedMarket] = useState<OrderBookDetail | null>(null)
  const [indicatorLeft, setIndicatorLeft] = useState(12)
  const [indicatorWidth, setIndicatorWidth] = useState(56)
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const navigate = useNavigate()

  const { isMobile } = useResponsiveness()
  const isRegistered = useIsRegisteredQuery().data
  const orderBookMetas = useOrderBookMetas()

  const useRefs = () => {
    const refsByKey = useRef<Record<string, HTMLElement | null>>({})

    const setRef = (element: HTMLElement | null, key: number) => {
      refsByKey.current[key] = element
    }

    return { refsByKey: refsByKey.current, setRef }
  }

  const { refsByKey, setRef } = useRefs()

  const refs = Object.values(refsByKey).filter(Boolean)

  const selectedTabIndex = TABS.findIndex((tab) => tab.key === selectedTab)!

  const marketOptions = [
    { title: t('all'), onClick: () => setSelectedMarket(null), icon: 'coin' as const, key: 'all' },
    ...Object.values(orderBookMetas).map((market) => ({
      title: market.symbol,
      key: market.symbol,
      onClick: () => setSelectedMarket(market),
      icon: TOKEN_LOGOS[market.symbol as keyof typeof TOKEN_LOGOS],
    })),
  ]

  const selectedTabRef = refs[selectedTabIndex]
  useEffect(() => {
    if (!selectedTabRef) {
      return
    }

    setIndicatorLeft(selectedTabRef.offsetLeft)
    setIndicatorWidth(selectedTabRef.getBoundingClientRect().width)
  }, [selectedTabIndex, selectedTabRef])

  return (
    <>
      <Backdrop
        fullScreen
        className="top-0 h-full"
        isVisible={isSelectorOpen}
        onClick={() => setIsSelectorOpen(false)}
      />
      <div className="flex flex-1 overflow-hidden max-mobile:overflow-visible mobile:min-h-[300px]">
        <div className="flex size-full max-h-full flex-1 flex-col justify-start gap-2 max-mobile:min-h-[300px] mobile:relative">
          <TabBar className="flex h-[unset] min-h-[unset] items-center justify-between rounded-lg border-none bg-white/5 p-1 max-mobile:gap-4 max-mobile:bg-transparent max-mobile:px-1">
            {!isMobile && (
              <div className="relative flex gap-6">
                <SelectorIndicator
                  left={indicatorLeft}
                  width={indicatorWidth}
                  numButtons={TABS.length}
                  selectedElemIdx={selectedTabIndex}
                  className="bottom-px after:h-0.5 after:w-10 after:rounded-full"
                />
                {TABS.map(({ key, titleKey }, index) => (
                  <TabButton
                    setRef={setRef}
                    id={index}
                    key={key}
                    isSelected={false}
                    onClick={() => {
                      setSelectedTab(key)
                      navigate(location.pathname, { state: { tab: key } })
                    }}
                    className={cn(
                      'typography-label-1 cursor-pointer rounded-lg px-4 py-2 transition-all',
                      {
                        'bg-transparent text-white-opaque': selectedTab !== key,
                        'bg-white/10 text-white': selectedTab === key,
                      },
                    )}
                  >
                    {t(titleKey)}
                  </TabButton>
                ))}
              </div>
            )}
            {isMobile && (
              <div className="flex flex-1 gap-6 rounded-lg bg-white/10 px-4">
                <div className="w-full">
                  <div
                    className="flex w-full items-center justify-between"
                    onClick={() => setIsSelectorOpen((prev) => !prev)}
                  >
                    <TabButton isSelected={false}>
                      <p className="typography-label-1 text-left text-white">
                        {t(TABS[selectedTabIndex]!.titleKey)}
                      </p>
                    </TabButton>
                    <SmallArrow direction="down" />
                  </div>
                  <BottomAnimatedDropdown isOpen={isSelectorOpen} className="z-[101]">
                    {TABS.map(({ key, titleKey }) => (
                      <TabButton
                        key={key}
                        className="h-[unset] py-4"
                        isSelected={false}
                        onClick={() => {
                          setSelectedTab(key)
                          setIsSelectorOpen(false)
                          navigate(location.pathname, { state: { tab: key } })
                        }}
                      >
                        <p className="typography-label-1 text-white">{t(titleKey)}</p>
                      </TabButton>
                    ))}
                  </BottomAnimatedDropdown>
                </div>
              </div>
            )}
            <div className="flex h-full items-center gap-2 pr-3 max-mobile:gap-1 max-mobile:pr-0">
              {selectedTab !== 'positions' &&
                selectedTab !== 'deposits' &&
                selectedTab !== 'withdraws' &&
                selectedTab !== 'publicPools' && (
                  <SelectorDropdown
                    options={marketOptions}
                    selectedOption={selectedMarket?.symbol ?? 'all'}
                    triggerClassName="max-mobile:h-full max-mobile:bg-white/10 max-mobile:rounded-lg max-mobile:border-none max-mobile:px-3 max-mobile:py-2"
                  />
                )}
              {selectedTab !== 'deposits' &&
                selectedTab !== 'withdraws' &&
                selectedTab !== 'publicPools' && (
                  <SelectorDropdown
                    options={ORDER_SIDE_TABS.map(({ key, titleKey, icon }) => ({
                      key,
                      icon,
                      title: t(titleKey),
                      onClick: () => setSelectedSide(key),
                    }))}
                    selectedOption={selectedSide}
                    triggerClassName="max-mobile:h-full max-mobile:bg-white/10 max-mobile:rounded-lg max-mobile:border-none max-mobile:px-3 max-mobile:py-2"
                  />
                )}
            </div>
          </TabBar>
          <div className="flex-1 overflow-hidden rounded-lg bg-white/5 max-mobile:flex max-mobile:border">
            <OrderListContainer>
              <TradeTablesBlocker>
                {selectedTab === 'positions' && (
                  <PositionsTable
                    accountIndex={accountIndex!}
                    showCloseColumn={!!isRegistered}
                    selectedSide={selectedSide}
                    onRowClick={(market) => changeMarket(market.market_id)}
                  />
                )}
                {selectedTab === 'activeOrders' && (
                  <ActiveOrderTable
                    accountIndex={accountIndex!}
                    showCancelColumn
                    selectedSide={selectedSide}
                    selectedMarket={selectedMarket}
                    setSelectedMarket={setSelectedMarket}
                  />
                )}
                {selectedTab === 'orderHistory' && (
                  <OrderHistoryTable
                    accountIndex={accountIndex!}
                    selectedSide={selectedSide}
                    selectedMarket={selectedMarket}
                    setSelectedMarket={setSelectedMarket}
                  />
                )}
                {selectedTab === 'tradeHistory' && (
                  <TradeHistoryTable
                    accountIndex={accountIndex!}
                    selectedSide={selectedSide}
                    selectedMarket={selectedMarket}
                    setSelectedMarket={setSelectedMarket}
                  />
                )}
                {selectedTab === 'fundingHistory' && (
                  <FundingHistoryTable
                    accountIndex={accountIndex!}
                    selectedSide={selectedSide}
                    selectedMarket={selectedMarket}
                    setSelectedMarket={setSelectedMarket}
                  />
                )}
                {selectedTab === 'publicPools' && <UserPublicPoolsTable />}
                {selectedTab === 'deposits' && <DepositHistory />}
                {selectedTab === 'withdraws' && <WithdrawHistory />}
              </TradeTablesBlocker>
            </OrderListContainer>
          </div>
        </div>
      </div>
    </>
  )
}
