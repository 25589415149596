import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { BaseError } from 'viem'
import { create } from 'zustand'

import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useUSDC } from 'js/providers/hooks/useERC20'
import { useWallet } from 'js/providers/hooks/useWallet'
import { useZkLighterL1 } from 'js/providers/hooks/useZkLighterL1'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Button } from 'js/shared-components'
import { CheckboxSelector } from 'js/shared-components/CheckboxSelector'
import { Clickable } from 'js/shared-components/Clickable'
import { Modal } from 'js/shared-components/Modal'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import Icon from 'js/shared-components/uikit/Icon'
import UIKitPriceInput from 'js/shared-components/uikit/PriceInput'
import Toast from 'js/shared-components/uikit/Toast'
import { pollLighterForL1Tx } from 'js/util/api/api'
import { accountApi, bridgeApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { formatUSDFixed } from 'js/util/formatting'
import {
  copyStringToClipboard,
  enforceNumber,
  getFrontendEnv,
  isMainnet,
  isZero,
} from 'js/util/util'

import {
  useLatestDepositQuery,
  useSupportedNetworksQuery,
  type DepositStatus,
  type EnDeposit,
} from './hooks'

type Step =
  | 'checkEligibility'
  | 'choose'
  | 'fill'
  | 'fillExternal'
  | 'Tx_WaitSign'
  | 'Tx_waitReceipt'
  | 'Tx_Failed'
  | 'pendingDeposit'
  | 'successfulDeposit'
  | 'waitForCollateral'

type DepositBtnStatus = 'pending' | 'completed' | 'fail' | 'idle'
export const DepositBtnStatusStyle = (status: DepositBtnStatus) =>
  cn({
    'bg-orange-500/20 text-orange-500 hover:bg-orange-500/40': status === 'pending',
    'bg-green-main/20 text-green-main hover:bg-green-main/40': status === 'completed',
    'bg-red-main/20 text-red-main hover:bg-red-main/40': status === 'fail',
  })

const DepositStatusStyle = (status: DepositStatus) =>
  cn({
    'bg-orange-500/20 text-orange-500 hover:bg-orange-500/40': status === 'bridging',
    'bg-blue-500/20 text-blue-500 hover:bg-blue-500/40': status === 'waitForTransfer',
    'bg-green-main/20 text-green-main hover:bg-green-main/40': status === 'completed',
    'bg-red-main/20 text-red-main hover:bg-red-main/40': status === 'cancelling',
  })

interface DepositStore {
  status: DepositBtnStatus
  setStatus: (s: DepositBtnStatus) => void
  openModal: () => void
  closeModal: () => void
  isModalOpen: boolean
}

export const useDepositStore = create<DepositStore>((set) => ({
  status: 'idle',
  isModalOpen: false,
  setStatus: (status) => set(() => ({ status })),
  closeModal: () => set(() => ({ isModalOpen: false })),
  openModal: () => set(() => ({ isModalOpen: true })),
}))

export const DepositButton = (props: { onClickCallback?: () => void }) => {
  const depositStore = useDepositStore()
  const accountIndex = useAccountIndex()
  const accountExistence = useAccountExistence()
  const { t } = useTranslation()

  if (!isMainnet() || accountExistence !== 'Exists') {
    return null
  }

  return (
    <Button
      disabled={!accountIndex}
      onClick={() => {
        props.onClickCallback?.()
        depositStore.openModal()
      }}
      className={cn('flex items-center gap-2', DepositBtnStatusStyle(depositStore.status), {
        'animate-pulse': depositStore.status === 'pending',
      })}
    >
      {depositStore.status === 'idle' && (
        <Icon
          icon="deposit"
          className={cn('size-5', {
            'text-white-opaque': !accountIndex,
            'text-white': accountIndex,
          })}
        />
      )}
      <span>{t('deposit')}</span>
      {depositStore.status === 'pending' && <Icon icon="spinner" className="size-5" />}
      {depositStore.status === 'completed' && <Icon icon="check" className="size-5" />}
    </Button>
  )
}

// I know this exists somewhere else we worry later.
const useMaxDepositAmt = () => {
  const userAddress = useUserAddress()

  return (
    useQuery({
      queryKey: ['whitelist', userAddress],
      queryFn: () => accountApi.isWhitelisted({ l1_address: userAddress }),
      select: ({ deposit_amount_left }) => deposit_amount_left,
      enabled: !!userAddress.length,
    }).data ?? '10000'
  )
}

const MIN_DEPOSIT = '0.11'

export const DepositModal = () => {
  const { isMobile } = useResponsiveness()
  const userAddress = useUserAddress()
  const navigate = useNavigate()
  const depositStore = useDepositStore()
  const latestDepositQuery = useLatestDepositQuery()
  const [step, setStep] = useState<Step>('checkEligibility')
  const [depositMethod, setDepositMethod] = useState<'external' | 'connected'>()
  const [termsAccepted, setTermsAccepted] = useState(false)
  const { primaryWallet } = useDynamicContext()
  const accountIndex = useAccountIndex()
  const maxDepositAmt = useMaxDepositAmt()
  const isModalOpen = depositStore.isModalOpen
  const isEmbeddedWallet = !!primaryWallet?.connector.isEmbeddedWallet
  const accountExistence = useAccountExistence()
  const { t } = useTranslation()

  // dont want to have the effect running when this changes but want to access  to decide what toast to show
  const accountExistenceRef = useRef(accountExistence)

  useEffect(() => {
    accountExistenceRef.current = accountExistence
  }, [accountExistence])

  useEffect(() => {
    const dep = latestDepositQuery.data

    if (isModalOpen) {
      if (isEmbeddedWallet) {
        setDepositMethod('external')
        setStep((x) => {
          if (!latestDepositQuery.isLoading && x == 'checkEligibility') {
            return 'fillExternal'
          }
          return x
        })
      } else {
        setStep((x) => {
          if (!latestDepositQuery.isLoading && x == 'checkEligibility') {
            return 'choose'
          }
          return x
        })
      }
    }

    switch (dep?.status) {
      case 'waitForTransfer':
        setStep((x) => (x.startsWith('Tx') ? x : 'pendingDeposit'))
        useDepositStore.getState().setStatus('pending')
        return
      case 'cancelling':
        setStep((x) => (x.startsWith('Tx') ? x : 'pendingDeposit'))
        useDepositStore.getState().setStatus('pending')
        return
      case 'bridging':
        setStep((x) => (x.startsWith('Tx') ? x : 'pendingDeposit'))
        setStep('pendingDeposit')
        useDepositStore.getState().setStatus('pending')
        return
      case 'completed': {
        const didNotConfirm = localStorage.getItem('lastdepos') !== dep.fast_bridge_tx_hash
        if (didNotConfirm && accountExistenceRef.current === 'Exists') {
          toast.success(t('collateral_arrived'), { richColors: true })
        }
        if (didNotConfirm) {
          useDepositStore.getState().setStatus('completed')
          setStep('successfulDeposit')
        }
      }
    }
  }, [
    isModalOpen,
    depositStore.isModalOpen,
    isEmbeddedWallet,
    latestDepositQuery.data,
    latestDepositQuery.isLoading,
    t,
  ])

  const [amount, setAmount] = useState('')

  const supportedNetworksQuery = useSupportedNetworksQuery()
  const wallet = useWallet()

  const [switching, setSwitching] = useState(false)
  const [externalDepositChain, setExternalChain] = useState<number>()

  const zklighter = useZkLighterL1()
  const selectedNetwork = useMemo(() => {
    if (depositMethod === 'connected') {
      return supportedNetworksQuery.data?.find((x) => x.chainId === wallet.network)
    }
    if (
      !externalDepositChain &&
      supportedNetworksQuery.data &&
      supportedNetworksQuery.data.length > 0
    ) {
      return supportedNetworksQuery.data.find((x) => x.chainId !== zklighter.chainId)
    }

    return supportedNetworksQuery.data?.find((x) => x.chainId === externalDepositChain)
  }, [
    supportedNetworksQuery.data,
    wallet.network,
    depositMethod,
    externalDepositChain,
    zklighter.chainId,
  ])

  const usdc = useUSDC(selectedNetwork?.usdcAddr)

  const usdcBalance = useQuery({
    queryKey: ['usdcBalance', selectedNetwork?.chainId],
    queryFn: () => usdc.getBalance(),
    refetchOnWindowFocus: true,
    enabled: !!selectedNetwork && depositStore.isModalOpen && depositMethod === 'connected',
  })

  const usingL1ToDeposit = useMemo(
    () => selectedNetwork?.chainId === zklighter.chainId,
    [selectedNetwork?.chainId, zklighter.chainId],
  )

  const amtErrMsg = useMemo(() => {
    if (usdcBalance.data && Number(amount) > Number(usdcBalance.data)) {
      return t('insufficient_funds')
    }
    if (Number(amount) > Number(maxDepositAmt)) {
      return t('max_deposit_error')
    }
    if (amount.length > 0 && Number(amount) > 0 && Number(amount) <= 0.1) {
      return t('min_deposit', { amount: MIN_DEPOSIT })
    }
    return ''
  }, [amount, usdcBalance.data, maxDepositAmt, t])

  const intentMutation = useMutation({
    mutationFn: ({
      from,
      amount,
      chainId,
      useExternalWallet,
    }: {
      from: string
      amount: string
      chainId: number
      useExternalWallet: boolean
    }) =>
      bridgeApi.createIntentAddress({
        chain_id: chainId.toString(),
        is_external_deposit: useExternalWallet,
        from_addr: from,
        amount: (Number(amount) * 10 ** 6).toFixed(0),
      }),
  })

  const usdcTransferMutation = useMutation({
    mutationFn: async ({ toAddr, amount }: { toAddr: string; amount: string }) => {
      setStep('Tx_WaitSign')
      const usingL1 = selectedNetwork?.chainId === zklighter.chainId
      let hash = '0x' as `0x${string}`
      // if picked l1 interact with the contract directly
      if (usingL1) {
        await wallet.switch(zklighter.chainId)
        hash = await zklighter.deposit(amount)
        // hash = '0xe3810a7cea0fce34ff7c0fc03f022554050a0d39041370fc9b7dcab8f3ab40fc'
      } else {
        hash = await usdc.send({ to: toAddr, amount })
      }
      return {
        toAddr,
        amount,
        hash,
        network: selectedNetwork?.chainId,
        usingL1: usingL1,
      }
    },
    onSuccess: (data) => {
      setStep('Tx_waitReceipt')
      txReceiptMutation.mutate({ hash: data.hash, usingL1: data.usingL1 })
    },
    onError: (e) => {
      if (e instanceof BaseError) {
        console.error('Viem Execution Error while deposit transfer ', e.shortMessage, e.name)
      } else {
        console.error('Unknown error while deposit transfer ', e.name)
      }
      useDepositStore.getState().setStatus('fail')
      setStep('Tx_Failed')
    },
  })

  const pollLighterForL1TxExec = useMutation({
    mutationFn: (args: { hash: `0x${string}` }) => pollLighterForL1Tx(args.hash),
    onSuccess: async () => {
      useDepositStore.getState().setStatus('idle')
      useDepositStore.getState().closeModal()
      txReceiptMutation.reset()
      latestDepositQuery.refetch()
      setStep('choose')
      toast.success(t('collateral_arrived'), { richColors: true })
    },
    onError: async (err) => {
      useDepositStore.getState().setStatus('idle')
      useDepositStore.getState().closeModal()
      toast.error(t('deposit_delay_error'), { richColors: true })
      setStep('choose')
      console.error(err)
    },
  })

  const txReceiptMutation = useMutation({
    mutationFn: async (args: { usingL1: boolean; hash: `0x${string}` }) => {
      const receipt = await wallet.waitTx(args.hash)
      return { ...receipt, usingL1: args.usingL1 }
    },
    onMutate: () => useDepositStore.getState().setStatus('pending'),
    onSuccess: (receipt) => {
      usdcBalance.refetch()
      if (receipt.usingL1) {
        pollLighterForL1TxExec.mutate({ hash: receipt.transactionHash })
        return
      }
      latestDepositQuery.refetch()
      intentMutation.reset()
    },
    onError: () => useDepositStore.getState().setStatus('idle'),
  })

  const onClickDeposit = async () => {
    if (!selectedNetwork) {
      return
    }
    setDepositMethod('connected')

    // if picked l1 chain interact with the contract directly
    if (selectedNetwork.chainId === zklighter.chainId) {
      usdcTransferMutation.mutate({ toAddr: '', amount })
      return
    }

    intentMutation.mutateAsync(
      {
        from: userAddress,
        amount,
        chainId: selectedNetwork.chainId,
        useExternalWallet: depositMethod === 'external',
      },
      {
        onSuccess: (addr) => usdcTransferMutation.mutate({ toAddr: addr.intent_address, amount }),
      },
    )
  }

  const onClickDepositExternal = () => {
    if (!selectedNetwork) {
      return
    }
    setDepositMethod('external')

    intentMutation.mutateAsync(
      {
        from: userAddress,
        amount,
        chainId: selectedNetwork.chainId,
        useExternalWallet: depositMethod === 'external',
      },
      {
        onSuccess: () => {
          // if cex we should switch to deposit view asap by triggering deposit status polling
          // otherwise we should prompt transfer tx
          intentMutation.reset()
          latestDepositQuery.refetch()
          // setStep('pendingDeposit')
        },
      },
    )
  }

  const ChainSelector = (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={switching} className="w-full bg-white/10">
        <div className="flex h-4 justify-center gap-3">
          {switching ? (
            <span className="flex items-center gap-2">
              <Icon icon="spinner" className="size-3" />
              <span>{t('wallet_approval_wait')}</span>
            </span>
          ) : (
            <>
              {selectedNetwork?.icon}
              {selectedNetwork?.name ?? t('supported_network_cta')}
            </>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-full">
        {supportedNetworksQuery.data
          ?.filter(
            (network) => depositMethod === 'connected' || network.chainId !== zklighter.chainId,
          )
          .map((network) => (
            <DropdownMenuItem
              key={network.chainId}
              className={cn({ 'bg-black': isMobile })}
              onClick={() => {
                if (depositMethod === 'connected') {
                  setSwitching(true)

                  // try twice as dynamic is not stable
                  wallet
                    .switch(network.chainId)
                    .catch(() =>
                      wallet
                        .switch(network.chainId)
                        .catch((err) =>
                          toast.custom((toastId) => (
                            <Toast
                              level="error"
                              description={err + ''}
                              onClose={() => toast.dismiss(toastId)}
                            />
                          )),
                        ),
                    )
                    .finally(() => setSwitching(false))
                } else if (depositMethod === 'external') {
                  setExternalChain(network.chainId)
                }
              }}
            >
              <div
                className={cn('typography-label-1 flex w-full items-center gap-3', {
                  'text-white': selectedNetwork?.name === network.name,
                  'text-white-opaque': selectedNetwork?.name !== network.name,
                })}
              >
                {network.icon}
                <p>{network.name}</p>
              </div>
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )

  const CancelDeposit = (
    <Button
      disabled={latestDepositQuery.data?.status === 'cancelling'}
      className=""
      color="red"
      onClick={() => {
        const { data, cancel } = latestDepositQuery
        if (!data || !cancel) return
        cancel(
          { chainId: data.source_chain_id, userAddr: userAddress },
          {
            onSuccess: () => {
              depositStore.closeModal()
              depositStore.setStatus('idle')
              setStep(isEmbeddedWallet ? 'fillExternal' : 'choose')
            },
          },
        )
      }}
    >
      {t('cancel')}
    </Button>
  )
  const IntentAddress = latestDepositQuery.data && latestDepositQuery.data.is_external_deposit && (
    <div
      onClick={() => {
        copyStringToClipboard(latestDepositQuery.data?.intent_address ?? '')
        toast.custom((toastId) => (
          <Toast
            level="success"
            description={t('address_copied')}
            onClose={() => toast.dismiss(toastId)}
          />
        ))
      }}
      className={cn(
        'group flex cursor-pointer items-center justify-between rounded-md border border-white/10 bg-white/5 p-2 text-center text-sm text-white',
      )}
    >
      <p className={cn({ 'text-xs': isMobile })}>{latestDepositQuery.data.intent_address}</p>
      <Icon icon="copy" className="w-6 text-white-opaque group-hover:text-primary-blue-main" />
    </div>
  )
  const IntentQR = latestDepositQuery.data &&
    latestDepositQuery.data.is_external_deposit &&
    latestDepositQuery.data.status !== 'completed' && (
      <>
        <span className="mx-auto text-white-opaque">{t('or')}</span>
        <QRCode
          className="mx-auto rounded-md border border-white/5 bg-white/10 p-4"
          value={latestDepositQuery.data.intent_address ?? ''}
        />
        <span className="mx-auto text-white-opaque">{t('scan_qr_code')}</span>
      </>
    )

  const BaseWarning = selectedNetwork?.chainId === 8453 && (
    <div className="flex items-center gap-1 text-white-opaque">
      <span className="text-sm text-orange-400">{t('base_usdc_warning')}</span>
    </div>
  )

  const stepView: Record<typeof step, React.ReactNode> = {
    checkEligibility: (
      <div className="flex flex-col items-center gap-3 pb-4">
        <p className="typography-label-1 pb-4 text-center text-lg">
          {t('checking_deposit_eligibility')}
        </p>
        <Icon icon="spinner" className="size-10" />
      </div>
    ),
    waitForCollateral: (
      <div className="flex flex-col items-center gap-3 pb-4">
        <p className="typography-label-1 pb-4 text-center text-lg">
          {t('deposit_modal_bridging_your_deposit', {
            source: latestDepositQuery.data?.source ?? '',
          })}
        </p>
        <Icon icon="spinner" className="size-10" />
      </div>
    ),
    successfulDeposit: latestDepositQuery.data && (
      <div className="flex flex-col gap-6 pb-2 ">
        <DepositInfo deposit={latestDepositQuery.data} />
        <span
          onClick={() => {
            navigate('/portfolio', { state: { tab: 'deposits' } })
            depositStore.closeModal()
          }}
          className="cursor-pointer text-center text-sm text-white-opaque underline"
        >
          {t('deposit_history')}
        </span>
      </div>
    ),
    pendingDeposit: latestDepositQuery.data && (
      <div className="flex flex-col gap-3 pb-4">
        <p className="typography-label-1 flex items-end gap-2 pl-2">
          {latestDepositQuery.data.status !== 'completed' &&
            latestDepositQuery.data.step !== '0' &&
            latestDepositQuery.data.description}
          {latestDepositQuery.data.is_external_deposit &&
            latestDepositQuery.data.step === '0' &&
            t('deposit_modal_external_deposit_cta')}
          {latestDepositQuery.data.status !== 'completed' && <DotsLoader />}
        </p>
        {IntentAddress}
        {IntentQR}
        <DepositInfo deposit={latestDepositQuery.data} />
        {BaseWarning}
        {isZero(latestDepositQuery.data.amount) && CancelDeposit}
        {getFrontendEnv() === 'Staging' && latestDepositQuery.data.is_external_deposit && (
          <Button onClick={() => latestDepositQuery.forceComplete()}>{t('fake_fill')}</Button>
        )}
      </div>
    ),
    choose: (
      <div className="flex flex-col items-center gap-3">
        <button
          className="w-full rounded-lg border-white/10 bg-white/10 p-4 text-start text-sm text-white-opaque hover:bg-white/5 hover:text-white"
          onClick={() => {
            setDepositMethod('connected')
            setStep('fill')
          }}
        >
          <div className="flex flex-col gap-3">
            <div className="flex items-start justify-between">
              <div className="flex grow flex-col">
                <span className="text-sm font-medium text-white">
                  {t('deposit_connected_wallet')}
                </span>
                <span className="mt-1 text-xs text-white/60">
                  {t('use_crypto_platform_description')}
                </span>
              </div>
              <div className="ml-4 flex gap-2">
                <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white/5">
                  <Icon icon="metamask" className="size-6" />
                </div>
                <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white/5">
                  <Icon icon="walletconnect" className="size-6" />
                </div>
                <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white/5">
                  <Icon icon="coinbasewallet" className="size-6" />
                </div>
              </div>
            </div>
          </div>
        </button>
        <button
          className="w-full rounded-lg border-white/10 bg-white/10 p-4 text-start text-sm text-white-opaque hover:bg-white/5 hover:text-white"
          onClick={() => {
            setDepositMethod('external')
            setStep('fillExternal')
          }}
        >
          <div className="flex flex-col gap-3 ">
            <div className="flex items-start justify-between">
              <div className="flex grow flex-col ">
                <span className="text-sm font-medium text-white">
                  {t('deposit_external_wallet')}
                </span>
                <span className="mt-1 text-xs text-white/60">
                  {t('deposit_external_wallet_description')}
                </span>
              </div>
              <div className="ml-4 flex gap-2">
                <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white/5">
                  <Icon icon="binance" className="size-6" />
                </div>
                <div className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white/5">
                  <Icon icon="coinbase" className="size-6" />
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
    ),
    fill: (
      <div className="flex flex-col items-center gap-3 pb-16">
        <div className="grid w-full grid-cols-2 gap-3 rounded-lg border border-white/10 bg-white/10 p-4">
          <div className="text-white-opaque">{t('deposit_from')}</div>
          <div className="col-span-2 flex w-full flex-col items-center justify-center gap-2 space-x-1 align-middle">
            {ChainSelector}
          </div>
          <div
            className={cn('flex items-center gap-1 whitespace-nowrap', {
              hidden: selectedNetwork?.isCex,
            })}
          >
            <span className="text-white-opaque">{t('available')} </span>
            {usdcBalance.isLoading && <Icon icon="spinner" className="size-3" />}
            {usdcBalance.data} USDC
          </div>
        </div>
        <UIKitPriceInput
          label={`${t('amount')} (USDC)`}
          placeholder="0.00"
          value={amount}
          disabled={Number(usdcBalance.data) < 1}
          onChange={(e) => {
            if (!enforceNumber(e, 2)) return
            setAmount(e.target.value)
          }}
          buttonText={t('max')}
          onClick={() => {
            if (!usdcBalance.data || Number(usdcBalance.data) === 0) {
              return
            }
            const maxAmount = Math.min(Number(usdcBalance.data), Number(maxDepositAmt))

            setAmount(formatUSDFixed(maxAmount))
          }}
        />

        {BaseWarning}
        <div className="flex w-full items-center justify-center gap-1 py-4 text-white-opaque">
          <span>{t('max_deposit_amount', { amount: maxDepositAmt })}</span>
          <span className="rounded-full bg-white/5 px-2 py-0.5">USDC</span>
        </div>
        <div className="text-red-main">{amtErrMsg}</div>
        {!accountIndex && (
          <div className="flex items-center gap-1 self-start">
            <div
              className="flex cursor-pointer items-center gap-3"
              onClick={() => setTermsAccepted((prevTermsAccepted) => !prevTermsAccepted)}
            >
              <CheckboxSelector isSelected={termsAccepted} />
              <span className="typography-body-2 text-white">{t('i_accept_the')}</span>
            </div>
            <a href="https://lighter.xyz/terms" target="_blank" rel="noopener noreferrer">
              <Clickable color="blue">{t('terms_of_service')}</Clickable>
            </a>
          </div>
        )}
        <Button
          className="absolute inset-x-0 bottom-0 w-full rounded-t-none border-t-0 py-8 max-mobile:rounded-b-none"
          color={amtErrMsg ? 'red' : 'green'}
          disabled={
            intentMutation.isPending ||
            !amount ||
            !!amtErrMsg ||
            (!termsAccepted && !accountIndex) ||
            Number(amount) === 0
          }
          onClick={onClickDeposit}
        >
          {intentMutation.isPending ? t('please_wait') : t('deposit')}
        </Button>
      </div>
    ),
    fillExternal: (
      <div className="flex flex-col items-center gap-3 pb-16">
        <div className="grid w-full grid-cols-2 gap-3 rounded-lg border border-white/10 bg-white/10 p-4">
          <div className="text-sm text-white-opaque">{t('deposit_modal_chain_to')}</div>
          <div className="col-span-2 flex w-full flex-col items-center justify-center gap-2 space-x-1 align-middle">
            {ChainSelector}
          </div>
        </div>
        {BaseWarning}
        <div className="flex items-center gap-1 py-4 text-white-opaque">
          <span>{t('max_deposit_amount', { amount: maxDepositAmt })}</span>
          <span className="rounded-full border-white/10 bg-black px-2 py-0.5">USDC</span>
        </div>
        {!accountIndex && (
          <div className="flex items-center gap-1 self-start">
            <div
              className="flex cursor-pointer items-center gap-3"
              onClick={() => setTermsAccepted((prevTermsAccepted) => !prevTermsAccepted)}
            >
              <CheckboxSelector isSelected={termsAccepted} />
              <span className="typography-body-2 text-white">{t('i_accept_the')}</span>
            </div>
            <a href="https://lighter.xyz/terms" target="_blank" rel="noopener noreferrer">
              <Clickable color="blue">{t('terms_of_service')}</Clickable>
            </a>
          </div>
        )}
        <Button
          className="absolute inset-x-0 bottom-0 w-full rounded-t-none border-t-0 py-8 max-mobile:rounded-b-none"
          color="green"
          disabled={
            intentMutation.isPending || (!termsAccepted && !accountIndex) || maxDepositAmt === '0'
          }
          isLoading={intentMutation.isPending}
          onClick={onClickDepositExternal}
        >
          {t('show_address_for_funds')}
        </Button>
      </div>
    ),
    Tx_waitReceipt: (
      <div className="flex flex-col items-center gap-3 pb-8">
        <p className="typography-label-1 pb-4 text-center text-lg">
          {txReceiptMutation.isPending &&
            t('deposit_modal_receipt_pending', {
              address: truncateAddress(usdcTransferMutation.data?.hash ?? ''),
            })}
          {txReceiptMutation.data?.status === 'success' && t('deposit_modal_receipt_success')}
          {txReceiptMutation.data?.status === 'reverted' && (
            <span className="text-red-main">
              {t('deposit_modal_receipt_error', {
                address: truncateAddress(txReceiptMutation.data.transactionHash),
              })}
            </span>
          )}
        </p>
        <div className="relative grid place-items-center">
          <Icon
            icon="logo"
            className={cn('size-16 text-white', {
              'animate-pulse': txReceiptMutation.isPending,
            })}
          />
        </div>
      </div>
    ),
    Tx_Failed: (
      <div className="flex flex-col items-center gap-3">
        <p className="typography-label-1 pb-4 text-center text-lg">
          {usdcTransferMutation.error &&
            (usdcTransferMutation.error instanceof BaseError ? (
              <div>
                {t('collateral_failed')}
                <br />
                {usdcTransferMutation.error.shortMessage}
              </div>
            ) : (
              <div>
                <summary>{t('collateral_failed')}</summary>
                <details>{usdcTransferMutation.error.name}</details>
              </div>
            ))}
        </p>
        <Button
          className="w-full"
          onClick={() => {
            if (
              !usingL1ToDeposit &&
              latestDepositQuery.cancelMutation &&
              selectedNetwork?.chainId
            ) {
              latestDepositQuery.cancelMutation(
                {
                  chainId: selectedNetwork.chainId.toString(),
                  userAddr: userAddress,
                },
                {
                  onSuccess: () => {
                    setStep('choose')
                    useDepositStore.getState().setStatus('idle')
                  },
                },
              )
            } else {
              setStep('choose')
              useDepositStore.getState().setStatus('idle')
            }
          }}
          color="red"
        >
          {t('start_over')}
        </Button>
      </div>
    ),
    Tx_WaitSign: (
      <div className="flex flex-col items-center ">
        <p className="typography-label-1 pb-4 text-center text-lg">{t('waiting_tx_approval')}</p>
        <Icon icon="spinner" className="size-12" />
      </div>
    ),
  }

  return (
    <Modal
      title={accountIndex ? t('deposit') : t('deposit_modal_title_create')}
      open={depositStore.isModalOpen}
      preventCloseOutside
      onOpenChange={(newOpen) => {
        if (newOpen) return

        depositStore.closeModal()
        if ((step === 'fillExternal' || step === 'fill') && !isEmbeddedWallet) {
          setStep('choose')
          setDepositMethod(undefined)
        }
        if (
          latestDepositQuery.data?.status === 'completed' &&
          accountExistenceRef.current !== 'Creating'
        ) {
          localStorage.setItem('lastdepos', latestDepositQuery.data.fast_bridge_tx_hash)
          useDepositStore.getState().setStatus('idle')
          setAmount('')
          setStep(isEmbeddedWallet ? 'fillExternal' : 'choose')
          latestDepositQuery.resetData(userAddress)
        }
        if (
          latestDepositQuery.data?.status === 'completed' &&
          accountExistenceRef.current === 'Creating'
        ) {
          toast.success(t('creating_account'), { richColors: true })
        }
      }}
    >
      {stepView[step]}
    </Modal>
  )
}
const DepositInfo = ({ deposit }: { deposit: EnDeposit }) => {
  const { t } = useTranslation()
  return (
    <div className="grid w-full grid-cols-2 gap-5 rounded-lg border border-white/10 bg-white/5 p-4 text-sm [&>*:nth-child(odd)]:text-white/60">
      <div>{t('network')}:</div>
      <div>{deposit.source}</div>
      <div>{t('deposited_amount')}:</div>
      <div className="flex items-center gap-2">
        {Number(deposit.amount).toFixed(2)}
        <div className={tag}>USDC</div>
      </div>
      <div>{t('initiated_at')}:</div>
      <div>{dateFmt(deposit.created_at)}</div>
      <div>{t('last_update')}:</div>
      <div>{dateFmt(deposit.updated_at)}</div>
      <div>{t('status')}:</div>
      <div
        className={cn(
          'w-[12ch] rounded-md px-2 py-1 text-center',
          DepositStatusStyle(deposit.status),
        )}
      >
        {deposit.status === 'waitForTransfer'
          ? t('waiting_transfer')
          : deposit.status.toUpperCase()}
      </div>
    </div>
  )
}
const tag =
  'rounded-lg border border-white/10 bg-white/10 px-2 py-1 text-xs text-center text-white-opaque'
const dateFmt = (unixSec: number) => new Date(unixSec * 1000).toLocaleString('en-US')
const truncateAddress = (address: string) => `${address.slice(0, 4)}...${address.slice(-4)}`

const DotsLoader = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="text-white-opaque"
    >
      <circle cx="4" cy="12" r="2" fill="currentColor" className="animate-pulse">
        <animate
          id="spinner_qFRN"
          begin="0;spinner_OcgL.end+0.25s"
          attributeName="cy"
          calcMode="spline"
          dur="0.6s"
          values="12;6;12"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
        />
      </circle>
      <circle cx="12" cy="12" r="2" fill="currentColor" className="animate-pulse">
        <animate
          begin="spinner_qFRN.begin+0.1s"
          attributeName="cy"
          calcMode="spline"
          dur="0.6s"
          values="12;6;12"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
        />
      </circle>
      <circle cx="20" cy="12" r="2" fill="currentColor" className="animate-pulse">
        <animate
          id="spinner_OcgL"
          begin="spinner_qFRN.begin+0.2s"
          attributeName="cy"
          calcMode="spline"
          dur="0.6s"
          values="12;6;12"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
        />
      </circle>
    </svg>
  )
}
