import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { useMainAccount, useSubAccounts, useUserAccount } from 'js/providers/hooks/useAccountQuery'
import { SubAccountType } from 'js/providers/types'
import { Button } from 'js/shared-components'
import { Modal } from 'js/shared-components/Modal'
import Toast from 'js/shared-components/uikit/Toast'
import WarningContainer from 'js/shared-components/WarningContainer'
import { formatUSD } from 'js/util/formatting'
import { useUserAccountPortfolioStats } from 'js/util/positions'
import { transfer } from 'js/zklighter-js-sdk/sdk'

import { AccountSelector } from './AccountSelector'
import { AmountInput } from './AmountInput'

const AMOUNT_DECIMALS = 6

interface TransferModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export const TransferModal = ({ open, onOpenChange }: TransferModalProps) => {
  const [toAccountIndex, setToAccountIndex] = useState<number | null>(null)
  const [selectedAmount, setSelectedAmount] = useState('')
  const subAccounts = useSubAccounts().filter(
    ({ account_type }) => account_type === SubAccountType.sub,
  )
  const userAccount = useUserAccount()
  const mainAccount = useMainAccount()
  const portfolioStats = useUserAccountPortfolioStats()
  const { t } = useTranslation()

  const isButtonDisabled =
    !userAccount || !toAccountIndex || !selectedAmount || Number(selectedAmount) < 0.5

  const transferMutation = useMutation({
    mutationFn: () =>
      transfer({
        accountIndex: userAccount!.index,
        toAccountIndex: toAccountIndex!,
        collateralAmount: Number(selectedAmount) * 10 ** AMOUNT_DECIMALS,
      }),
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('transfer_successful')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('errors_generic_try_again')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => onOpenChange(false),
  })

  if (!userAccount || !mainAccount) {
    return null
  }

  return (
    <Modal open={open} title={t('transfer')} onOpenChange={onOpenChange}>
      <div className="flex w-full flex-col gap-3">
        <div className="flex w-full flex-col gap-1">
          <p className="text-sm text-white">
            {t('transfer_sub_accounts', {
              direction: 'from',
            })}
          </p>
          <div className="w-full rounded-lg border bg-white-transparent p-2">
            <p className="typography-label-1 text-white">
              {mainAccount.index === userAccount?.index
                ? t('main_account')
                : userAccount.name === ''
                  ? t('unnamed_account')
                  : userAccount.name}
            </p>
          </div>
        </div>
        <AccountSelector
          accountsList={[mainAccount, ...subAccounts].filter(
            (account) => account.index !== userAccount.index,
          )}
          onAccountChange={setToAccountIndex}
          selectedAccountIndex={toAccountIndex}
          direction="to"
        />
        <div className="flex flex-col gap-1">
          <AmountInput
            value={selectedAmount}
            onChange={(amount) => setSelectedAmount(amount)}
            decimal={2}
          />
          <p className="typography-body-1 text-white">
            <span className="text-white-opaque">{t('available')} </span>
            {portfolioStats === null ? '-' : formatUSD(portfolioStats.portfolioValue)}
          </p>
        </div>
        <Button
          disabled={isButtonDisabled}
          isLoading={transferMutation.isPending && !transferMutation.isError}
          className="w-full"
          onClick={() => transferMutation.mutate()}
        >
          {t('transfer')}
        </Button>
        {Number(selectedAmount) !== 0 && Number(selectedAmount) < 0.5 && (
          <WarningContainer>
            <p className="typography-body-2 text-white">
              {t('transfer_min_amount', {
                amount: '0.5',
              })}
            </p>
          </WarningContainer>
        )}
      </div>
    </Modal>
  )
}
