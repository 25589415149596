import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'

import { DepositButton, useDepositStore } from 'js/pages/deposit/form'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import { useIsWhitelistedQuery } from 'js/providers/hooks/useAccountQuery'
import { useLighterStore } from 'js/providers/lighterStore'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Clickable } from 'js/shared-components/Clickable'
import LanguagePicker from 'js/shared-components/LanguagePicker'
import { PointsModal } from 'js/shared-components/PointsModal'
import { SupportModal } from 'js/shared-components/SupportModal'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'
import { useHealthQuery } from 'js/util/queries'
import { getBackendEnv, getFrontendEnv, isMainnet } from 'js/util/util'

import { CurrentBlock } from './CurrentBlock'
import { LinksMenu } from './LinksMenu'
import { WalletDropdownButton } from './wallet/WalletDropdownButton'

export const Navbar = () => {
  const { t } = useTranslation()
  const healthQuery = useHealthQuery()
  const { isMobile } = useResponsiveness()
  const isWhitelistedQuery = useIsWhitelistedQuery()

  const accountExistence = useAccountExistence()
  const depositStore = useDepositStore()

  return (
    <>
      <SupportModal />
      <div className="z-10 flex h-[60px] w-full items-center justify-between bg-black p-3 max-mobile:py-6">
        <div className="flex gap-12">
          <Link
            to={`/trade/${localStorage.getItem('last_symbol')}`}
            className="flex items-center gap-2 text-white max-mobile:gap-1"
          >
            <Icon icon="logo" className="size-6" />
            <p className="typography-label-1 whitespace-nowrap">
              Lighter {isMainnet() ? '' : t(getBackendEnv())}
            </p>
          </Link>
          <Links />
        </div>
        {healthQuery.data?.serverOk && (
          <div className="flex items-center gap-3 max-mobile:gap-3">
            <CurrentBlock />
            <LanguagePicker />
            {isWhitelistedQuery.data === false && <PointsModal />}
            {!isMobile && <DepositButton />}
            {isMainnet() &&
              isMobile &&
              (accountExistence === 'ShouldDeposit' ||
                accountExistence === 'DepositInProgress' ||
                accountExistence === 'Creating') && (
                <Clickable onClick={() => depositStore.openModal()}>
                  <Icon
                    icon={depositStore.status === 'pending' ? 'spinner' : 'deposit'}
                    className="size-5 text-white"
                  />
                </Clickable>
              )}
            <WalletDropdownButton />
            {isMobile && <LinksMenu />}
          </div>
        )}
      </div>
    </>
  )
}

const Links = () => {
  const { isMobile } = useResponsiveness()
  const accIndex = useAccountIndex()
  const { t } = useTranslation()

  if (isMobile) return null

  return (
    <div className="flex items-center gap-8">
      <NavLink
        to={`/trade/${localStorage.getItem('last_symbol')}`}
        className={({ isActive }) =>
          cn('typography-label-1 text-white-opaque hover:text-white', {
            'text-white': isActive,
          })
        }
        data-testid="navbar-trade-link"
      >
        {t('trade')}
      </NavLink>
      {accIndex && (
        <NavLink
          to="/portfolio"
          className={({ isActive }) =>
            cn('typography-label-1 text-white-opaque hover:text-white', {
              'text-white': isActive,
            })
          }
          data-testid="navbar-portfolio-link"
        >
          {t('portfolio')}
        </NavLink>
      )}
      {accIndex && (
        <NavLink
          to="/public-pools"
          className={({ isActive }) =>
            cn('typography-label-1 text-white-opaque hover:text-white', {
              'text-white': isActive,
            })
          }
          data-testid="navbar-public-pools-link"
        >
          {t('public_pools')}
        </NavLink>
      )}
      {accIndex && (
        <NavLink
          to="/leaderboard"
          className={({ isActive }) =>
            cn('typography-label-1 text-white-opaque hover:text-white', {
              'text-white': isActive,
            })
          }
          data-testid="navbar-leaderboard-link"
        >
          {t('leaderboard')}
        </NavLink>
      )}

      <DropdownMenu>
        <DropdownMenuTrigger
          className={
            'typography-label-1 items-baseline border-0 !bg-transparent p-0 text-white-opaque hover:text-white data-[state=open]:text-white'
          }
          data-testid="navbar-more-link"
        >
          {t('more')}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-56" align="start">
          {getFrontendEnv() === 'Mainnet' ? (
            <a
              href="https://testnet.app.lighter.xyz"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="navbar-testnet-link"
            >
              <DropdownMenuItem>{t('testnet')}</DropdownMenuItem>
            </a>
          ) : (
            <a
              href="https://app.lighter.xyz"
              target="_blank"
              rel="noopener noreferrer"
              data-testid="navbar-testnet-link"
            >
              <DropdownMenuItem>{t('mainnet')}</DropdownMenuItem>
            </a>
          )}

          {accIndex && (
            <Link to="/sub-accounts">
              <DropdownMenuItem data-testid="navbar-sub-accounts-link">
                {t('sub_accounts')}
              </DropdownMenuItem>
            </Link>
          )}

          <a
            href="https://assets.lighter.xyz/whitepaper.pdf"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="navbar-whitepaper-link"
          >
            <DropdownMenuItem>{t('whitepaper')}</DropdownMenuItem>
          </a>
          <a
            href="https://docs.lighter.xyz"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="navbar-docs-link"
          >
            <DropdownMenuItem>{t('docs')}</DropdownMenuItem>
          </a>
          <DropdownMenuItem
            data-testid="navbar-support-link"
            onClick={() => useLighterStore.setState({ showFeedback: true })}
          >
            {t('support')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
