import { create } from 'zustand'

import { type AccountsSlice, createAccountsSlice } from './accountsSlice'
import { type OrderBookSlice, createOrderBookSlice } from './orderBookSlice'
import { type UserSlice, createUserSlice } from './userSlice'

export type LighterStore = UserSlice & OrderBookSlice & AccountsSlice

export const useLighterStore = create<LighterStore>((...args) => ({
  ...createAccountsSlice(...args),
  ...createOrderBookSlice(...args),
  ...createUserSlice(...args),
}))
