import { useTranslation } from 'react-i18next'

import { useUserAccountPositions } from 'js/providers/accountsSlice/selectors'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import cn from 'js/util/cn'
import { formatLeverage, formatUSD } from 'js/util/formatting'
import {
  useMaintenanceMarginReq,
  useAccountTotalUnrealizedPnL,
  useUserAccountPortfolioStats,
} from 'js/util/positions'

export const AccountOverview = () => {
  const { t } = useTranslation()
  const portfolioStats = useUserAccountPortfolioStats()
  const positions = useUserAccountPositions()
  const maintenanceMarginReq = useMaintenanceMarginReq(positions)
  const unrealizedPnl = useAccountTotalUnrealizedPnL(positions)

  const stats = [
    {
      title: t('portfolio_value_title'),
      value: portfolioStats === null ? '-' : formatUSD(portfolioStats.portfolioValue),
      explanation: t('portfolio_value_description'),
    },
    {
      title: t('cross_leverage_title'),
      value: portfolioStats === null ? '-' : formatLeverage(portfolioStats.leverage),
      explanation: t('cross_leverage_description'),
    },
    {
      title: t('cross_margin_usage_title'),
      value: portfolioStats === null ? '-' : `${portfolioStats.initialMarginUsage.toFixed(2)}%`,
      explanation: t('cross_margin_usage_description'),
    },
    {
      title: t('maintenance_margin_requirement_title'),
      value: formatUSD(maintenanceMarginReq),
      explanation: t('maintenance_margin_requirement_description'),
    },
    {
      title: t('cross_margin_ratio_title'),
      value: portfolioStats === null ? '-' : `${portfolioStats.maintenanceMarginUsage.toFixed(2)}%`,
      explanation: t('cross_margin_ratio_description'),
    },
    {
      title: t('unrealized_pnl_title'),
      value: formatUSD(unrealizedPnl),
      explanation: t('unrealized_pnl_description'),
      valueClassName: cn({
        'text-red-main': unrealizedPnl < 0,
        'text-green-main': unrealizedPnl > 0,
      }),
    },
  ]

  return (
    <>
      <div className="flex w-full flex-col gap-3 rounded-lg border bg-white-transparent p-3">
        <p className="typography-label-1">{t('account_overview')}</p>
        {stats.map(({ title, explanation, value, valueClassName }) => (
          <div key={title} className="flex w-full items-center justify-between">
            {explanation && (
              <>
                <Explanation
                  explanation={explanation}
                  trigger={
                    <p className="typography-body-2 text-white-opaque underline">{title}:</p>
                  }
                />
                <p className={cn('typography-body-2 text-white', valueClassName)}>{value}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
