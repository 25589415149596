import { useTranslation } from 'react-i18next'

import { Button } from 'js/shared-components'

const ErrorPage = () => {
  const { t } = useTranslation()

  return (
    <div className="flex size-full items-center justify-center">
      <div className="flex w-fit flex-col items-center justify-center gap-12 max-mobile:gap-4">
        <p className="text-9xl font-light text-white">Oops!</p>
        <p className="text-2xl font-light text-white">{t('error_page_header')}</p>
        <p className="typography-body-2 text-white max-mobile:mx-auto max-mobile:my-7">
          {t('error_page_body')}
        </p>
        <Button className="w-full" onClick={() => (window.location.href = '/')}>
          {t('return_to_homepage')}
        </Button>
      </div>
    </div>
  )
}

export default ErrorPage
