import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import Toast from 'js/shared-components/uikit/Toast'
import { copyStringToClipboard } from 'js/util/util'

import PublicPoolOperatorActions from './PublicPoolOperatorActions'
import { getPublicPoolName, usePublicPoolQuery } from './utils'

const PublicPoolHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const publicPoolQuery = usePublicPoolQuery()
  const l1Address = publicPoolQuery.data.l1_address

  return (
    <div className="flex items-center justify-between max-mobile:flex-col max-mobile:items-start max-mobile:gap-2">
      <div className="flex w-full flex-col gap-4">
        <div className="flex items-center gap-2">
          <Clickable onClick={() => navigate('/public-pools')}>
            <p className="typography-body-2 whitespace-nowrap text-white-opaque">
              {t('public_pools')}
            </p>
          </Clickable>
          <Icon icon="chevron" className="size-3 -rotate-90 text-white-opaque" />
          <p className="typography-body-2 line-clamp-1 text-white-opaque">
            {getPublicPoolName(publicPoolQuery.data, t)}
          </p>
          {!!l1Address.trim() && (
            <Clickable
              className="rounded-full border bg-white/5 px-2 py-1 max-mobile:ml-auto max-mobile:mr-0"
              onClick={() => {
                copyStringToClipboard(l1Address)
                toast.custom((toastId) => (
                  <Toast
                    level="success"
                    description={t('address_copied')}
                    onClose={() => toast.dismiss(toastId)}
                  />
                ))
              }}
            >
              <p className="typography-body-2 text-white">
                {l1Address.substring(0, 6).concat('...').concat(l1Address.slice(-4))}
              </p>
            </Clickable>
          )}
        </div>
        <div className="flex w-full items-center justify-between">
          <p className="typography-text-8 max-mobile:typography-text-7 line-clamp-1 text-white">
            {getPublicPoolName(publicPoolQuery.data, t)}
          </p>
          <p className="typography-body-2 text-white">{publicPoolQuery.data.description}</p>
          <PublicPoolOperatorActions />
        </div>
      </div>
    </div>
  )
}

export default PublicPoolHeader
