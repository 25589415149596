import { useEffect } from 'react'

import { useAccountIndex } from '../userSlice/selectors'
import { useWsSubStore } from '../wsStore'

export const useInitWs = () => {
  const ws = useWsSubStore((state) => state.ws)

  const accountIndex = useAccountIndex()

  useEffect(() => {
    const interval = setInterval(useWsSubStore.getState().actions.init, 2000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!ws) {
      return
    }

    useWsSubStore.getState().actions.switchAccount(accountIndex)
  }, [ws, accountIndex])
}
