import { useQuery } from '@tanstack/react-query'

interface HealthStatus {
  online: boolean
  serverOk: boolean
}

export const useHealthQuery = () => {
  const isOnline = async () => {
    const reliableEndpoints = ['https://1.1.1.1', 'https://8.8.8.8']

    const results = await Promise.all(
      reliableEndpoints.map((url) =>
        fetch(url, {
          method: 'HEAD',
          mode: 'no-cors',
          cache: 'no-store',
        }).catch(() => null),
      ),
    )

    return results.every((result) => result !== null)
  }

  return useQuery<HealthStatus>({
    queryKey: ['health'],
    queryFn: async () => {
      const status: HealthStatus = {
        online: true,
        serverOk: true,
      }

      try {
        const res = await fetch(import.meta.env.VITE_REST_API_BASE)
        if (!res.ok) {
          status.online = await isOnline()
          status.serverOk = !status.online
          return status
        }

        return status
      } catch (error) {
        console.error('Health check error:', error)
        status.online = await isOnline()

        if (status.online) {
          try {
            const res = await fetch(import.meta.env.VITE_REST_API_BASE)
            status.serverOk = res.ok
          } catch (error) {
            console.error('Health check error:', error)
            status.serverOk = false
          }
        }

        return status
      }
    },
    refetchInterval: 10000,
    networkMode: 'always',
  })
}
