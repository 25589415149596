import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderStatusEnum, OrderTimeInForceEnum } from 'zklighter-perps'

import type { Order } from 'js/providers/types'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import TableCell from 'js/shared-components/uikit/table/TableCell'

interface OrderStatusCellProps {
  order: Order
}

const OrderStatusCell = ({ order }: OrderStatusCellProps) => {
  const { t } = useTranslation()

  const { title, explanation } = useMemo(() => {
    switch (order.status) {
      case OrderStatusEnum.Open: {
        return { title: t('open') }
      }
      case OrderStatusEnum.Pending: {
        return { title: t('pending') }
      }
      case OrderStatusEnum.Filled: {
        return { title: t('filled') }
      }
      case OrderStatusEnum.Canceled: {
        return { title: t('canceled'), explanation: t('order_status_canceled_description') }
      }
      case OrderStatusEnum.InProgress: {
        return { title: t('in_progress') }
      }
      case OrderStatusEnum.CanceledPostOnly: {
        return {
          title: t('canceled'),
          explanation: t('order_status_canceled_post_only_description'),
        }
      }
      case OrderStatusEnum.CanceledReduceOnly: {
        return {
          title: t('canceled'),
          explanation: t('order_status_canceled_reduce_only_description'),
        }
      }
      case OrderStatusEnum.CanceledPositionNotAllowed: {
        return {
          title: t('canceled'),
          explanation: t('order_status_canceled_position_not_allowed_description'),
        }
      }
      case OrderStatusEnum.CanceledMarginNotAllowed: {
        return {
          title: t('canceled'),
          explanation: t('order_status_canceled_margin_not_allowed_description'),
        }
      }
      case OrderStatusEnum.CanceledTooMuchSlippage: {
        return {
          title: t('canceled'),
          explanation: t('order_status_canceled_too_much_slippage_description'),
        }
      }
      case OrderStatusEnum.CanceledNotEnoughLiquidity: {
        return {
          title: t('canceled'),
          explanation:
            order.time_in_force !== OrderTimeInForceEnum.ImmediateOrCancel
              ? t('order_status_canceled_not_enough_liquidity_description')
              : order.is_ask
                ? t('order_status_canceled_not_enough_liquidity_ioc_ask_description')
                : t('order_status_canceled_not_enough_liquidity_ioc_bid_description'),
        }
      }
      case OrderStatusEnum.CanceledSelfTrade: {
        return {
          title: t('canceled'),
          explanation: t('order_status_canceled_self_trade_description'),
        }
      }
      case OrderStatusEnum.CanceledExpired: {
        return { title: t('canceled'), explanation: t('order_status_canceled_expired_description') }
      }
    }
  }, [t, order])

  return (
    <TableCell>
      <div className="flex justify-end gap-1">
        <p className="typography-body-1 text-white">{title}</p>
        {!!explanation && <Explanation explanation={explanation} side="left" />}
      </div>
    </TableCell>
  )
}
export default OrderStatusCell
