import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePublicPoolInfo } from 'js/providers/accountsSlice/selectors'
import {
  useAccountsQuery,
  useIsRegisteredQuery,
  useMainAccount,
  useUserAccount,
  useVerifyAndSwitchAccountMutation,
} from 'js/providers/hooks/useAccountQuery'
import { useLighterStore } from 'js/providers/lighterStore'
import { Button } from 'js/shared-components'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'

import { usePublicPoolQuery } from '../utils'

import EditPublicPoolModal from './EditPublicPoolModal'
import FreezePublicPoolModal from './FreezePublicPoolModal'

const PublicPoolOperatorActions = () => {
  const { t } = useTranslation()
  const [editPublicPoolModalOpen, setEditPublicPoolModalOpen] = useState(false)
  const [freezePublicPoolModalOpen, setFreezePublicPoolModalOpen] = useState(false)
  const publicPoolQuery = usePublicPoolQuery()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)
  const verifyAndSwitchAccountMutation = useVerifyAndSwitchAccountMutation()
  const accountsQuery = useAccountsQuery()
  const userAccount = useUserAccount()
  const isRegisteredQuery = useIsRegisteredQuery()
  const mainAccount = useMainAccount()

  // don't geoCheck here cause a user might have to freeze a pool to withdraw their money
  if (
    !accountsQuery.data?.accounts.some((account) => account.index === publicPoolQuery.data.index) ||
    !userAccount ||
    !mainAccount ||
    isRegisteredQuery.data === null
  ) {
    return null
  }

  if (userAccount.index !== mainAccount.index) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => verifyAndSwitchAccountMutation.mutate(mainAccount)}
            isLoading={verifyAndSwitchAccountMutation.isPending}
          >
            {t('switch')}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">{t('switch_edit_pp')}</p>
        </TooltipContent>
      </Tooltip>
    )
  }

  if (!isRegisteredQuery.data) {
    return (
      <Button onClick={() => useLighterStore.setState({ showOnboarding: true })}>
        {t('authenticate')}
      </Button>
    )
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="whitespace-nowrap">
          {t('operator_actions')}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setEditPublicPoolModalOpen(true)}>
            {t('edit_public_pool')}
          </DropdownMenuItem>
          {!!poolInfo && poolInfo.status !== 1 && (
            <DropdownMenuItem
              onClick={() => setFreezePublicPoolModalOpen(true)}
              disabled={poolInfo.status === 1}
            >
              {t('freeze_pool')}
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <EditPublicPoolModal
        open={editPublicPoolModalOpen}
        onOpenChange={setEditPublicPoolModalOpen}
      />
      <FreezePublicPoolModal
        open={freezePublicPoolModalOpen}
        onOpenChange={setFreezePublicPoolModalOpen}
      />
    </>
  )
}

export default PublicPoolOperatorActions
