import type { ChangeEvent } from 'react'

export const wait = (duration = 1000) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), duration))

export const copyStringToClipboard = async (str: string) => {
  if (navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(str)
      console.log('Copied to clipboard:', str)
    } catch (err) {
      console.error('Failed to copy using Clipboard API:', err)
    }
  } else {
    // Fallback for older browsers and environments
    const el = document.createElement('textarea')
    el.value = str
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    try {
      const success = document.execCommand('copy')
      console.log('Copied using fallback:', success)
    } catch (err) {
      console.error('Failed to copy using fallback:', err)
    }
    document.body.removeChild(el)
  }
}

export const isZero = (value: string | undefined) => {
  if (value === undefined) return true
  if (Number(value) === 0) return true
  return false
}

export const enforceNumber = (e: ChangeEvent<HTMLInputElement>, decimal: number) => {
  let inputValue = e.target.value.replace(/,/g, '.').replace(/^0+(?=[0-9])/, '')

  if (decimal === 0) {
    return /^[0-9]{0,12}$/.test(inputValue)
  }

  let split = inputValue.split('.')

  // 3,301.12 from chart -> 3301.12
  if (split.length > 2) {
    split = [split.slice(0, split.length - 1).join(''), split[split.length - 1]!]
    inputValue = split.join('.')
  }

  if (inputValue.length > 12 || !/^[0-9]*\.?[0-9]*$/.test(inputValue) || split.length > 2) {
    return false
  }

  if (split.length === 2 && split[1]!.length > decimal) {
    return false
  }

  e.target.value = inputValue
  return true
}

export const getBackendEnv = () => import.meta.env.VITE_ENV

type FE_ENV = 'Staging' | 'Mainnet' | 'Testnet'
export const getFrontendEnv = (): FE_ENV => {
  const host = window.location.hostname
  if (host.includes('mainnet')) return 'Mainnet'
  if (host.includes('beta')) return 'Mainnet'
  if (host.includes('testnet')) return 'Testnet'
  if (host.includes('staging')) return 'Staging'
  if (host.includes('localhost')) return 'Staging'
  return 'Mainnet' as FE_ENV
}

export const isMainnet = () => getBackendEnv() == 'mainnet'

const STAGING_ALLOWED_MARKET_IDS = new Set([
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
])
const TESTNET_ALLOWED_MARKET_IDS = new Set([
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
])
const MAINNET_ALLOWED_MARKET_IDS = new Set([
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
])

const getAllowedMarketIds = () => {
  switch (getBackendEnv()) {
    case 'mainnet':
      return MAINNET_ALLOWED_MARKET_IDS
    case 'testnet':
      return TESTNET_ALLOWED_MARKET_IDS
    case 'staging':
      return STAGING_ALLOWED_MARKET_IDS
  }
}

export const isMarketIdAllowed = (marketId: number) => getAllowedMarketIds().has(marketId)

type MultiCursor = {
  index: number
  index2: number
}
export const deserializeCursor = (cursor: string): MultiCursor => {
  return JSON.parse(atob(cursor)) as MultiCursor
}
