import { isBefore, differenceInMilliseconds, add as addDuration, formatDate } from 'date-fns'
import { useRef, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { USDC_SYMBOL } from 'js/constants/shared'
import { MarketSelector } from 'js/pages/trade/components/info-box/market-selector/MarketSelector'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useCurrentMarketStats } from 'js/providers/orderBookSlice/selectors'
import SkeletonRectangle from 'js/shared-components/SkeletonRectangle'
import Icon from 'js/shared-components/uikit/Icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import cn from 'js/util/cn'
import {
  formatMarketPrice,
  formatMarketSize,
  formatOpenInterest,
  formatUSD,
} from 'js/util/formatting'

import { MaxLeverage } from './MaxLeverage'

type InfoItem = {
  key: string
  title: string
  value: string
  valueExplanation?: string
  explanation?: string
}

export const InfoBox = () => {
  const currentMarket = useCurrentMarket()
  const infoBoxRef = useRef<HTMLDivElement>(null)
  const currentMarketStats = useCurrentMarketStats()

  const indexPrice = currentMarketStats
    ? formatMarketPrice(currentMarketStats.index_price, currentMarket)
    : '-'
  const markPrice = currentMarketStats
    ? formatMarketPrice(currentMarketStats.mark_price, currentMarket)
    : '-'
  const fundingRate = currentMarketStats
    ? `${(Number(currentMarketStats.current_funding_rate) / 8).toFixed(4)}%`
    : '-'
  const anualizedFundingRate = currentMarketStats
    ? `${((24 * 365 * Number(currentMarketStats.current_funding_rate)) / 8).toFixed(4)}%`
    : '-'
  const dayLow = currentMarketStats
    ? formatMarketPrice(currentMarketStats.daily_price_low, currentMarket)
    : '-'
  const dayHigh = currentMarketStats
    ? formatMarketPrice(currentMarketStats.daily_price_high, currentMarket)
    : '-'
  const dayVolume0 = currentMarketStats
    ? formatMarketSize(currentMarketStats.daily_base_token_volume, currentMarket)
    : '-'
  const dayVolume1 = currentMarketStats
    ? formatUSD(currentMarketStats.daily_quote_token_volume)
    : '-'

  const openInterest = currentMarketStats
    ? formatOpenInterest(currentMarketStats.open_interest)
    : '-'

  const openInterestBase = currentMarketStats
    ? `${formatOpenInterest(Number(currentMarketStats.open_interest) / Number(currentMarketStats.mark_price), false)} ${currentMarket.symbol}`
    : '-'

  const roundedDiffPct = parseFloat((currentMarketStats?.daily_price_change ?? 0).toFixed(2))
  const [currentTimestamp, setCurrentTimestamp] = useState(new Date())
  const { t } = useTranslation()
  const nextFunding = useMemo(() => {
    const fundingTime = addDuration(
      currentMarketStats ? new Date(currentMarketStats.funding_timestamp) : new Date(),
      { hours: 1 },
    )

    if (isBefore(currentTimestamp, fundingTime)) {
      return formatDate(differenceInMilliseconds(fundingTime, currentTimestamp), 'mm:ss')
    }

    return '00:00'
  }, [currentMarketStats, currentTimestamp])

  useEffect(() => {
    const t = setInterval(() => setCurrentTimestamp(new Date()), 1000)

    return () => clearInterval(t)
  }, [])

  const infoItems: InfoItem[] = [
    {
      key: 'markPrice',
      title: t('mark_price'),
      value: markPrice,
      explanation: t('mark_price_explanation'),
    },
    {
      key: 'indexPrice',
      title: t('index_price'),
      value: indexPrice,
      explanation: t('index_price_explanation'),
    },
    {
      key: 'openInterest',
      title: t('open_interest'),
      value: openInterest,
      valueExplanation: openInterestBase,
      explanation: t('open_interest_explanation'),
    },
    { key: 'high', title: t('24h_high'), value: dayHigh },
    { key: 'low', title: t('24h_low'), value: dayLow },
    { key: 'change', title: t('24h_change'), value: `${roundedDiffPct}%` },
    {
      key: 'volumeToken0',
      title: t('24h_volume_token', { symbol: currentMarket.symbol }),
      value: dayVolume0,
    },
    {
      key: 'volumeToken1',
      title: t('24h_volume_token', { symbol: USDC_SYMBOL }),
      value: dayVolume1,
    },
    {
      key: 'fundingRate',
      title: t('1hr_funding'),
      value: fundingRate,
      valueExplanation: t('annualized', { value: anualizedFundingRate }),
      explanation: t('funding_rate_explanation'),
    },
    {
      key: 'nextFunding',
      title: t('next_funding'),
      value: nextFunding,
      explanation: t('next_funding_explanation'),
    },
  ]

  return (
    <div className="flex w-full items-center gap-6 py-2" ref={infoBoxRef}>
      <MarketSelector />
      <div className="w-full max-w-full overflow-x-hidden">
        <div
          className="flex h-10 w-full flex-[3] justify-start gap-6 overflow-x-scroll"
          data-testid="stats"
        >
          {infoItems.map((item) => (
            <InfoItem
              item={item}
              key={item.key}
              isLoading={!currentMarketStats}
              roundedDiffPct={roundedDiffPct}
            />
          ))}
        </div>
      </div>
      <MaxLeverage />
    </div>
  )
}

interface InfoItemProps {
  item: InfoItem
  isLoading: boolean
  roundedDiffPct: number
}

const InfoItem = ({ item, isLoading, roundedDiffPct }: InfoItemProps) => (
  <div className="flex max-h-full items-center gap-6 py-1 ">
    <div className="flex max-h-full min-w-14 flex-col whitespace-nowrap">
      <Tooltip open={!item.explanation ? false : undefined}>
        <TooltipTrigger asChild>
          <p
            className={cn('typography-body-1 text-white-opaque', {
              'underline decoration-dotted': !!item.explanation,
            })}
          >
            {item.title}
          </p>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">{item.explanation}</p>
        </TooltipContent>
      </Tooltip>
      {isLoading && <SkeletonRectangle className="h-3.5 w-16 min-w-16 rounded-sm" />}
      {!isLoading && (
        <Tooltip open={!item.valueExplanation ? false : undefined}>
          <TooltipTrigger asChild>
            <div className="flex items-center gap-1">
              <p className="typography-body-1 text-white">{item.value}</p>
              {item.key === 'change' && roundedDiffPct !== 0 && (
                <Icon
                  icon="triangle"
                  className={cn('size-2', {
                    'text-green-main rotate-180': roundedDiffPct > 0,
                    'text-red-main rotate-0': roundedDiffPct < 0,
                  })}
                />
              )}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p className="typography-body-2 text-white">{item.valueExplanation}</p>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  </div>
)
