import { useQuery } from '@tanstack/react-query'
import * as i18next from 'i18next'
import LocizeBackend from 'i18next-locize-backend'
import { initReactI18next } from 'react-i18next'

export const LOCALES = ['en', 'zh'] as const
type Locale = (typeof LOCALES)[number]

const getInitialLocale = (): Locale => {
  const urlLocale = new URLSearchParams(window.location.search).get('locale')

  if (urlLocale !== null && LOCALES.includes(urlLocale)) {
    return urlLocale as Locale
  }

  const localStorageLocale = localStorage.getItem('selected_locale')

  if (localStorageLocale && LOCALES.includes(localStorageLocale)) {
    return localStorageLocale as Locale
  }

  return 'en'
}

export const useI18NextQuery = () =>
  useQuery({
    queryKey: ['i18n'],
    queryFn: () =>
      i18next
        .use(LocizeBackend)
        .use(initReactI18next)
        .init({
          defaultNS: 'translations',
          backend: {
            projectId: '3bad5e94-b0a9-40b6-8ffd-8de43284a052',
            referenceLng: 'en',
            translatedPercentageThreshold: 0,
          },
          lng: getInitialLocale(),
          fallbackLng: 'en',
          interpolation: {
            escapeValue: false,
          },
        }),
  })
