import { useQuery } from '@tanstack/react-query'
import { formatDate } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { NotificationPopup } from 'js/shared-components/NotificationPopup'
import { useMemoryUsage } from 'js/util/useMemUsage'

import { ServerStatus } from './ServerStatus'

type Version = {
  commitHash: string
  buildDate: string
}

export const Footer = () => {
  const { t } = useTranslation()
  const [currentVersion, setCurrentVersion] = useState<Version | null>(null)
  const mem = useMemoryUsage()

  const versionQuery = useQuery({
    queryKey: ['version'],
    queryFn: () =>
      fetch('/version.json', { cache: 'no-store' })
        .then((res) => res.json())
        .then((data) => data as Version),
    refetchInterval: 5000,
  })

  useEffect(() => {
    if (!versionQuery.data) {
      return
    }

    if (!currentVersion) {
      return setCurrentVersion(versionQuery.data)
    }

    if (currentVersion !== versionQuery.data) {
      toast.custom(
        () => (
          <NotificationPopup>
            <div className="flex flex-col gap-2">
              <p className="typography-label-1 text-white">{t('version_available')}</p>
              <button onClick={() => window.location.reload()}>
                {t('version_available_description')}
              </button>
            </div>
          </NotificationPopup>
        ),
        { duration: Infinity, id: 'version' },
      )
    }
  }, [versionQuery.data, currentVersion, t])

  return (
    <div className="fixed inset-x-0 bottom-0 z-20 flex items-center justify-between border-t bg-black px-3 py-1">
      <ServerStatus />
      {mem.showMem && <div className="font-mono">memory: {JSON.stringify(mem)}</div>}
      <div className="text-white">
        rev {formatDate(new Date(versionQuery.data?.buildDate || Date.now()), 'yyyy-MM-dd')}-
        {versionQuery.data?.commitHash?.slice(0, 7) || ''}
      </div>
    </div>
  )
}
