import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useResponsiveness } from 'js/ResponsivenessProvider'
import { Button } from 'js/shared-components'

import { Clickable } from './Clickable'
import Icon from './uikit/Icon'

export const MetamaskLogin = forwardRef<HTMLButtonElement>((_, forwardedRef) => {
  const { t } = useTranslation()
  const { isMobile } = useResponsiveness()
  const { setShowAuthFlow } = useDynamicContext()

  const login = () => setShowAuthFlow(true)

  if (isMobile) {
    return (
      <Clickable ref={forwardedRef} onClick={login}>
        <Icon icon="wallet" className="size-5" />
      </Clickable>
    )
  }

  return (
    <Button className="gap-2" ref={forwardedRef} onClick={login}>
      <Icon icon="wallet" className="size-5" />
      {t('connect_wallet')}
    </Button>
  )
})

MetamaskLogin.displayName = 'MetamaskLogin'
