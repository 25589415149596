import { useLighterStore, type LighterStore } from '../lighterStore'

const selectAccountIndex = (state: LighterStore) => state.accountIndex
export const useAccountIndex = () => useLighterStore(selectAccountIndex)

const selectShowOnboarding = (state: LighterStore) => state.showOnboarding
export const useShowOnboarding = () => useLighterStore(selectShowOnboarding)

const selectShowWhitelist = (state: LighterStore) => state.showWhitelist
export const useShowWhitelist = () => useLighterStore(selectShowWhitelist)

const selectShowFeedback = (state: LighterStore) => state.showFeedback
export const useShowFeedback = () => useLighterStore(selectShowFeedback)
