import { useTranslation } from 'react-i18next'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import UIKitCurrencyInput from 'js/shared-components/uikit/CurrencyInput'
import { enforceNumber } from 'js/util/util'

import { useOrderInputStore } from '../PlaceOrder'

export const TriggerPriceInput = () => {
  const { t } = useTranslation()
  const orderInputs = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const value = orderInputs.triggerPrice
  const onChange = (e: string) => orderInputs.setTriggerPrice(e)
  const decimal = currentMarket.price_decimals + currentMarket.size_decimals

  return (
    <UIKitCurrencyInput
      label={t('trigger_price_input_label')}
      helperText={t('trigger_price_input_helper_text')}
      value={value}
      symbol="USD"
      onChange={(e) => {
        if (enforceNumber(e, decimal)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
    />
  )
}
