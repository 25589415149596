import { Navigate, useParams } from 'react-router-dom'

import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useResponsiveness } from 'js/ResponsivenessProvider'

import DesktopViewTrade from './DesktopViewTrade'
import MobileViewTrade from './MobileViewTrade'

const useValidateSymbolParam = () => {
  const orderBookMetas = useOrderBookMetas()

  return (symbol: string | null | undefined) =>
    Object.values(orderBookMetas).some((metas) => metas.symbol === symbol)
}

const Trade = () => {
  const validateSymbolParam = useValidateSymbolParam()
  const params = useParams()
  const { isMobile } = useResponsiveness()

  if (!validateSymbolParam(params.symbol)) {
    const lastSymbol = localStorage.getItem('last_symbol')

    if (!validateSymbolParam(lastSymbol)) {
      localStorage.setItem('last_symbol', 'ETH')
      return <Navigate replace to={`/trade/ETH`} />
    }

    return <Navigate replace to={`/trade/${lastSymbol}`} />
  }

  if (isMobile) {
    return <MobileViewTrade />
  }

  return <DesktopViewTrade />
}

export default Trade
