import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderStatusEnum, OrderTimeInForceEnum, OrderTypeEnum } from 'zklighter-perps'

import { TOKEN_LOGOS } from 'js/constants/shared'
import {
  useUserAccountActiveOrders,
  useUserAccountInactiveOrders,
} from 'js/providers/accountsSlice/selectors'
import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { NotificationPopup } from 'js/shared-components/NotificationPopup'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'
import { formatMarketPrice, formatMarketSize } from 'js/util/formatting'
import { isZero } from 'js/util/util'

interface OrderNotificationProps {
  orderType: OrderTypeEnum
  size: number
  price: number
  isAsk?: boolean

  onClose: () => void
  marketId: number
  orderIndex?: number
}

export const OrderNotification = ({
  orderType,
  size,
  price,
  isAsk,
  orderIndex,
  marketId,
  onClose,
}: OrderNotificationProps) => {
  const market = useOrderBookMetas()[marketId]!
  const activeOrders = useUserAccountActiveOrders()
  const inactiveOrders = useUserAccountInactiveOrders()
  const { t } = useTranslation()

  const order = useMemo(
    () =>
      [...(activeOrders[marketId] ?? []), ...(inactiveOrders[marketId] ?? [])].find(
        (order) => order.order_index === orderIndex,
      ),
    [activeOrders, inactiveOrders, marketId, orderIndex],
  )

  const status = order?.status ?? OrderStatusEnum.Pending
  const isIoCOrder = order?.time_in_force === OrderTimeInForceEnum.ImmediateOrCancel
  const filledBaseAmount = Number(order?.filled_base_amount ?? 0)

  const displayedPrice = useMemo(() => {
    switch (orderType) {
      case OrderTypeEnum.Market: {
        return order && !isZero(order.filled_base_amount)
          ? Number(order.filled_quote_amount) / Number(order.filled_base_amount)
          : null
      }
      case OrderTypeEnum.Limit:
      case OrderTypeEnum.StopLossLimit:
      case OrderTypeEnum.TakeProfitLimit: {
        return price
      }
      default: {
        return null
      }
    }
  }, [order, orderType, price])

  const orderTypeLabel = useMemo(() => {
    switch (orderType) {
      case OrderTypeEnum.Market: {
        return t('market')
      }
      case OrderTypeEnum.Limit: {
        return t('limit')
      }
      case OrderTypeEnum.StopLoss: {
        return t('sl_market')
      }
      case OrderTypeEnum.StopLossLimit: {
        return t('sl_limit')
      }
      case OrderTypeEnum.TakeProfit: {
        return t('tp_market')
      }
      case OrderTypeEnum.TakeProfitLimit: {
        return t('tp_limit')
      }
      case OrderTypeEnum.Twap: {
        return t('twap')
      }
      default: {
        return '' as never
      }
    }
  }, [t, orderType])

  const statusCopy = useMemo(() => {
    switch (status) {
      case OrderStatusEnum.Canceled:
      case OrderStatusEnum.CanceledPostOnly:
      case OrderStatusEnum.CanceledReduceOnly:
      case OrderStatusEnum.CanceledPositionNotAllowed:
      case OrderStatusEnum.CanceledMarginNotAllowed:
      case OrderStatusEnum.CanceledTooMuchSlippage:
      case OrderStatusEnum.CanceledSelfTrade:
      case OrderStatusEnum.CanceledNotEnoughLiquidity: {
        if (filledBaseAmount > 0) {
          return t('partially_filled')
        }
        return t('failed')
      }

      case OrderStatusEnum.CanceledExpired: {
        return t('expired')
      }
      case OrderStatusEnum.Filled: {
        return t('filled')
      }
      case OrderStatusEnum.Open: {
        return t('open')
      }
      case OrderStatusEnum.Pending: {
        switch (order?.type) {
          case OrderTypeEnum.Twap:
          case OrderTypeEnum.StopLoss:
          case OrderTypeEnum.StopLossLimit:
          case OrderTypeEnum.TakeProfit:
          case OrderTypeEnum.TakeProfitLimit: {
            return t('open')
          }
          default: {
            return t('pending')
          }
        }
      }
      default: {
        return ''
      }
    }
  }, [status, filledBaseAmount, order?.type, t])

  const errorCopy = useMemo(() => {
    switch (status) {
      case OrderStatusEnum.CanceledPostOnly: {
        return t('post_only')
      }
      case OrderStatusEnum.CanceledReduceOnly: {
        return t('reduce_only')
      }
      case OrderStatusEnum.CanceledSelfTrade: {
        return t('order_status_canceled_self_trade_description')
      }
      case OrderStatusEnum.CanceledPositionNotAllowed: {
        return t('order_status_canceled_position_not_allowed_description')
      }
      case OrderStatusEnum.CanceledMarginNotAllowed: {
        return t('order_status_canceled_margin_not_allowed_description')
      }
      case OrderStatusEnum.CanceledTooMuchSlippage: {
        return t('order_status_canceled_too_much_slippage_description')
      }
      case OrderStatusEnum.CanceledNotEnoughLiquidity: {
        if (isIoCOrder && orderType === OrderTypeEnum.Limit) {
          return isAsk
            ? t('order_status_canceled_not_enough_liquidity_ioc_ask_description')
            : t('order_status_canceled_not_enough_liquidity_ioc_bid_description')
        }

        return t('order_status_canceled_not_enough_liquidity_description')
      }
      default: {
        return null
      }
    }
  }, [status, isAsk, isIoCOrder, orderType, t])

  const getOrderSuccess = () => {
    switch (status) {
      case OrderStatusEnum.Canceled:
      case OrderStatusEnum.CanceledPostOnly:
      case OrderStatusEnum.CanceledReduceOnly:
      case OrderStatusEnum.CanceledPositionNotAllowed:
      case OrderStatusEnum.CanceledMarginNotAllowed:
      case OrderStatusEnum.CanceledTooMuchSlippage:
      case OrderStatusEnum.CanceledSelfTrade:
      case OrderStatusEnum.CanceledNotEnoughLiquidity: {
        if (filledBaseAmount > 0) {
          return 'success'
        }
        return 'fail'
      }

      case OrderStatusEnum.CanceledExpired: {
        return 'fail'
      }
      case OrderStatusEnum.Filled:
      case OrderStatusEnum.Open: {
        return 'success'
      }
      case OrderStatusEnum.Pending: {
        switch (order?.type) {
          case OrderTypeEnum.Twap:
          case OrderTypeEnum.StopLoss:
          case OrderTypeEnum.StopLossLimit:
          case OrderTypeEnum.TakeProfit:
          case OrderTypeEnum.TakeProfitLimit: {
            return 'success'
          }
          default: {
            return 'pending'
          }
        }
      }
      default: {
        return 'pending'
      }
    }
  }

  return (
    <NotificationPopup onClose={onClose}>
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <Icon
            icon={TOKEN_LOGOS[market.symbol as keyof typeof TOKEN_LOGOS]!}
            className="z-[1] size-5"
          />
          <p className="typography-body-2 text-white">
            {orderTypeLabel} {t('order')}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="typography-body-2 text-white-opaque">{statusCopy}</p>
          {getOrderSuccess() === 'pending' && <Icon icon="spinner" className="size-4" />}
          {getOrderSuccess() === 'success' && (
            <Icon
              icon="check"
              className="size-4 rounded-full border border-green-main text-green-main"
            />
          )}
          {getOrderSuccess() === 'fail' && <Icon icon="x" className="size-5 text-red-main" />}
        </div>
      </div>
      <div className="flex w-full items-end justify-between">
        <div className="flex flex-col">
          <div className="flex gap-2">
            <p className="typography-body-2 text-white-opaque">{t('size')}</p>
            <div className="flex items-center gap-1">
              <p className="typography-body-1 text-white">{formatMarketSize(size, market)}</p>
              <div className="rounded-lg bg-grey-light px-2 py-px text-xs text-white">
                {market.symbol}
              </div>
            </div>
          </div>
          {displayedPrice !== null && (
            <div className="flex items-baseline gap-2">
              <p className="typography-body-2 text-white-opaque">{t('price')}</p>
              <p className="typography-body-1 text-white">
                {formatMarketPrice(displayedPrice, market)}
              </p>
            </div>
          )}
        </div>
        <div
          className={cn('typography-body-1 h-fit rounded-lg border-2 px-1 py-0.5', {
            'bg-red-pale border-red-main text-red-main': isAsk,
            'bg-green-pale border-green-main text-green-main': !isAsk,
          })}
        >
          {isAsk ? t('short') : t('long')}
        </div>
      </div>
      {!!errorCopy && <p className="typography-body-2 self-start text-red-main">{errorCopy}</p>}
    </NotificationPopup>
  )
}
