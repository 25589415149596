import { useTranslation } from 'react-i18next'

export const NoOrdersText = ({
  type,
}: {
  type:
    | 'activeOrders'
    | 'positions'
    | 'orderHistory'
    | 'tradeHistory'
    | 'fundingHistory'
    | 'publicPools'
    | 'deposits'
    | 'withdraws'
}) => {
  const { t } = useTranslation()

  const getText = () => {
    switch (type) {
      case 'activeOrders':
        return t('table_no_items_description_active_orders')
      case 'positions':
        return t('table_no_items_description_positions')
      case 'orderHistory':
        return t('table_no_items_description_order_history')
      case 'tradeHistory':
        return t('table_no_items_description_trades')
      case 'fundingHistory':
        return t('table_no_items_description_funding_history')
      case 'publicPools':
        return t('table_no_items_description_user_public_pools')
      case 'deposits':
        return t('table_no_items_description_deposits')
      case 'withdraws':
        return t('table_no_items_description_withdrawals')
      default:
        return ''
    }
  }
  return (
    <div className="flex h-full max-h-full min-h-64 items-center justify-center text-center">
      <p className="typography-label-1 m-auto text-white">{getText()}</p>
    </div>
  )
}
