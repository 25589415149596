import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { OrderBookDetail } from 'zklighter-perps'

import { type OrderSide } from 'js/constants/trades'
import { useAccountActiveOrders, useAccountLoading } from 'js/providers/accountsSlice/selectors'
import { useCurrentMarketId, useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useIsRegisteredQuery } from 'js/providers/hooks/useAccountQuery'
import DateCell from 'js/shared-components/cells/DateCell'
import MarketCell from 'js/shared-components/cells/MarketCell'
import OrderAmountFilledCell from 'js/shared-components/cells/orders/OrderAmountFilledCell'
import OrderExpiryCell from 'js/shared-components/cells/orders/OrderExpiryCell'
import OrderPriceCell from 'js/shared-components/cells/orders/OrderPriceCell'
import OrderTriggerCell from 'js/shared-components/cells/orders/OrderTriggerCell'
import OrderTypeCell from 'js/shared-components/cells/orders/OrderTypeCell'
import SideCell from 'js/shared-components/cells/SideCell'
import { Clickable } from 'js/shared-components/Clickable'
import HeaderCell from 'js/shared-components/HeaderCell'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableLoader from 'js/shared-components/uikit/table/TableLoader'
import TableRow from 'js/shared-components/uikit/table/TableRow'

import { NoItemsInMarket } from '../NoItemsInMarket'
import { NoOrdersText } from '../NoOrdersText'

import { CancelAllModal } from './CancelAllModal'
import CancelOrderButton from './CancelOrderButton'

interface ActiveOrderTableProps {
  accountIndex: number
  showCancelColumn?: boolean
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (market: OrderBookDetail | null) => void
}

export const ActiveOrderTable = ({
  accountIndex,
  showCancelColumn: showCancelOrderColumn = false,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: ActiveOrderTableProps) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const isRegistered = useIsRegisteredQuery().data
  const wsActiveOrdersLoading = useAccountLoading(accountIndex)
  const orderBookMetas = useOrderBookMetas()
  const currentMarketId = useCurrentMarketId()
  const { t } = useTranslation()

  const userActiveOrders = useAccountActiveOrders(accountIndex)

  const activeOrdersToDisplay = useMemo(
    () =>
      (selectedMarket
        ? (userActiveOrders[selectedMarket.market_id] ?? [])
        : Object.values(userActiveOrders).flat()
      )
        .filter((order) => {
          switch (selectedSide) {
            case 'asks': {
              return order.is_ask
            }
            case 'bids': {
              return !order.is_ask
            }
            case 'all':
            default: {
              return true
            }
          }
        })
        .sort((a, b) => b.timestamp - a.timestamp),
    [selectedSide, selectedMarket, userActiveOrders],
  )

  const showMarketColumn = !selectedMarket
  const showCancelColumn = showCancelOrderColumn && !!isRegistered
  const showCancelAll = !selectedMarket && activeOrdersToDisplay.length > 1

  useEffect(() => {
    if (isCancelModalOpen) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [isCancelModalOpen])

  if (wsActiveOrdersLoading) {
    return (
      <Table>
        <TableHeader>
          <TableHeaderRow>
            {showMarketColumn && <HeaderCell title={t('market')} />}
            <HeaderCell title={t('date')} />
            <HeaderCell title={t('type')} />
            <HeaderCell title={t('side')} />
            <HeaderCell title={t('amount')} subTitle={t('filled')} className="justify-end" />
            <HeaderCell title={t('price')} className="justify-end" />
            <HeaderCell title={t('trigger_conditions')} className="justify-end" />
            <HeaderCell title={t('expires_on')} className="justify-end" />
            {showCancelColumn && <HeaderCell title={t('cancel')} className="justify-end" />}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <TableLoader rows={4} columns={6 + Number(showMarketColumn) + Number(showCancelColumn)} />
        </TableBody>
      </Table>
    )
  }

  if (!Object.values(userActiveOrders).flat().length) {
    return <NoOrdersText type={'activeOrders'} />
  }

  if (selectedMarket && !userActiveOrders[selectedMarket.market_id]?.length) {
    return (
      <NoItemsInMarket
        type={'activeOrders'}
        buttonOnClick={setSelectedMarket ? () => setSelectedMarket(null) : undefined}
      />
    )
  }

  return (
    <>
      {isCancelModalOpen && <CancelAllModal closeModal={() => setIsCancelModalOpen(false)} />}
      <Table>
        <TableHeader>
          <TableHeaderRow>
            {showMarketColumn && <HeaderCell title={t('market')} />}
            <HeaderCell title={t('date')} />
            <HeaderCell title={t('type')} />
            <HeaderCell title={t('side')} />
            <HeaderCell title={t('amount')} subTitle={t('filled')} className="justify-end" />
            <HeaderCell title={t('price')} className="justify-end" />
            <HeaderCell title={t('trigger_conditions')} className="justify-end" />
            <HeaderCell title={t('expires_on')} className="justify-end" />
            {showCancelColumn &&
              (showCancelAll ? (
                <TableCell>
                  <Clickable color="red" onClick={() => setIsCancelModalOpen(true)}>
                    <p className="typography-body-2">{t('cancel_all')}</p>
                  </Clickable>
                </TableCell>
              ) : (
                <HeaderCell title={t('cancel')} className="justify-end" />
              ))}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {activeOrdersToDisplay.map((order) => (
            <TableRow
              key={order.order_index}
              onClick={
                order.market_index !== currentMarketId
                  ? () => setSelectedMarket(orderBookMetas[order.market_index]!)
                  : undefined
              }
            >
              {showMarketColumn && <MarketCell marketIndex={order.market_index} />}
              <DateCell timestamp={order.timestamp} />
              <OrderTypeCell order={order} />
              <SideCell is_short={order.is_ask} />
              <OrderAmountFilledCell order={order} />
              <OrderPriceCell order={order} />
              <OrderTriggerCell order={order} />
              <OrderExpiryCell order={order} />
              {showCancelColumn && (
                <TableCell>
                  <CancelOrderButton order={order} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
