import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import { postWaitlist } from 'js/util/api/api'

import { Button } from './Button'
import { Clickable } from './Clickable'
import { Modal } from './Modal'
import Icon from './uikit/Icon'
import Input from './uikit/Input'
import Toast from './uikit/Toast'

export const PointsModal = () => {
  const { t } = useTranslation()
  const userAddress = useUserAddress()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [telegram, setTelegram] = useState('')
  const [l1address, setL1Address] = useState(userAddress)

  const onSubmit = async () => {
    setIsLoading(true)
    const res = await postWaitlist({ telegram, l1address, subject: 'points' })
    if (res) {
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('toasts_success')}
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      setOpen(false)
    } else {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('toasts_error')}
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    }
    setIsLoading(false)
  }

  const isInvalid = !l1address.trim() || !telegram.trim()

  return (
    <>
      <Clickable className="flex items-center gap-2" onClick={() => setOpen(true)}>
        <Icon icon="trophy" className="size-5" />
        <p className="typography-label-1 underline underline-offset-4 max-mobile:hidden">
          {t('points_modal_trigger_label')}
        </p>
      </Clickable>
      <Modal
        title={t('points_modal_title')}
        open={open}
        onOpenChange={(newOpen) => {
          setOpen(newOpen)

          if (!newOpen) {
            setTelegram('')
            setL1Address('')
          }
        }}
      >
        <div className="flex flex-col gap-6">
          <p className="typography-label-1 text-white">{t('points_modal_description')}</p>
          <Input
            value={telegram}
            placeholder={t('telegram')}
            onChange={(e) => setTelegram(e.target.value)}
            className="bg-white-transparent"
          />
          <Input
            value={l1address}
            placeholder={t('l1_address')}
            onChange={(e) => setL1Address(e.target.value)}
            className="bg-white-transparent"
          />
          <Tooltip open={isInvalid ? undefined : false}>
            <TooltipTrigger asChild>
              <Button
                disabled={isInvalid}
                onClick={onSubmit}
                className="w-full"
                isLoading={isLoading}
              >
                {t('submit')}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p className="typography-body-2 text-white">{t('fill_all_fields')}</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </Modal>
    </>
  )
}
