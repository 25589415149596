import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WithdrawButton } from 'js/pages/withdraw/form'
import {
  useAccountTotalTradesCount,
  useAccountTotalVolume,
} from 'js/providers/accountsSlice/selectors'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import { useLighterStore } from 'js/providers/lighterStore'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Button } from 'js/shared-components'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import { FaucetModal } from 'js/shared-components/FaucetModal'
import Icon from 'js/shared-components/uikit/Icon'
import { formatLeverage, formatUSD } from 'js/util/formatting'
import { useUserAccountPortfolioStats } from 'js/util/positions'

import RequestFundsButton from '../navbar/wallet/RequestFundsButton'

export const AccountDetails = () => {
  const accountIndex = useAccountIndex() ?? -1
  const { t } = useTranslation()
  const [isFaucetOpen, setIsFaucetOpen] = useState(false)
  const portfolioStats = useUserAccountPortfolioStats()
  const accountTotalVolume = useAccountTotalVolume(accountIndex)
  const accountTotalTrades = useAccountTotalTradesCount(accountIndex)
  const accountExistence = useAccountExistence()

  const stats = [
    {
      title: t('portfolio_value_title'),
      value: portfolioStats === null ? '-' : formatUSD(portfolioStats.portfolioValue),
      explanation: t('portfolio_value_description'),
    },
    {
      title: t('available_balance_title'),
      value: portfolioStats === null ? '-' : formatUSD(portfolioStats.availableBalance),
      explanation: t('available_balance_description'),
    },
    {
      title: t('leverage_title'),
      value: portfolioStats === null ? '-' : formatLeverage(portfolioStats.leverage),
      explanation: t('leverage_description'),
    },
    {
      title: t('margin_usage_title'),
      value: portfolioStats === null ? '-' : `${portfolioStats.initialMarginUsage.toFixed(2)}%`,
      explanation: t('margin_usage_description'),
    },
    {
      title: t('total_trades_title'),
      value: accountTotalTrades === null ? '-' : accountTotalTrades,
      explanation: t('total_trades_description'),
    },
    {
      title: t('total_volume_title'),
      value: accountTotalVolume === null ? '-' : formatUSD(accountTotalVolume),
      explanation: t('total_volume_description'),
    },
  ]

  return (
    <>
      <FaucetModal open={isFaucetOpen} onOpenChange={setIsFaucetOpen} />
      <div className="flex flex-1 flex-col gap-3">
        <div className="flex h-full flex-col gap-6 rounded-lg bg-white/5 p-6">
          <p className="typography-text-8 text-white max-mobile:px-3">{t('your_portfolio')}</p>
          <div className="flex flex-col">
            {stats.map((stat) => (
              <div
                className="flex items-center justify-between gap-1 whitespace-nowrap border-b border-white/10 p-3"
                key={stat.title}
              >
                <div className="flex flex-row items-center gap-2">
                  <p className="typography-body-2 text-white-opaque">{stat.title}</p>
                  <Explanation
                    explanation={stat.explanation}
                    trigger={<Icon icon="question" className="size-3.5 text-white-opaque" />}
                  />
                </div>
                <p className="typography-label-1 text-white">{stat.value}</p>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2 px-1">
            <RequestFundsButton openModal={() => setIsFaucetOpen(true)} closeDropdown={() => {}} />
            <WithdrawButton />
            {accountExistence === 'KeysDontMatch' && (
              <Button
                className="w-full"
                onClick={() => useLighterStore.setState({ showOnboarding: true })}
              >
                {t('authenticate')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
