import * as Slider from '@radix-ui/react-slider'
import { useTranslation } from 'react-i18next'

import PercentageInput from 'js/shared-components/PercentageInput'
import cn from 'js/util/cn'
import { formatLeverage } from 'js/util/formatting'

export const LeverageLimiter = ({
  leverage,
  limit,
  onChange,
}: {
  leverage: number
  limit: number
  onChange: (value: number) => void
}) => {
  const { t } = useTranslation()
  return (
    <div className="mt-auto flex w-full flex-col items-center justify-between  gap-6 rounded-lg border-white/10 max-mobile:border max-mobile:bg-white/5 max-mobile:p-4">
      <p className="typography-body-1 text-white-opaque mobile:hidden">
        {t('adjust_max_leverage')}
      </p>
      <div className="flex w-full items-center justify-between gap-3 mobile:rounded-lg mobile:border mobile:bg-white/5 mobile:p-4">
        <Slider.Root
          className="relative -mx-2 flex w-full touch-none select-none items-center px-2"
          onValueChange={([value]) => onChange(value!)}
          value={[leverage]}
          max={limit}
          min={1}
          step={1}
        >
          <Slider.Track className="relative h-2 grow bg-white/5">
            <Slider.Range
              className={cn('absolute h-full bg-gradient-to-r from-white/5 to-white')}
            />
            {new Array(5).fill(null).map((_, idx) => {
              return (
                <div
                  key={idx}
                  className="absolute top-1/2 h-3 w-px -translate-y-1/2 bg-white/30"
                  style={{ left: `calc(${idx * 25}% - 1px)` }}
                />
              )
            })}
            {new Array(16).fill(null).map((_, idx) => {
              return (
                <div
                  key={idx}
                  className="absolute top-1/2 h-2 w-px -translate-y-1/2 bg-white/10"
                  style={{ left: `calc(${idx * 6.25}% - 1px)` }}
                />
              )
            })}
          </Slider.Track>
          <Slider.Thumb
            className={cn('block size-5 cursor-pointer rounded-full border border-white bg-white')}
          />
        </Slider.Root>
        <div className="flex max-w-[50px] items-center rounded-lg border bg-white-transparent p-2">
          <PercentageInput
            className="max-w-6"
            value={leverage.toFixed(0)}
            inputMode="numeric"
            placeholder="0"
            onChange={(e) =>
              Number(e.target.value) < limit ? onChange(Number(e.target.value)) : onChange(limit)
            }
          />
          <p className="typography-body-1 text-white">x</p>
        </div>
      </div>
      <p className="typography-body-1 text-white-opaque max-mobile:hidden">
        {t('max')}.: {formatLeverage(limit)}
      </p>
    </div>
  )
}
