import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { usePublicPoolInfo, useUserAccountShares } from 'js/providers/accountsSlice/selectors'
import { useAccountsQuery } from 'js/providers/hooks/useAccountQuery'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { useResponsiveness } from 'js/ResponsivenessProvider'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Button } from 'js/shared-components/Button'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import UIKitPriceInput from 'js/shared-components/uikit/PriceInput'
import Toast from 'js/shared-components/uikit/Toast'
import cn from 'js/util/cn'
import { formatUSD } from 'js/util/formatting'
import { enforceNumber } from 'js/util/util'
import { burnShares } from 'js/zklighter-js-sdk/sdk'

import { usePublicPoolQuery, usePublicPoolSharePrice } from '../utils'

import ActionButtonBlocker from './ActionButtonBlocker'

const MINIMUM_WITHDRAWAL_AMOUNT = 10

const useAvailableSharesToBurn = () => {
  const accountsQuery = useAccountsQuery()
  const publicPoolQuery = usePublicPoolQuery()
  const shares = useUserAccountShares()
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)

  // catch all for loading state
  if (publicPoolSharePrice === null || !poolInfo || !accountsQuery.data) {
    return 0
  }

  const isOperator = accountsQuery.data.accounts.some(
    (account) => account.index === publicPoolQuery.data.index,
  )

  const ownedShares =
    shares.find((share) => share.public_pool_index === publicPoolQuery.data.index)?.shares_amount ??
    0

  // operator_share can only increase if the non-operators are withdrawing
  if (!isOperator || poolInfo.status === 1) {
    return ownedShares
  }

  // coming from BE as '5.000' -> 5% and needs to be converted to 0.05
  const minOperatorShareRate = Number(poolInfo.min_operator_share_rate) / 100

  /*
    min_operator_share_rate <= (operator_shares - delta) / (total_shares - delta)
    min_operator_share_rate * (total_shares - delta) <= operator_shares - delta
    min_operator_share_rate * total_shares - min_operator_share_rate * delta <= operator_shares - delta
    min_operator_share_rate * total_shares <= operator_shares - delta + min_operator_share_rate * delta
    min_operator_share_rate * total_shares - operator_shares <= delta * (min_operator_share_rate - 1)
    (min_operator_share_rate * total_shares - operator_shares) / (min_operator_share_rate - 1) <= delta
  */
  return Math.abs(
    Math.floor(
      (minOperatorShareRate * poolInfo.total_shares - poolInfo.operator_shares) /
        (minOperatorShareRate - 1),
    ),
  )
}

const WithdrawForm = () => {
  const shares = useUserAccountShares()
  const accountIndex = useAccountIndex()!
  const publicPoolQuery = usePublicPoolQuery()
  const [value, setValue] = useState('')
  const publicPoolSharePrice = usePublicPoolSharePrice()
  const poolInfo = usePublicPoolInfo(publicPoolQuery.data.index)
  const { isMobile } = useResponsiveness()
  const { t } = useTranslation()
  const ownedSharesAmount =
    shares.find((share) => share.public_pool_index === publicPoolQuery.data.index)?.shares_amount ??
    0
  const burnSharesMutation = useMutation({
    mutationFn: burnShares,
    onSuccess: () =>
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('public_pool_withdraw_toasts_success')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onError: () =>
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('toasts_error')}
          onClose={() => toast.dismiss(toastId)}
        />
      )),
    onSettled: () => setValue(''),
  })
  const availableSharesToBurn = useAvailableSharesToBurn()
  const availableToWithdraw = availableSharesToBurn * (publicPoolSharePrice ?? 0)

  const shouldWithdrawAll =
    Math.round(availableToWithdraw * 100) < Math.round(MINIMUM_WITHDRAWAL_AMOUNT * 100)
  const tooMuchWithdraw = Math.round(Number(value) * 100) > Math.round(availableToWithdraw * 100)
  const tooLittleWithdraw = Math.round(Number(value) * 100) < MINIMUM_WITHDRAWAL_AMOUNT * 100
  const isDisabled = !value || tooLittleWithdraw || tooMuchWithdraw

  const invalidAmountError = tooMuchWithdraw && !shouldWithdrawAll

  const form = (
    <div className="flex h-full flex-col justify-between gap-4 max-mobile:gap-6">
      <div className="flex flex-col gap-2">
        <p className="typography-body-2 text-white">{t('withdraw_funds')}</p>
        <UIKitPriceInput
          disabled={shouldWithdrawAll}
          label={`${t('amount')} (USDC)`}
          value={value}
          onChange={(e) => {
            if (enforceNumber(e, 2)) {
              setValue(e.target.value)
            }
          }}
          buttonText={t('max')}
          onClick={() => {
            if (availableToWithdraw > 0) setValue(availableToWithdraw.toFixed(2))
          }}
          placeholder={'0.00'}
        />
        <div className="flex items-center justify-between">
          <p className="typography-body-1 text-white">{t('available_to_withdraw')}</p>
          <div className="flex items-center gap-2">
            <p className="typography-body-1 text-white">{formatUSD(availableToWithdraw)}</p>
            {availableSharesToBurn < ownedSharesAmount && (
              <Explanation
                explanation={t('public_pool_withdraw_withdraw_limit', {
                  amount: formatUSD(availableToWithdraw),
                  minOperatorShareRate: `${Number(poolInfo?.min_operator_share_rate).toLocaleString(
                    'en-US',
                    { maximumFractionDigits: 2, minimumFractionDigits: 0 },
                  )}%`,
                })}
              />
            )}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <p className="typography-body-1 text-white">{t('min_withdraw_amount')}</p>
          <p className="typography-body-1 text-white">10 USDC</p>
        </div>
        <p className={cn('typography-body-1 text-red-main', { 'opacity-0': !invalidAmountError })}>
          {t('insufficient_funds')}
        </p>
      </div>
      <div className="flex gap-1">
        <ActionButtonBlocker>
          {availableToWithdraw >= MINIMUM_WITHDRAWAL_AMOUNT && (
            <Button
              color="red"
              className="w-full"
              disabled={isDisabled}
              isLoading={burnSharesMutation.isPending || publicPoolSharePrice === null}
              onClick={() =>
                burnSharesMutation.mutate({
                  accountIndex,
                  publicPoolIndex: publicPoolQuery.data.index,
                  shareAmount: Math.min(
                    Math.floor(Number(value) / publicPoolSharePrice!),
                    availableSharesToBurn,
                  ),
                })
              }
            >
              {t('withdraw')}
            </Button>
          )}
          {availableToWithdraw < MINIMUM_WITHDRAWAL_AMOUNT && (
            <Button
              color="red"
              className="w-full"
              disabled={availableSharesToBurn === 0}
              isLoading={burnSharesMutation.isPending}
              onClick={() =>
                burnSharesMutation.mutate({
                  accountIndex,
                  publicPoolIndex: publicPoolQuery.data.index,
                  shareAmount: Math.min(availableSharesToBurn, ownedSharesAmount),
                })
              }
            >
              {t('withdraw_all')}
            </Button>
          )}
        </ActionButtonBlocker>
      </div>
    </div>
  )

  if (!isMobile) {
    return form
  }

  return (
    <BottomAnimatedDropdown isOpen className="z-[101] min-h-[50%] p-6">
      {form}
    </BottomAnimatedDropdown>
  )
}

export default WithdrawForm
