import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PageContainer } from 'js/pages/page-container/PageContainer'
import { DepthChart } from 'js/pages/trade/components/chart/DepthChart'
import { FundingRateChart } from 'js/pages/trade/components/chart/FundingRateChart'
import { MarketDetails } from 'js/pages/trade/components/chart/MarketDetails'
import { TradingViewChart } from 'js/pages/trade/components/chart/TradingViewChart'
import { OrderBook } from 'js/pages/trade/components/orders/all-orders/OrderBook'
import { TradesList } from 'js/pages/trade/components/orders/all-orders/TradesList'
import { UserOrders } from 'js/pages/trade/components/orders/user-orders'
import {
  PlaceOrder,
  useIsClosingPosition,
} from 'js/pages/trade/components/place-order-panel/PlaceOrder'
import { TradeFooter } from 'js/pages/trade/components/TradeFooter'
import { useCurrentMarketId } from 'js/providers/hooks/order-book-metas-hooks'
import { useWsSubStore } from 'js/providers/wsStore'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Backdrop } from 'js/shared-components/Backdrop'
import { Clickable } from 'js/shared-components/Clickable'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'

import { MobileMarketSelector } from './components/mobile/MobileMarketSelector'

const TABS = [
  { key: 'price', titleKey: 'price', icon: 'price' },
  { key: 'book', titleKey: 'order_book', icon: 'book' },
  { key: 'trades', titleKey: 'trades', icon: 'trades' },
  { key: 'depth', titleKey: 'depth', icon: 'depth' },
  { key: 'funding', titleKey: 'funding', icon: 'funding' },
  { key: 'details', titleKey: 'details', icon: 'details' },
] as const

type Tab = (typeof TABS)[number]['key']

const MobileViewTrade = () => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState<Tab>('price')
  const [tradeModalOpen, setTradeModalOpen] = useState(false)

  const ws = useWsSubStore((state) => state.ws)
  const currentMarketId = useCurrentMarketId()

  useEffect(() => {
    if (!ws || currentMarketId === undefined) {
      return
    }
    useWsSubStore.getState().actions.subscribeMarket(currentMarketId)
    return () => {
      useWsSubStore.getState().actions.unsubscribeMarket()
    }
  }, [ws, currentMarketId])

  const isClosingPosition = useIsClosingPosition()

  return (
    <PageContainer className="max-mobile:pb-20">
      <div className="mb-3 flex w-full">
        <Backdrop isVisible={tradeModalOpen} onClick={() => setTradeModalOpen(false)} />
        <div className="relative flex size-full flex-col gap-6">
          <MobileMarketSelector />
          <div className="flex flex-col">
            <div className="flex h-[400px] w-full">
              <div className={cn('relative size-full', { hidden: selectedTab !== 'depth' })}>
                {selectedTab === 'depth' && <DepthChart />}
              </div>
              <div className={cn('size-full', { hidden: selectedTab !== 'price' })}>
                <TradingViewChart />
              </div>
              <div className={cn('relative size-full', { hidden: selectedTab !== 'funding' })}>
                <FundingRateChart />
              </div>
              <div className={cn('flex size-full flex-col', { hidden: selectedTab !== 'book' })}>
                {selectedTab === 'book' && <OrderBook />}
              </div>
              <div className={cn('flex size-full flex-col', { hidden: selectedTab !== 'trades' })}>
                {selectedTab === 'trades' && <TradesList />}
              </div>
              <div className={cn('flex size-full flex-col', { hidden: selectedTab !== 'details' })}>
                {selectedTab === 'details' && <MarketDetails />}
              </div>
            </div>
          </div>
          <div className="flex w-full items-center justify-evenly">
            {TABS.map(({ key, icon, titleKey }) => (
              <Clickable
                key={t(titleKey)}
                onClick={() => setSelectedTab(key)}
                color="white"
                className="flex flex-col items-center"
              >
                <Icon
                  icon={icon}
                  className={cn('size-8 rounded-lg p-2', {
                    'bg-blue-grey': selectedTab === key,
                  })}
                />
                <p className="typography-body-1">{t(titleKey)}</p>
              </Clickable>
            ))}
          </div>
        </div>
      </div>
      <UserOrders />
      <BottomAnimatedDropdown
        className="top-0 z-[11] h-full"
        isOpen={tradeModalOpen || isClosingPosition}
      >
        <Clickable className="flex justify-end p-2" onClick={() => setTradeModalOpen(false)}>
          <Icon icon="x" className="size-5" />
        </Clickable>
        <PlaceOrder />
      </BottomAnimatedDropdown>
      {!tradeModalOpen && !isClosingPosition && (
        <TradeFooter setTradeModalOpen={setTradeModalOpen} />
      )}
    </PageContainer>
  )
}

export default MobileViewTrade
