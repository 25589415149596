import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import type { Deposit } from 'zklighter-perps'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import Icon from 'js/shared-components/uikit/Icon'
import { bridgeApi, parseErrorFromCodegen, transactionApi } from 'js/util/api/sdk'
import { isZero } from 'js/util/util'

export const CHAIN_ID = {
  arbitrum: 42161,
  base: 8453,
  avalanche: 43114,
  ethereum: 1,
} as const

export type DepositStatus = 'waitForTransfer' | 'bridging' | 'completed' | 'cancelling'
export type EnDeposit = Omit<Deposit, 'status'> & {
  status: DepositStatus
  cancellable: boolean
}
export const useLatestDepositQuery = () => {
  const userAddress = useUserAddress()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const attemptQuery = useQuery<EnDeposit, { code: number; message: string }>({
    queryKey: ['latestdeposit', userAddress],
    queryFn: () =>
      bridgeApi
        .depositLatest({ l1_address: userAddress })
        .then(async (attempt) => {
          let mappedStatus: DepositStatus = 'waitForTransfer'
          const cancellable = isZero(attempt.amount)

          // todo extract inside to lambda for readibility
          if (attempt.status === 'completed') {
            try {
              const data = await transactionApi.txFromL1TxHash({
                hash: attempt.fast_bridge_tx_hash,
              })
              const appErr = (JSON.parse(data.event_info) as { ae?: string })['ae']
              if (appErr === '') {
                mappedStatus = 'completed'
              }
            } catch (e) {
              const err = await parseErrorFromCodegen(e)
              const isTransactionNotFound =
                err.code === 21500 || err.message === 'transaction not found'
              if (isTransactionNotFound) {
                mappedStatus = 'bridging'
              } else {
                throw err
              }
            }
          } else if (attempt.status === 'pending') {
            mappedStatus = isZero(attempt.amount) ? 'waitForTransfer' : 'bridging'
          } else {
            console.error('unexpected deposit status', attempt.status)
          }
          return { ...attempt, status: mappedStatus, cancellable }
        })
        .catch((err) =>
          parseErrorFromCodegen(err).then((e) => {
            throw e
          }),
        ),
    refetchInterval: (last) => {
      switch (last.state.data?.status) {
        case 'completed':
          return 60_000
        case 'waitForTransfer':
          return 2_000
        case 'bridging':
          return 3_000
        default:
          return false
      }
    },
    enabled: !!userAddress,
  })

  const forceComplete = useCallback(() => {
    queryClient.setQueryData(['latestdeposit', userAddress], (old: EnDeposit | undefined) =>
      old ? { ...old, status: 'completed' as DepositStatus, amount: '0.2' } : null,
    )
  }, [queryClient, userAddress])

  const cancelMutation = useMutation({
    mutationFn: async (args: { chainId: string; userAddr: string }) => {
      queryClient.setQueryData(['latestdeposit', args.userAddr], (old: EnDeposit | undefined) =>
        old ? { ...old, status: 'cancelling' as DepositStatus } : null,
      )
      return bridgeApi
        .depositCancel({ chain_id: args.chainId, from_addr: args.userAddr })
        .catch((e) =>
          parseErrorFromCodegen(e).then((e) => {
            throw e
          }),
        )
    },
    onSuccess: (_, args) => queryClient.setQueryData(['latestdeposit', args.userAddr], null),
    onError: () => {
      toast.error(t('cancel_failed'))
      attemptQuery.refetch()
    },
  })

  const cancel = useMemo(
    () => (attemptQuery.data?.cancellable ? cancelMutation.mutate : null),
    [attemptQuery.data?.cancellable, cancelMutation.mutate],
  )

  const resetData = useCallback(
    (userAddress: string) => {
      queryClient.setQueryData(['latestdeposit', userAddress], null)
    },
    [queryClient],
  )

  return useMemo(() => {
    return {
      data: attemptQuery.data,
      cancel: attemptQuery.data?.cancellable ? cancel : null,
      cancelMutation: cancelMutation.mutate,
      refetch: attemptQuery.refetch,
      isLoading: attemptQuery.isLoading,
      isPending: attemptQuery.isPending,
      error: attemptQuery.error,
      resetData,
      forceComplete,
    }
  }, [
    attemptQuery.data,
    cancel,
    cancelMutation.mutate,
    resetData,
    attemptQuery.refetch,
    attemptQuery.isLoading,
    attemptQuery.isPending,
    attemptQuery.error,
    forceComplete,
  ])
}

export const useSupportedNetworksQuery = () => {
  return useQuery({
    queryKey: ['bridgeSupportedNetworks'],
    queryFn: () => {
      return [
        {
          name: 'Ethereum',
          chainId: CHAIN_ID.ethereum,
          isCex: false,
          usdcAddr: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
          icon: <Icon icon="eth" className="size-4 text-black" />,
          blockExplorer: 'https://etherscan.io/',
        },
        {
          name: 'Arbitrum One',
          chainId: CHAIN_ID.arbitrum,
          isCex: false,
          usdcAddr: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
          icon: <Icon icon="arb" className="size-4" />,
          blockExplorer: 'https://arbiscan.io/',
        },
        {
          name: 'Base',
          chainId: CHAIN_ID.base,
          isCex: false,
          usdcAddr: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
          icon: <Icon icon="base" className="size-4" />,
          blockExplorer: 'https://basescan.org/',
        },
        {
          name: 'Avalanche C-Chain',
          chainId: CHAIN_ID.avalanche,
          isCex: false,
          usdcAddr: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
          icon: <Icon icon="avalanche" className="size-4" />,
          blockExplorer: 'https://snowtrace.io/',
        },
      ]
    },
  })
}
