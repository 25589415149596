import { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useMidPrice } from 'js/providers/orderBookSlice/selectors'
import UIKitPriceInput from 'js/shared-components/uikit/PriceInput'
import { enforceNumber } from 'js/util/util'

import { useOrderInputStore } from '../PlaceOrder'

export const PriceInput = () => {
  const { t } = useTranslation()
  const orderInputs = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const midPrice = useMidPrice()
  const value = orderInputs.limitPrice
  const onChange = useCallback((e: string) => orderInputs.update('limitPrice', e), [orderInputs])
  const decimal = currentMarket.price_decimals
  const didSetRef = useRef(false)

  useEffect(() => {
    didSetRef.current = false
  }, [currentMarket.symbol])

  useEffect(() => {
    const notReady = !midPrice
    const notEmpty = !!value
    if (notReady || notEmpty || !orderInputs.isLimit() || didSetRef.current) return
    onChange(midPrice.toFixed(decimal))
    didSetRef.current = true
  }, [midPrice, onChange, decimal, currentMarket.symbol, value, orderInputs])

  return (
    <UIKitPriceInput
      label={t('limit_price_input_label')}
      helperText={t('limit_price_input_helper_text')}
      value={value}
      onClick={() => onChange(midPrice.toFixed(decimal))}
      buttonText="Mid"
      onChange={(e) => {
        if (enforceNumber(e, decimal)) onChange(e.target.value)
      }}
      placeholder={Number(0).toFixed(decimal)}
    />
  )
}
