import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { Explanation } from 'js/shared-components/ExplanationPopup'
import Icon from 'js/shared-components/uikit/Icon'
import { formatUSD } from 'js/util/formatting'
import {
  marginFractionToLeverage,
  useGetInitialMarginFraction,
  useUpdatedPositions,
  useUserAccountPortfolioStats,
  usePortfolioStats,
} from 'js/util/positions'

import { useOrderInputStore, useOrderToPlaceUpdater } from '../PlaceOrder'

interface BuyingStatsProps {
  newSize?: number
  isShort?: boolean
  isLimitOrder?: boolean
  setLiquidationWarning: React.Dispatch<React.SetStateAction<boolean>>
}

export const BuyingStats = ({
  newSize = 0,
  isShort = false,
  isLimitOrder = false,
  setLiquidationWarning,
}: BuyingStatsProps) => {
  const { t } = useTranslation()
  const accountIndex = useAccountIndex()
  const getInitialMarginFraction = useGetInitialMarginFraction()
  const currentMarket = useCurrentMarket()
  const leverage = marginFractionToLeverage(getInitialMarginFraction(currentMarket.market_id))
  const { matchInfo } = useOrderToPlaceUpdater()
  const { limitPrice } = useOrderInputStore()
  const portfolioStats = useUserAccountPortfolioStats()
  const updatedPositions = useUpdatedPositions(
    newSize,
    isShort,
    isLimitOrder ? limitPrice : matchInfo.estPrice,
  )
  const updatedUserStats = usePortfolioStats(updatedPositions, accountIndex ?? -1)
  const buyingPower = portfolioStats === null ? null : portfolioStats.availableBalance * leverage
  const updatedAvailableBalance =
    updatedUserStats !== null &&
    portfolioStats !== null &&
    updatedUserStats?.availableBalance !== portfolioStats?.availableBalance
      ? updatedUserStats.availableBalance
      : null

  useEffect(() => {
    setLiquidationWarning(updatedAvailableBalance !== null && updatedAvailableBalance === 0)
  }, [updatedAvailableBalance, setLiquidationWarning])

  return (
    <div className="mb-3 grid grid-cols-2">
      <Stat
        title={t('available_balance_title')}
        explanation={t('available_balance_description')}
        value={portfolioStats === null ? '-' : formatUSD(portfolioStats.availableBalance)}
        updatedValue={updatedAvailableBalance === null ? '' : formatUSD(updatedAvailableBalance)}
      />
      <Stat
        title={t('buying_power_title')}
        explanation={t('buying_power_description')}
        value={buyingPower === null ? '-' : formatUSD(buyingPower)}
        updatedValue={
          updatedAvailableBalance === null ? '' : formatUSD(updatedAvailableBalance * leverage)
        }
      />
    </div>
  )
}

interface StatProps {
  title: string
  value: string
  explanation: string
  updatedValue: string | null
}

const Stat = ({ title, explanation, value, updatedValue }: StatProps) => (
  <div className="flex flex-col items-start gap-1 px-3 py-2 shadow-[0_0_0_0.5px_#2F343E]">
    <div className="flex items-center gap-2">
      <p className="typography-body-1 text-white">{title}</p>
      <Explanation explanation={explanation} />
    </div>
    <p className="typography-body-2 w-full truncate text-white">{value}</p>
    {updatedValue?.length ? (
      <div className="flex w-full items-center">
        <Icon icon="arrow" className="size-4 text-red-main" />
        <p className="typography-body-1 w-[calc(100%-24px)] truncate text-white">{updatedValue}</p>
      </div>
    ) : (
      <div className="h-4" />
    )}
  </div>
)
