import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckboxSelector } from 'js/shared-components/CheckboxSelector'
import { Clickable } from 'js/shared-components/Clickable'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'js/shared-components/uikit/Collapsible'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import Input from 'js/shared-components/uikit/Input'

import { useOrderInputStore } from '../PlaceOrder'

const TIME_IN_FORCE_OPTIONS = [
  { titleKey: 'good_till_time', value: 'gtd' },
  { titleKey: 'immediate_or_cancel', value: 'ioc' },
] as const

const GOOD_TILL_TIME_UNIT_OPTIONS = [
  { titleKey: 'minutes', value: 'm' },
  { titleKey: 'hours', value: 'h' },
  { titleKey: 'days', value: 'd' },
] as const

export const AdvancedLimitDropdown = () => {
  const { t } = useTranslation()
  const {
    timeInForce,
    timeInForceValue,
    timeInForceUnit,
    postOnly,
    changeTimeInForce,
    changeTimeInForceValue,
    changeTimeInForceUnit,
    changePostOnly,
    isStop,
  } = useOrderInputStore()
  const [open, setOpen] = useState(false)

  const selectedTimeInForceOption = TIME_IN_FORCE_OPTIONS.find(
    (option) => option.value === timeInForce,
  )!
  const selectedGoodTillTimeUnitOption = GOOD_TILL_TIME_UNIT_OPTIONS.find(
    (option) => option.value === timeInForceUnit,
  )!

  const options = isStop()
    ? TIME_IN_FORCE_OPTIONS.filter((option) => option.value !== 'ioc')
    : TIME_IN_FORCE_OPTIONS

  return (
    <Collapsible onOpenChange={setOpen} open={open}>
      <CollapsibleTrigger asChild>
        <Clickable className="mt-2 flex items-center gap-2">
          <p className="typography-body-2 text-white">{t('advanced')}</p>
          <SmallArrow direction={open ? 'up' : 'down'} />
        </Clickable>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-col gap-4 py-3">
          <div className="flex flex-col gap-2">
            <p className="typography-body-1 text-white">{t('time_in_force')}</p>
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full">
                {t(selectedTimeInForceOption.titleKey)}
              </DropdownMenuTrigger>
              <DropdownMenuContent className="-top-0.5" align="start">
                {options.map((option) => (
                  <DropdownMenuItem
                    key={option.value}
                    onClick={() => changeTimeInForce(option.value)}
                  >
                    {t(option.titleKey)}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {timeInForce === 'gtd' && (
              <div className="flex gap-2">
                <Input
                  placeholder="10"
                  value={timeInForceValue}
                  onChange={(e) => changeTimeInForceValue(e.target.value)}
                  className="flex-1 bg-white-transparent px-2 indent-0 text-sm placeholder:indent-0 placeholder:text-sm max-mobile:text-base max-mobile:placeholder:text-base"
                />
                <DropdownMenu>
                  <DropdownMenuTrigger className="flex-1">
                    {t(selectedGoodTillTimeUnitOption.titleKey)}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {GOOD_TILL_TIME_UNIT_OPTIONS.map((option) => (
                      <DropdownMenuItem
                        key={option.value}
                        onClick={() => changeTimeInForceUnit(option.value)}
                      >
                        {t(option.titleKey)}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
          </div>
          {!isStop() && timeInForce === 'gtd' && (
            <div className="flex flex-col gap-2">
              <p className="typography-body-1 text-white">{t('execution')}</p>
              <div
                className="flex cursor-pointer items-center gap-3"
                onClick={() => changePostOnly(!postOnly)}
              >
                <CheckboxSelector isSelected={postOnly} />
                <p className="typography-body-2 text-white">{t('post_only')}</p>
              </div>
            </div>
          )}
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
