import type { StateCreator } from 'zustand'

import { type AccountStoreData, type PoolInfo, type PortfolioStats } from '../types'

export interface AccountsSlice {
  accounts: Record<number, AccountStoreData>
  portfolioStats: Record<number, PortfolioStats>
  poolInfo: Record<number, PoolInfo>
}

export const createAccountsSlice: StateCreator<AccountsSlice, [], [], AccountsSlice> = () => ({
  accounts: {},
  portfolioStats: {},
  poolInfo: {},
})
