import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { useLighterStore } from 'js/providers/lighterStore'
import { useShowFeedback } from 'js/providers/userSlice/selectors'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import { postFeedback } from 'js/util/api/api'

import { Button } from './Button'
import { Modal } from './Modal'
import Input from './uikit/Input'
import Textarea from './uikit/Textarea'
import Toast from './uikit/Toast'

export const SupportModal = () => {
  const { t } = useTranslation()
  const showFeedback = useShowFeedback()
  const [isLoading, setIsLoading] = useState(false)
  const [telegram, setTelegram] = useState('')
  const [feedback, setFeedback] = useState('')

  const onSubmit = async () => {
    setIsLoading(true)
    const res = await postFeedback({ telegram, feedback })
    if (res) {
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('support_modal_toasts_success')}
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      useLighterStore.setState({ showFeedback: false })
    } else {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('toasts_error')}
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    }
    setIsLoading(false)
  }
  const isInvalid = !telegram.trim() || !feedback.trim()

  return (
    <Modal
      open={showFeedback}
      title={t('support')}
      dataTestId="feedback-modal"
      preventClose={isLoading}
      onOpenChange={(newOpen) => {
        useLighterStore.setState({ showFeedback: newOpen })

        if (!newOpen) {
          setTelegram('')
          setFeedback('')
        }
      }}
    >
      <div className="flex flex-col gap-6">
        <p className="typography-label-1 text-white">{t('support_modal_description')}</p>
        <Input
          className="bg-white-transparent"
          placeholder={t('telegram')}
          onChange={(e) => setTelegram(e.target.value)}
        />
        <Textarea
          className="bg-white-transparent pt-2"
          placeholder={t('feedback')}
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Tooltip open={isInvalid ? undefined : false}>
          <TooltipTrigger asChild>
            <Button
              disabled={isInvalid}
              isLoading={isLoading}
              onClick={onSubmit}
              className="w-full"
            >
              {t('submit')}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="typography-body-2 text-white">{t('fill_all_fields')}</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </Modal>
  )
}
