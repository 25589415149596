import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import {
  DynamicContextProvider,
  DynamicWidget,
  useDynamicContext,
} from '@dynamic-labs/sdk-react-core'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Suspense, type PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'
import { Toaster } from 'sonner'

import { DepositModal } from './pages/deposit/form'
import ErrorPage from './pages/error'
import { Maintenance } from './pages/maintenance'
import { LiquidationNotifications } from './pages/trade/components/place-order-panel/components/LiquidationNotifications'
import { WithdrawModal } from './pages/withdraw/form'
import { useOrderBookMetasQuery } from './providers/hooks/order-book-metas-hooks'
import { useAccount, useIsGeoLocBlockedQuery } from './providers/hooks/useAccountQuery'
import { useInitWs } from './providers/hooks/useInitWs'
import { useSiteTitleSetter } from './providers/hooks/useSiteTitleSetter'
import { useZklLighterInfoQuery } from './providers/hooks/useZkLighterL1'
import { useLighterStore } from './providers/lighterStore'
import ResponsivenessProvider from './ResponsivenessProvider'
import { LoadingSpinner } from './shared-components'
import useQueryClientConfig from './util/api/useQueryClientConfig'
import cn from './util/cn'
import { useI18NextQuery } from './util/i18next'
import { useHealthQuery } from './util/queries'
import { isMainnet } from './util/util'

// this technically blocks for translations as well
const HealthCheck = ({ children }: PropsWithChildren) => {
  const i18nextQuery = useI18NextQuery()
  const healthQuery = useHealthQuery()

  if (healthQuery.isPending || i18nextQuery.isPending) {
    return <LoadingSpinner />
  }

  if (!healthQuery.data?.serverOk) {
    return <Maintenance />
  }

  return children
}

const DataSyncBlocker = ({ children }: PropsWithChildren) => {
  const { sdkHasLoaded } = useDynamicContext()

  const orderBookMetasQuery = useOrderBookMetasQuery()
  const zkLighterInfoQuery = useZklLighterInfoQuery()
  const isGeoLocBlockerQuery = useIsGeoLocBlockedQuery()

  useSiteTitleSetter()
  useAccount()
  useInitWs()

  if (
    zkLighterInfoQuery.isPending ||
    isGeoLocBlockerQuery.isPending ||
    orderBookMetasQuery.isPending ||
    !sdkHasLoaded
  ) {
    return <LoadingSpinner />
  }

  return children
}

const DynamicProvider = ({ children }: PropsWithChildren) => {
  // TODO: localize dynamic
  return (
    <DynamicContextProvider
      settings={{
        mobileExperience: 'redirect',
        walletConnectors: [EthereumWalletConnectors],
        appLogoUrl: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Examplelogo.svg',
        initialAuthenticationMode: 'connect-only',
        appName: 'Lighter',
        environmentId: 'de15f0f9-44b8-47c0-871d-6ca54c5e6a54',
        localStorageSuffix: 'v44',
        events: {
          onWalletAdded: () => {
            if (!isMainnet()) {
              useLighterStore.setState({ showOnboarding: true })
            }
          },
        },
      }}
    >
      {children}
    </DynamicContextProvider>
  )
}

const Wrapper = () => {
  const queryClient = useQueryClientConfig()

  return (
    <ResponsivenessProvider>
      <TooltipProvider delayDuration={0} skipDelayDuration={0} disableHoverableContent>
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <Suspense fallback={<LoadingSpinner />}>
            <QueryClientProvider client={queryClient}>
              <DynamicProvider>
                <HealthCheck>
                  <DataSyncBlocker>
                    <div className="absolute opacity-0">
                      <DynamicWidget />
                    </div>
                    <WithdrawModal />
                    <DepositModal />
                    <LiquidationNotifications />
                    <Outlet />
                    <ReactQueryDevtools buttonPosition="bottom-left" position="bottom" />
                    <Toaster cn={cn} className="mobile:![--width:240px]" />
                  </DataSyncBlocker>
                </HealthCheck>
              </DynamicProvider>
            </QueryClientProvider>
          </Suspense>
        </Sentry.ErrorBoundary>
      </TooltipProvider>
    </ResponsivenessProvider>
  )
}

export default Wrapper
