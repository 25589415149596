import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { FeeBucketRequest } from 'zklighter-perps'

import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { accountApi } from 'js/util/api/sdk'
import { formatMarketSize } from 'js/util/formatting'

import { useOrderInputStore } from '../PlaceOrder'

interface TwapStatsProps {
  orderSize: string
}

const FREQUENCY = 30

export const TwapStats = ({ orderSize }: TwapStatsProps) => {
  const { t } = useTranslation()
  const accountIndex = useAccountIndex() ?? -1
  const { runtimeMinutes, runtimeHours } = useOrderInputStore()
  const currentMarket = useCurrentMarket()
  const params: FeeBucketRequest = useMemo(() => ({ account_index: accountIndex }), [accountIndex])
  const feeBucketQuery = useQuery({
    queryKey: ['feeBucket', params],
    queryFn: () => accountApi.feeBucket(params),
    enabled: accountIndex !== -1,
  })
  const runtimeInSeconds = Number(runtimeMinutes) * 60 + Number(runtimeHours) * 60 * 60
  const hours = Math.floor(runtimeInSeconds / 60 / 60)
  const minutes = Math.floor((runtimeInSeconds - hours * 60 * 60) / 60)
  const runtime = (hours > 0 ? `${hours}h` : '') + `${minutes}m`

  const numOrders = runtimeInSeconds / FREQUENCY + 1

  const summary = [
    {
      title: t('frequency'),
      value: t('frequency_value', { frequency: `${FREQUENCY}` }),
    },
    { title: t('runtime'), value: runtime },
    { title: t('number_of_orders'), value: numOrders.toString() },
    {
      title: t('size_per_suborder'),
      value: formatMarketSize(Number(orderSize) / numOrders, currentMarket),
    },
    {
      title: t('fees'),
      value: t('fees_value', {
        takerFee: `${(feeBucketQuery.data?.taker_fee ?? 0) / 10 ** 6}%`,
        makerFee: `${(feeBucketQuery.data?.maker_fee ?? 0) / 10 ** 6}%`,
      }),
    },
  ] as const

  return (
    <div className="flex w-full flex-col gap-3 rounded-md border bg-white-transparent p-3">
      {summary.map(({ title, value }) => (
        <div key={title} className="flex w-full justify-between">
          <p className="typography-body-2 text-white-opaque">{title}:</p>
          <div className="flex items-center gap-1">
            <p className="typography-body-2 text-white">{value}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
