import { useTranslation } from 'react-i18next'
import type { DetailedAccount } from 'zklighter-perps'

import { SubAccountType } from 'js/providers/types'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'js/shared-components/uikit/DropdownMenu'
import Icon from 'js/shared-components/uikit/Icon'
import cn from 'js/util/cn'
import { formatUSD } from 'js/util/formatting'

export const AccountSelector = ({
  accountsList,
  onAccountChange,
  selectedAccountIndex,
  direction,
}: {
  accountsList: DetailedAccount[]
  onAccountChange: (idx: number) => void
  selectedAccountIndex: number | null
  direction: 'to' | 'from'
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-1">
      <p className="typography-body-2 text-white">
        {t('transfer_sub_accounts', {
          direction: direction === 'to' ? 'to' : 'from',
        })}
      </p>
      <DropdownMenu>
        <DropdownMenuTrigger>
          {selectedAccountIndex !== null
            ? t('sub_acc_index', {
                index: selectedAccountIndex.toString(),
              })
            : t('select_sub_acc')}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {accountsList.map((account) => (
            <DropdownMenuItem
              key={account.index}
              onClick={() => onAccountChange(account.index)}
              className="flex items-center justify-between"
            >
              <div className="flex flex-col items-start gap-1">
                <p className="typography-label-1 text-white">
                  {account.account_type === SubAccountType.main
                    ? t('main_account')
                    : account.name ||
                      t('account_index', {
                        index: account.index.toString(),
                      })}
                </p>
                <p className="typography-body-1 text-white">
                  <span className="text-white-opaque">{t('available')} </span>
                  {formatUSD(account.collateral)}
                </p>
              </div>
              <Icon
                icon="check"
                className={cn('size-4 rounded-full', {
                  'border border-green-main text-green-main':
                    selectedAccountIndex === account.index,
                  'border-2 border-grey-light text-grey-light':
                    selectedAccountIndex !== account.index,
                })}
              />
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
