import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderTypeEnum } from 'zklighter-perps'

import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketPrice } from 'js/util/formatting'

interface OrderTriggerCellProps {
  order: Order
}

const OrderTriggerCell = ({ order }: OrderTriggerCellProps) => {
  const { t } = useTranslation()
  const market = useOrderBookMetas()[order.market_index]!
  const isShort = order.is_ask
  const formattedTriggerPrice = formatMarketPrice(order.trigger_price, market)

  const label = useMemo(() => {
    switch (order.type) {
      case OrderTypeEnum.StopLossLimit:
      case OrderTypeEnum.StopLoss: {
        return t(isShort ? 'price_below_trigger_price' : 'price_above_trigger_price', {
          triggerPrice: formattedTriggerPrice,
        })
      }
      case OrderTypeEnum.TakeProfitLimit:
      case OrderTypeEnum.TakeProfit: {
        return t(isShort ? 'price_above_trigger_price' : 'price_below_trigger_price', {
          triggerPrice: formattedTriggerPrice,
        })
      }
      default: {
        return '-'
      }
    }
  }, [t, isShort, order.type, formattedTriggerPrice])

  return (
    <TableCell>
      <p className="typography-body-1 whitespace-nowrap text-end text-white">{label}</p>
    </TableCell>
  )
}
export default OrderTriggerCell
