import { useTranslation } from 'react-i18next'

import { useUserAccount } from 'js/providers/hooks/useAccountQuery'
import { Button } from 'js/shared-components'
import { isMainnet } from 'js/util/util'

interface RequestFundsButtonProps {
  closeDropdown: () => void
  openModal: () => void
}

const RequestFundsButton = ({ closeDropdown, openModal }: RequestFundsButtonProps) => {
  const { t } = useTranslation()
  const userAccount = useUserAccount()

  if (!userAccount || isMainnet()) {
    return null
  }

  return (
    <Button
      onClick={() => {
        closeDropdown()
        openModal()
      }}
      className="w-full"
    >
      {t('request_funds')}
    </Button>
  )
}

export default RequestFundsButton
