import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

if (import.meta.env.VITE_SENTRY_ENVIRONMENT) {
  const restApiBase = import.meta.env.VITE_REST_API_BASE
  // injected at build command on netlfy
  const commitRef = import.meta.env.VITE_COMMIT_REF ?? ''
  Sentry.init({
    dsn: 'https://e7790471924f46e5ada7e4afcc63cace@o4507652019322880.ingest.us.sentry.io/4507667850133504',
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    beforeSend: (event) => {
      if (event.transaction) {
        event.transaction = event.transaction.replace(/\/trade\/\w+/, '/trade/{coin}')
      }
      event.tags = {
        ...event.tags,
        'app.version': commitRef,
      }

      return event
    },
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [
          window.location.origin,
          restApiBase,
          new RegExp(`^${restApiBase}`),
        ],
      }),
      Sentry.replayCanvasIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    replaysSessionSampleRate: ['mainnet'].includes(import.meta.env.VITE_SENTRY_ENVIRONMENT) ? 1 : 0,
    replaysOnErrorSampleRate: ['mainnet'].includes(import.meta.env.VITE_SENTRY_ENVIRONMENT)
      ? 1
      : ['testnet'].includes(import.meta.env.VITE_SENTRY_ENVIRONMENT)
        ? 0.1
        : 0,
  })
}
