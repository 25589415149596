import { createSelector } from 'reselect'

import { useLighterStore } from '../lighterStore'
import { useAccountIndex } from '../userSlice/selectors'

import type { AccountsSlice } from '.'

const selectAccount = (accountIndex: number) => (state: AccountsSlice) =>
  state.accounts[accountIndex]

const selectAccountLoading = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account === undefined)
export const useAccountLoading = (accountIndex: number) =>
  useLighterStore(selectAccountLoading(accountIndex))

const selectAccountTrades = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.trades ?? {})
export const useAccountTrades = (accountIndex: number) =>
  useLighterStore(selectAccountTrades(accountIndex))

const selectAccountActiveOrders = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.activeOrders ?? {})
export const useAccountActiveOrders = (accountIndex: number) =>
  useLighterStore(selectAccountActiveOrders(accountIndex))
export const useUserAccountActiveOrders = () => {
  const accountIndex = useAccountIndex()

  return useAccountActiveOrders(accountIndex ?? -1)
}

const selectAccountInactiveOrders = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.inactiveOrders ?? {})
export const useAccountInactiveOrders = (accountIndex: number) =>
  useLighterStore(selectAccountInactiveOrders(accountIndex))
export const useUserAccountInactiveOrders = () => {
  const accountIndex = useAccountIndex()

  return useAccountInactiveOrders(accountIndex ?? -1)
}

const selectAccountPositions = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.positions ?? {})
export const useAccountPositions = (accountIndex: number) =>
  useLighterStore(selectAccountPositions(accountIndex))
export const useUserAccountPositions = () => {
  const accountIndex = useAccountIndex()

  return useAccountPositions(accountIndex ?? -1)
}

const selectAccountLiquidations = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.liquidations ?? [])
export const useUserAccountLiquidations = () => {
  const accountIndex = useAccountIndex()

  return useLighterStore(selectAccountLiquidations(accountIndex ?? -1))
}

const selectAccountShares = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.shares ?? [])
const useAccountShares = (accountIndex: number) =>
  useLighterStore(selectAccountShares(accountIndex))
export const useUserAccountShares = () => {
  const accountIndex = useAccountIndex()

  return useAccountShares(accountIndex ?? -1)
}

const selectAccountFundingHistory = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.fundingHistory ?? {})
export const useAccountFundingHistory = (accountIndex: number) =>
  useLighterStore(selectAccountFundingHistory(accountIndex))

const selectAccountTotalTradesCount = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.totalTradesCount ?? null)
export const useAccountTotalTradesCount = (accountIndex: number) =>
  useLighterStore(selectAccountTotalTradesCount(accountIndex))

const selectAccountTotalVolume = (accountIndex: number) =>
  createSelector([selectAccount(accountIndex)], (account) => account?.totalVolume ?? null)
export const useAccountTotalVolume = (accountIndex: number) =>
  useLighterStore(selectAccountTotalVolume(accountIndex))

const selectAccountStats = (accountIndex: number) => (state: AccountsSlice) =>
  state.portfolioStats[accountIndex]
export const useAccountStats = (accountIndex: number) =>
  useLighterStore(selectAccountStats(accountIndex))

const selectPublicPoolInfos = (state: AccountsSlice) => state.poolInfo

const selectPublicPoolInfo = (publicPoolIndex: number) =>
  createSelector([selectPublicPoolInfos], (poolInfos) => poolInfos[publicPoolIndex])
export const usePublicPoolInfo = (publicPoolIndex: number) =>
  useLighterStore(selectPublicPoolInfo(publicPoolIndex))
