import { useTranslation } from 'react-i18next'

import { Button } from 'js/shared-components'

const noOrdersTexts = {
  activeOrders: {
    titleKey: 'table_no_items_in_market_description_active_orders',
    buttonTextKey: 'table_no_items_in_market_button_active_orders',
  },
  orderHistory: {
    titleKey: 'table_no_items_in_market_description_order_history',
    buttonTextKey: 'table_no_items_in_market_button_order_history',
  },
  tradeHistory: {
    titleKey: 'table_no_items_in_market_description_trades',
    buttonTextKey: 'table_no_items_in_market_button_trades',
  },
  fundingHistory: {
    titleKey: 'table_no_items_in_market_description_funding_history',
    buttonTextKey: 'table_no_items_in_market_button_funding_history',
  },
} as const

interface NoItemsInMarketProps {
  buttonOnClick?: () => void
  type: 'activeOrders' | 'orderHistory' | 'tradeHistory' | 'fundingHistory'
}

export const NoItemsInMarket = ({ buttonOnClick, type }: NoItemsInMarketProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex h-full flex-col items-center justify-center gap-6 max-mobile:my-6 max-mobile:px-6 max-mobile:text-center">
      <p className="typography-label-1 text-white">{t(noOrdersTexts[type].titleKey)}</p>
      {!!buttonOnClick && (
        <Button onClick={buttonOnClick}>{t(noOrdersTexts[type].buttonTextKey)}</Button>
      )}
    </div>
  )
}
