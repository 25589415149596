import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useResponsiveness } from 'js/ResponsivenessProvider'
import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import { BottomAnimatedDropdown } from 'js/shared-components/AnimatedDropdown'
import { Backdrop } from 'js/shared-components/Backdrop'
import { SmallArrow } from 'js/shared-components/SmallArrow'
import { ActiveOrderTable } from 'js/shared-components/tables/ActiveOrderTable'
import { FundingHistoryTable } from 'js/shared-components/tables/FundingHistoryTable'
import { OrderHistoryTable } from 'js/shared-components/tables/OrderHistoryTable'
import { PositionsTable } from 'js/shared-components/tables/PositionsTable'
import { TradeHistoryTable } from 'js/shared-components/tables/TradeHistoryTable'
import cn from 'js/util/cn'

import { usePublicPoolQuery } from '../utils'

const TABS = [
  { titleKey: 'positions', key: 'positions' },
  { titleKey: 'open_orders', key: 'open_orders' },
  { titleKey: 'order_history', key: 'order_history' },
  { titleKey: 'trade_history', key: 'trade_history' },
  { titleKey: 'funding_history', key: 'funding_history' },
] as const
type Tab = (typeof TABS)[number]['key']

const PublicPoolTables = () => {
  const { t } = useTranslation()
  const [isSelectorOpen, setIsSelectorOpen] = useState(false)
  const [selectedTabKey, setSelectedTabKey] = useState<Tab>('positions')
  const selectedTabIndex = TABS.findIndex((tab) => tab.key === selectedTabKey)!
  const selectedTab = TABS[selectedTabIndex]!

  const publicPoolQuery = usePublicPoolQuery()
  const { isMobile } = useResponsiveness()

  return (
    <div className="flex flex-1 flex-col gap-2 overflow-hidden bg-black">
      <Backdrop
        fullScreen
        className="top-0 h-full"
        isVisible={isSelectorOpen}
        onClick={() => setIsSelectorOpen(false)}
      />
      {!isMobile && (
        <TabBar className="h-[unset] min-h-[unset] rounded-lg border-none bg-white/5 p-1 max-mobile:gap-4 max-mobile:bg-transparent max-mobile:p-0">
          <div className="relative flex w-full">
            <SelectorIndicator
              numButtons={Object.values(TABS).length}
              selectedElemIdx={selectedTabIndex}
              className="bottom-px after:h-0.5 after:w-10 after:rounded-full"
            />
            {TABS.map((tab) => (
              <TabButton
                key={tab.key}
                isSelected={false}
                onClick={() => setSelectedTabKey(tab.key)}
                className={cn(
                  'typography-label-1 cursor-pointer rounded-lg px-4 py-2 transition-all',
                  {
                    'text-white bg-white/10': selectedTabKey === tab.key,
                    'text-white-opaque bg-transparent': selectedTabKey !== tab.key,
                  },
                )}
              >
                {t(tab.titleKey)}
              </TabButton>
            ))}
          </div>
        </TabBar>
      )}
      {isMobile && (
        <div className="flex flex-1 gap-6 rounded-lg bg-white/10 px-4">
          <div className="w-full">
            <div
              className="flex w-full items-center justify-between"
              onClick={() => setIsSelectorOpen((prev) => !prev)}
            >
              <TabButton isSelected={false}>
                <p className="typography-label-1 text-left text-white">{t(selectedTab.titleKey)}</p>
              </TabButton>
              <SmallArrow direction="down" />
            </div>
            <BottomAnimatedDropdown isOpen={isSelectorOpen} className="z-[101]">
              {TABS.map((tab) => (
                <TabButton
                  key={tab.key}
                  className="h-[unset] py-4"
                  isSelected={false}
                  onClick={() => {
                    setSelectedTabKey(tab.key)
                    setIsSelectorOpen(false)
                  }}
                >
                  <p className="typography-label-1 text-white">{t(tab.titleKey)}</p>
                </TabButton>
              ))}
            </BottomAnimatedDropdown>
          </div>
        </div>
      )}
      <div className="h-[400px] overflow-scroll rounded-lg bg-white/5 max-mobile:h-[200px] max-mobile:border">
        {selectedTabKey === 'positions' && (
          <PositionsTable accountIndex={publicPoolQuery.data.index} />
        )}
        {selectedTabKey === 'open_orders' && (
          <ActiveOrderTable accountIndex={publicPoolQuery.data.index} />
        )}
        {selectedTabKey === 'order_history' && (
          <OrderHistoryTable accountIndex={publicPoolQuery.data.index} />
        )}
        {selectedTabKey === 'trade_history' && (
          <TradeHistoryTable accountIndex={publicPoolQuery.data.index} />
        )}
        {selectedTabKey === 'funding_history' && (
          <FundingHistoryTable accountIndex={publicPoolQuery.data.index} />
        )}
      </div>
    </div>
  )
}

export default PublicPoolTables
