import type { StateCreator } from 'zustand'

import type { Trade } from '../types'
import type { WsMarketStats, WsOrderbook } from '../wsStore/types'

export interface OrderBookSlice {
  marketsStats: Record<string, WsMarketStats>
  trades: Trade[] | null
  orderBook: WsOrderbook | null
  height: number | null
}

export const createOrderBookSlice: StateCreator<OrderBookSlice, [], [], OrderBookSlice> = () => ({
  marketsStats: {},
  trades: null,
  orderBook: null,
  height: null,
})
