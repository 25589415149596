import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { useIsWhitelistedQuery, useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useLighterStore } from 'js/providers/lighterStore'
import { useShowWhitelist } from 'js/providers/userSlice/selectors'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import { postWaitlist } from 'js/util/api/api'

import { Button } from './Button'
import { Modal } from './Modal'
import Input from './uikit/Input'
import Toast from './uikit/Toast'

export const WhitelistModal = () => {
  const { t } = useTranslation()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const showWhitelist = useShowWhitelist()
  const userAddress = useUserAddress()
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [l1address, setL1Address] = useState(userAddress)

  const onSubmit = async () => {
    const res = await postWaitlist({ telegram: telegram, l1address, discord, subject: 'whitelist' })
    if (res) {
      toast.custom((toastId) => (
        <Toast
          level="success"
          description={t('toasts_success')}
          onClose={() => toast.dismiss(toastId)}
        />
      ))
      useLighterStore.setState({ showWhitelist: false })
    } else {
      toast.custom((toastId) => (
        <Toast
          level="error"
          description={t('toasts_error')}
          onClose={() => toast.dismiss(toastId)}
        />
      ))
    }
  }

  const isDisabled = !l1address || (!telegram && !discord)

  return (
    <Modal
      title={t('whitelist_modal_title')}
      open={isWhitelistedQuery.data === false && showWhitelist}
      onOpenChange={(newOpen) => {
        useLighterStore.setState({ showWhitelist: newOpen })

        if (!newOpen) {
          setTelegram('')
          setDiscord('')
          setL1Address(userAddress)
        }
      }}
    >
      <div className="flex flex-col gap-6">
        <p className="typography-label-1 text-white">{t('whitelist_modal_description')}</p>
        <Input
          value={telegram}
          placeholder={t('telegram')}
          onChange={(e) => setTelegram(e.target.value)}
          required
          className="bg-white-transparent"
        />
        <Input
          value={discord}
          placeholder={t('discord')}
          onChange={(e) => setDiscord(e.target.value)}
          required
          className="bg-white-transparent"
        />
        <Input
          value={l1address}
          placeholder={t('l1_address')}
          onChange={(e) => setL1Address(e.target.value)}
          className="bg-white-transparent"
        />

        <p className="typography-label-1 text-white">
          <Trans
            i18nKey="join_discord"
            components={{
              DiscordLink: (
                <a
                  className="border-b border-b-primary-blue-main text-primary-blue-main"
                  href="https://discord.gg/sUzWrdWxy3"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </p>
        <Tooltip open={isDisabled ? undefined : false}>
          <TooltipTrigger asChild>
            <Button disabled={isDisabled} onClick={onSubmit} className="w-full">
              {t('submit')}
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="typography-body-2 text-white">{t('fill_all_fields')}</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </Modal>
  )
}
