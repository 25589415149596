import { useTranslation } from 'react-i18next'

import { BackgroundLights } from 'js/shared-components/BackgroundLights'

import { PageContainer } from '../page-container/PageContainer'

import PublicPoolList from './PublicPoolList'

const PublicPools = () => {
  const { t } = useTranslation()

  return (
    <PageContainer className="bg-gradient-to-b">
      <BackgroundLights />
      <div className="flex flex-col gap-8 px-80 pb-10 pt-20 max-mobile:p-1">
        <div className="flex items-center justify-between gap-4 px-2">
          <p className="typography-text-8 max-mobile:typography-text-7 whitespace-nowrap text-white">
            {t('public_pools')}
          </p>
          {/* TODO add back when we wanna allow users to create pools */}
          {/* <CreatePublicPool /> */}
        </div>
        <PublicPoolList />
      </div>
    </PageContainer>
  )
}

export default PublicPools
