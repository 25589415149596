import { isEthereumWallet } from '@dynamic-labs/ethereum'
import { useDynamicContext } from '@dynamic-labs/sdk-react-core'

export const useWallet = () => {
  const { handleLogOut, primaryWallet, network } = useDynamicContext()

  return {
    network,
    switch: async (networkId: number) => {
      if (primaryWallet?.connector.supportsNetworkSwitching()) {
        return primaryWallet.connector.switchNetwork({ networkChainId: networkId })
      }
      throw new Error('Network switching not supported')
    },
    waitTx: async (hash: `0x${string}`) => {
      if (!primaryWallet || !isEthereumWallet(primaryWallet) || !network)
        throw new Error('No wallet connected')

      const publicClient = await primaryWallet.getPublicClient()

      return publicClient.waitForTransactionReceipt({ hash })
    },
    disconnect: async () => {
      if (!primaryWallet) {
        return
      }
      await handleLogOut()
      if (isEthereumWallet(primaryWallet)) {
        const publicClient = await primaryWallet?.getPublicClient()
        const walletClient = await primaryWallet?.getWalletClient(publicClient?.chain.id.toString())

        if (!walletClient) {
          return
        }

        if (primaryWallet.key === 'metamask') {
          // See: https://docs.metamask.io/wallet/reference/wallet_revokepermissions
          await walletClient.request({
            method: 'wallet_revokePermissions',
            params: [{ eth_accounts: {} }],
          })
        }
      }
    },
  }
}
