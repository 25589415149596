import { useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useOrderBookTrades } from '../orderBookSlice/selectors'

export const useSiteTitleSetter = () => {
  const { symbol } = useParams()
  const trades = useOrderBookTrades()
  const lastPrice = useMemo(() => trades?.[0]?.price, [trades])

  useEffect(() => {
    document.title =
      !symbol || lastPrice === undefined ? 'Lighter' : `$${lastPrice} ${symbol} • Lighter`
  }, [lastPrice, symbol])
}
