import { animate, stagger } from 'motion'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SelectorIndicator, TabBar, TabButton } from 'js/shared-components'
import cn from 'js/util/cn'

import { DepthChart } from './DepthChart'
import { FundingRateChart } from './FundingRateChart'
import { MarketDetails } from './MarketDetails'
import { TradingViewChart } from './TradingViewChart'

export const Chart = () => {
  const [activeChart, setActiveChart] = useState<Tab>('price')
  const chartRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const TABS = [
    { label: t('price'), key: 'price' },
    { label: t('depth'), key: 'depth' },
    { label: t('funding'), key: 'funding' },
    { label: t('details'), key: 'details' },
  ] as const
  type Tab = (typeof TABS)[number]['key']

  const changeChart = (tab: Tab) => {
    if (activeChart === tab || !chartRef || chartRef.current == null) {
      return
    }

    animate(chartRef.current, { opacity: [0, 1] }, { delay: stagger(5), duration: 0.8 })
    setActiveChart(tab)
  }
  const selectedTabIndex = TABS.findIndex((tab) => tab.key === activeChart)

  return (
    <>
      <TabBar>
        <SelectorIndicator numButtons={TABS.length} selectedElemIdx={selectedTabIndex} />
        {TABS.map(({ key, label }) => (
          <TabButton key={key} isSelected={activeChart === key} onClick={() => changeChart(key)}>
            {label}
          </TabButton>
        ))}
      </TabBar>
      <div className="h-[calc(100%_-_40px)] max-w-full" ref={chartRef}>
        <div className={cn('relative size-full', { hidden: activeChart !== 'depth' })}>
          {activeChart === 'depth' && <DepthChart />}
        </div>
        <div className={cn('relative size-full', { hidden: activeChart !== 'price' })}>
          <TradingViewChart />
        </div>
        <div className={cn('relative size-full', { hidden: activeChart !== 'funding' })}>
          <FundingRateChart />
        </div>
        <div className={cn('relative size-full', { hidden: activeChart !== 'details' })}>
          {activeChart === 'details' && <MarketDetails />}
        </div>
      </div>
    </>
  )
}
