import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import type { Order } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketSize } from 'js/util/formatting'

interface OrderAmountFilledCellProps {
  order: Order
}

const OrderAmountFilledCell = ({ order }: OrderAmountFilledCellProps) => {
  const market = useOrderBookMetas()[order.market_index]!

  return (
    <TableCell>
      <div className="flex flex-col items-end">
        <p className="typography-body-1 text-white">
          {formatMarketSize(order.initial_base_amount, market)}
        </p>
        <p className="typography-body-1 text-white">
          {formatMarketSize(order.filled_base_amount, market)}
        </p>
      </div>
    </TableCell>
  )
}

export default OrderAmountFilledCell
