import * as Slider from '@radix-ui/react-slider'

import { OrderDirections } from 'js/constants/shared'
import PercentageInput from 'js/shared-components/PercentageInput'
import cn from 'js/util/cn'

export const PercentageSlider = ({
  side,
  leverage,
  onChange,
  setIsLeverageInput,
  disabled = false,
}: {
  side: OrderDirections
  leverage: number
  onChange: (value: number) => void
  setIsLeverageInput: (focused: boolean) => void
  disabled?: boolean
}) => {
  return (
    <div className="flex w-full flex-col justify-between rounded-lg border bg-black p-4">
      <div className="flex w-full items-center justify-between gap-3">
        <Slider.Root
          className="relative -mx-2 flex w-full touch-none select-none items-center px-2"
          onValueChange={([value]) => !disabled && onChange(value!)}
          value={[leverage]}
          max={100}
          min={0}
          step={1}
        >
          <Slider.Track className="relative h-1.5 grow bg-grey-light">
            <Slider.Range
              className={cn('absolute h-full bg-gradient-to-r', {
                'from-green-dark to-green-main': side === OrderDirections.Long,
                'from-red-dark to-red-main': side === OrderDirections.Short,
              })}
            />
            {new Array(5).fill(null).map((_, idx) => {
              const isGrey = disabled || idx * 25 > Math.ceil(leverage)

              return (
                <div
                  key={idx}
                  className={cn('absolute top-1/2 h-3 w-0.5 -translate-y-1/2', {
                    'bg-grey-light': isGrey,
                    'bg-green-main': !isGrey && side === OrderDirections.Long,
                    'bg-red-main': !isGrey && side === OrderDirections.Short,
                  })}
                  style={{ left: `calc(${idx * 25}% - 1px)` }}
                />
              )
            })}
          </Slider.Track>
          <Slider.Thumb
            className={cn('block size-4 cursor-pointer rounded-full border bg-grey-main', {
              'border-green-main': side === OrderDirections.Long,
              'border-red-main': side === OrderDirections.Short,
              'border-grey-light': disabled,
            })}
          />
        </Slider.Root>
        <div className="flex max-w-[50px] items-center rounded-lg border bg-white-transparent p-2">
          <PercentageInput
            className="max-w-6"
            onFocus={() => setIsLeverageInput(true)}
            onBlur={() => setIsLeverageInput(false)}
            value={leverage.toString()}
            inputMode="decimal"
            placeholder="0"
            onChange={(e) => onChange(Number(e.target.value))}
          />
          <p className="typography-body-1 text-white">%</p>
        </div>
      </div>
    </div>
  )
}
