import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { OrderBookDetail } from 'zklighter-perps'

import { type OrderSide } from 'js/constants/trades'
import { useAccountInactiveOrders, useAccountLoading } from 'js/providers/accountsSlice/selectors'
import DateCell from 'js/shared-components/cells/DateCell'
import MarketCell from 'js/shared-components/cells/MarketCell'
import OrderAmountFilledCell from 'js/shared-components/cells/orders/OrderAmountFilledCell'
import OrderAveragePriceCell from 'js/shared-components/cells/orders/OrderAveragePriceCell'
import OrderPriceCell from 'js/shared-components/cells/orders/OrderPriceCell'
import OrderStatusCell from 'js/shared-components/cells/orders/OrderStatusCell'
import OrderTypeCell from 'js/shared-components/cells/orders/OrderTypeCell'
import SideCell from 'js/shared-components/cells/SideCell'
import HeaderCell from 'js/shared-components/HeaderCell'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { orderApi } from 'js/util/api/sdk'
import { useLastTableItemRef } from 'js/util/table'
import { isMarketIdAllowed } from 'js/util/util'

import TableLoader from '../uikit/table/TableLoader'

import { NoItemsInMarket } from './NoItemsInMarket'
import { NoOrdersText } from './NoOrdersText'

interface OrderHistoryTableHeaderProps {
  showMarketColumn: boolean
}

const OrderHistoryTableHeader = ({ showMarketColumn }: OrderHistoryTableHeaderProps) => {
  const { t } = useTranslation()

  return (
    <TableHeader>
      <TableHeaderRow>
        {showMarketColumn && <HeaderCell title={t('market')} />}
        <HeaderCell title={t('date')} />
        <HeaderCell title={t('type')} />
        <HeaderCell title={t('side')} />
        <HeaderCell title={t('amount')} subTitle={t('filled')} className="justify-end" />
        <HeaderCell title={t('price')} className="justify-end" />
        <HeaderCell title={t('average')} className="justify-end" />
        <HeaderCell title={t('status')} className="justify-end" />
      </TableHeaderRow>
    </TableHeader>
  )
}

interface OrderHistoryTableProps {
  accountIndex: number
  selectedSide?: OrderSide
  selectedMarket?: OrderBookDetail | null
  setSelectedMarket?: (market: OrderBookDetail | null) => void
}

const PAGE_SIZE = 20

const getAskFilter = (selectedSide: OrderSide) => {
  switch (selectedSide) {
    case 'asks': {
      return 1
    }
    case 'bids': {
      return 0
    }
    case 'all':
    default: {
      return -1
    }
  }
}

export const OrderHistoryTable = ({
  accountIndex,
  selectedSide = 'all',
  selectedMarket = null,
  setSelectedMarket = () => {},
}: OrderHistoryTableProps) => {
  const showMarketColumn = !selectedMarket
  const wsInactiveOrdersLoading = useAccountLoading(accountIndex)
  const userInactiveOrders = useAccountInactiveOrders(accountIndex)

  const params = useMemo(
    () =>
      ({
        limit: PAGE_SIZE,
        account_index: accountIndex,
        market_id: selectedMarket?.market_id,
        ask_filter: getAskFilter(selectedSide),
      }) as const,
    [selectedMarket, selectedSide, accountIndex],
  )
  const inactiveOrdersQuery = useInfiniteQuery({
    queryKey: ['inactiveOrdersQuery', params],
    queryFn: ({ pageParam }) => orderApi.accountInactiveOrders({ cursor: pageParam, ...params }),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage.next_cursor,
    select: (data) =>
      data.pages
        .map((page) => page.orders)
        .flat()
        .filter((order) => isMarketIdAllowed(order.market_index)),
    enabled: !!accountIndex,
  })
  const lastOrderRef = useLastTableItemRef(inactiveOrdersQuery)

  const wsInactiveOrders = useMemo(
    () =>
      (selectedMarket
        ? (userInactiveOrders[selectedMarket.market_id] ?? [])
        : Object.values(userInactiveOrders).flat()
      ).filter((order) => {
        switch (selectedSide) {
          case 'asks':
            return order.is_ask
          case 'bids':
            return !order.is_ask
          case 'all':
          default:
            return true
        }
      }),
    [selectedMarket, selectedSide, userInactiveOrders],
  )

  const restApiInactiveOrders = useMemo(
    () =>
      (inactiveOrdersQuery.data ?? []).filter(
        (order) => !wsInactiveOrders.some((wsOrder) => wsOrder.order_index === order.order_index),
      ),
    [inactiveOrdersQuery.data, wsInactiveOrders],
  )

  const inactiveOrdersToDisplay = useMemo(
    () => [...wsInactiveOrders, ...restApiInactiveOrders].sort((a, b) => b.timestamp - a.timestamp),
    [restApiInactiveOrders, wsInactiveOrders],
  )

  if (inactiveOrdersQuery.isLoading || wsInactiveOrdersLoading) {
    return (
      <Table>
        <OrderHistoryTableHeader showMarketColumn={showMarketColumn} />
        <TableBody>
          <TableLoader rows={4} columns={7 + Number(showMarketColumn)} />
        </TableBody>
      </Table>
    )
  }
  if (selectedMarket && !inactiveOrdersToDisplay.length) {
    return (
      <NoItemsInMarket
        type={'orderHistory'}
        buttonOnClick={setSelectedMarket ? () => setSelectedMarket(null) : undefined}
      />
    )
  }

  if (!inactiveOrdersToDisplay.length && !inactiveOrdersQuery.hasNextPage) {
    return <NoOrdersText type={'orderHistory'} />
  }

  return (
    <Table>
      <OrderHistoryTableHeader showMarketColumn={showMarketColumn} />
      <TableBody>
        {inactiveOrdersToDisplay.map((order, index) => (
          <TableRow
            key={order.order_index}
            ref={index === inactiveOrdersToDisplay.length - 1 ? lastOrderRef : undefined}
          >
            {showMarketColumn && <MarketCell marketIndex={order.market_index} />}
            <DateCell timestamp={order.timestamp} />
            <OrderTypeCell order={order} />
            <SideCell is_short={order.is_ask} />
            <OrderAmountFilledCell order={order} />
            <OrderPriceCell order={order} />
            <OrderAveragePriceCell order={order} />
            <OrderStatusCell order={order} />
          </TableRow>
        ))}
        {inactiveOrdersQuery.isFetchingNextPage && (
          <TableLoader rows={1} columns={7 + Number(showMarketColumn)} />
        )}
      </TableBody>
    </Table>
  )
}
