import { QueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { getErrorObjStatusCode } from './errors'

export default function useQueryClientConfig() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: 0,
            // TODO: check what this does
            throwOnError: (error) => {
              const status = getErrorObjStatusCode(error)
              // don't catch error for "Too many requests" response
              return status === 429
            },
          },
        },
      }),
  )

  return queryClient
}
