import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { PageContainer } from 'js/pages/page-container/PageContainer'
import { Button } from 'js/shared-components'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <PageContainer>
      <div className="flex size-full items-center justify-center">
        <div className="flex w-fit flex-col items-center justify-center gap-12 max-mobile:gap-4">
          <p className="text-9xl font-light text-white">404</p>
          <p className="text-2xl font-light text-white">{t('page_not_found')}</p>
          <p className="typography-body-2 text-white max-mobile:mx-auto max-mobile:my-7">
            {t('page_not_found_description')}
          </p>
          <Link to="/">
            <Button className="w-full">{t('return_to_homepage')}</Button>
          </Link>
        </div>
      </div>
    </PageContainer>
  )
}

export default NotFound
