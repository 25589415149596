import { useCallback, useMemo } from 'react'
import type { OrderBookDetail } from 'zklighter-perps'

import { useOrderInputStore } from 'js/pages/trade/components/place-order-panel/PlaceOrder'
import {
  useAccountPositions,
  useAccountStats,
  useUserAccountPositions,
} from 'js/providers/accountsSlice/selectors'
import {
  useCurrentMarket,
  useCurrentMarketId,
  getMarketOrderFromAmount1,
  useOrderBookMetas,
  getAvailableLiquidityUSD,
  getMarketOrderFromAmount0,
} from 'js/providers/hooks/order-book-metas-hooks'
import {
  useCurrentMarketStats,
  useMarketsStats,
  useOrderBook,
} from 'js/providers/orderBookSlice/selectors'
import type { OrderbookItem, Position } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/userSlice/selectors'

export const usePositionsWithLiqPrices = (
  positions: Record<string, Position>,
  accountIndex: number,
) => {
  const orderBookMetas = useOrderBookMetas()
  const marketsStats = useMarketsStats()
  const portfolioValue = usePortfolioStats(positions, accountIndex)?.portfolioValue ?? 0
  const totalMaintenanceMarginReq = useMaintenanceMarginReq(positions)

  return useMemo(
    () =>
      Object.values(positions)
        .filter((position) => Number(position.position) !== 0)
        .map((position) => {
          const marketStats = marketsStats[position.market_id]

          if (!marketStats) {
            return position
          }

          return {
            ...position,
            liquidation_price: getPositionLiquidationPrice(
              position,
              Number(marketStats.mark_price),
              orderBookMetas[position.market_id]!,
              portfolioValue,
              totalMaintenanceMarginReq,
            ),
          }
        })
        .reduce((acc, pos) => ({ ...acc, [pos.market_id]: pos }), {} as Record<string, Position>),
    [marketsStats, orderBookMetas, positions, portfolioValue, totalMaintenanceMarginReq],
  )
}

const getPositionLiquidationPrice = (
  position: Position,
  markPrice: number,
  market: OrderBookDetail,
  totalAccountValue: number,
  totalMaintenanceMarginReq: number,
) => {
  /*
    MMR: maintenance margin requirement
    TAV: total account value
    liquidationPrice: Mark price which user needs to get liquidated, assuming everything else stays the same for cross margin
    maintenanceMarginFraction: 5%, for partial liquidation
    positionSize: size of the open position (positive if long, negative if short)
    S: max(positionSize + bid size, ask size - positionSize) -> in our case, bid size/ask size are 0 so S is abs(positionSize)
    
    newMMR = MMR + S * liquidationPrice * maintenanceMarginFraction - S * markPrice * maintenanceMarginFraction
    newTAV = TAV + positionSize * (liquidationPrice - avgEntryPrice) - positionSize * (markPrice - avgEntryPrice)

    newMMR = MMR + S * maintenanceMarginFraction * (liquidationPrice - markPrice)
    newTAV = TAV + positionSize * (liquidationPrice - markPrice)

    newMMR = newTAV
    MMR + S * maintenanceMarginFraction * (liquidationPrice - markPrice) = TAV + positionSize * (liquidationPrice - markPrice)
    MMR - TAV = positionSize * (liquidationPrice - markPrice) - S * maintenanceMarginFraction * (liquidationPrice - markPrice)
    MMR - TAV = (positionSize - S * maintenanceMarginFraction) * (liquidationPrice - markPrice)
    (MMR - TAV) / (positionSize - S * maintenanceMarginFraction) = liquidationPrice - markPrice
    liquidationPrice = markPrice - (TAV - MMR) / (positionSize - S * maintenanceMarginFraction)
  */

  const maintenanceMarginRate = 1 / marginFractionToLeverage(market.maintenance_margin_fraction)
  const positionSize = Number(position.position)
  const numerator = totalAccountValue - totalMaintenanceMarginReq
  const denominator = positionSize * position.sign - positionSize * maintenanceMarginRate

  if (numerator <= 0) {
    // If numerator is negative, TAV <= MMR, which means the account needs to be liquidated
    return markPrice.toFixed(market.price_decimals)
  }

  if (denominator === 0) {
    return undefined
  }

  const liquidationPrice = markPrice - numerator / denominator

  if (isNaN(liquidationPrice) || !isFinite(liquidationPrice) || liquidationPrice < 0) {
    return undefined
  }

  if (
    (position.sign === 1 && liquidationPrice >= markPrice) ||
    (position.sign === -1 && liquidationPrice <= markPrice)
  ) {
    return markPrice.toFixed(market.price_decimals)
  }

  return liquidationPrice.toFixed(market.price_decimals)
}

export const usePortfolioStats = (positions: Record<string, Position>, accountIndex: number) => {
  const totalUnrealizedPnl = useAccountTotalUnrealizedPnL(positions)
  const initialMarginReq = useInitialMarginReq(positions)
  const maintenanceMarginReq = useMaintenanceMarginReq(positions)
  const totalPositionValue = useTotalPositionValue(positions)
  const accountStats = useAccountStats(accountIndex)

  if (!accountStats) {
    return null
  }

  const collateral = Number(accountStats.collateral)
  const portfolioValue = collateral + totalUnrealizedPnl
  const uncappedAvailableBalance = portfolioValue - initialMarginReq
  const availableBalance = Math.max(0, uncappedAvailableBalance)
  const withdrawableCollateral = Math.min(availableBalance, collateral)
  const leverage = totalPositionValue / portfolioValue
  const initialMarginUsage = (initialMarginReq / portfolioValue) * 100
  const maintenanceMarginUsage = (maintenanceMarginReq / portfolioValue) * 100

  return {
    portfolioValue,
    collateral,
    availableBalance,
    uncappedAvailableBalance,
    initialMarginUsage,
    maintenanceMarginUsage,
    leverage,
    withdrawableCollateral,
  }
}

export const useUserAccountPortfolioStats = () => {
  const accountIndex = useAccountIndex() ?? -1
  const positions = useAccountPositions(accountIndex)

  return usePortfolioStats(positions, accountIndex)
}

export const useMaintenanceMarginReq = (positions: Record<string, Position>) => {
  const orderBookMetas = useOrderBookMetas()
  const marketsStats = useMarketsStats()

  return Object.values(positions).reduce((acc, position) => {
    const market = marketsStats[position.market_id]

    if (!market) {
      return acc
    }

    return (
      acc +
      computePositionMarginReq(
        Number(position.position),
        Number(market.mark_price),
        orderBookMetas[position.market_id]!.maintenance_margin_fraction,
      )
    )
  }, 0)
}

const useTotalPositionValue = (positions: Record<string, Position>) => {
  const marketsStats = useMarketsStats()

  return Object.values(positions).reduce((acc, position) => {
    const market = marketsStats[position.market_id]

    if (!market) {
      return acc
    }

    return acc + Number(position.position) * Number(market.mark_price)
  }, 0)
}

const useInitialMarginReq = (positions: Record<string, Position>) => {
  const getInitialMarginFraction = useGetInitialMarginFraction()
  const marketsStats = useMarketsStats()

  return Object.values(positions).reduce((acc, position) => {
    const market = marketsStats[position.market_id]

    if (!market) {
      return acc
    }

    return (
      acc +
      computePositionMarginReq(
        Number(position.position),
        Number(market.mark_price),
        getInitialMarginFraction(position.market_id),
      )
    )
  }, 0)
}

export const useAccountTotalUnrealizedPnL = (positions: Record<string, Position>) => {
  const marketsStats = useMarketsStats()

  return Object.values(positions).reduce((pnl, position) => {
    const market = marketsStats[position.market_id]

    if (!market) {
      return pnl
    }

    return pnl + getPositionPnl(position, Number(market.mark_price))
  }, 0)
}

const MARGIN_FRACTION_TICK = 100

export const getPositionPnl = (position: Position, markPrice: number) => {
  const positionSize = Number(position.position)
  const avgEntryPrice = Number(position.avg_entry_price)

  return positionSize * (markPrice - avgEntryPrice) * position.sign
}

export const marginFractionToLeverage = (marginFraction: number) =>
  (100 * MARGIN_FRACTION_TICK) / marginFraction

export const leverageToMarginFraction = (leverage: number) =>
  (100 * MARGIN_FRACTION_TICK) / leverage

export const marginPercentageToFraction = (marginPercentage: number) => 100 * marginPercentage

const useCombinePositions = () => {
  const currentMarket = useCurrentMarket()

  return useCallback(
    (position1: Position, position2: Position): Position => {
      const position1Size = Number(position1.position) * position1.sign
      const position2Size = Number(position2.position) * position2.sign
      const combinedPositionSize = position1Size + position2Size
      let avgEntryPrice = 0

      if (combinedPositionSize === 0) {
        return { ...position1, position: '0', avg_entry_price: '0' }
      }

      if (position1.sign !== position2.sign) {
        avgEntryPrice =
          Math.sign(combinedPositionSize) === position1.sign
            ? Number(position1.avg_entry_price)
            : Number(position2.avg_entry_price)
      } else {
        const position1Value = position1Size * Number(position1.avg_entry_price)
        const position2Value = position2Size * Number(position2.avg_entry_price)
        const combinedPositionValue = position1Value + position2Value

        avgEntryPrice = combinedPositionValue / combinedPositionSize
      }

      return {
        ...position1,
        sign: Math.sign(combinedPositionSize),
        position: Math.abs(combinedPositionSize).toFixed(currentMarket.size_decimals),
        avg_entry_price: avgEntryPrice.toFixed(currentMarket.price_decimals),
      }
    },
    [currentMarket],
  )
}

export const useUpdatedPositions = (
  newSize: number,
  isShort: boolean,
  estimatedPrice: string,
): Record<string, Position> => {
  const positions = useUserAccountPositions()
  const currentMarket = useCurrentMarket()
  const combinePositions = useCombinePositions()
  const currentPosition = positions[currentMarket.market_id]
  const getInitialMarginFraction = useGetInitialMarginFraction()

  const newPosition: Position = useMemo(
    () => ({
      market_id: currentMarket.market_id,
      initial_margin_fraction: getInitialMarginFraction(currentMarket.market_id),
      sign: isShort ? -1 : 1,
      position: newSize.toFixed(currentMarket.size_decimals),
      avg_entry_price: estimatedPrice,
    }),
    [currentMarket, isShort, newSize, estimatedPrice, getInitialMarginFraction],
  )

  return useMemo(
    () => ({
      ...positions,
      [newPosition.market_id]: currentPosition
        ? combinePositions(currentPosition, newPosition)
        : newPosition,
    }),
    [combinePositions, positions, currentPosition, newPosition],
  )
}

export const computePositionMarginReq = (
  positionSize: number,
  markPrice: number,
  marginFraction: number,
) => (positionSize * markPrice) / marginFractionToLeverage(marginFraction)

export const computePositionLeverage = (
  positionSize: number,
  markPrice: number,
  portfolioValue: number,
) => (positionSize * markPrice) / portfolioValue

export const useGetInitialMarginFraction = () => {
  const accountIndex = useAccountIndex()
  const orderBookMetas = useOrderBookMetas()
  const positions = useAccountPositions(accountIndex ?? -1)

  return useCallback(
    (marketId: number) =>
      positions[marketId]?.initial_margin_fraction ??
      orderBookMetas[marketId]!.default_initial_margin_fraction,
    [positions, orderBookMetas],
  )
}

const useOrderBookOrders = (isShort: boolean) => {
  const orderBook = useOrderBook()

  if (!orderBook) {
    return []
  }

  return isShort ? orderBook.bids : orderBook.asks
}

const getReduceOnlyUpperBound = (
  market: OrderBookDetail,
  position: Position | undefined,
  orders: OrderbookItem[],
  reduceOnly: boolean,
  isShort: boolean,
) => {
  if (!reduceOnly) {
    return Infinity
  }

  if (!position || (position.sign === -1) === isShort) {
    return 0
  }

  return Number(getMarketOrderFromAmount0(market, position.position, orders).amount1)
}

const findBuyingPower = (
  market: OrderBookDetail,
  orders: OrderbookItem[],
  position: Position | undefined,
  marginFraction: number,
  availableBalance: number,
  isShort: boolean,
  markPrice: number,
) => {
  let left = 0
  let right = 10 ** 9

  const eps = 10 ** -market.price_decimals

  const leverage = marginFractionToLeverage(marginFraction)

  while (right - left > eps) {
    const amount1 = (left + right) / 2
    let adjustedBalance = availableBalance

    const order = getMarketOrderFromAmount1(market, amount1.toFixed(market.price_decimals), orders)

    let amount0 = Number(order.amount0)

    // position in the opposite direction means we can first close that position
    if (position && (position.sign === 1) === isShort) {
      amount0 = amount0 - Number(position.position)
      if (amount0 < 0) {
        left = amount1
        continue
      }

      adjustedBalance += (Number(position.position) * Number(order.estPrice)) / leverage
    }

    const extraPaid = isShort
      ? markPrice - Number(order.estPrice)
      : Number(order.estPrice) - markPrice
    const premium = extraPaid * Number(order.amount0)

    if (
      (adjustedBalance - premium) * leverage < markPrice * amount0 ||
      adjustedBalance * leverage < Number(order.estPrice) * amount0
    ) {
      right = amount1
    } else {
      left = amount1
    }
  }

  return left
}

export const useRealBuyingPower = () => {
  const isShort = useOrderInputStore().isShort()
  const reduceOnly = useOrderInputStore().reduceOnly
  const currentMarketId = useCurrentMarketId()
  const currentMarketStats = useCurrentMarketStats()
  const uncappedAvailableBalance = useUserAccountPortfolioStats()?.uncappedAvailableBalance ?? 0
  const position = useUserAccountPositions()[currentMarketId]
  const getInitialMarginFraction = useGetInitialMarginFraction()
  const currentMarket = useCurrentMarket()
  const orderBookOrders = useOrderBookOrders(isShort)
  const markPrice = currentMarketStats ? Number(currentMarketStats.mark_price) : 0

  const availableLiquidity = getAvailableLiquidityUSD(orderBookOrders)
  const reduceOnlyUpperBound = getReduceOnlyUpperBound(
    currentMarket,
    position,
    orderBookOrders,
    reduceOnly,
    isShort,
  )

  return Math.max(
    0,
    Math.min(
      availableLiquidity * 0.99,
      reduceOnlyUpperBound,
      findBuyingPower(
        currentMarket,
        orderBookOrders,
        position,
        getInitialMarginFraction(currentMarketId),
        uncappedAvailableBalance,
        isShort,
        markPrice,
      ) * 0.99,
    ),
  )
}
