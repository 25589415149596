import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { formatDate } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DepositHistoryItemStatusEnum, type DepositHistoryRequest } from 'zklighter-perps'

import { useUserAddress } from 'js/providers/hooks/useAccountQuery'
import { useZkLighterL1 } from 'js/providers/hooks/useZkLighterL1'
import { Clickable } from 'js/shared-components/Clickable'
import HeaderCell from 'js/shared-components/HeaderCell'
import { NoOrdersText } from 'js/shared-components/tables/NoOrdersText'
import Table from 'js/shared-components/uikit/table/Table'
import TableBody from 'js/shared-components/uikit/table/TableBody'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import TableHeader from 'js/shared-components/uikit/table/TableHeader'
import TableHeaderRow from 'js/shared-components/uikit/table/TableHeaderRow'
import TableLoader from 'js/shared-components/uikit/table/TableLoader'
import TableRow from 'js/shared-components/uikit/table/TableRow'
import { transactionApi } from 'js/util/api/sdk'
import { formatUSD } from 'js/util/formatting'
import { useLastTableItemRef } from 'js/util/table'

import { useWithdrawStore } from '../withdraw/form'

const DepositsHeader = () => {
  const { t } = useTranslation()
  return (
    <TableHeader>
      <TableHeaderRow>
        <HeaderCell title={t('date')} />
        <HeaderCell title={t('amount')} />
        <HeaderCell title={t('status')} />
        <HeaderCell title={t('transaction')} />
      </TableHeaderRow>
    </TableHeader>
  )
}

const REFRESH_INTERVAL = 10000

export const DepositHistory = () => {
  const { t } = useTranslation()
  const DEPOSIT_STATUS_LABELS = {
    [DepositHistoryItemStatusEnum.Completed]: t('completed'),
    [DepositHistoryItemStatusEnum.Claimable]: t('claimable'),
    [DepositHistoryItemStatusEnum.Failed]: t('failed'),
    [DepositHistoryItemStatusEnum.Pending]: t('pending'),
    ['refunded']: t('refunded'),
  } as const
  const userAddress = useUserAddress()

  const params: DepositHistoryRequest = useMemo(
    () => ({ l1_address: userAddress, filter: 'all' }),
    [userAddress],
  )

  const depositHistoryQuery = useInfiniteQuery({
    queryKey: ['depositHistory', params],
    queryFn: ({ pageParam }) => transactionApi.depositHistory({ ...params, cursor: pageParam }),
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage.cursor || undefined,
    enabled: !!userAddress,
    refetchInterval: REFRESH_INTERVAL,
  })

  const deposits = useMemo(
    () => (depositHistoryQuery.data?.pages ?? []).map(({ deposits }) => deposits).flat(),
    [depositHistoryQuery.data],
  )

  const lastDepositRef = useLastTableItemRef(depositHistoryQuery)

  const { getPendingBalance } = useZkLighterL1()
  const pendingBalanceQuery = useQuery({
    queryKey: ['pendingBalance', userAddress],
    queryFn: () => getPendingBalance(),
    select: (pendingBalance) => Number(pendingBalance),
    refetchInterval: REFRESH_INTERVAL * 2,
    enabled:
      !!deposits.length &&
      deposits.some((deposit) => deposit.status === DepositHistoryItemStatusEnum.Claimable),
  })

  const displayStatuses = useMemo(() => {
    let processedAmount = 0

    return deposits.reduce(
      (statuses: Record<string, DepositHistoryItemStatusEnum | 'refunded'>, deposit) => {
        if (deposit.status === DepositHistoryItemStatusEnum.Claimable) {
          processedAmount += Number(deposit.amount)

          if (processedAmount > (pendingBalanceQuery.data ?? Number.MAX_VALUE)) {
            statuses[deposit.id] = 'refunded'
          } else {
            statuses[deposit.id] = DepositHistoryItemStatusEnum.Claimable
          }
        } else {
          statuses[deposit.id] = deposit.status
        }
        return statuses
      },
      {},
    )
  }, [deposits, pendingBalanceQuery.data])

  if (depositHistoryQuery.isLoading) {
    return (
      <Table>
        <DepositsHeader />
        <TableBody>
          <TableLoader rows={4} columns={3} />
        </TableBody>
      </Table>
    )
  }

  if (!deposits.length) {
    return <NoOrdersText type="deposits" />
  }

  return (
    <Table>
      <DepositsHeader />
      <TableBody>
        {deposits.map((deposit, index) => (
          <TableRow
            key={deposit.id}
            ref={index === deposits.length - 1 ? lastDepositRef : undefined}
          >
            <TableCell>
              <p className="typography-body-1 text-white">
                {formatDate(deposit.timestamp, 'yyyy-MM-dd HH:mm:ss')}
              </p>
            </TableCell>
            <TableCell>
              <p className="typography-body-1 text-white">{formatUSD(deposit.amount)}</p>
            </TableCell>
            <TableCell>
              <p className="typography-body-1 text-left text-white">
                {displayStatuses[deposit.id] === DepositHistoryItemStatusEnum.Claimable ? (
                  <Clickable
                    color="blue"
                    onClick={() => {
                      useWithdrawStore.setState({ isModalOpen: true, withdrawType: 'l2ToL1' })
                    }}
                  >
                    {t('claimable')}
                  </Clickable>
                ) : (
                  DEPOSIT_STATUS_LABELS[
                    (displayStatuses[deposit.id] ??
                      DepositHistoryItemStatusEnum.Pending) as DepositHistoryItemStatusEnum
                  ]
                )}
              </p>
            </TableCell>
            <TableCell>
              {deposit.l1_tx_hash ? (
                <a
                  href={`https://etherscan.io/tx/${deposit.l1_tx_hash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="typography-body-1 float-left text-blue-500 hover:text-blue-400"
                >
                  {`${deposit.l1_tx_hash.slice(0, 6)}...${deposit.l1_tx_hash.slice(-4)}`}
                </a>
              ) : (
                <p className="typography-body-1 text-left text-gray-500">-</p>
              )}
            </TableCell>
          </TableRow>
        ))}
        {depositHistoryQuery.isFetchingNextPage && <TableLoader rows={1} columns={3} />}
      </TableBody>
    </Table>
  )
}
