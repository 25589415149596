import { useDynamicContext } from '@dynamic-labs/sdk-react-core'
import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { DepositBtnStatusStyle, useDepositStore } from 'js/pages/deposit/form'
import { useAccountExistence } from 'js/providers/hooks/useAccountExistence'
import {
  useUserAccount,
  useIsWhitelistedQuery,
  useAccountsQuery,
} from 'js/providers/hooks/useAccountQuery'
import { useLighterStore } from 'js/providers/lighterStore'
import { SubAccountType } from 'js/providers/types'
import { Button } from 'js/shared-components'
import Icon from 'js/shared-components/uikit/Icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'js/shared-components/uikit/Tooltip'
import cn from 'js/util/cn'
import { isMainnet } from 'js/util/util'

const ActionButtonBlocker = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  const userAccount = useUserAccount()
  const depositStore = useDepositStore()
  const accountExistence = useAccountExistence()
  const isWhitelistedQuery = useIsWhitelistedQuery()
  const accountsQuery = useAccountsQuery()
  const { setShowAuthFlow } = useDynamicContext()

  switch (accountExistence) {
    case 'NoWallet':
      return (
        <Button onClick={() => setShowAuthFlow(true)} className="flex w-full gap-2">
          <Icon className="size-5" icon="wallet" />
          {t('connect_wallet')}
        </Button>
      )

    case 'NotWhitelisted':
      return (
        <Button
          className="w-full"
          isLoading={isWhitelistedQuery.isLoading}
          onClick={() => useLighterStore.setState({ showOnboarding: true })}
        >
          {t('request_access')}
        </Button>
      )

    case 'KeysDontMatch':
      return (
        <Button
          className="w-full"
          onClick={() => useLighterStore.setState({ showOnboarding: true })}
        >
          {t('authenticate')}
        </Button>
      )

    case 'ShouldDeposit':
      if (!isMainnet()) {
        return (
          <Button
            className="w-full"
            isLoading={accountsQuery.isLoading}
            onClick={() => useLighterStore.setState({ showOnboarding: true })}
          >
            {t('create_account')}
          </Button>
        )
      }
      return (
        <Button className="w-full" onClick={() => depositStore.openModal()}>
          {t('create_account')}
        </Button>
      )
    case 'DepositInProgress':
      return (
        <Button
          className={cn('w-full animate-pulse gap-2', DepositBtnStatusStyle('pending'))}
          onClick={depositStore.openModal}
        >
          {depositStore.status === 'pending' && <Icon icon="spinner" className="size-5" />}
          {t('deposit_in_progress')}
        </Button>
      )
    case 'Creating':
      return (
        <Button className="w-full animate-pulse cursor-default gap-2 hover:bg-primary-blue-main">
          <Icon icon="spinner" className="size-5" />
          {t('creating_account')}
        </Button>
      )
    case 'Deciding':
      return <Button className="w-full" isLoading />
  }

  if (
    userAccount?.account_type === SubAccountType.lighterPublic ||
    userAccount?.account_type === SubAccountType.public
  ) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button disabled className="w-full">
            {t('unavailable')}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="typography-body-2 text-white">{t('public_pool_only_personal')}</p>
        </TooltipContent>
      </Tooltip>
    )
  }

  return children
}

export default ActionButtonBlocker
