import {
  OrderStatusEnum,
  OrderTimeInForceEnum,
  OrderTypeEnum,
  TradeTypeEnum,
} from 'zklighter-perps'
import { z } from 'zod'

import { LiquidationType } from 'js/types/user'
import { marginPercentageToFraction } from 'js/util/positions'

export const wsOrderbookItemSchema = z.object({
  price: z.string(),
  size: z.string(),
})

export const wsOrderbookSchema = z.object({
  asks: z.array(wsOrderbookItemSchema),
  bids: z.array(wsOrderbookItemSchema),
})

export const wsTradeSchema = z.object({
  ask_id: z.number(),
  bid_id: z.number(),
  block_height: z.number(),
  is_maker_ask: z.boolean(),
  ask_account_id: z.number(),
  market_id: z.number(),
  size: z.string(),
  price: z.string(),
  usd_amount: z.string(),
  bid_account_id: z.number(),
  timestamp: z.number(),
  trade_id: z.number(),
  tx_hash: z.string(),
  type: z.enum([TradeTypeEnum.Deleverage, TradeTypeEnum.Liquidation, TradeTypeEnum.Trade]),
})

export const wsOrderSchema = z.object({
  filled_base_amount: z.string(),
  filled_quote_amount: z.string(),
  initial_base_amount: z.string(),
  is_ask: z.boolean(),
  market_index: z.number(),
  nonce: z.number(),
  owner_account_index: z.number(),
  price: z.string(),
  remaining_base_amount: z.string(),
  reduce_only: z.boolean(),
  trigger_price: z.string(),
  order_expiry: z.number(),
  order_index: z.number(),
  status: z.enum([
    OrderStatusEnum.Open,
    OrderStatusEnum.Pending,
    OrderStatusEnum.Filled,
    OrderStatusEnum.Canceled,
    OrderStatusEnum.CanceledExpired,
    OrderStatusEnum.CanceledPostOnly,
    OrderStatusEnum.CanceledReduceOnly,
    OrderStatusEnum.CanceledPositionNotAllowed,
    OrderStatusEnum.CanceledMarginNotAllowed,
    OrderStatusEnum.CanceledTooMuchSlippage,
    OrderStatusEnum.CanceledNotEnoughLiquidity,
    OrderStatusEnum.CanceledSelfTrade,
    OrderStatusEnum.InProgress,
  ]),
  timestamp: z.number(),
  type: z.enum([
    OrderTypeEnum.Limit,
    OrderTypeEnum.Market,
    OrderTypeEnum.StopLoss,
    OrderTypeEnum.StopLossLimit,
    OrderTypeEnum.TakeProfit,
    OrderTypeEnum.TakeProfitLimit,
    OrderTypeEnum.Twap,
    OrderTypeEnum.TwapSub,
    OrderTypeEnum.Liquidation,
  ]),
  time_in_force: z.enum([
    OrderTimeInForceEnum.GoodTillTime,
    OrderTimeInForceEnum.ImmediateOrCancel,
    OrderTimeInForceEnum.PostOnly,
    OrderTimeInForceEnum.Unknown,
  ]),
})

export const wsPositionSchema = z.object({
  market_id: z.number(),
  initial_margin_fraction: z.string().transform((initialMarginFraction) =>
    // BE actually sends a margin percentage for now
    marginPercentageToFraction(Number(initialMarginFraction)),
  ),
  // sign is 1 for long and 0 for short
  sign: z.number(),
  position: z.string(),
  avg_entry_price: z.string(),
})

export const wsLiquidationSchema = z.object({
  account_index: z.number(),
  liquidation_id: z.number(),
  liquidation_type: z.union([z.literal(LiquidationType.Partial), z.literal(LiquidationType.Full)]),
})

export const wsShareSchema = z.object({
  public_pool_index: z.number(),
  shares_amount: z.number(),
  entry_usdc: z.string(),
})

export const wsFundingHistoryItemSchema = z.object({
  funding_id: z.number(),
  change: z.string(),
  market_id: z.number(),
  timestamp: z.number(),
  rate: z.string(),
  position_size: z.string(),
  position_side: z.string(),
})

export const wsPortfolioStatsSchema = z.object({
  collateral: z.string(),
})

export const wsMarketStatsSchema = z.object({
  daily_base_token_volume: z.number(),
  daily_price_change: z.number(),
  daily_price_high: z.number(),
  daily_price_low: z.number(),
  daily_quote_token_volume: z.number(),
  funding_rate: z.string(), // prev round funding_rate
  current_funding_rate: z.string(), // current rounds so far funding rate
  funding_timestamp: z.number(),
  index_price: z.string(),
  last_trade_price: z.string(),
  mark_price: z.string(),
  market_id: z.number(),
  open_interest: z.string(),
})

export const wsTxSchema = z.object({
  hash: z.string(),
  status: z.number(),
  event_info: z.string(),
})

export const wsPoolInfoSchema = z.object({
  operator_fee: z.string(),
  min_operator_share_rate: z.string(),
  total_shares: z.number(),
  operator_shares: z.number(),
  status: z.number(),
})

export const wsSubscribedOrderBookSchema = z.object({
  type: z.literal('subscribed/order_book'),
  channel: z.string(),
  order_book: wsOrderbookSchema,
})

export const wsUpdateOrderBookSchema = z.object({
  type: z.literal('update/order_book'),
  channel: z.string(),
  order_book: wsOrderbookSchema,
})

export const wsSubscribedTradeSchema = z.object({
  type: z.literal('subscribed/trade'),
  channel: z.string(),
  trades: z.array(wsTradeSchema),
})

export const wsUpdateTradeSchema = z.object({
  type: z.literal('update/trade'),
  channel: z.string(),
  trades: z.array(wsTradeSchema),
})

export const wsSubscribedMarketStatsSchema = z.object({
  type: z.literal('subscribed/market_stats'),
  channel: z.string(),
  market_stats: z.record(z.string(), wsMarketStatsSchema),
})

export const wsUpdateMarketStatsSchema = z.object({
  type: z.literal('update/market_stats'),
  channel: z.string(),
  market_stats: z.record(z.string(), wsMarketStatsSchema),
})

export const wsSubscribedAccountSchema = z.object({
  type: z.literal('subscribed/account_all'),
  channel: z.string(),
  trades: z.record(z.string(), z.array(wsTradeSchema)),
  positions: z.record(z.string(), wsPositionSchema),
  liquidations: z.array(wsLiquidationSchema),
  shares: z.array(wsShareSchema),
  funding_histories: z.record(z.string(), z.array(wsFundingHistoryItemSchema)),
  total_trades_count: z.number(),
  total_volume: z.number(),
})

export const wsUpdateAccountSchema = z.object({
  type: z.literal('update/account_all'),
  channel: z.string(),
  trades: z.record(z.string(), z.array(wsTradeSchema)),
  positions: z.record(z.string(), wsPositionSchema),
  liquidations: z.array(wsLiquidationSchema),
  shares: z.array(wsShareSchema),
  funding_histories: z.record(z.string(), z.array(wsFundingHistoryItemSchema)),
})

export const wsSubscribedAccountOrderSchema = z.object({
  type: z.literal('subscribed/account_orders'),
  channel: z.string(),
  account: z.number(),
  orders: z.record(z.string(), z.array(wsOrderSchema)),
})

export const wsUpdateAccountOrderSchema = z.object({
  type: z.literal('update/account_orders'),
  channel: z.string(),
  account: z.number(),
  orders: z.record(z.string(), z.array(wsOrderSchema)),
})

export const wsSubscribedPoolDataSchema = z.object({
  type: z.literal('subscribed/pool_data'),
  channel: z.string(),
  account: z.number(),
  orders: z.record(z.string(), z.array(wsOrderSchema)),
  trades: z.record(z.string(), z.array(wsTradeSchema)),
  positions: z.record(z.string(), wsPositionSchema),
  liquidations: z.array(wsLiquidationSchema),
  shares: z.array(wsShareSchema),
  funding_histories: z.record(z.string(), z.array(wsFundingHistoryItemSchema)),
})

export const wsUpdatePoolDataSchema = z.object({
  type: z.literal('update/pool_data'),
  channel: z.string(),
  orders: z.record(z.string(), z.array(wsOrderSchema)),
  trades: z.record(z.string(), z.array(wsTradeSchema)),
  positions: z.record(z.string(), wsPositionSchema),
  liquidations: z.array(wsLiquidationSchema),
  shares: z.array(wsShareSchema),
  funding_histories: z.record(z.string(), z.array(wsFundingHistoryItemSchema)),
})

export const wsSubscribedUserStatsSchema = z.object({
  type: z.literal('subscribed/user_stats'),
  channel: z.string(),
  stats: wsPortfolioStatsSchema,
})

export const wsUpdateUserStatsSchema = z.object({
  type: z.literal('update/user_stats'),
  channel: z.string(),
  stats: wsPortfolioStatsSchema,
})

const wsUnsubscribeSchema = z.object({
  type: z.literal('unsubscribed'),
  channel: z.string(),
})

const wsSubscribedExecutedTransactionSchema = z.object({
  type: z.literal('subscribed/account_tx'),
  channel: z.string(),
})

export const wsUpdateExecutedTransactionSchema = z.object({
  type: z.literal('update/account_tx'),
  channel: z.string(),
  txs: z.array(wsTxSchema),
})

export const wsSubscribedPoolInfoSchema = z.object({
  type: z.literal('subscribed/pool_info'),
  channel: z.string(),
  pool_info: wsPoolInfoSchema,
})

export const wsUpdatePoolInfoSchema = z.object({
  type: z.literal('update/pool_info'),
  channel: z.string(),
  pool_info: wsPoolInfoSchema,
})

export const wsSubscribedHeightSchema = z.object({
  type: z.literal('subscribed/height'),
  channel: z.string(),
  height: z.number(),
})

export const wsUpdateHeightSchema = z.object({
  type: z.literal('update/height'),
  channel: z.string(),
  height: z.number(),
})

const changeDesiredMarketSchema = z.object({
  type: z.literal('change_desired_market'),
  marketIndex: z.number(),
})

const connectedSchema = z.object({
  type: z.literal('connected'),
  session_id: z.string(),
})

const wsPingSchema = z.object({
  type: z.literal('ping'),
})

const wsPongSchema = z.object({
  type: z.literal('pong'),
})

const genericErrorSchema = z.object({
  type: z.undefined(),
  error: z.object({
    code: z.number(),
    message: z.string(),
  }),
})

export const wsMessageSchema = z.discriminatedUnion('type', [
  wsSubscribedOrderBookSchema,
  wsUpdateOrderBookSchema,
  wsSubscribedTradeSchema,
  wsUpdateTradeSchema,
  wsSubscribedMarketStatsSchema,
  wsUpdateMarketStatsSchema,
  wsSubscribedAccountSchema,
  wsUpdateAccountSchema,
  wsSubscribedAccountOrderSchema,
  wsUpdateAccountOrderSchema,
  wsSubscribedPoolDataSchema,
  wsUpdatePoolDataSchema,
  wsSubscribedUserStatsSchema,
  wsUpdateUserStatsSchema,
  wsUnsubscribeSchema,
  wsSubscribedExecutedTransactionSchema,
  wsUpdateExecutedTransactionSchema,
  wsSubscribedPoolInfoSchema,
  wsUpdatePoolInfoSchema,
  wsSubscribedHeightSchema,
  wsUpdateHeightSchema,
  changeDesiredMarketSchema,
  connectedSchema,
  wsPingSchema,
  wsPongSchema,
  genericErrorSchema,
])
