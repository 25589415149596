import { useOrderBookMetas } from 'js/providers/hooks/order-book-metas-hooks'
import { useMarketsStats } from 'js/providers/orderBookSlice/selectors'
import type { Position } from 'js/providers/types'
import TableCell from 'js/shared-components/uikit/table/TableCell'
import { formatMarketPrice } from 'js/util/formatting'

interface PositionMarkPriceCellProps {
  position: Position
}

const PositionMarkPriceCell = ({ position }: PositionMarkPriceCellProps) => {
  const market = useOrderBookMetas()[position.market_id]!
  const marketStats = useMarketsStats()[position.market_id]

  return (
    <TableCell className="w-28 max-w-28">
      {marketStats ? formatMarketPrice(marketStats.mark_price, market) : '-'}
    </TableCell>
  )
}

export default PositionMarkPriceCell
