/* eslint-disable no-empty */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import smoothscroll from 'smoothscroll-polyfill'
import {
  OrderStatusEnum,
  OrderTypeEnum,
  type Candlestick,
  type OrderBookDetail,
} from 'zklighter-perps'

import 'css/index.css'
import { colors, fonts } from 'css/css'
import {
  useUserAccountActiveOrders,
  useUserAccountPositions,
} from 'js/providers/accountsSlice/selectors'
import { useCurrentMarket } from 'js/providers/hooks/order-book-metas-hooks'
import { useCurrentMarketStats, useOrderBookTrades } from 'js/providers/orderBookSlice/selectors'
import { type Trade } from 'js/providers/types'
import { useAccountIndex } from 'js/providers/userSlice/selectors'
import { useWsSubStore } from 'js/providers/wsStore'
import { useCancelOrderMutation } from 'js/shared-components/tables/ActiveOrderTable/CancelOrderButton'
import { candlesticksApi, orderApi } from 'js/util/api/sdk'
import cn from 'js/util/cn'
import { formatMarketPrice } from 'js/util/formatting'
import { getPositionPnl, usePositionsWithLiqPrices } from 'js/util/positions'
import { wait } from 'js/util/util'

import {
  widget,
  type Bar,
  type ChartingLibraryWidgetOptions,
  type IChartingLibraryWidget,
  type ResolutionString,
  type Timezone,
  type IPositionLineAdapter,
  type IOrderLineAdapter,
} from '../../../../../../public/charting_library'

import { ChartLoader } from './ChartLoader'

type ExtendedBar = Bar & {
  lastTradeId: number
}

const getInitialInterval = (): ResolutionString => {
  const lastUsedTimeBasedResolution = localStorage.getItem(
    'tradingview.chart.lastUsedTimeBasedResolution',
  )

  if (
    typeof lastUsedTimeBasedResolution === 'string' &&
    resolutions.includes(lastUsedTimeBasedResolution)
  ) {
    return lastUsedTimeBasedResolution as ResolutionString
  }

  return '5' as ResolutionString
}

const candleToBar = (candle: Candlestick): ExtendedBar => ({
  time: candle.timestamp,
  open: candle.open,
  close: candle.close,
  high: candle.high,
  low: candle.low,
  volume: candle.volume1,
  lastTradeId: candle.last_trade_id,
})

const resolutions = ['1', '5', '15', '60', '240', '1D'] as ResolutionString[]

const tvResToObRes = (res: ResolutionString) => {
  switch (res) {
    case '1':
      return '1m'
    case '5':
      return '5m'
    case '15':
      return '15m'
    case '60':
      return '1h'
    case '240':
      return '4h'
    case '1D':
      return '1d'
    default:
      return '' as never
  }
}

const tvResToResInMiliseconds = (res: ResolutionString) => {
  switch (res) {
    case '1':
      return 60 * 1000
    case '5':
      return 5 * 60 * 1000
    case '15':
      return 15 * 60 * 1000
    case '60':
      return 60 * 60 * 1000
    case '240':
      return 4 * 60 * 60 * 1000
    case '1D':
      return 24 * 60 * 60 * 1000
    default:
      return 0 as never
  }
}

// Polyfill invocation
smoothscroll.polyfill()

const lastBarsCache = new Map<number, ExtendedBar>()

export const TradingViewChart = () => {
  const isChangingSymbolRef = useRef(false)
  const resetRef = useRef<(() => void) | null>(null)
  const trades = useOrderBookTrades()
  const [isChartLoading, setIsChartLoading] = useState(true)
  const positionLineRef = useRef<IPositionLineAdapter | null>(null)
  const liquidationLineRef = useRef<IPositionLineAdapter | null>(null)
  const orderLinesRef = useRef<IOrderLineAdapter[] | null>(null)
  const currentMarket = useCurrentMarket()
  const currentMarketRef = useRef<OrderBookDetail>(currentMarket)
  const currentMarketStats = useCurrentMarketStats()
  const markPrice = Number(currentMarketStats?.mark_price ?? '0')
  const accountIndex = useAccountIndex()
  const positions = useUserAccountPositions()
  const position = usePositionsWithLiqPrices(positions, accountIndex ?? -1)[currentMarket.market_id]
  const activeOrders = useUserAccountActiveOrders()[currentMarket.market_id]
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const widgetRef = useRef<IChartingLibraryWidget | null>(null)
  const newTradesCb = useRef<((trades: Trade[] | null) => void) | null>(null)
  const ws = useWsSubStore((state) => state.ws)
  const cancelOrderMutation = useCancelOrderMutation()
  const { t } = useTranslation()

  const accountIndexRef = useRef(accountIndex)

  const resetChart = useCallback(() => {
    if (!widgetRef.current) {
      return
    }

    resetRef.current?.()
    widgetRef.current.activeChart().resetData()
    widgetRef.current.activeChart().getPanes()[0]?.getRightPriceScales()[0]?.setAutoScale(true)
    widgetRef.current.activeChart().setSymbol(currentMarketRef.current.symbol)
    isChangingSymbolRef.current = true
  }, [])

  useEffect(() => {
    accountIndexRef.current = accountIndex
  }, [accountIndex])

  useEffect(() => {
    currentMarketRef.current = currentMarket
    resetChart()
  }, [currentMarket, resetChart])

  useEffect(() => {
    widgetRef.current?.activeChart().dataReady(() => {
      if (isChangingSymbolRef.current) {
        return
      }
      // these try/catch are needed because it will crash when removing a position for absolutely no reason :)
      try {
        positionLineRef.current?.remove()
      } catch {}
      try {
        liquidationLineRef.current?.remove()
      } catch {}

      if (!position) {
        return
      }

      if (position.liquidation_price) {
        liquidationLineRef.current = widgetRef
          .current!.activeChart()
          .createPositionLine()
          .setLineLength(60, 'percentage')
          .setLineStyle(2)
          .setBodyBackgroundColor(colors.black)
          .setBodyTextColor(colors.whiteOpaque)
          .setText(t('liq_price'))
          .setQuantity('')
          .setPrice(Number(position.liquidation_price))
      }

      const positionPnl = getPositionPnl(position, markPrice)
      const positionLineColor = positionPnl < 0 ? colors.redMain : colors.greenMain

      positionLineRef.current = widgetRef
        .current!.activeChart()
        .createPositionLine()
        .setLineLength(80, 'percentage')
        .setLineStyle(2)
        .setLineColor(positionLineColor)
        .setBodyBorderColor(positionLineColor)
        .setBodyBackgroundColor(colors.whiteOpaque)
        .setQuantityBackgroundColor(colors.black)
        .setPrice(Number(position.avg_entry_price))
        .setText(`${t('pnl')} ${formatMarketPrice(positionPnl, currentMarket)}`)
        .setQuantity(position.position)
    })
  }, [position, markPrice, currentMarket, activeOrders, t])

  const cancelOrderMutate = cancelOrderMutation.mutate
  useEffect(() => {
    widgetRef.current?.activeChart().dataReady(() => {
      if (isChangingSymbolRef.current) {
        return
      }
      // these try/catch are needed because it will crash when removing a position for absolutely no reason :)
      try {
        orderLinesRef.current?.forEach((line) => line.remove())
        orderLinesRef.current = []
      } catch {}

      if (!activeOrders) {
        return
      }

      activeOrders
        .filter(
          (order) =>
            order.type === OrderTypeEnum.StopLoss ||
            order.type === OrderTypeEnum.StopLossLimit ||
            order.type === OrderTypeEnum.TakeProfit ||
            order.type === OrderTypeEnum.TakeProfitLimit ||
            order.type === OrderTypeEnum.Limit,
        )
        .forEach((order) => {
          const orderLine = widgetRef
            .current!.activeChart()
            .createOrderLine()
            .setLineLength(40, 'percentage')
            .setLineStyle(2)
            .setBodyBackgroundColor(colors.black)
            .setBodyTextColor(colors.whiteOpaque)
            .setCancelButtonBackgroundColor(colors.black)
            .setCancelButtonIconColor(colors.whiteOpaque)
            .setCancelTooltip(t('click_to_cancel'))
            .setQuantity(order.remaining_base_amount)
            .onCancel(() => {
              orderLine.setCancellable(false)
              cancelOrderMutate(order)
            })

          if (order.type === OrderTypeEnum.Limit) {
            const formattedLimitPrice = formatMarketPrice(order.price, currentMarket)

            return orderLinesRef.current?.push(
              orderLine
                .setText(`${t('limit')} ${formattedLimitPrice}`)
                .setPrice(Number(order.price)),
            )
          }

          const isSL =
            order.type === OrderTypeEnum.StopLoss || order.type === OrderTypeEnum.StopLossLimit

          const sign = order.is_ask !== isSL ? '<' : '>'
          const formattedTriggerPrice = formatMarketPrice(order.trigger_price, currentMarket)
          const sltpLabel = isSL ? t('sl') : t('tp')

          orderLinesRef.current?.push(
            orderLine
              .setText(`${sltpLabel} ${sign} ${formattedTriggerPrice}`)
              .setPrice(Number(order.trigger_price)),
          )
        })
    })
  }, [activeOrders, currentMarket, cancelOrderMutate, t])

  useEffect(() => {
    newTradesCb.current?.(trades)
  }, [trades])

  useEffect(() => {
    if (!widgetRef.current) {
      return
    }
    if (!accountIndex) {
      widgetRef.current.activeChart()?.clearMarks()
      return
    }
    widgetRef.current.activeChart()?.refreshMarks()
  }, [accountIndex])

  useEffect(() => {
    const datafeed: ChartingLibraryWidgetOptions['datafeed'] = {
      onReady(callback) {
        setTimeout(() => callback({ supported_resolutions: resolutions, supports_marks: true }), 0)
      },
      resolveSymbol(name, resolve) {
        setTimeout(
          () =>
            resolve({
              ticker: name ?? '',
              listed_exchange: '',
              has_intraday: true,
              format: 'price',
              name: name ?? '',
              description: currentMarketRef.current.symbol,
              type: 'crypto',
              session: '24x7',
              timezone: 'Etc/UTC',
              exchange: '',
              minmov: 1,
              pricescale: 10 ** currentMarketRef.current.price_decimals,
              visible_plots_set: 'ohlcv',
              supported_resolutions: resolutions,
              volume_precision: 2,
              data_status: 'streaming',
            }),
          0,
        )
      },
      unsubscribeBars: () => {},
      subscribeBars(
        _symbolnfo,
        resolution,
        onRealtimeCallback,
        _listenerGuid,
        onResetCacheNeededCallback,
      ) {
        newTradesCb.current = (trades) => {
          const marketId = currentMarketRef.current.market_id

          trades
            ?.slice()
            .reverse()
            .forEach((trade) => {
              const lastBar = lastBarsCache.get(marketId)

              if (
                !lastBar ||
                trade.market_id !== marketId ||
                lastBar.lastTradeId >= trade.trade_id
              ) {
                return
              }

              const tradePrice = Number(trade.price)
              const tradeSize = Number(trade.size) * tradePrice

              if (trade.timestamp >= lastBar.time + tvResToResInMiliseconds(resolution)) {
                const newLastBar: ExtendedBar = {
                  high: tradePrice,
                  low: tradePrice,
                  time: lastBar.time + tvResToResInMiliseconds(resolution),
                  open: lastBar.close,
                  close: tradePrice,
                  volume: tradeSize,
                  lastTradeId: trade.trade_id,
                }

                lastBarsCache.set(marketId, newLastBar)
                onRealtimeCallback(newLastBar)
                return
              }

              lastBar.volume = lastBar.volume! + tradeSize
              lastBar.high = Math.max(lastBar.high, tradePrice)
              lastBar.low = Math.min(lastBar.low, tradePrice)
              lastBar.close = tradePrice
              lastBar.lastTradeId = trade.trade_id
              lastBarsCache.set(marketId, lastBar)

              onRealtimeCallback(lastBar)
            })
        }
        resetRef.current = onResetCacheNeededCallback
      },

      searchSymbols() {},
      getBars: async (_symbol, res, period, resolve, _err) => {
        const marketId = currentMarketRef.current.market_id

        const returnBars = async ({
          period,
        }: {
          period: { to: number; from: number; countBack: number }
        }) => {
          try {
            const { candlesticks } = await candlesticksApi.candlesticks({
              market_id: marketId,
              resolution: tvResToObRes(res),
              // TradingView period uses seconds instead of miliseconds
              start_timestamp: period.from * 1000,
              end_timestamp: period.to * 1000,
              count_back: period.countBack,
            })

            return candlesticks
              .filter((candlestick) => candlestick.timestamp < Date.now())
              .map(candleToBar)
          } catch {
            return null
          }
        }
        try {
          let attempts = 5
          let bars = await returnBars({ period })

          while (bars === null && attempts > 0) {
            await wait(5000)
            attempts--
            bars = await returnBars({ period })
          }

          if (bars === null) {
            bars = []
          }

          if (period.firstDataRequest) {
            lastBarsCache.set(marketId, bars.at(-1)!)
          }

          resolve(bars, { noData: bars.length === 0 })
        } catch (error) {
          _err(error as string)
        }
      },
      getMarks: async (_symbolInfo, from, to, onDataCallback) => {
        const returnMarks = async ({
          period,
        }: {
          period: { to: number; from: number; countBack: number }
        }) => {
          if (!accountIndexRef.current) {
            return []
          }

          try {
            const inactiveOrdersQuery = await orderApi.accountInactiveOrders({
              limit: 100,
              account_index: accountIndexRef.current,
              market_id: currentMarketRef.current.market_id,
              ask_filter: -1,
              between_timestamps: `${period.from}-${period.to}`,
            })
            return inactiveOrdersQuery.orders
          } catch {
            return []
          }
        }
        try {
          const orders = await returnMarks({ period: { to, from, countBack: 100 } })

          const marks = orders
            .sort((a, b) => b.timestamp - a.timestamp)
            .filter(
              (order) =>
                order.timestamp >= from &&
                order.timestamp <= to &&
                order.status === OrderStatusEnum.Filled &&
                order.market_index === currentMarketRef.current.market_id,
            )
            .map((order) => ({
              id: order.order_index,
              time: order.timestamp,
              color: order.is_ask
                ? { background: colors.redMain, border: 'transparent' }
                : { background: colors.greenMain, border: 'transparent' },
              text:
                (order.is_ask ? 'Short' : 'Long') +
                ' ' +
                order.filled_base_amount +
                ' ' +
                currentMarketRef.current.symbol +
                ' at ' +
                '$' +
                order.price,
              label: order.is_ask ? 'S' : 'B',
              labelFontColor: 'white',
              labelBackgroundColor: order.is_ask ? 'red' : 'green',
              minSize: 16,
            }))
          onDataCallback(marks)
        } catch (error) {
          console.log(error as string)
        }
      },
    }

    const widgetOpts: ChartingLibraryWidgetOptions = {
      container: chartContainerRef.current!,
      symbol: currentMarketRef.current.symbol,
      locale: 'en',
      load_last_chart: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone,
      study_count_limit: 5,
      disabled_features: [
        'symbol_search_hot_key',
        'study_templates',
        'save_chart_properties_to_local_storage',
        'chart_property_page_trading',
        'hide_last_na_study_output',
        'go_to_date',
        'header_compare',
        'header_symbol_search',
        'timeframes_toolbar',
        'control_bar',
        'display_market_status',
        'symbol_info',
      ],
      debug: false,
      enabled_features: [
        'hide_left_toolbar_by_default',
        'lock_visible_time_range_on_resize',
        'shift_visible_range_on_new_bar',
      ],
      interval: getInitialInterval(),
      custom_font_family: fonts.satoshi,
      datafeed,
      library_path: '/charting_library/',
      custom_css_url: '/tradingview.css',
      fullscreen: false,
      autosize: true,
      studies_overrides: {},
      theme: 'dark',
      toolbar_bg: colors.black,
      overrides: {
        'paneProperties.background': colors.black,
        'paneProperties.backgroundType': 'solid',
        'paneProperties.legendProperties.showStudyArguments': false,
        'paneProperties.legendProperties.showStudyValues': true,
        'mainSeriesProperties.statusViewStyle.showInterval': false,
        'mainSeriesProperties.candleStyle.upColor': colors.greenMain,
        'mainSeriesProperties.candleStyle.downColor': colors.redMain,
        'paneProperties.legendProperties.showSeriesTitle': true,
        'scalesProperties.scaleSeriesOnly': true,
        'scalesProperties.showStudyLastValue': false,
        // 'mainSeriesProperties.statusViewStyle.showSymbol': false,
        'symbolWatermarkProperties.visibility': false,
      },
    }

    const tvWidget = new widget(widgetOpts)

    tvWidget.onChartReady(() => {
      tvWidget
        .activeChart()
        .onIntervalChanged()
        .subscribe(null, () => resetChart())

      tvWidget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, () => {
          isChangingSymbolRef.current = false
        })
      setIsChartLoading(false)

      const volumeStudyId = tvWidget.activeChart().getAllStudies()[0]?.id

      if (volumeStudyId) {
        tvWidget.activeChart().getStudyById(volumeStudyId).unmergeDown()
      }

      widgetRef.current = tvWidget
    })

    return () => tvWidget.remove()
  }, [resetChart])

  useEffect(() => {
    if (!ws) {
      resetChart()
    }
  }, [ws, resetChart])

  return (
    <div className="relative size-full">
      {isChartLoading && <ChartLoader />}
      <div className={cn('size-full', { hidden: isChartLoading })} ref={chartContainerRef} />
    </div>
  )
}
